import {
    CommissionDischargeSchema,
    CommissionGoodsSchema,
    CommissionLoadingSchema,
    CommissionSchema,
} from 'modules/commissions/types';

import {
    GoodsCreateSchema,
    GoodsDeleteSchema,
    GoodsUpdateSchema,
    TransformedGoodsSchema,
    transformedGoodsSchema,
} from './types';

// GOODS

export function transformGoods(
    goods: CommissionGoodsSchema[],
    oldData: CommissionSchema,
    newLoadings?: CommissionLoadingSchema[],
    newDischarges?: CommissionDischargeSchema[],
): TransformedGoodsSchema {
    const { added, changed, removed } = getGoodsChanges(oldData.commissionitem, goods);

    function modifyAddedGoods(items: CommissionGoodsSchema[]): GoodsCreateSchema[] {
        return items.map(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ({
                loadingIdx,
                dischargeIdx, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                commissionLoading_id, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                commissionDischarge_id,
                weightBrutto, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                weight,
                ...item
            }) => {
                const template: GoodsCreateSchema = {
                    ...item,
                    weightBrutto: !weightBrutto ? null : Number(weightBrutto),
                    weight: weight ? String(weight) : null,
                };

                if (newLoadings) {
                    const loadingId = newLoadings[Number(loadingIdx) - 1]?.commissionLoading_id; // if loading has an ID, use ID as reference to loading
                    if (loadingId) {
                        template.commissionLoading_id = loadingId;
                        // if loading is newly created, use an index as reference to loading
                    } else if (loadingIdx) {
                        // we need to use index only from created loadings, not from all loadings
                        const stayedLoadingsLength = newLoadings.filter((item) => item.commissionLoading_id).length;
                        template.loadingIdx = Number(loadingIdx) - stayedLoadingsLength - 1;
                    }
                }

                if (newDischarges) {
                    const dischargeId = newDischarges[Number(dischargeIdx) - 1]?.commissionDischarge_id;

                    // if discharge has an ID, use ID as reference to loading
                    if (dischargeId) {
                        template.commissionDischarge_id = dischargeId;
                        // if discharge is newly created, use an index as reference to loading
                    } else if (dischargeIdx) {
                        // we need to use index only from created discharges, not from all discharges
                        const stayedDischargesLength = newDischarges.filter(
                            (item) => item.commissionDischarge_id,
                        ).length;
                        template.dischargeIdx = Number(dischargeIdx) - stayedDischargesLength - 1;
                    }
                }
                return template;
            },
        ) as GoodsCreateSchema[];
    }

    function modifyUpdatedGoods(items: CommissionGoodsSchema[]): GoodsUpdateSchema[] {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return items.map(
            ({
                loadingIdx,
                dischargeIdx, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                commissionLoading_id, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                commissionDischarge_id,
                weightBrutto, // eslint-disable-next-line @typescript-eslint/no-unused-vars
                weight,
                ...item
            }) => {
                const template: GoodsUpdateSchema = {
                    ...item,
                    commissionItem_id: item.commissionItem_id || 0,
                    weightBrutto: !weightBrutto ? null : Number(weightBrutto),
                    weight: weight ? String(weight) : null,
                };

                if (newLoadings) {
                    const loadingId = newLoadings[Number(loadingIdx) - 1]?.commissionLoading_id; // if loading has an ID, use ID as reference to loading
                    if (loadingId) {
                        template.commissionLoading_id = loadingId;
                        // if loading is newly created, use an index as reference to loading
                    } else if (loadingIdx) {
                        // we need to use index only from created loadings, not from all loadings
                        const stayedLoadingsLength = newLoadings.filter((item) => item.commissionLoading_id).length;
                        template.loadingIdx = Number(loadingIdx) - stayedLoadingsLength - 1;
                    }
                }

                if (newDischarges) {
                    const dischargeId = newDischarges[Number(dischargeIdx) - 1]?.commissionDischarge_id;

                    // if discharge has an ID, use ID as reference to loading
                    if (dischargeId) {
                        template.commissionDischarge_id = dischargeId;
                        // if discharge is newly created, use an index as reference to loading
                    } else if (dischargeIdx) {
                        // we need to use index only from created discharges, not from all discharges
                        const stayedDischargesLength = newDischarges.filter(
                            (item) => item.commissionDischarge_id,
                        ).length;
                        template.dischargeIdx = Number(dischargeIdx) - stayedDischargesLength - 1;
                    }
                }
                return template;
            },
        ) as GoodsUpdateSchema[];
    }

    function modifyRemovedGoods(items: CommissionGoodsSchema[]): GoodsDeleteSchema[] {
        return items.map(({ commissionItem_id }) => ({
            commissionItem_id,
        })) as GoodsDeleteSchema[];
    }

    const template: TransformedGoodsSchema = {};
    const modificatedAddedGoods = added ? modifyAddedGoods(added) : null;
    const modificatedUpdatedGoods = changed ? modifyUpdatedGoods(changed) : null;
    const modificatedRemovedGoods = removed ? modifyRemovedGoods(removed) : null;

    // add only if they are not empty
    if (modificatedAddedGoods?.length) {
        template.toCreate = modificatedAddedGoods;
    }
    if (modificatedUpdatedGoods?.length) {
        template.toUpdate = modificatedUpdatedGoods;
    }
    if (modificatedRemovedGoods?.length) {
        template.toDelete = modificatedRemovedGoods;
    }

    // validate the data & remove redundant properties
    return transformedGoodsSchema.parse(template);
}

const checkingGoodsValues: (keyof CommissionGoodsSchema)[] = [
    'name',
    'loadingIdx',
    'dischargeIdx',
    'quantity',
    'weight',
    'weightBrutto',
    'packaging',
    'loadingMeters',
    // 'width',
    // 'height',
    // 'length',
    'size',
];

interface IGetGoodsChanges {
    added?: CommissionGoodsSchema[];
    changed?: CommissionGoodsSchema[];
    removed?: CommissionGoodsSchema[];
}

function getGoodsChanges(oldGoods: CommissionGoodsSchema[], newGoods: CommissionGoodsSchema[]): IGetGoodsChanges {
    // "newGoods" does not mean dispatcher "toCreate", but all goods (commission items) which are in actual/current form.
    // unchanged goods (commission items) do not need to be sent to the server.

    // goods which are new, to create
    const added = newGoods.filter((item) => !item.commissionItem_id);
    // dispatchers which are updated or unchanged
    const others = newGoods.filter((item) => item.commissionItem_id);
    const othersIDs = others.map((item) => item.commissionItem_id) as number[];
    // old goods which not exist in others
    const removed = oldGoods.filter((item) =>
        item.commissionItem_id ? !othersIDs.includes(item?.commissionItem_id) : false,
    );
    // old goods which still exist
    const stayedOld = oldGoods.filter((item) =>
        item.commissionItem_id ? othersIDs.includes(item?.commissionItem_id) : false,
    );
    // new goods which still exist
    const stayedNew = newGoods.filter((item) =>
        item.commissionItem_id ? othersIDs.includes(item?.commissionItem_id) : false,
    );
    // updated goods - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingGoodsValues.some((value) => item[value] !== stayedOld[index][value]),
    );

    const template: IGetGoodsChanges = {};

    // add only if they are not empty
    if (added.length) {
        template.added = added;
    }
    if (changed.length) {
        template.changed = changed;
    }
    if (removed.length) {
        template.removed = removed;
    }
    return template;
}
