import { CommissionGoodsSchema } from 'modules/commissions';

type ItemToCalculate = Partial<Pick<CommissionGoodsSchema, 'loadingMeters' | 'weightBrutto'>>;

export const calculateCommissionItemsProperties = (commissionItems: ItemToCalculate[]) => {
    if (!commissionItems || commissionItems.length === 0) return { totalLoadingMeters: 0, totalWeight: 0 };
    const totalLoadingMeters = commissionItems.reduce(
        (acc, item) => acc + (item.loadingMeters ? Number(item.loadingMeters) : 0),
        0,
    );
    const totalWeight =
        commissionItems.reduce((acc, item) => acc + (item.weightBrutto ? Number(item.weightBrutto) : 0), 0) / 1000;

    return { totalLoadingMeters, totalWeight };
};
