import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { config } from 'config';
import { selectAuth } from 'core/auth/services/selectors';
import { toast } from 'modules/toast';
import { downloadURI } from 'utils';

import { AllRowsSelected, RowSelection } from '../types';
import { getSelectionParams } from '../utils/getSelectionParams';

export const useTableExport = ({
    rowSelection,
    allRowsSelected,
    endpoint,
    method,
    name,
}: {
    queryParams: Record<string, string | number>;
    rowSelection: RowSelection;
    allRowsSelected: AllRowsSelected;
    endpoint: keyof (typeof config)['api']['endpoints'];
    method: 'GET' | 'POST';
    name: string;
}) => {
    const { t } = useTranslation();
    const auth = useSelector(selectAuth);
    const [isLoading, setIsLoading] = useState(false);
    const selectionParams = useMemo(
        () => getSelectionParams(rowSelection, allRowsSelected),
        [rowSelection, allRowsSelected],
    );
    // Enable export functionality only if there are selected values (either some or all).
    const isEnabled = selectionParams !== undefined;

    const handleExport = async () => {
        if (!isEnabled) {
            return;
        }

        const urlParams = `?items=${selectionParams.selected}`;

        const body =
            method === 'POST' ? JSON.stringify(selectionParams.selected?.split(',').map((item) => Number(item))) : null;
        const url = `${config.api.url}/${config.api.endpoints[endpoint]}${method === 'GET' ? urlParams : ''}`;

        try {
            const response = await fetch(url, {
                method,
                credentials: 'include',
                body,
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': auth.token || '',
                },
            });
            if (response.ok) {
                const blob = await response.blob();
                const file = window.URL.createObjectURL(blob);

                downloadURI(file, name);
                toast.success(t('table.actions.export.message.success'));
            } else {
                toast.error(
                    response.status === 401
                        ? t('table.actions.export.message.authError')
                        : t('table.actions.export.message.notAllowedError'),
                );
            }
        } catch (e) {
            toast.error(t('table.actions.export.message.networkError'));
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        handleExport,
        isLoading,
        isEnabled,
    };
};
