import { CommissionDischargeSchema } from 'modules/commissions/types';

import {
    DischargeCreateSchema,
    DischargeDeleteSchema,
    DischargeUpdateSchema,
    TransformedDischargesSchema,
    transformedDischargesSchema,
} from './types';

export function transformDischarges(
    discharges: CommissionDischargeSchema[],
    oldData: CommissionDischargeSchema[],
): TransformedDischargesSchema {
    const { added, changed, removed } = getDischargesChanges(oldData, discharges);

    function modifyAddedDischarges(items: CommissionDischargeSchema[]): DischargeCreateSchema[] {
        return items.map(({ date, dateTo, ...item }) => ({
            ...item,
            date: Number(date),
            dateTo: dateTo ? Number(dateTo) : null,
        })) as DischargeCreateSchema[];
    }
    function modifyUpdatedDischarges(items: CommissionDischargeSchema[]): DischargeUpdateSchema[] {
        return items.map(({ date, dateTo, ...item }) => ({
            ...item,
            date: Number(date),
            dateTo: dateTo ? Number(dateTo) : null,
        })) as DischargeUpdateSchema[];
    }
    function modifyRemovedDischarges(items: CommissionDischargeSchema[]): DischargeDeleteSchema[] {
        return items.map(({ commissionDischarge_id }) => ({ commissionDischarge_id })) as DischargeDeleteSchema[];
    }

    const template: TransformedDischargesSchema = {};
    const modificatedAddedDischarges = added ? modifyAddedDischarges(added) : null;
    const modificatedUpdatedDischarges = changed ? modifyUpdatedDischarges(changed) : null;
    const modificatedRemovedDischarges = removed ? modifyRemovedDischarges(removed) : null;

    // add only if they are not empty
    if (modificatedAddedDischarges?.length) {
        template.toCreate = modificatedAddedDischarges;
    }
    if (modificatedUpdatedDischarges?.length) {
        template.toUpdate = modificatedUpdatedDischarges;
    }
    if (modificatedRemovedDischarges?.length) {
        template.toDelete = modificatedRemovedDischarges;
    }

    // validate the data & remove redundant properties
    return transformedDischargesSchema.parse(template);
}

const checkingDischargesValues: (keyof CommissionDischargeSchema)[] = ['date', 'dateTo', 'time', 'neutralization', 'location_id', 'number'];
interface IGetDischargesChanges {
    added?: CommissionDischargeSchema[];
    changed?: CommissionDischargeSchema[];
    removed?: CommissionDischargeSchema[];
}

function getDischargesChanges(
    oldDischarges: CommissionDischargeSchema[],
    newDischarges: CommissionDischargeSchema[],
): IGetDischargesChanges {
    // "newDischarges" does not mean dispatcher "toCreate", but all discharges (commission items) which are in actual/current form.
    // unchanged discharges (commission items) do not need to be sent to the server.
    // discharges which are new, to create;
    const added = newDischarges.filter((item) => !item.commissionDischarge_id);
    // dispatchers which are updated or unchanged
    const others = newDischarges.filter((item) => item.commissionDischarge_id);
    const othersIDs = others.map((item) => item.commissionDischarge_id) as number[];
    // old discharges which not exist in others
    const removed = oldDischarges.filter((item) =>
        item.commissionDischarge_id ? !othersIDs.includes(item?.commissionDischarge_id) : false,
    );
    // old discharges which still exist
    const stayedOld = oldDischarges.filter((item) =>
        item.commissionDischarge_id ? othersIDs.includes(item?.commissionDischarge_id) : false,
    );
    // new discharges which still exist
    const stayedNew = newDischarges.filter((item) =>
        item.commissionDischarge_id ? othersIDs.includes(item?.commissionDischarge_id) : false,
    );
    // updated discharges - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingDischargesValues.some((value) => item[value] !== stayedOld[index][value]),
    );

    const template: IGetDischargesChanges = {};

    // add only if they are not empty
    if (added.length) {
        template.added = added;
    }
    if (changed.length) {
        template.changed = changed;
    }
    if (removed.length) {
        template.removed = removed;
    }

    return template;
}
