import { T } from 'core/translation/types';
import { carrierPlaceSchema, dispatcherVehicleFeature } from 'modules/carriers';
import { z } from 'zod';


export const dispatcherVehicleSchema = () =>
    z.object({
        dispatcherVehicle_id: z.number().nullish(),
        vehicleType_id: z.number(),
        maxHeight: z.number(),
        maxWidth: z.number(),
        maxLength: z.number(),
        maxWeight: z.number(),
        dispatchervehiclefeature: z.array(dispatcherVehicleFeature()),
        // other
        dispatcher_id: z.number().nullish(),
    });

export const onboardingBasicInfoSchema = (t: T) =>
    z.object({
        // personal info
        firstName: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        lastName: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        phone: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .regex(new RegExp(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/), t('form.errors.phoneFormat')).nullable()
            .or(z.literal('')),

        email: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.emailFormat'),
            })
            .min(1, { message: t('form.errors.required') })
            .email({
                message: t('auth.email.error.validEmail'),
            }),
        // auth info
        password: z.string({
            required_error: t('form.errors.required'),
            invalid_type_error: t('form.errors.emailFormat'),
        }),
        confirmPassword: z.string({
            required_error: t('form.errors.required'),
            invalid_type_error: t('form.errors.emailFormat'),
        }),
        // offers notifications
        notificationEmail: z.boolean(),
        notificationWhatsapp: z.boolean(),
        // other
        carrier_id: z.number(),
        dispatcher_id: z.number().nullish(),
        language_id: z.number({
            required_error: t('form.errors.required'),
        }),
    });

export const onboardingVehiclesInfoSchema = () =>
    z.object({
        // details of the vehicle fleet
        deliveryVehicle: z.boolean(),
        soloVehicle: z.boolean(),
        semiTrailerVehicle: z.boolean(),
        combinationVehicle: z.boolean(),
        // detail of vehicles
        dispatcherVehicles: z.array(dispatcherVehicleSchema()),
    });

export const onboardingLocationsInfoSchema = (t: T) => z.object({ places: z.array(carrierPlaceSchema(t)) });

export const onboardingSchema = (t: T) =>
    onboardingBasicInfoSchema(t).merge(onboardingVehiclesInfoSchema()).merge(onboardingLocationsInfoSchema(t));

export const getOneDispatcherResponseSchema = (t: T) =>
    z.object({
        carrier_id: z.number(),
        deleted: z.boolean(),
        dispatcher_id: z.number(),
        dispatchervehicle: z.array(dispatcherVehicleSchema().extend({ dispatcher_id: z.number() })),
        email: z.string(),
        emailSent: z.boolean().nullable(),
        firstName: z.string(),
        language_id: z.number(),
        lastName: z.string(),
        lastRequestTimeSent: z.number().nullable(),
        lastRequest_id: z.number().nullable(),
        notificationEmail: z.boolean(),
        notificationWhatsapp: z.boolean(),
        onboardingState: z.enum(['unregistered', 'registered', 'pending']),
        password: z.string().nullable(),
        phone: z.string().nullable(),
        place: z.array(carrierPlaceSchema(t)),
        selected: z.boolean(),
        token: z.string(),
    });

export type DecodedQueryParams = {
    role: 'onboarding';
    carrier_id: number;
    dispatcher_id?: number;
    iat: number;
    exp: number;
};

export type DispatcherVehicleSchema = z.infer<ReturnType<typeof dispatcherVehicleSchema>>;
export type OnboardingBasicInfoSchema = z.infer<ReturnType<typeof onboardingBasicInfoSchema>>;
export type OnboardingVehiclesInfoSchema = z.infer<ReturnType<typeof onboardingVehiclesInfoSchema>>;
export type OnboardingLocationsInfoSchema = z.infer<ReturnType<typeof onboardingLocationsInfoSchema>>;
export type OnboardingSchema = z.infer<ReturnType<typeof onboardingSchema>>;
export type GetOneDispatcherResponseSchema = z.infer<ReturnType<typeof getOneDispatcherResponseSchema>>;
