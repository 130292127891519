import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOfferStatusValue, DispatcherOfferStatusValue } from 'modules/offers/types';
import { RadioGroup, Typography } from 'modules/ui';

import { OfferStatusAdmin } from '../../OfferStatusAdmin';
import { OfferStatusDispatcher } from '../../OfferStatusDispatcher';

import styles from './FilterDropdown.module.scss';

interface IFilterDropdownProps<T> {
    filterValue: AdminOfferStatusValue | DispatcherOfferStatusValue | undefined;
    role: 'admin' | 'dispatcher';
    setFilterValue: (value?: T) => void;
    setShowFilterDropdown: (value: boolean) => void;
}

export const adminFilterOptions = [
    {
        label: (
            <OfferStatusAdmin
                value={AdminOfferStatusValue.Opened}
                onChange={() => {
                    return;
                }}
            />
        ),
        value: AdminOfferStatusValue.Opened,
    },
    {
        label: (
            <OfferStatusAdmin
                value={AdminOfferStatusValue.Closed}
                onChange={() => {
                    return;
                }}
            />
        ),
        value: AdminOfferStatusValue.Closed,
    },
];

export const dispatcherFilterOptions = [
    {
        label: (
            <OfferStatusDispatcher
                value={DispatcherOfferStatusValue.New}
                onChange={() => {
                    return;
                }}
            />
        ),
        value: DispatcherOfferStatusValue.New,
    },
    {
        label: (
            <OfferStatusDispatcher
                value={DispatcherOfferStatusValue.Responded}
                onChange={() => {
                    return;
                }}
            />
        ),
        value: DispatcherOfferStatusValue.Responded,
    },
    {
        label: (
            <OfferStatusDispatcher
                value={DispatcherOfferStatusValue.Won}
                onChange={() => {
                    return;
                }}
            />
        ),
        value: DispatcherOfferStatusValue.Won,
    },

    {
        label: (
            <OfferStatusDispatcher
                value={DispatcherOfferStatusValue.Closed}
                onChange={() => {
                    return;
                }}
            />
        ),
        value: DispatcherOfferStatusValue.Closed,
    },
];

export const FilterDropdown = <T,>({
    filterValue,
    role,
    setFilterValue,
    setShowFilterDropdown,
}: IFilterDropdownProps<T>) => {
    const { t } = useTranslation();

    return (
        <div className={styles.filterDropdown}>
            <Typography className={styles.title} variant="p" fontWeight="semibold">
                {t('offers.offerState')}
            </Typography>
            <RadioGroup
                value={filterValue || undefined}
                onValueChange={(value: string) => {
                    setFilterValue(value as T);
                    setShowFilterDropdown(false);
                }}
                options={role === 'admin' ? adminFilterOptions : dispatcherFilterOptions}
                optionStyles={styles.filterOption}
            />
            <button
                onClick={() => {
                    setShowFilterDropdown(false);
                    setFilterValue(undefined);
                }}
                className={styles.clearFilterButton}
            >
                <Typography variant="h6" color="primary">
                    {t('table.filtering.clear')}
                </Typography>
            </button>
        </div>
    );
};
