import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { baseQueryWithAuthRefresh } from 'core/api/baseQuery';
import i18n from 'core/translation/i18next/i18next';

import { GetOneDispatcherResponseSchema, getOneDispatcherResponseSchema } from '../types';
import { TransformedOnboardingData } from '../utils/FormattingOnboardingData/types';

export const dispatchersApi = createApi({
    reducerPath: 'dispatchersApi',
    baseQuery: baseQueryWithAuthRefresh,
    tagTypes: ['DispatcherItem'],
    endpoints: (builder) => ({
        getOneDispatcher: builder.query<GetOneDispatcherResponseSchema, { id: number }>({
            providesTags: (_result, _error, arg) => [{ type: 'DispatcherItem', id: arg.id }],
            query: ({ id }) => ({
                url: `${config.api.endpoints.dispatchers}/${id}`,
                method: 'GET',
            }),
            transformResponse: (res: any) => {
                // safe parse only used for back compatibility
                const validatedSchema = getOneDispatcherResponseSchema(i18n.t).safeParse(res);
                if (!validatedSchema.success) console.warn('Validation failed in getOneDispatcher');
                return res;
            },
        }),
        postNewDispatcher: builder.mutation<
            Omit<GetOneDispatcherResponseSchema, 'place' | 'dispatchervehicle'>,
            { data: TransformedOnboardingData }
        >({
            query: ({ data }) => ({
                url: config.api.endpoints.dispatchers,
                method: 'POST',
                body: data,
            }),
        }),
        putUpdatedDispatcher: builder.mutation<
            Omit<GetOneDispatcherResponseSchema, 'place' | 'dispatchervehicle'>,
            { data: TransformedOnboardingData; id: number }
        >({
            query: ({ data, id }) => ({
                url: `${config.api.endpoints.dispatchers}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_, error, arg) => (error ? [] : [{ type: 'DispatcherItem', id: arg.id }]),
        }),
        getCheckDispatcherMail: builder.query<{ available: boolean }, { email: string }>({
            query: (data) => ({
                url: config.api.endpoints.checkDispatcherMail,
                method: 'GET',
                params: data,
            }),
        }),
        getCheckOnboardingToken: builder.query<{ valid: boolean }, null>({
            query: () => ({
                url: config.api.endpoints.checkOnboardingToken,
                method: 'GET',
            }),
        }),
        postDispatcherEmail: builder.query<
            any,
            {
                dispatcherId: number;
                subject: string;
                body: string;
            }
        >({
            query: (data) => ({
                url: config.api.endpoints.dispatcherEmail,
                method: 'POST',
                body: data,
            }),
        }),
    }),
});

export const {
    useGetOneDispatcherQuery,
    usePostNewDispatcherMutation,
    usePutUpdatedDispatcherMutation,
    useLazyGetCheckDispatcherMailQuery,
    useGetCheckOnboardingTokenQuery,
    useLazyPostDispatcherEmailQuery,
} = dispatchersApi;
