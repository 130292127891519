import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config } from 'config';
import { AdminOfferStatusValue } from 'modules/offers/types';
import { Typography } from 'modules/ui';

import styles from './OfferStatePill.module.scss';

export interface IOfferStatePillProps {
    state: AdminOfferStatusValue | null;
    enquiryId?: number | null;
}

export const OfferStatePill: React.FC<IOfferStatePillProps> = ({ state, enquiryId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = () => {
        if(!enquiryId) return;
        navigate(config.routes.offers.detail.replace(':id', String(enquiryId)));
    }

    if(!state) return <></>;
    return (
        <div className={styles.stateWrapper} data-state={state} data-clickable={Boolean(enquiryId)} onClick={handleNavigate}>
            <Typography className={styles.text} variant="p">
                {t(`common.label.value.${state}`)}
            </Typography>
        </div>
    );
};
