import React from 'react';
import { type FieldValues, type Path, type UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from 'modules/ui';

// Defines the arguments passed to the render function for form rendering
export type RenderArgs<T extends FieldValues, V extends FieldValues> = Omit<UseFieldArrayReturn<T, any>, 'fields'> & {
    index: number;
    getFieldProps: (field: Extract<keyof V, string>) => { name: Path<T> };
};

export type FormArrayProps<T extends FieldValues, V extends FieldValues> = UseFieldArrayReturn<T, any> & {
    name: string;
    className?: string;
    render: (args: RenderArgs<T, V>) => JSX.Element | null;
};

const FormArrayInner = <T extends FieldValues = FieldValues, V extends FieldValues = FieldValues>(
    { name, render, fields, className, ...fieldArrayProps }: FormArrayProps<T, V>,
    ref: React.ForwardedRef<HTMLUListElement>,
) => {
    const { t } = useTranslation();

    return (
        <ul ref={ref} className={className}>
            {!fields.length && <Typography variant="p">{t('form.noItems')}</Typography>}
            {fields.map((field, index) => (
                <li key={field.id}>
                    {render({
                        index,
                        getFieldProps: (field) => ({ name: `${name}.${index}.${field}` as Path<T> }),
                        ...fieldArrayProps,
                    })}
                </li>
            ))}
        </ul>
    );
};

export const FormArray = React.forwardRef(FormArrayInner) as <T extends FieldValues, V extends FieldValues>(
    props: FormArrayProps<T, V> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof FormArrayInner>;
