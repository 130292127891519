import { useEffect, useState } from 'react';
import React from 'react';
import * as RadixSlider from '@radix-ui/react-slider';
import _ from 'lodash';

import { Input } from '../Input';

import styles from './Slider.module.scss';

export type SliderProps = Required<Pick<RadixSlider.SliderProps, 'min' | 'max'>> &
    Omit<RadixSlider.SliderProps, 'value' | 'onChange'> & {
        error?: boolean;
        value: number;
        onChange: (value: number | undefined) => void;
        onChangeCustom?: (value: number | undefined) => void;
        id?: string;
    };

export const Slider = React.forwardRef<HTMLInputElement, SliderProps>(
    ({ id, value, onChange, min, max, error, onChangeCustom, ...props }, ref) => {
        const [sliderValue, setSliderValue] = useState<number | undefined>(value || min);

        const checkValue = () => {
            if ((sliderValue || 0) < min) setSliderValue(min);
            if ((sliderValue || 0) > max) setSliderValue(max);
        };

        const sliderOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === '') {
                setSliderValue(undefined);
            } else {
                const value = e.target.valueAsNumber;
                if (_.isNumber(value)) {
                    setSliderValue(value);
                }
            }
        };

        useEffect(() => {
            onChangeCustom ? onChangeCustom(sliderValue) : onChange(sliderValue);
        }, [sliderValue, onChange]);
        const rangeProps = {
            min,
            max,
        };

        return (
            <div className={styles['slider-container']}>
                <div className={styles['slider-wrapper']}>
                    <RadixSlider.Root
                        // This condition prevents the slider value to be out of the min-max range
                        value={[(sliderValue || 0) < min ? min : (sliderValue || 0) > max ? max : sliderValue || min]}
                        onValueChange={(values) => setSliderValue(values[0])}
                        className={styles.root}
                        {...props}
                        {...rangeProps}
                    >
                        <RadixSlider.Track className={styles.track}>
                            <RadixSlider.Range className={styles.range} />
                        </RadixSlider.Track>
                        <RadixSlider.Thumb className={styles.thumb} />
                    </RadixSlider.Root>
                </div>
                <div className={styles['input-wrapper']}>
                    <Input
                        id={id}
                        ref={ref}
                        type="number"
                        step={props.step}
                        value={sliderValue ?? ''}
                        //onBlur={sliderOnChange}
                        onChange={sliderOnChange}
                        onBlur={checkValue}
                        error={error}
                        {...rangeProps}
                    />
                </div>
            </div>
        );
    },
);
