import { CSSProperties } from 'react';

import styles from './FormGrid.module.scss';

export const FormGrid = ({
    children,
    columns = 1,
    rows = 1,
    style,
}: {
    children: React.ReactNode;
    columns?: 1 | 2 | 3 | 4| 5;
    rows?: 1 | 2 | 3 | 4 | 5;
    style?: CSSProperties;
}) => {
    return (
        <div
            style={{
                gridTemplateColumns: `repeat(${columns}, minmax(0, 11fr))`,
                gridTemplateRows: `repeat(${rows}, auto)`,
                ...style,
            }}
            className={styles.grid}
        >
            {children}
        </div>
    );
};
