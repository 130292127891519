import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLazyGetGeoCodedAddressQuery } from 'core/api';
import { isNumber } from 'lodash';
import { Countries } from 'modules/common';
import { LocationCustomerSchema } from 'modules/customers';

import { DebouncedAutocomplete } from '../../DebouncedAutocomplete';

export interface ILocationAutocompleteProps {}

export const LocationAutocomplete: React.FC<ILocationAutocompleteProps> = () => {
    const { t } = useTranslation();
    const [getGeoCodedAddress, { data }] = useLazyGetGeoCodedAddressQuery();

    const { setValue } = useFormContext<LocationCustomerSchema>();

    const options = useMemo(() => {
        if (!data) return [];
        return data.map((item, index) => {
            const { city, country, addressNumber, postCode, street } = item;
            // feature type is address
            if (addressNumber && postCode && street) {
                return {
                    label: `${street} ${addressNumber}, ${city} ${postCode}, ${country}`,
                    value: `${street} ${addressNumber}`,
                    index,
                };
            }
            // feature type is street
            if(street && postCode) {
                return {
                    label: `${street}, ${city} ${postCode}, ${country}`,
                    value: street,
                    index,
                };
            }
            // feature type is place
            return {
                label: `${city}, ${country}`,
                value: null,
                index,
            }
        });
    }, [data]);

    const handleTyping = async (value: string | null) => {
        if (!value) return;
        await getGeoCodedAddress(value);
    };

    const handleSelect = (value: number) => {
        if (!isNumber(value) || !data) return;
        const selectedOption = data[value];
        if (!selectedOption) return;
        setValue('city', selectedOption.city);
        setValue('countryCode', selectedOption.countryCode as keyof typeof Countries);
        setValue('postalCode', selectedOption.postCode || null);
        setValue('longitude', selectedOption.longitude);
        setValue('latitude', selectedOption.latitude);
    };

    return (
        <>
            <DebouncedAutocomplete<(typeof options)[number]>
                name="street"
                label={t('customers.form.locations.street')}
                labelProperty="label"
                valueProperty="value"
                options={options}
                onTyping={handleTyping}
                onSelect={(data) => data && handleSelect(data.index)}
                debounceInterval={100}
            />
        </>
    );
};
