import { z } from 'zod';

// DISCHARGES

const dischargeCreateSchema = z.object({
    neutralization: z.boolean(),
    date: z.number(),
    dateTo: z.number().nullable(),
    time: z.string().nullable(),
    location_id: z.number().nullable(),
    number: z.number().nullable(),
});

const dischargeUpdateSchema = dischargeCreateSchema.partial().extend({
    commissionDischarge_id: z.number(),
});

const dischargeDeleteSchema = z.object({
    commissionDischarge_id: z.number(),
});

export const transformedDischargesSchema = z.object({
    toCreate: z.array(dischargeCreateSchema).optional(),
    toUpdate: z.array(dischargeUpdateSchema).optional(),
    toDelete: z.array(dischargeDeleteSchema).optional(),
});

export type DischargeCreateSchema = z.infer<typeof dischargeCreateSchema>;
export type DischargeUpdateSchema = z.infer<typeof dischargeUpdateSchema>;
export type DischargeDeleteSchema = z.infer<typeof dischargeDeleteSchema>;
export type TransformedDischargesSchema = z.infer<typeof transformedDischargesSchema>;
