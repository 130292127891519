import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { SettingsIcon } from 'assets/icons';
import classNames from 'classnames';
import { config } from 'config';
import { fetchExchangeRate } from 'core/api/currenciesApi';
import { useDocumentTitle } from 'core/application/hooks';
import { useAuth } from 'core/auth';
import { AuthRole } from 'core/auth/types';
import { Currency, SearchBar, tableNames } from 'modules/common';
import { PageContent } from 'modules/layout';
import { Subheader } from 'modules/layout/components/Subheader';
import { useGetAllEnquiriesQuery } from 'modules/offers/services';
import { AdminOfferStatusValue, DispatcherOfferStatusValue } from 'modules/offers/types';
import { IOfferItemAdmin } from 'modules/offers/types';
import { IOfferItemDispatcher } from 'modules/offers/types';
import { ITransformedOfferItem } from 'modules/offers/types';
import { Table, useTableState } from 'modules/table';
import { Button, Spinner } from 'modules/ui';

import { transformEnquiryIncomingData } from '../../utils';
import { OfferItemAdmin } from '../OfferItemAdmin';
import { OfferItemDispatcher } from '../OfferItemDispatcher';
import { FilterDropdown } from './FilterDropdown';

import styles from './OffersPage.module.scss';

const columnHelper = createColumnHelper<ITransformedOfferItem>();

export const OffersPage = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.offers'));

    const navigate = useNavigate();
    const { user } = useAuth();

    const [filterValue, setFilterValue] = useState<AdminOfferStatusValue | DispatcherOfferStatusValue>();
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);

    const { tableProps, paginationParams, queryParams } = useTableState<ITransformedOfferItem>({
        rowIdKey: 'id',
        tableName: tableNames.offers.list,
    });
    const { globalFilter, setGlobalFilter } = tableProps;

    const { data: response, isLoading } = useGetAllEnquiriesQuery({
        ...paginationParams,
        search: queryParams.search,
        state: filterValue,
    });
    const { data, totalCount } = response || {};
    const isAdmin = user?.role === AuthRole.USER;

    const columns = useMemo(
        () => [
            columnHelper.accessor('id', {}),
            columnHelper.accessor('city', {}),
            columnHelper.accessor('dispatchers', {}),
            columnHelper.accessor('offerStatusValue', {}),
            columnHelper.accessor('firstLoading', {}),
            columnHelper.accessor('lastDischarge', {}),
            columnHelper.accessor('totalLoadingDischargeCount', {}),
            columnHelper.accessor('totalLoadingMeters', {}),
            columnHelper.accessor('totalWeight', {}),
            columnHelper.accessor('offers', {}),
            columnHelper.accessor('exchangeRate', {}),
        ],
        [],
    );

    const [exchangeRate, setExchangeRate] = useState<number>();

    useEffect(() => {
        const fetchRate = async () => {
            const rate = await fetchExchangeRate({ base: Currency.EUR, target: Currency.CZK });
            if (rate) setExchangeRate(rate);
        };
        fetchRate();
    }, []);

    if (isLoading || !data || !exchangeRate) return <Spinner fullScreen />;

    return (
        <PageContent
            subheader={
                <Subheader
                    title={t('offers.listTitle')}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <div className={styles.filterSection}>
                                <button
                                    onClick={() => setShowFilterDropdown((prev) => !prev)}
                                    className={classNames(styles.filterBtn, { [styles.active]: showFilterDropdown })}
                                >
                                    <SettingsIcon />
                                </button>
                                {showFilterDropdown &&
                                    (isAdmin ? (
                                        <FilterDropdown<AdminOfferStatusValue>
                                            role="admin"
                                            filterValue={filterValue}
                                            setFilterValue={setFilterValue}
                                            setShowFilterDropdown={setShowFilterDropdown}
                                        />
                                    ) : (
                                        <FilterDropdown<DispatcherOfferStatusValue>
                                            role="dispatcher"
                                            filterValue={filterValue}
                                            setFilterValue={setFilterValue}
                                            setShowFilterDropdown={setShowFilterDropdown}
                                        />
                                    ))}
                            </div>
                            {isAdmin && (
                                <Button onClick={() => navigate(config.routes.commissions.new)} variant="primary">
                                    {t('offers.newOffer')}
                                </Button>
                            )}
                        </div>
                    }
                >
                    <SearchBar value={globalFilter} onChange={setGlobalFilter} />
                </Subheader>
            }
        >
            <Table
                data={transformEnquiryIncomingData(data, exchangeRate)}
                columns={columns}
                totalCount={Number(totalCount)}
                {...tableProps}
                // total rows: 3, 5, 10, 20
                pageSizes={[9, 15, 30, 60]}
                renderRowCard={(offer: any) =>
                    isAdmin ? (
                        <OfferItemAdmin key={offer.id} offerData={offer as IOfferItemAdmin} />
                    ) : (
                        <OfferItemDispatcher key={offer.id} offerData={offer as IOfferItemDispatcher} />
                    )
                }
            />
        </PageContent>
    );
};
