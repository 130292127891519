import { transformCarrierBasicData } from './Basic';
import { transformDispatchers } from './Dispatchers';
import { CarrierDataFormattingProps, TransformedCarrierData } from './types';

/**
 * Converting the data into the format required for the API query.
 *
 * @param currentData data received after submitting the form
 * @param editedBy currently logged in user.
 * @param oldData data we fetched before, or empty template.
 * @return transformed object.
 */
export function transformCarrierData({
    currentData,
    editedBy,
    oldData,
}: CarrierDataFormattingProps): TransformedCarrierData {
    // split into three parts
    const { dispatcher, ...basicValues } = currentData;

    // modify every part separately
    const basic = transformCarrierBasicData(basicValues, editedBy);

    const dispatchers = structuredClone(
        transformDispatchers({ dispatchers: dispatcher, oldDispatchers: oldData?.dispatcher }),
    );

    // build all main parts together
    return {
        ...basic,
        dispatchers,
    };
}

export const createFullName = (firstName: string | null | undefined, lastName: string | null | undefined) =>
    `${firstName || ''} ${lastName || ''}`.trim();
