import { User } from 'core/auth/types';
import i18n from 'core/translation/i18next/i18next';
import { T } from 'core/translation/types';
import { differenceInDays, format, formatRelative, Locale } from 'date-fns';
import { format as formatDate } from 'date-fns';
import { cs, de } from 'date-fns/locale';
import { CarrierNewSchema } from 'modules/carriers';
import { createFullName } from 'modules/carriers/utils';
import {
    CommissionDischargeSchema,
    CommissionExportSchema,
    CommissionGoodsSchema,
    commissionLanguages,
    CommissionLoadingSchema,
    CommissionSchema,
    SendCreateDeliveryConfirmationPdfSchema,
    SendCreateLoadingConfirmationPdfSchema,
    SendCreateNeutralizationPdfSchema,
    SendCreateOrderCarrierPdfSchema,
    SendCreateOrderConfirmationEmailSchema,
} from 'modules/commissions';
import { Countries, Currency, universalLanguage } from 'modules/common';
import { CustomerDetailSchema, LocationCustomerSchema } from 'modules/customers';
import { CommissionInvoiceSchema, InvoiceDetailSchema, InvoicePreviewPdfRequestSchema } from 'modules/invoicing';
import { GeocoderSearchResult, GeocoderSearchResultType } from 'modules/onboarding/components/GeocoderPanel';
import moment from 'moment';
import { PaginationParams, QueryParams } from 'types/query-params';

/**
 * Downloading the file.
 *
 * @param uri specifies the path to the file.
 * @param name new filename for the downloaded file.
 */
export function downloadURI(uri: string, name: string) {
    const link = document.createElement('a');
    document.body.appendChild(link);

    link.download = name;
    link.href = uri;
    link.click();
    link.parentNode && link.parentNode.removeChild(link);
}

/**
 * Printing the file.
 *
 * @param uri specifies the path to the file.
 */
export function printURI(uri: string) {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = uri;
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            iframe.contentWindow?.print();
        }, 1);
    };
}

/**
 * Get the lowest order date across commissions.
 *
 * @param newCommissionsList new commissions list to get the order date from.
 * @return order date.
 */
export function getInvoiceOrderDate(newCommissionsList: CommissionInvoiceSchema[]): string {
    const lowestOrderDate = newCommissionsList.reduce<number>(
        (prev, next) => (prev < Number(next.orderDate) ? prev : Number(next.orderDate)),
        Number(newCommissionsList[0].orderDate),
    );
    return String(lowestOrderDate);
}

/**
 * Get the lo point date across commissions.
 *
 * @param newCommissionsList new commissions list to get date point date from.
 * @return order date.
 */
export function getInvoicePointDate(newCommissionsList: CommissionInvoiceSchema[]): string {
    const highestDischargeDate = newCommissionsList
        .flatMap((commission) => commission.discharge_date)
        .reduce<number>((prev, next) => (prev > next ? prev : next), 0);
    return String(highestDischargeDate);
}

/**
 * Simplify creating the request body to one function.
 *
 * @param invoice invoice to get data from.
 * @param customer invoice customer.
 * @param user current authorized user.
 * @return body prepared for request.
 */
export function simplifyGetInvoicePdfRequestBody(
    invoice: InvoiceDetailSchema,
    customer: Pick<
        CustomerDetailSchema,
        'street' | 'country' | 'postalCode' | 'city' | 'taxId' | 'place' | 'companyRegistrationNumber'
    >,
    user: User,
): InvoicePreviewPdfRequestSchema {
    return {
        createdBy: createFullName(user.name, user.surname),
        createdByMail: user.email,
        customerCompany: invoice.customer_company,
        customerStreet: customer.street || customer.place?.street || '',
        customerCountry: customer.country || customer.place?.country || '',
        customerPostalCode: customer.postalCode || customer.place?.postalCode || '',
        customerCity: customer.city || customer.place?.city || '',
        registrationNumber: customer.companyRegistrationNumber?.toString() || '',
        currency: invoice.currency,
        taxId: customer.taxId?.toString() || '',
        language: commissionLanguages[invoice.language || 'cs'],
        // the rateBase still will be recalculated in the server
        rateBase: invoice.currency == 'CZK' ? 1 : 24.5,
        varSymbol: invoice.invoiceNumber,
        constSymbol: invoice.constantSymbol || '',
        orderDate: format(Number(invoice.orderDate || 0), 'dd.MM.yyyy'),
        exposureDate: format(Number(invoice.issueDate), 'dd.MM.yyyy'),
        maturityDate: format(Number(invoice.dueDate), 'dd.MM.yyyy'),
        performanceDate: format(Number(invoice.pointDate), 'dd.MM.yyyy'),
        paymentMethod: invoice.paymentMethod,
        commissions: invoice.commission.map((item) => ({
            text: `${item.qid} (${item.loading_city_string} -> ${item.discharge_city_string} )`,
            orderedBy: item.orderSource || String(item.commissionNumber),
            orderDate: format(Number(item.orderDate), 'dd.MM.yyyy'),
            loadingDate: format(Number(item.loading_date?.[0]), 'dd.MM.yyyy') || '',
            price: item.priceCustomer,
            vat: item.vat === '0' || item.vat === '21' ? Number(item.vat) : 21,
        })),
    };
}

/**
 * Simplify creating the request body to one function.
 *
 * @param options export commission form data.
 * @param commission commission object.
 * @param loadings array of all commission loadings.
 * @param discharges array of all commission discharges.
 * @param goods array of commission goods.
 * @param carrier current carrier.
 * @param user current authorized user.
 * @return body prepared for request.
 */
export function simplifyCreateOrderCarrierPdfRequestBody({
    options,
    commission,
    loadings,
    discharges,
    goods,
    carrier,
    user,
}: {
    options: CommissionExportSchema;
    commission: CommissionSchema;
    loadings: (CommissionLoadingSchema & LocationCustomerSchema)[];
    discharges: (CommissionDischargeSchema & LocationCustomerSchema)[];
    goods: CommissionGoodsSchema[];
    carrier: CarrierNewSchema;
    user: User;
}): SendCreateOrderCarrierPdfSchema {
    const dispatcher = carrier.dispatcher.find((dispatcher) => dispatcher.dispatcher_id === commission.dispatcher_id);

    const body = {
        lang: options.language,
        qid: commission.qid || '',
        carrierDispatcherName: createFullName(dispatcher?.firstName, dispatcher?.lastName) || '',
        carrierCompany: carrier.company,
        carrierDispatcherGsm: dispatcher?.phone || '',
        carrierStreet: carrier.street || carrier.place.street || '',
        carrierDispatcherEmail: dispatcher?.email || '',
        carrierCountry: carrier.country || carrier.place.country || '',
        carrierPostalCode: carrier.postalCode || carrier.place.postalCode || '',
        carrierCity: carrier.city || carrier.place.city || '',
        carrierDriver: commission.carrierDriver || '',
        carrierRegistrationNumber: carrier.companyRegistrationNumber ? String(carrier.companyRegistrationNumber) : '',
        carrierGsm: commission.carrierGsm || '',
        carrierTaxNumber: carrier.taxId || '',
        carrierRegistrationPlate: commission.carrierRegistrationPlate || '',
        loadings: loadings.map((loading, index) => ({
            loadingNumber: index + 1,
            loadingDate: format(Number(loading.date), 'dd.MM.yyyy'),
            loadingDateTo: loading.dateTo ? format(Number(loading.dateTo), 'dd.MM.yyyy') : '',
            loadingTime: loading.time || '',
            loadingCompany: loading.company || '',
            loadingStreet: loading.street || '',
            loadingCountry: loading.countryCode || '',
            loadingPostalCode: loading.postalCode || '',
            loadingCity: loading.city || '',
            loadingGps: ` `,
            // ${loading.latitude} N, ${loading.longitude} W
            loadingRefNumber: loading.refNumber ? String(loading.refNumber) : '',
        })),
        discharges: discharges.map(({ neutralization, ...discharge }, index) => ({
            dischargeNumber: index + 1,
            dischargeDate: format(Number(discharge.date), 'dd.MM.yyyy'),
            dischargeDateTo: discharge.dateTo ? format(Number(discharge.dateTo), 'dd.MM.yyyy') : '',
            dischargeTime: discharge.time || '',
            dischargeCompany: discharge.company || '',
            dischargeStreet: discharge.street || '',
            dischargeCountry: discharge.countryCode || '',
            dischargePostalCode: discharge.postalCode || '',
            dischargeCity: discharge.city || '',
            dischargeGps: ` `,
            neutralization,
            // ${discharge.latitude} N, ${discharge.longitude} W
        })),
        items: goods.map((item) => ({
            itemLoading: item.loadingIdx,
            itemDischarge: item.dischargeIdx,
            itemName: item.name,
            itemPackage: item.packaging || '',
            itemQuantity: item.quantity ? String(item.quantity) : '',
            itemSize: item.size || '',
            itemLoadingMeters: item.loadingMeters ? String(item.loadingMeters) : '',
            itemWeight: item.weight ? String(item.weight) : '',
            itemWeightBrutto: item.weightBrutto ? String(item.weightBrutto) : '',
        })),
        disposition: commission.disposition || '',
        carrierPrice: commission.priceCarrier || 0,
        orderCurrency: commission.currencyCarrier || Currency['CZK'],
        date: format(new Date(), 'dd.MM.yyyy'),
        createdBy: createFullName(user.name, user.surname),
        createdByPhoneNumber: user.mobilePhone,
    };
    return body;
}

/**
 * Simplify creating the request body to one function.
 *
 * @param user current authorized user.
 * @param options export commission form data.
 * @param commission commission object.
 * @param loadings array of all commission loadings.
 * @param discharges array of all commission discharges.
 * @param goods array of commission goods.
 * @return body prepared for request.
 */
export function simplifyCreateOrderConfirmationRequestBody({
    user,
    options,
    commission,
    loadings,
    discharges,
    goods,
}: {
    user: User;
    options: CommissionExportSchema;
    commission: CommissionSchema;
    loadings: (CommissionLoadingSchema & LocationCustomerSchema)[];
    discharges: (CommissionDischargeSchema & LocationCustomerSchema)[];
    goods: CommissionGoodsSchema[];
}): SendCreateOrderConfirmationEmailSchema {
    const body = {
        to: options.recipient,
        dispatcher: {
            name: user.name,
            surname: user.surname,
            phone: user.mobilePhone,
            email: user.email,
        },
        subject: options.subject,
        body: options.message,
        itemName: goods.map(({ name }) => name).join(','),
        itemPackage: goods.map(({ packaging }) => packaging).join(',') || '',
        itemWeight: goods.map(({ weight }) => weight).join(','),
        itemLoadingMeters: goods.map(({ loadingMeters }) => loadingMeters).join(','),
        loadingDate: loadings
            .map(({ date, dateTo }) => {
                return `${format(Number(date), 'dd.MM.yyyy')} ${
                    date === dateTo ? '' : ` - ${format(Number(dateTo), 'dd.MM.yyyy')}`
                }`;
            })
            .join(', '),
        loadingAddress: loadings
            .map(({ city, street }) => {
                return `${city} - ${street}`;
            })
            .join(', '),
        dischargeAddress: discharges
            .map(({ city, street }) => {
                return `${city} - ${street}`;
            })
            .join(', '),
        dischargeDate: discharges
            .map(({ date, dateTo }) => {
                return `${format(Number(date), 'dd.MM.yyyy')} ${
                    date === dateTo ? '' : ` - ${format(Number(dateTo), 'dd.MM.yyyy')}`
                }`;
            })
            .join(', '),
        carrierPrice: commission.priceCarrier ? String(commission.priceCarrier) : '',
        driverPhone: commission.carrierGsm || '',
        carrierRegistrationPlate: commission.carrierRegistrationPlate || '',
        qid: commission.qid || '',
        lang: options.language,
    };

    return body;
}

/**
 * Simplify creating the request body to one function.
 *
 * @param options export commission form data.
 * @param commission commission object.
 * @param loadings array of all commission loadings.
 * @param goods array of commission goods.
 * @param customer current customer.
 * @return body prepared for request.
 */
export function simplifyCreateLoadingConfirmationPdfRequestBody({
    options,
    commission,
    loadings,
    goods,
    customer,
}: {
    options: CommissionExportSchema;
    commission: CommissionSchema;
    loadings: (CommissionLoadingSchema & LocationCustomerSchema)[];
    goods: CommissionGoodsSchema[];
    customer: CustomerDetailSchema;
}): SendCreateLoadingConfirmationPdfSchema {
    const body = {
        lang: options.language,
        qid: commission.qid || '',
        loadingRefNumber: loadings.map(({ refNumber }) => refNumber).join(', '),
        customerCompany: customer.company,
        items: goods.map(({ name }) => name).join(', '),
        loadingDate: loadings
            .map(({ date, dateTo }) => {
                return `${format(Number(date), 'dd.MM.yyyy')} ${
                    date === dateTo ? '' : ` - ${format(Number(dateTo), 'dd.MM.yyyy')}`
                }`;
            })
            .join(', '),
        loadingTime: loadings.map(({ time }) => time).join(', ') || '',
        carrierRegistrationPlate: commission.carrierRegistrationPlate || '',
        carrierDriver: commission.carrierDriver || '',
        carrierGsm: commission.carrierGsm || '',
    };

    return body;
}

/**
 * Simplify creating the request body to one function.

 * @param options export commission form data.
 * @param commission commission object.
 * @param discharges array of all commission discharges.
 * @param goods array of commission goods.
 * @param customer current customer.
 * @return body prepared for request.
 */
export function simplifyCreateDeliveryConfirmationPdfRequestBody({
    options,
    commission,
    discharges,
    goods,
    customer,
}: {
    options: CommissionExportSchema;
    commission: CommissionSchema;
    discharges: (CommissionDischargeSchema & LocationCustomerSchema)[];
    goods: CommissionGoodsSchema[];
    customer: CustomerDetailSchema;
}): SendCreateDeliveryConfirmationPdfSchema {
    const body = {
        lang: options.language,
        qid: commission.qid || '',
        customerCompany: customer.company,
        items: goods.map(({ name }) => name).join(', '),
        dischargeDate: discharges
            .map(({ date, dateTo }) => {
                return `${format(Number(date), 'dd.MM.yyyy')} ${
                    date === dateTo ? '' : ` - ${format(Number(dateTo), 'dd.MM.yyyy')}`
                }`;
            })
            .join(', '),
        dischargeTime: discharges.map(({ time }) => time).join(', ') || '',
        carrierRegistrationPlate: commission.carrierRegistrationPlate || '',
        carrierDriver: commission.carrierDriver || '',
        carrierGsm: commission.carrierGsm || '',
    };

    return body;
}

/**
 * Simplify creating the request body to one function.
 *
 * @param options export commission form data.
 * @param commission commission object.
 * @param discharge discharges with neutralization.
 * @param customer current customer.
 * @return body prepared for request.
 */
export function simplifyCreateNeutralizationPdfRequestBody({
    options,
    commission,
    discharge,
    customer,
}: {
    options: CommissionExportSchema;
    commission: CommissionSchema;
    discharge: CommissionDischargeSchema & LocationCustomerSchema;
    customer: CustomerDetailSchema;
}): SendCreateNeutralizationPdfSchema {
    const body = {
        lang: options.language,
        qid: commission.qid || '',
        date: String(discharge.date),
        dischargeNumber: discharge.commissionDischarge_id || 0,
        senderCompany: customer.company,
        senderStreet: customer.street,
        senderCountry: customer.country,
        senderPostalCode: customer.postalCode,
        senderCity: customer.city,
        receiverCompany: discharge.company || '',
        receiverStreet: discharge.street || '',
        receiverCountry: discharge.country || '',
        receiverPostalCode: discharge.postalCode || '',
        receiverCity: discharge.city || '',
    };
    return body;
}

/**
 * Merge the loading with appropriate location.
 * @param loadings array of loadings.
 * @param locations array of locations.
 * @return array of merged loadings and locations.
 */

export const mergeLoadingsWithLocations = (
    loadings: CommissionLoadingSchema[],
    locations: LocationCustomerSchema[],
): (CommissionLoadingSchema & LocationCustomerSchema)[] => {
    // 1. create the object so then we can simply find the location by location_id
    const loadingsByLocationId =
        locations.reduce<{ [id: number]: LocationCustomerSchema }>((acc, location) => {
            return { ...acc, [Number(location.location_id)]: location };
        }, {}) || {};

    const loadingsWithLocation = loadings.map((item) => {
        return {
            ...item,
            ...loadingsByLocationId[Number(item.location_id)],
        };
    });
    return loadingsWithLocation;
};

/**
 * Merge the discharges with appropriate location.
 * @param discharges array of discharges.
 * @param locations array of locations.
 * @return array of merged discharges and locations.
 */

export const mergeDischargesWithLocations = (
    discharges: CommissionDischargeSchema[],
    locations: LocationCustomerSchema[],
): (CommissionDischargeSchema & LocationCustomerSchema)[] => {
    // 1. create the object so then we can simply find the location by location_id
    const dischargesByLocationId =
        locations.reduce<{ [id: number]: LocationCustomerSchema }>((acc, location) => {
            return { ...acc, [Number(location.location_id)]: location };
        }, {}) || {};

    const dischargesWithLocation = discharges.map((item) => {
        return {
            ...item,
            ...dischargesByLocationId[Number(item.location_id)],
        };
    });
    return dischargesWithLocation;
};

export const phoneRegex = new RegExp(/(?:\(?\+\d{2}\)?\s*)?\d+(?:[ -]*\d+)*$/);
export const numberRegex = new RegExp(/^[0-9]*$/);

export function getMessageAndSubject({
    format,
    language,
    commissionNumber,
    loadings,
    discharges,
    goods,
    driverPhoneNumber,
    driverName,
    commission,
}: {
    format:
        | 'createOrderCarrier'
        | 'createOrderConfirmation'
        | 'createLoadingConfirmation'
        | 'createDeliveryConfirmation'
        | 'neutralization';
    language: 'cs' | 'de' | 'en';
    commissionNumber: string;
    loadings: (CommissionLoadingSchema & { city: string | null; street: string | null })[];
    discharges: (CommissionDischargeSchema & { city: string | null; street: string | null })[];
    goods: CommissionGoodsSchema[];
    driverPhoneNumber: string;
    driverName: string;
    commission: CommissionSchema;
}): { subject: string; message: string } {
    let { subject, message } = emailTemplates?.[format]?.[language] || {};

    const pricesByCurrencyAndLanguage: { cs: string; de: string; en: string } = {
        cs: commission.currencyCustomer === 'EUR' ? `,\n\t   ${commission.priceCustomer || 0},- EUR bez DPH.` : '.',
        de: commission.currencyCustomer === 'EUR' ? `,\n\t   ${commission.priceCustomer || 0},- EUR ohne MwSt.` : '.',
        en: commission.currencyCustomer === 'EUR' ? `,\n\t   ${commission.priceCustomer || 0},- EUR without VAT.` : '.',
    };

    const priceInCZK =
        commission.currencyCustomer === 'EUR'
            ? String(((commission.priceCustomer || 0) * commission.exchangeRateCustomer).toFixed())
            : String(commission.priceCustomer || '');

    subject = subject.replace('{commissionNumber}', commissionNumber);
    message = message.replace(/\n/g, '\n');
    message = message.replace('{commissionNumber}', commissionNumber);
    message = message.replace(
        '{loadingCities}',
        loadings
            .filter(({ city }) => city)
            .map(({ city }) => city)
            .join(' + '),
    );
    message = message.replace(
        '{dischargeCities}',
        discharges
            .filter(({ city }) => city)
            .map(({ city }) => city)
            .join(' + '),
    );
    message = message.replace('{goodsNames}', goods.map(({ name }) => name).join(', '));
    message = message.replace('{driverPhoneNumber}', driverPhoneNumber);
    message = message.replace('{driverName}', driverName);
    message = message.replace('{commissionPriceInCZK}', priceInCZK);
    message = message.replace('{rz}', String(commission.carrierRegistrationPlate));
    message = message.replace('{commissionPriceInEUR}', String(commission.priceCustomer));
    message = message.replace('{commissionPriceInEURFullText}', pricesByCurrencyAndLanguage[language]);
    message = message.replace(
        '{loadingDates}',
        loadings
            .map(
                ({ date, dateTo }) =>
                    `${formatDate(Number(date), 'dd.MM.yyyy')} ${
                        date === dateTo || !dateTo ? '' : `- ${formatDate(Number(dateTo), 'dd.MM.yyyy')}`
                    }`,
            )
            .join(' + '),
    );
    message = message.replace(
        '{dischargeDates}',
        discharges
            .map(
                ({ date, dateTo }) =>
                    `${formatDate(Number(date), 'dd.MM.yyyy')} ${
                        date === dateTo || !dateTo ? '' : `- ${formatDate(Number(dateTo), 'dd.MM.yyyy')}`
                    }`,
            )
            .join(' + '),
    );
    message = message.replace('{goodsWeights}', goods.map(({ weight }) => weight).join(', '));
    message = message.replace('{goodsPackaging}', goods.map(({ packaging }) => packaging).join(', '));
    message = message.replace('{goodsLoadingMeters}', goods.map(({ loadingMeters }) => loadingMeters).join(', '));

    return { subject, message };
}

export const emailTemplates = {
    createOrderCarrier: {
        cs: {
            subject: 'Objednávka přepravy',
            message:
                'Dobrý den,\n\nv příloze Vám posílám objednávku přepravy, kterou evidujeme pod číslem {commissionNumber}.\n\nProsím o potvrzení přijetí.\n\n{registrationSection}\n\nDěkuji\nS přáním hezkého dne',
        },
        de: {
            subject: 'Transportbestellung',
            message:
                'Guten Tag,\n\nbeifügend  schicke ich Ihnen die TransportBestellung.\nIch bitte um die Bestätigung.\n\n{registrationSection}\n\nVielen Dank\nMit freudlichen Grüßen',
        },
        en: {
            subject: 'Transport order',
            message:
                'Hello,\n\nAttached please find a transportation order.\nPlease confirm receipt.\n\n{registrationSection}\n\nThank you in advance.\n\nBest regards,',
        },
    },
    createOrderConfirmation: {
        cs: {
            subject: 'Potvrzení objednávky {commissionNumber}',
            message: `Dobrý den,\n\npotvrzujeme přepravu na trase {loadingCities} -> {dischargeCities} evidovanou pod číslem {commissionNumber}.\n\nTermín nakládky: {loadingDates}\nTermín vykládky: {dischargeDates}\nZboží: {goodsNames}\nHmotnost: {goodsWeights} kg\nZpůsob balení: {goodsPackaging}\nRozměr: {goodsLoadingMeters}\n\nRZ vozidla: {rz}\nŘidič: {driverName}, Tel.: {driverPhoneNumber}\nCena: {commissionPriceInCZK},- CZK bez DPH{commissionPriceInEURFullText}\n\nŽádáme Vás o závazné potvrzení Vaší objednávky.\n\nS přáním pěkného dne`,
        },
        de: {
            subject: 'Bestätigung der Bestellung',
            message: `Guten Tag,\n\nHiermit bestätigen wir den Transport aus {loadingCities} -> {dischargeCities}, die wir unter der Nummer {commissionNumber} registrieren.\n\nAufladung: {loadingDates}\nEntladung: {dischargeDates}\nWare: {goodsNames}\nPreis: {commissionPriceInCZK},- CZK ohne MwSt{commissionPriceInEURFullText}\n\nBitte bestätigen Sie Ihre Bestellung`,
        },
        en: {
            subject: 'Order confirmation',
            message: `Hello,\n\nI confirm the transportation from {loadingCities} -> {dischargeCities}. Which we register under number: {commissionNumber}.\n\nLoading date: {loadingDates}\nDelivery date: {dischargeDates}\nGoods: {goodsNames}\nPrice: {commissionPriceInCZK},- CZK without VAT{commissionPriceInEURFullText}\n\nPlease, confirm by return.`,
        },
    },
    createLoadingConfirmation: {
        cs: {
            subject: 'Informace k nakládce',
            message: 'Dobrý den,\n\nv příloze Vám posílám avizaci nakládky u Vás ve firmě.\n\nS přáním pěkného dne',
        },
        de: {
            subject: 'Information über der Aufladung',
            message:
                'Guten Tag,\n\nbeifügend schicke ich Ihnen die Information über die Ladung bei Ihrer Firma.\n\nMit freudlichen Grüßen',
        },
        en: {
            subject: 'Loading confirmation',
            message:
                'Hello,\n\nIn attached file find you the information about loading in your company.\n\nBest regards',
        },
    },
    createDeliveryConfirmation: {
        cs: {
            subject: 'Informace k vykládce',
            message:
                'Dobrý den,\n\nv příloze Vám posílám avizaci příjezdu auta, které u Vás ve firmě bude vykládat.\n\nS přáním pěkného dne',
        },
        de: {
            subject: 'Information über Entladung',
            message:
                'Guten Tag,\n\nbeifügend schicke ich Ihnen die Information über die Ausladung bei Ihrer Firma.\n\nMit freudlichen Grüßen',
        },
        en: {
            subject: 'Delivery information',
            message:
                'Hello,\n\nIn attached file find you the iformation about delivery in your company.\n\nBest regards',
        },
    },
    neutralization: {
        cs: {
            subject: 'Informace k neutralizaci',
            message:
                'Dobrý den,\n\nv příloze Vám posílám informace a pokyny k neutralizaci.\n\nDěkuji\nS přáním pěkného dne',
        },
        de: {
            subject: 'Genaue Adresse und Informationen',
            message:
                'Guten Tag,\n\nbeifügend schicke ich Ihnen die Instruktionen und genaue Adresse der Ausladung.\n\nVielen Dank\nMit freudlichen Grüßen',
        },
        en: {
            subject: 'Exact adress and instructions',
            message:
                'Hello,\n\nIn attached file find you the instructions and exact adress of delivery.\n\nThank you\nBest regards',
        },
    },
};

/**
 * Universal function to find language in different ways.
 * @param value countryCode (CZ), lang (cs), languageId (36) or languageText (čeština) .
 * @return object with other language data.
 */

export function universalLanguageFinder(value: string): universalLanguage | undefined {
    const langs: universalLanguage[] = [
        { countryCode: ['CZ', 'SK'], lang: 'cs', languageId: 36, languageText: 'čeština' },
        { countryCode: ['DE', 'AT'], lang: 'de', languageId: 52, languageText: 'němčina' },
        { countryCode: ['EN'], lang: 'en', languageId: 41, languageText: 'angličtina' },
    ];

    return langs.find(
        (lang) => lang.countryCode.includes(value) || String(lang.languageId) === value || lang.languageText === value,
    );
}

/**
 * Universal way to get language for dispatcher.
 * @param dispatcherLanguageId languageId of dispatcher.
 * @param carrierCountryCode countryCode of carrier.
 * @return language in "lang" format.
 */

export function getDispatcherLanguage({
    dispatcherLanguageId,
    carrierCountryCode,
}: {
    dispatcherLanguageId?: number;
    carrierCountryCode?: keyof typeof Countries;
}): 'cs' | 'de' | 'en' {
    //  1. try to get the lang based on dispatchers's languageId
    if (dispatcherLanguageId) {
        const lang = universalLanguageFinder(String(dispatcherLanguageId));
        if (lang) return lang.lang;
    }
    // 2. otherwise try to get the lang based on carrier's countryCode
    if (carrierCountryCode && ['CZ', 'SK', 'DE', 'AT'].includes(carrierCountryCode)) {
        // 3. if the countryCode is CZ, SK, DE or AT - return the based language
        const lang = universalLanguageFinder(carrierCountryCode);
        if (lang) return lang.lang;
    }
    // 4. if the countryCode is not CZ, SK, DE or AT - return 'en'
    // 5. if country is not specified, return 'cs'
    return carrierCountryCode ? 'en' : 'cs';
}

/**
 * Format the data from MapBox response.
 * @param res first feature of response, if exist
 * @return object with address info.
 */

export function formatLocationDataFromApi(res: GeocoderSearchResult) {
    const placeData = res.context;
    const countryData = placeData.find((item) => item.id.split('.')[0] === 'country');

    const getCityValue = (GeocoderSearchResult: GeocoderSearchResult) => {
        const type = GeocoderSearchResult.place_type[0];
        if (type === GeocoderSearchResultType.Address) {
            return GeocoderSearchResult.context.find((item) => item.id.split('.')[0] === 'place')?.text || '';
        }
        if (type === GeocoderSearchResultType.Place) {
            return GeocoderSearchResult.text || '';
        }
        return '';
    };

    const transformedResult = {
        city: getCityValue(res),
        country: Countries[countryData?.short_code?.toUpperCase() as keyof typeof Countries],
        countryCode: countryData?.short_code?.toUpperCase() as keyof typeof Countries,
        postalCode: placeData.find((item) => item.id.split('.')[0] === 'postcode')?.text || '',
        latitude: res.geometry.coordinates[1],
        longitude: res.geometry.coordinates[0],
    };

    return transformedResult;
}

/**
 * Disable scrolling when on input with number type
 */

export const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
    // Prevent the input value change;
    'blur' in e.target && typeof e.target.blur === 'function' && e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
        'focus' in e.target && typeof e.target.focus === 'function' && e.target.focus();
    }, 0);
};

export type ITransformedTableLocation = {
    originalIndex: number;
    location_id: number;
    company: string;
    country: string;
    postalCode: string;
    city: string;
    street: string;
    routeDirection: string;
    contactPerson: string;
};

// table has another fields than fetched addresses (locations)
export function transformAddressesIncomingData(data: LocationCustomerSchema[], t: T): ITransformedTableLocation[] {
    const newData: ITransformedTableLocation[] = data.map((address, index) => {
        // address can have routeDirection = loading or discharge
        // if both loading and discharge, use "Nakládka, Vykládka"
        // if only some of them, use "Nakládka" or "Vykládka"
        // if nothing of them, use "—"
        const routeDirection =
            address.loading && address.discharge
                ? t('customers.form.locations.routeDirection.loading') +
                  ', ' +
                  t('customers.form.locations.routeDirection.discharge')
                : (address.loading && t('customers.form.locations.routeDirection.loading')) ||
                  (address.discharge && t('customers.form.locations.routeDirection.discharge')) ||
                  '—';

        return {
            location_id: address.location_id,
            company: address.company || '—',
            country: address.country || '—',
            postalCode: address.postalCode || '—',
            city: address.city || '—',
            street: address.street || '—',
            routeDirection,
            contactPerson: createFullName(address.firstName, address.lastName),
            originalIndex: index,
        };
    });
    return newData;
}

interface ITableSortingFilteringPaginationParams<T = Record<string, any>> {
    data: T[];
    treatAsDate?: string[];
    queryParams: QueryParams<Record<string, any>>;
    paginationParams: PaginationParams | null;
}
/**
 * filter, sort and paginate tables with no BE.
 * @param data array of objects.
 * @param queryParam filter parameters.
 * @param paginationParams pagination parameters.
 * @return filtered, sorted and paginated result & total sorted items count
 */
export function tableSortingFilteringPagination<T>({
    data,
    queryParams,
    paginationParams,
    treatAsDate = [],
}: ITableSortingFilteringPaginationParams): {
    result: T[];
    sortedCount: number;
    filtered: T[];
    sorted: T[];
} {
    const filtered = data.filter((row) => {
        for (const [key, value] of Object.entries(queryParams)) {
            if (key === 'sort') continue;

            // if min value specified
            if (key.split('_')[1] === 'gte') {
                if (treatAsDate.includes(key.split('_')[0])) {
                    if (
                        moment
                            .unix(Math.floor(row[key.split('_')[0]] / 1000))
                            .isBefore(moment.unix(Math.floor(Number(value) / 1000)), 'days')
                    )
                        return false;
                    continue;
                }
                if (row[key.split('_')[0]] < Number(value)) return false;
                continue;
            }
            // if max value specified
            if (key.split('_')[1] === 'lte') {
                if (treatAsDate.includes(key.split('_')[0])) {
                    if (
                        moment
                            .unix(Math.floor(row[key.split('_')[0]] / 1000))
                            .isAfter(moment.unix(Math.floor(Number(value) / 1000)), 'days')
                    )
                        return false;
                    continue;
                }
                if (row[key.split('_')[0]] > Number(value)) return false;
                continue;
            }
            const actualValue = (row[key] as string).toString().toLowerCase().trim().replaceAll(' ', '');
            const searchValue = value.toLowerCase().trim().replaceAll(' ', '');
            if (!actualValue.includes(searchValue)) return false;
        }
        return true;
    });

    const sorted = filtered.sort((a: Record<string, any>, b: Record<string, any>) => {
        if (!queryParams.sort) return -1;

        const [sortBy, sortDir] = queryParams.sort?.split(':');
        // sort strings
        if (typeof a[sortBy] === 'string') {
            const aVal = (a[sortBy] as string).toLowerCase().trim().replaceAll(' ', '');
            const bVal = (b[sortBy] as string).toLowerCase().trim().replaceAll(' ', '');
            if (sortDir === 'asc') {
                return aVal.localeCompare(bVal);
            } else {
                return bVal.localeCompare(aVal);
            }
        }

        // sort numbers
        const aVal = Number(a[sortBy]) || 0;
        const bVal = Number(b[sortBy]) || 0;
        return sortDir === 'asc' ? aVal - bVal : bVal - aVal;
    });

    if (!paginationParams)
        return { result: sorted as T[], sortedCount: sorted.length, filtered: filtered as T[], sorted: sorted as T[] };
    const paginated = sorted.slice(paginationParams.offset, paginationParams.limit + paginationParams.offset);
    return { result: paginated as T[], sortedCount: sorted.length, filtered: filtered as T[], sorted: sorted as T[] };
}

/**
 * format date in human-readable in user's language.
 * @param date timestamp number or Date.
 * @return formatted date in string.
 */
export function getFormattedDate(date: number | Date): string {
    const locales: { [key: string]: Locale } = {
        cs: cs,
        de: de,
    };

    /* if date is more than 2 days past, use "2.1." instead of "yesterday" */
    if (differenceInDays(new Date(), date) > 1) {
        const isCurrentYear = new Date(date).getFullYear() === new Date().getFullYear();

        return isCurrentYear ? format(date, 'd.M.') : format(date, 'd.M.yyyy');
    }

    const formattedDate = formatRelative(date, new Date(), {
        locale: i18n.language in locales ? locales[i18n.language] : undefined,
    }).split(' ');

    // remove the hours ("in 14:44")
    const indexOfHours = formattedDate.findIndex((part) => part.includes(':'));

    // EXAMPLE: "last monday at 12:00 PM" -> ["last", "monday", "at", "12:00", "PM"] -> ["last", "monday"] -> "last monday"
    return formattedDate.slice(0, indexOfHours - 1).join(' ');
}

/**
 * get number is negative.
 * @param number number to check.
 * @return boolean result.
 */
export function isNegative(number: number): boolean {
    return number < 0;
}
