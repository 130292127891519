import { useNavigate } from 'react-router-dom';

import { Typography } from '../Typography';

import styles from './PillLinkButton.module.scss';

export interface IPillLinkButtonProps {
    link?: string;
    children?: React.ReactNode;
    color?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
}
export const PillLinkButton = ({ link, endIcon, startIcon, color, children }: IPillLinkButtonProps) => {
    const navigate = useNavigate();
    return (
        <div
            className={styles.stateWrapper}
            data-color={color}
            onClick={() => {
                link && navigate(link);
            }}
        >
            {startIcon}
            <Typography className={styles.text} variant="p">
                {children}
            </Typography>
            {endIcon}
        </div>
    );
};
