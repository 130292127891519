import { ArrayPath, FieldArray, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DragAndDropFormArray, FormActionButton, type FormArrayProps, FormSection } from 'modules/form';

import styles from './LoadingDischargeFormArray.module.scss';

export type LoadingDischargeFormArrayProps<T extends FieldValues, V extends FieldValues> = FormArrayProps<T, V> & {
    title: string;
    defaultValues: FieldArray<T, ArrayPath<T>>;
    addTitle?: string;
    addOptions?: { label: string; defaultValues: FieldArray<T, ArrayPath<T>>; addToPosition?: number }[];
    addToPosition?: number;
    getLoadingDischargeIndexes: {
        loadingsIndexes: number[];
        dischargesIndexes: number[];
    }
};

export const LoadingDischargeFormArray = <T extends FieldValues, V extends FieldValues>({
    title,
    append,
    insert,
    defaultValues,
    addTitle,
    addOptions,
    addToPosition,
    getLoadingDischargeIndexes,
    ...props
}: LoadingDischargeFormArrayProps<T, V>) => {
    const { t } = useTranslation();

    return (
        <FormSection
            className={styles.section}
            title={title}
            headerEndSlot={
                addOptions ? (
                    <div className={styles.optionsContainer}>
                        <FormActionButton
                            type="button"
                            className={styles.optionItem}
                            onClick={() => {
                                addToPosition !== undefined
                                    ? insert(addToPosition, defaultValues)
                                    : append(defaultValues);
                            }}
                        >
                            {addTitle ? addTitle : t('form.addItem')}
                        </FormActionButton>
                    </div>
                ) : (
                    <FormActionButton onClick={() => append(defaultValues)}>{t('form.addItem')} +</FormActionButton>
                )
            }
        >
            <DragAndDropFormArray getLoadingDischargeIndexes={getLoadingDischargeIndexes} append={append} insert={insert} className={styles.container} {...props} />
        </FormSection>
    );
};
