import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { createSearchParams, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounce } from '@uidotdev/usehooks';
import { ChevronRightIcon } from 'assets/icons';
import { config } from 'config';
import { fetchLocationQuery, useLazyGetCompanyDataQuery } from 'core/api';
import { selectAuth } from 'core/auth/services/selectors';
import { User } from 'core/auth/types';
import { useNonTypedTranslation } from 'core/translation';
import { useAppDispatch, useAppSelector } from 'hooks';
import useChange from 'hooks/useChange';
import { usePostOnboardingEmailMutation } from 'modules/carriers/services';
import { createFullName } from 'modules/carriers/utils/FormattingCarrierData';
import { countryCodeToLanguageId, getCountryOptions } from 'modules/common';
import {
    ComboBoxField,
    Form,
    FormActionButton,
    FormGrid,
    FormSection,
    RenderArgs,
    TextAreaField,
    TextField,
    VerticalFormArray,
    VisualFormInputsContext,
} from 'modules/form';
import { OnboardingState } from 'modules/onboarding/components/OnboardingState';
import { Button, Checkbox, Modal, MultiSelectInput, Tooltip, Typography } from 'modules/ui';
import { removeDispatcher } from 'store/appSlice';
import { getSavedDispatchers } from 'store/appSlice/selectors';
import { universalLanguageFinder } from 'utils';
import { getProbableFetchLocationQueryResult } from 'utils/getProbableFetchLocationQueryResult';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';
import { z } from 'zod';

import { carrierDispatcherSchema, CarrierNewSchema, carrierNewSchema, DispatcherSchema } from '../../../carriers/types';
import { Countries } from '../../../common';

import styles from './CarrierBasicForm.module.scss';

export const CarrierBasicForm: React.FC<{
    fetchedData?: CarrierNewSchema;
    prepareData: (data: CarrierNewSchema, editedBy: User, oldData?: CarrierNewSchema) => void;
}> = ({ fetchedData, prepareData }) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    const navigate = useNavigate();
    const { user } = useAppSelector(selectAuth);

    const dispatch = useAppDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const isAlreadyCreated = Boolean(fetchedData);
    const [getCompanyData] = useLazyGetCompanyDataQuery();
    const [postOnboardingEmail, { isLoading: isOnboardingEmailFetching }] = usePostOnboardingEmailMutation();

    const [dispatcherIndexesToRemove, setDispatcherIndexesToRemove] = useState<number[]>([]);
    const [showSendOnboardingModal, setShowSendOnboardingModal] = useState(false);
    const [onboardingEmails, setOnboardingEmails] = useState<{ label: string; value: string }[]>([]);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const useCachedData = searchParams.get('useCachedData');
    // get cached carrier data
    const savedData = localStorage.getItem('lastOpenedCarrier');
    // get cached dispatchers
    const savedDispatchers = useAppSelector(getSavedDispatchers);

    // add dispatcher fullname to vehicles by ID, `cause server returns only ID
    const data = useMemo(() => transformIncomingData(fetchedData), [fetchedData]);

    const carrierUserCredentials = {
        addedBy: isAlreadyCreated ? data?.email : user?.email,
        editedBy: isAlreadyCreated ? data?.email : user?.email,
        phone: isAlreadyCreated ? data?.phone : user?.mobilePhone,
        firstName: isAlreadyCreated ? data?.firstName : user?.name,
        lastName: isAlreadyCreated ? data?.lastName : user?.surname,
        email: isAlreadyCreated ? data?.email : user?.email,
    };

    const methods = useForm<CarrierNewSchema>({
        defaultValues:
            useCachedData === 'true' && savedData
                ? {
                      ...JSON.parse(savedData),
                  }
                : data || {
                      place: {},
                      dispatcher: [],
                      ts_added: null,
                      companyRegistrationNumber: null,
                      ts_edited: null,
                  },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(
            carrierNewSchema(t).extend({
                dispatcher: z.array(carrierDispatcherSchema(t).extend({ email: z.string().nullable() })),
            }),
        ),
    });
    const { handleSubmit, control, reset, setValue, getValues } = methods;

    const { dispatcher, countryCode, postalCode, companyRegistrationNumber } = useWatch({
        control,
    });

    const debouncedPostalCode = useDebounce(postalCode, 1000);

    const dispatchersFieldArray = useFieldArray({
        control,
        name: 'dispatcher',
    });

    useEffect(() => {
        if (!savedDispatchers || isAlreadyCreated) return;
        setValue(
            'dispatcher',
            savedDispatchers.map((dispatcher) => ({
                ...dispatcher,
                lastRequestTimeSent: null,
                onboardingState: 'unregistered',
            })) as DispatcherSchema[],
        );
    }, [savedDispatchers]);

    // remove this carrier from localStorage & update values
    useEffect(() => {
        if (useCachedData === 'true') {
            localStorage.removeItem('lastOpenedCarrier');
            searchParams.delete('useCachedData');
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [useCachedData]);

    // fetch the company info
    // api docs: https://wwwinfo.mfcr.cz/ares/ares_xml_basic.html.cz
    useChange(() => {
        const fetchCompanyData = async (companyRegistrationNumber: number) => {
            const { data, isSuccess } = await getCompanyData({ companyRegistrationNumber });
            if (!isSuccess) return;

            setValue('company', data.name);
            setValue('postalCode', String(data.address.zip));
            setValue('city', data.address.city);
            setValue('street', `${data.address.street} ${data.address.houseNumber}`);
            setValue('taxId', data.dic);
            setValue('countryCode', data.address.country);
        };
        if (!companyRegistrationNumber || String(companyRegistrationNumber).length < 7) return;

        fetchCompanyData(Number(companyRegistrationNumber));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyRegistrationNumber]);

    useChange(
        () => {
            if (countryCode) setValue('country', Countries[countryCode]);
        },
        [countryCode],
        [],
    );

    // get new coordinates only after user directly changes postalCode (not after rendering)
    useChange(
        () => {
            const getCoordinates = async () => {
                const locations = await fetchLocationQuery({ postalCode: debouncedPostalCode });
                const location = getProbableFetchLocationQueryResult(locations);
                if (!location) return;

                setValue('place.latitude', location?.lat);
                setValue('place.longitude', location?.lng);
            };

            getCoordinates();
        },
        [debouncedPostalCode, setValue],
        [debouncedPostalCode],
    );

    const sendOnboardingEmail = async () => {
        if (!data?.carrier_id) return toast.error(t('carriers.saveCarrierFirst'));

        const receivers = onboardingEmails.map((email) => {
            const foundDispatcher = dispatcher?.find((dispatcher) => {
                return dispatcher.email === email.label;
            });
            return {
                email: email.label,
                lang: foundDispatcher?.language_id
                    ? universalLanguageFinder(String(foundDispatcher.language_id))?.lang || 'en'
                    : universalLanguageFinder(String(countryCode))?.lang || 'en',
                dispatcher_id: foundDispatcher?.dispatcher_id || undefined,
            };
        });

        if (receivers.length < 1 || !user) return;

        const sendEmailRequestBody = {
            to: receivers,
            carrier_id: data?.carrier_id,
            dispatcher: { name: user.name, surname: user.surname, phone: user.mobilePhone, email: user.email },
        };

        const postOnboardingEmailResponse = await postOnboardingEmail(sendEmailRequestBody);

        if ('error' in postOnboardingEmailResponse) return toast.error(t('carriers.modalOnboarding.error'));
        toast.success(t('carriers.modalOnboarding.success'));
        setOnboardingEmails([]);
        setShowSendOnboardingModal(false);
    };

    const onAddNewDispatcher = () => {
        localStorage.setItem('lastOpenedCarrier', JSON.stringify(getValues()));
        navigate({
            pathname: config.routes.dispatcher.new,
            search: createSearchParams({
                pageToReturn: window.location.pathname + window.location.search,
                carrier_id: fetchedData?.carrier_id ? String(fetchedData.carrier_id) : 'new',
            }).toString(),
        });
    };

    const getTooltipContent = () => {
        if (!data?.carrier_id) return t('carriers.saveCarrierFirst');
        else if (!dispatcherIndexesToRemove.length) return t('carriers.mustSelectDispatchers');
        return t('carriers.form.dispatcher.sendOnboardingEmail');
    };

    const onSubmit = (currentData: CarrierNewSchema) => {
        if (!user) return;
        prepareData({ ...currentData, ...carrierUserCredentials }, user, data);
    };

    return (
        <FormProvider {...methods}>
            <VisualFormInputsContext.Provider
                value={{
                    visualInputsList,
                    setVisualInputsList,
                }}
            >
                <Form
                    id="carrier-form"
                    onSubmit={handleSubmit(
                        (data: CarrierNewSchema) => {
                            onSubmit({
                                ...data,
                                place: {
                                    ...data.place,
                                    city: data.city,
                                    street: data.street,
                                    country: data.country,
                                    postalCode: data.postalCode,
                                    countryCode: data.countryCode,
                                },
                            });
                        },
                        (error) => handleErrorsWithNoInputs(error, visualInputsList),
                    )}
                    onReset={() => reset()}
                >
                    <FormSection title={t('carriers.form.basicInfo.sectionTitle')}>
                        <FormGrid columns={4}>
                            <TextField name="company" label={t('carriers.form.basicInfo.company')} />
                            <ComboBoxField
                                name="countryCode"
                                label={t('carriers.fields.country')}
                                options={getCountryOptions(tnt)}
                            />
                            <TextField name="postalCode" label={t('carriers.fields.postalCode')} />
                            <TextField name="city" label={t('carriers.form.basicInfo.city')} />
                            <TextField name="street" label={t('carriers.form.basicInfo.street')} />
                            <TextField
                                name="companyRegistrationNumber"
                                label={t('carriers.form.basicInfo.companyRegistrationNumber')}
                            />
                            <TextField name="taxId" label={t('carriers.form.basicInfo.taxId')} />
                        </FormGrid>
                    </FormSection>
                    <VerticalFormArray
                        className={styles.dispatchersSectionContainer}
                        {...dispatchersFieldArray}
                        name="dispatcher"
                        title={t('carriers.form.dispatcher.sectionTitle')}
                        addTitle={t('carriers.form.dispatcher.addDispatcher')}
                        onAddItem={onAddNewDispatcher}
                        defaultValues={{
                            firstName: '',
                            lastName: '',
                            phone: '',
                            email: '',
                            // if country is is not in the list (not Czech nor Germany), the default language is English
                            language_id: countryCodeToLanguageId[countryCode || 'CZ'] || 41,
                            lastRequestTimeSent: null,
                            places: [],
                            // notificationEmail: false,
                            // notificationWhatsapp: false,
                            dispatcherVehicles: [],
                            onboardingState: 'unregistered',
                        }}
                        headerEndSlot={
                            <div className={styles.dispatcherActionBtn}>
                                {dispatcherIndexesToRemove.length > 0 && (
                                    <FormActionButton
                                        variant="danger"
                                        onClick={() => {
                                            isAlreadyCreated
                                                ? dispatchersFieldArray.remove(dispatcherIndexesToRemove)
                                                : dispatch(removeDispatcher({ indexes: dispatcherIndexesToRemove }));
                                            setDispatcherIndexesToRemove([]);
                                        }}
                                    >
                                        {t('form.removeItem')}
                                    </FormActionButton>
                                )}
                                <Tooltip content={getTooltipContent()}>
                                    <div>
                                        <FormActionButton
                                            onClick={() => setShowSendOnboardingModal((prev) => !prev)}
                                            disabled={!data?.carrier_id || !dispatcherIndexesToRemove.length}
                                            variant="primary"
                                            type="button"
                                        >
                                            {t('carriers.form.dispatcher.sendOnboardingEmail')}
                                        </FormActionButton>
                                    </div>
                                </Tooltip>
                            </div>
                        }
                        render={({ index }: RenderArgs<CarrierNewSchema, DispatcherSchema>) => {
                            const currentDispatcher = dispatchersFieldArray.fields[index];
                            return (
                                <div className={styles.dispatcherItem}>
                                    <div className={styles.dispatcherInfo}>
                                        <Checkbox
                                            checked={dispatcherIndexesToRemove.includes(index)}
                                            onCheckedChange={(value) => {
                                                setOnboardingEmails((prev) =>
                                                    Boolean(value)
                                                        ? [
                                                              ...prev,
                                                              {
                                                                  label: currentDispatcher.email,
                                                                  value: currentDispatcher.email,
                                                              },
                                                          ]
                                                        : prev.filter(({ label }) => label !== currentDispatcher.email),
                                                );

                                                setDispatcherIndexesToRemove((prev) =>
                                                    Boolean(value)
                                                        ? [...prev, index]
                                                        : prev.filter((item) => item !== index),
                                                );
                                            }}
                                        />
                                        <div className={styles.dispatcherStateAndName}>
                                            <OnboardingState
                                                size="small"
                                                state={currentDispatcher.onboardingState || 'unregistered'}
                                            />
                                            <Typography className={styles.dispatcherName} variant="h5">
                                                {createFullName(
                                                    dispatchersFieldArray.fields[index].firstName,
                                                    dispatchersFieldArray.fields[index].lastName,
                                                )}
                                            </Typography>
                                        </div>
                                    </div>
                                    <NavLink
                                        className={styles.dispatcherButton}
                                        onClick={() => {
                                            localStorage.setItem('lastOpenedCarrier', JSON.stringify(getValues()));
                                        }}
                                        to={{
                                            pathname: `${config.routes.dispatcher.list}/${
                                                isAlreadyCreated ? currentDispatcher.dispatcher_id : `new`
                                            }`,
                                            search: createSearchParams({
                                                pageToReturn: window.location.pathname + window.location.search,
                                                carrier_id: fetchedData?.carrier_id
                                                    ? String(fetchedData.carrier_id)
                                                    : 'new',
                                            }).toString(),
                                        }}
                                    >
                                        <ChevronRightIcon />
                                    </NavLink>
                                </div>
                            );
                        }}
                    />
                    <FormSection title={t('carriers.form.note.sectionTitle')}>
                        <TextAreaField
                            name="note"
                            label={t('carriers.form.note.sectionTitle')}
                            placeholder={t('carriers.form.note.sectionTitle')}
                        />
                    </FormSection>
                </Form>
                {showSendOnboardingModal && (
                    <Modal
                        onClick={() => {
                            setShowSendOnboardingModal(false);
                            setOnboardingEmails([]);
                        }}
                        label={t('carriers.modalOnboarding.label')}
                        width={930}
                        cancelComponent={
                            <Button
                                className={styles.btn}
                                type="button"
                                variant="secondary"
                                onClick={() => {
                                    setShowSendOnboardingModal(false);
                                }}
                            >
                                {t('carriers.modalOnboarding.cancel')}
                            </Button>
                        }
                        approveComponent={
                            <Button
                                isLoading={isOnboardingEmailFetching}
                                disabled={isOnboardingEmailFetching}
                                className={styles.btn}
                                type="button"
                                variant="primary"
                                onClick={sendOnboardingEmail}
                            >
                                {t('carriers.modalOnboarding.approve')}
                            </Button>
                        }
                    >
                        <>
                            <Typography className={styles.modalDescription} variant="p">
                                {t('carriers.modalOnboarding.dispatchersEmail')}
                            </Typography>
                            <MultiSelectInput
                                options={
                                    dispatcher?.map((item) => ({ label: item.email || '', value: item.email || '' })) ||
                                    []
                                }
                                placeholder={t('carriers.modalOnboarding.dispatcherEmail')}
                                selected={onboardingEmails}
                                onValuesChange={setOnboardingEmails}
                            />
                        </>
                    </Modal>
                )}
            </VisualFormInputsContext.Provider>
        </FormProvider>
    );
};

// find a dispatcher name by its id & add that name to associated vehicle (the dispatcher name is not returned from the server)
function transformIncomingData(data?: CarrierNewSchema): CarrierNewSchema | undefined {
    if (!data) return;

    const fetchedData = structuredClone(data);

    // object to get dispatcher name by dispatcher ID
    const dispatchersNameByID: { [id: number]: string } = {};

    // get all dispatcher names, combine with ID
    fetchedData.dispatcher = fetchedData.dispatcher.map((item) => {
        if (item.dispatcher_id) {
            dispatchersNameByID[item.dispatcher_id] = createFullName(item.firstName, item.lastName);
        }
        return {
            ...item,
            lastRequestTimeSent: item.lastRequestTimeSent || new Date().getTime(),
        };
    });

    fetchedData.companyRegistrationNumber = fetchedData.companyRegistrationNumber?.padStart(8, '0') || null;
    fetchedData.ts_added = Number(fetchedData.ts_added);
    fetchedData.ts_edited = Number(fetchedData.ts_edited);

    // here we set the countryCode, if there is only the country name
    let countryInfo: [string, Countries] | undefined = undefined;
    if ((!fetchedData.place.countryCode || fetchedData.place.countryCode.length > 2) && fetchedData.place.country) {
        countryInfo = Object.entries(Countries).find(([_key, value]) => fetchedData.place?.country === value);
    } else if ((!fetchedData.countryCode || fetchedData.countryCode.length > 2) && fetchedData.country) {
        countryInfo = Object.entries(Countries).find(([_key, value]) => fetchedData.country === value);
    }

    if (countryInfo) {
        const countryCode = countryInfo[0] as keyof typeof Countries;
        fetchedData.countryCode = countryCode;
        fetchedData.place.countryCode = countryCode;
    }

    fetchedData.country = fetchedData.place.country ? fetchedData.place.country : fetchedData.country;
    fetchedData.countryCode = fetchedData.place.countryCode ? fetchedData.place.countryCode : fetchedData.countryCode;
    fetchedData.postalCode = fetchedData.place.postalCode ? fetchedData.place.postalCode : fetchedData.postalCode;
    fetchedData.city = fetchedData.place.city ? fetchedData.place.city : fetchedData.city;
    fetchedData.street = fetchedData.place.street ? fetchedData.place.street : fetchedData.street;

    return fetchedData;
}
