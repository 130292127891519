import * as React from 'react';
import type { OverlayTriggerState } from 'react-stately';
import type { AriaPopoverProps } from '@react-aria/overlays';
import { DismissButton, Overlay, usePopover } from '@react-aria/overlays';
import classnames from 'classnames';

import styles from './ComboBoxPopover.module.scss';

interface ComboBoxPopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
    children: React.ReactNode;
    state: OverlayTriggerState;
    popoverRef?: React.RefObject<HTMLDivElement>;
    width?: number;
}

export const ComboBoxPopover = (props: ComboBoxPopoverProps) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const { popoverRef = ref, state, children, isNonModal, width } = props;

    const { popoverProps, underlayProps } = usePopover(
        {
            ...props,
            popoverRef,
        },
        state,
    );

    const { style, ...otherPopoverProps } = popoverProps;
    const mergedStyles = React.useMemo(() => {
        return Object.assign({}, { width: `${width}px` }, style);
    }, [width, style]);

    return (
        <Overlay>
            {!isNonModal && <div {...underlayProps} className={styles.underlay} />}
            <div
                ref={popoverRef}
                className={classnames(styles.popover, styles.random)}
                style={mergedStyles}
                {...otherPopoverProps}
            >
                {!isNonModal && <DismissButton onDismiss={state.close} />}
                {children}
                <DismissButton onDismiss={state.close} />
            </div>
        </Overlay>
    );
};
