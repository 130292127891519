import { useFieldArray } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { useNonTypedTranslation } from 'core/translation';
import { DispatcherVehicleFeature } from 'modules/carriers';
import { BaseFieldProps } from 'modules/form/types';
import { getVehicleFeatureOptions } from 'modules/onboarding/utils/vehicleFeature';
import { Checkbox, type CheckboxProps, FormError, FormField, FormLabel } from 'modules/ui';

import styles from './VehicleFeatures.module.scss';

interface OptionsProps {
    // nestIndex: number;
    name: string;
    control: any;
    register: any;
    setValue: any;
    possibleVehicleFeatures: number[];
}

export const VehicleFeatures: React.FC<OptionsProps> = ({ name, control, possibleVehicleFeatures }) => {
    const { tnt } = useNonTypedTranslation();

    const { fields: arrayVehicleFeatures, replace } = useFieldArray({
        control,
        name,
    });
    const features = arrayVehicleFeatures as DispatcherVehicleFeature[];
    // get the "checked" features (if they exist that means that checkbox checked should be true)
    const activeFieldsFiltered = features.filter((item) => item.vehicleFeature_id) as {
        vehicleFeature_id: number;
    }[];
    const activeFields = activeFieldsFiltered.map(
        (item) => getVehicleFeatureOptions(tnt)[item.vehicleFeature_id - 1].label,
    );

    const removeItem = (id: number) => {
        const newValues = features.filter((item) => {
            return item.vehicleFeature_id !== id;
        });
        replace(newValues);
    };
    const addItem = (id: number) => {
        if (!id) return;
        replace([...features.filter((item) => item.vehicleFeature_id !== undefined), { vehicleFeature_id: id }]);
    };

    return (
        <>
            {possibleVehicleFeatures &&
                possibleVehicleFeatures
                    .filter((number) => number >= 1 && number < 15)
                    .map((item, k) => {
                        const feature = getVehicleFeatureOptions(tnt).find((feature) => feature.value === item);
                        const checked = activeFields.includes(feature?.label as string);
                        return (
                            <CheckboxFieldForCarrier
                                checked={checked}
                                name={`${name}[${k}].vehicleFeature_id`}
                                label={feature?.label as string}
                                key={feature?.value}
                                removeItem={removeItem}
                                addItem={addItem}
                                feature_id={feature?.value as number}
                            />
                        );
                    })}
        </>
    );
};

export type CheckboxFieldForCarrierProps = BaseFieldProps &
    CheckboxProps & {
        outlined?: boolean;
        removeItem: (name: number) => void;
        addItem: (name: number) => void;
        feature_id: number;
    };

const CheckboxFieldForCarrier = ({
    name,
    label,
    removeItem,
    addItem,
    checked,
    feature_id,
    ...props
}: CheckboxFieldForCarrierProps) => {
    const toggleStates = (checked: boolean) => {
        checked ? removeItem(feature_id) : addItem(feature_id);
    };

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={name}>
                    <div
                        className={classNames(styles.field, {
                            [styles['field-error']]: error,
                        })}
                    >
                        <Checkbox {...field} id={name} value={undefined} checked={checked} {...props} />
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <div
                            onClick={() => {
                                toggleStates(!!checked);
                            }}
                            className={styles['invisible-button']}
                        />
                    </div>
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
