import { NonTypedTranslation } from 'core/translation';

import { Countries } from '../enums';

export const getCountryOptions = (
    t: NonTypedTranslation,
): {
    value: keyof typeof Countries;
    label: string;
}[] =>
    Object.keys(Countries).map((value) => ({ value: value as keyof typeof Countries, label: t(`countries.${value}`) }));
