import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SelectedDispatcher } from '../hooks/useSelectedDispatchers';
import { DispatcherSearchParameters } from '../types';

export type CommissionSliceState = {
    selectedDispatchersForEnquiryEmail: SelectedDispatcher[];
    dispatcherSearchParameters: DispatcherSearchParameters;
};

const initialState: CommissionSliceState = {
    selectedDispatchersForEnquiryEmail: [],
    dispatcherSearchParameters: {
        minHeight: 0,
        minLength: 0,
        minWeight: 0,
        minWidth: 0,
        vehicleFeaturesMustHaveAll: [],
        vehicleFeaturesMustHaveOne: [],
        vehicleType: [],
    },
};

export const commissionSlice = createSlice({
    name: 'commission',
    initialState,
    reducers: {
        setDispatcherSearchParameters: (state, { payload }: PayloadAction<DispatcherSearchParameters>) => {
            state.dispatcherSearchParameters = payload;
            return state;
        },
        setDispatcherSearchParameter: (
            state,
            { payload }: PayloadAction<{ key: keyof DispatcherSearchParameters; value: number & number[] }>,
        ) => {
            if (!state.dispatcherSearchParameters) return;
            state.dispatcherSearchParameters[payload.key] = payload.value;
            return state;
        },
    },
});

export const { setDispatcherSearchParameter, setDispatcherSearchParameters } = commissionSlice.actions;
