import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { config } from 'config';
import { passwordMinLength } from 'core/auth/config';
import { useAppSelector } from 'hooks';
import { Form, VisualFormInputsContext } from 'modules/form';
import { PlacesInfoForm } from 'modules/onboarding/components/PlacesInfoForm';
import { GetOneDispatcherResponseSchema, OnboardingSchema, onboardingSchema } from 'modules/onboarding/types';
import { getSavedDispatchers } from 'store/appSlice/selectors';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';
import { z } from 'zod';

import { BasicInfoForm } from '../BasicInfoForm';
import { VehiclesInfoForm } from '../VehiclesInfoForm';

import styles from './DispatcherBasicForm.module.scss';

interface IOnboardingPageForm {
    carrier_id?: number;
    viewSide: 'profile' | 'detail';
    selectedDispatcherIndex?: number;
    fetchedData?: GetOneDispatcherResponseSchema;
    prepareData: (data: OnboardingSchema, oldData?: OnboardingSchema) => void;
}

export const DispatcherBasicForm = ({
    carrier_id,
    viewSide,
    selectedDispatcherIndex,
    fetchedData,
    prepareData,
}: IOnboardingPageForm) => {
    const { t } = useTranslation();

    // get saved dispatcher
    const savedDispatchers = useAppSelector(getSavedDispatchers);

    const [isEmailCheckFetching, setIsEmailCheckFetching] = useState(false);
    const [isEmailAvailable, setIsEmailAvailable] = useState(true);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const transformIncomingData = (data?: GetOneDispatcherResponseSchema): OnboardingSchema | undefined => {
        if (!data) return;

        const { dispatchervehicle, place, ...rest } = structuredClone(data);

        //? Convert from cm to m
        dispatchervehicle.forEach((vehicle) => {
            vehicle.maxHeight = vehicle.maxHeight / 100;
            vehicle.maxWidth = vehicle.maxWidth / 100;
            vehicle.maxLength = vehicle.maxLength / 100;
        });

        const availableVehicleTypeIds = dispatchervehicle.map((vehicle) => vehicle.vehicleType_id);

        const template = {
            ...rest,
            dispatcherVehicles: dispatchervehicle
                .sort((prev, next) => (prev.vehicleType_id < next.vehicleType_id ? -1 : 1))
                .map(({ maxLength, maxWidth, maxWeight, maxHeight, ...rest }) => ({
                    ...rest,
                    maxLength: Number(maxLength),
                    maxWidth: Number(maxWidth),
                    maxWeight: Number(maxWeight),
                    maxHeight: Number(maxHeight),
                })),
            places: place.map(({ latitude, longitude, ...rest }) => ({
                latitude: Number(latitude),
                longitude: Number(longitude),
                ...rest,
            })),
            password: '',
            confirmPassword: '',
            deliveryVehicle: availableVehicleTypeIds.includes(1),
            soloVehicle: availableVehicleTypeIds.includes(2),
            semiTrailerVehicle: availableVehicleTypeIds.includes(3),
            combinationVehicle: availableVehicleTypeIds.includes(4),
        };

        return template;
    };

    const data = useMemo(() => transformIncomingData(fetchedData), [fetchedData]);

    const methods = useForm<OnboardingSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(
            onboardingSchema(t)
                .extend({
                    ...(!carrier_id && { carrier_id: z.number().nullish() }),
                    password: z
                        .string()
                        .min(passwordMinLength, {
                            message: t('auth.password.error.length', { minLength: passwordMinLength }),
                        })
                        .nullish()
                        .or(z.literal('')),
                    confirmPassword: z.string().nullish().or(z.literal('')),
                })
                .refine((data) => data.password === data.confirmPassword, {
                    message: t('auth.confirmPassword.error.dontMatch'),
                    path: ['confirmPassword'],
                }),
        ),
        defaultValues:
            typeof selectedDispatcherIndex === 'number'
                ? savedDispatchers[Number(selectedDispatcherIndex)]
                : data || {
                      carrier_id: carrier_id ? carrier_id : undefined,
                      notificationEmail: false,
                      notificationWhatsapp: false,
                      deliveryVehicle: false,
                      soloVehicle: false,
                      phone: null,
                      semiTrailerVehicle: false,
                      combinationVehicle: false,
                      dispatcherVehicles: [],
                      places: [],
                  },
    });

    const onSubmit = async (currentData: OnboardingSchema) => {
        if (isEmailCheckFetching) {
            return toast.error(t('auth.email.error.waitCheckEmail'));
        }

        if (!isEmailAvailable) {
            return methods.setError('email', { message: t('auth.email.error.RECORD_NOT_UNIQUE') });
        }

        prepareData(currentData, data);
    };

    if (typeof selectedDispatcherIndex === 'number' && !savedDispatchers[selectedDispatcherIndex])
        return <Navigate to={`${config.routes.carriers.list}/new`} />;

    return (
        <FormProvider {...methods}>
            <VisualFormInputsContext.Provider
                value={{
                    visualInputsList,
                    setVisualInputsList,
                }}
            >
                <Form
                    id="dispatcher-form"
                    onSubmit={methods.handleSubmit(onSubmit, (error) =>
                        handleErrorsWithNoInputs(error, visualInputsList),
                    )}
                    className={styles.formContainer}
                >
                    <BasicInfoForm
                        viewSide={viewSide}
                        originalUserEmail={fetchedData?.email}
                        setIsEmailCheckFetching={setIsEmailCheckFetching}
                        setIsEmailAvailable={setIsEmailAvailable}
                    />
                    <VehiclesInfoForm viewSide={viewSide} methods={methods} />
                    <PlacesInfoForm methods={methods} />
                </Form>
            </VisualFormInputsContext.Provider>
        </FormProvider>
    );
};
