import { Tooltip } from 'modules/ui';

import styles from './FormLabel.module.scss';

type FormLabelProps = React.ComponentProps<'label'>;

export const FormLabel = ({ children, ...props }: FormLabelProps) => {
    return (
        // Ensure tooltip is displayed for truncated labels
        // (e.g., "Customer ad..." showing full label "Customer address")
        <Tooltip content={children} align="center">
            <label className={styles.label} {...props}>
                {children}
            </label>
        </Tooltip>
    );
};
