import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'core/application/hooks';
import useUpdateEffect from 'hooks/useUpdateEffect';

import { useLogout } from '../../hooks';

export interface LogoutPageProps {}

export const LogoutPage = ({}: LogoutPageProps) => {
    const logout = useLogout();
    const { t } = useTranslation();

    useDocumentTitle(t('nav.logout'));
    useUpdateEffect(() => {
        logout();
    }, []);

    return null;
};
