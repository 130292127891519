import React from 'react';
import { Controller } from 'react-hook-form';
import { MultiSwitch, MultiSwitchProps } from 'modules/multiswitch';
import { FormError, FormField, FormLabel } from 'modules/ui';

export const MultiSwitchField: React.FC<Omit<MultiSwitchProps, 'value' | 'onChange'>> = ({ name, label, ...props }) => {
    return (
        <Controller
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                return (
                    <FormField name={name}>
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <MultiSwitch
                            name={name}
                            label={label}
                            {...props}
                            value={value}
                            onChange={(value) => {
                                onChange(value);
                            }}
                        />
                        <FormError>{error?.message}</FormError>
                    </FormField>
                );
            }}
        />
    );
};
