import { t } from 'i18next';
import { carrierPlaceSchema, dispatcherSchema, dispatcherVehicleFeature } from 'modules/carriers/types';
import { dispatcherVehicleSchema } from 'modules/onboarding/types';
import { z } from 'zod';

const dispatcherCreateSchema = dispatcherSchema(t)
    .omit({ onboardingState: true })
    .extend({
        dispatcherVehicles: z
            .object({
                toCreate: z
                    .array(
                        dispatcherVehicleSchema()
                            .omit({ dispatchervehiclefeature: true })
                            .extend({
                                dispatcherVehicleFeatures: z
                                    .object({ toCreate: z.array(dispatcherVehicleFeature()).optional() })
                                    .optional(),
                            }),
                    )
                    .optional(),
            })
            .optional(),
        places: z
            .object({
                toCreate: z.array(carrierPlaceSchema(t)).optional(),
            })
            .optional(),
    });

const dispatcherRemoveSchema = z.object({ dispatcher_id: z.number() });

export const TransformedDispatchersSchema = z.object({
    toCreate: z.array(dispatcherCreateSchema).optional(),
    toDelete: z.array(dispatcherRemoveSchema).optional(),
});

export type DispatcherCreateSchema = z.infer<typeof dispatcherCreateSchema>;
export type DispatcherRemoveSchema = z.infer<typeof dispatcherRemoveSchema>;
export type TransformedDispatchersSchema = z.infer<typeof TransformedDispatchersSchema>;
