import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CarrierNewSchema } from 'modules/carriers';
import { DispatcherSearchParameters } from 'modules/commissions';
import { tableNames } from 'modules/common';
import { CommissionCarrierForm, CommissionCarrierTable } from 'modules/form';
import { setFetchedDispatchers } from 'modules/offers/services';
import { Button, Typography } from 'modules/ui';

import styles from './ModifyParametersModal.module.scss';

interface IModifyParametersModalProps {
    // usually first loading of commission and last discharge
    loadingParams: { lat: number; lon: number; radius: number };
    dischargeParams: { lat: number; lon: number; radius: number };
    defaultValues: DispatcherSearchParameters;
    setShowModal: (value: boolean) => void;
    onSubmit: (dispatcherSearchParameters: DispatcherSearchParameters) => void;
}

export const ModifyParametersModal: React.FC<IModifyParametersModalProps> = ({
    setShowModal,
    loadingParams,
    dischargeParams,
    defaultValues,
    onSubmit,
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { existedDispatchers, fetchedDispatchers } = useAppSelector((state) => state.offer);
    const { dispatcherSearchParameters } = useAppSelector((state) => state.commission);

    const existedDispatchersFlat = existedDispatchers.flatMap((carrier) =>
        carrier.dispatcher.map((dispatcher) => ({
            carrier_id: carrier.carrier_id || 0,
            company: carrier.company || '',
            dispatcher: [dispatcher],
            place: carrier?.place || {},
        })),
    );

    const fetchedIds = fetchedDispatchers?.flatMap((item) =>
        item.dispatcher.map((dispatcher) => dispatcher.dispatcher_id || 0),
    );

    return (
        <div className={styles.findCarrierModal} onClick={() => setShowModal(false)}>
            <div className={styles.findCarrierModalWrapper} onClick={(e) => e.stopPropagation()}>
                <div className={styles.findCarrierModalTitle}>
                    <Typography variant="headline-h4">{t('offers.modifyParametersModal.title')}</Typography>
                </div>
                <CommissionCarrierForm
                    locations={{
                        loading: loadingParams,
                        discharge: dischargeParams,
                    }}
                    defaultValues={defaultValues}
                    hiddenFields={['searchType', 'directions']}
                    onFetchedData={(carriers: CarrierNewSchema[]) => {
                        dispatch(setFetchedDispatchers(carriers));
                    }}
                />
                <div className={styles.selectCarrierTitle}>
                    <Typography variant="headline-h4">
                        {t('offers.modifyParametersModal.alreadyContactedDispatchers')}
                    </Typography>
                </div>
                <CommissionCarrierTable
                    data={existedDispatchers}
                    disabledIds={existedDispatchersFlat.map((item) => item.dispatcher[0].dispatcher_id || 0)}
                    notSatisfyParamsIds={
                        fetchedIds
                            ? existedDispatchersFlat
                                  .filter((item) => !fetchedIds.includes(item.dispatcher[0].dispatcher_id || 0))
                                  .map((item) => item.dispatcher[0].dispatcher_id || 0)
                            : undefined
                    }
                    tableName={tableNames.offers.modifyModal}
                />
                <div className={styles.buttons}>
                    <Button onClick={() => setShowModal(false)} variant="secondary">
                        {t('offers.modifyParametersModal.cancel')}
                    </Button>
                    <Button onClick={() => onSubmit(dispatcherSearchParameters)} variant="primary">
                        {t('offers.modifyParametersModal.approve')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
