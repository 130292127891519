import { CarrierDispatcherSchema, DispatcherSchema } from 'modules/carriers/types';
import { transformDispatcherVehicles } from 'modules/onboarding/utils/FormattingOnboardingData/DispatcherVehicles';

import { DispatcherCreateSchema, DispatcherRemoveSchema, TransformedDispatchersSchema } from './types';

export function transformDispatchers({
    dispatchers,
    oldDispatchers = [],
}: {
    dispatchers: (DispatcherSchema | CarrierDispatcherSchema)[];
    oldDispatchers?: (DispatcherSchema | CarrierDispatcherSchema)[];
}): TransformedDispatchersSchema | undefined {
    function modifyAddedDispatchers(items: (DispatcherSchema | CarrierDispatcherSchema)[]): DispatcherCreateSchema[] {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return items
            .filter(({ dispatcher_id }) => !dispatcher_id)
            .map(({ dispatcherVehicles, places, ...item }) => {
                delete item.onboardingState;
                const currentDispatcher: DispatcherCreateSchema = {
                    ...item,
                    lastRequestTimeSent: new Date().getTime(),
                    ...(places?.length && {
                        places: {
                            ...(places?.length > 0 && { toCreate: places }),
                        },
                    }),
                };

                if (dispatcherVehicles) {
                    currentDispatcher.dispatcherVehicles = {};
                    const transformedDispatchers = transformDispatcherVehicles({ dispatcherVehicles });

                    currentDispatcher.dispatcherVehicles = transformedDispatchers;
                }

                return currentDispatcher;
            }) as DispatcherCreateSchema[];
    }
    function modifyRemovedDispatchers(items: (DispatcherSchema | CarrierDispatcherSchema)[]): DispatcherRemoveSchema[] {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return items.map(({ dispatcher_id }) => ({ dispatcher_id })) as DispatcherRemoveSchema[];
    }

    // dispatchers which are new, to create
    const added = dispatchers.filter((item) => !item.dispatcher_id);
    // dispatchers which are updated or unchanged
    const others = dispatchers.filter((item) => item.dispatcher_id);
    const othersIDs = others.map((item) => item.dispatcher_id) as number[];
    const removed = oldDispatchers.filter((item) => !othersIDs.includes(item?.dispatcher_id as number));

    const template: TransformedDispatchersSchema = {};
    const modificatedAddedDispatchers = modifyAddedDispatchers(added);
    const modificatedRemovedDispatchers = modifyRemovedDispatchers(removed);

    // add only if they are not empty
    if (modificatedAddedDispatchers?.length) {
        template.toCreate = modificatedAddedDispatchers;
    }
    if (modificatedRemovedDispatchers?.length) {
        template.toDelete = modificatedRemovedDispatchers;
    }

    return template as TransformedDispatchersSchema;
}
