import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import { type BaseFieldProps } from 'modules/form';
import { FormError, FormField, FormLabel, Select, type SelectProps } from 'modules/ui';

export type SelectFieldProps = BaseFieldProps & Omit<SelectProps, 'placeholder'>;

export const SelectField = ({
    name,
    label,
    placeholder,
    setValueDebounce,
    isNumber = false,
    ...props
}: SelectFieldProps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTyping = useCallback(
        debounce((value) => {
            if (setValueDebounce) setValueDebounce(value);
        }, 300),
        [],
    );

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={name}>
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <Select
                        id={name}
                        error={!!error}
                        placeholder={placeholder || label}
                        onValueChange={(value) => {
                            field.onChange(isNumber ? Number(value) : value);
                            if (setValueDebounce) handleTyping(value);
                        }}
                        {...field}
                        {...props}
                        value={String(field.value)}
                    />
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
