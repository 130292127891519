import { NonTypedTranslation } from 'core/translation';

export const vehicleType = {
    1: 'Dodávka',
    2: 'Sólo',
    3: 'Návěs',
    4: 'Souprava',
};

export const getVehicleTypeOptions = (
    t: NonTypedTranslation,
): {
    value: keyof typeof vehicleType;
    label: string;
}[] => {
    return Object.keys(vehicleType).map((key) => ({
        value: Number(key) as keyof typeof vehicleType,
        label: t(`onboarding.vehicleInfoForm.vehicleType.${key}`),
    }));
};
