import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import { type BaseFieldProps } from 'modules/form';
import { FormError, FormField, FormLabel, Input, type InputProps } from 'modules/ui';

export type TextFieldProps = BaseFieldProps & Omit<InputProps, 'ref'>;

export const TextField = ({
    name,
    label,
    placeholder,
    type,
    setValueDebounce,
    debounceInterval = 300,
    ...props
}: TextFieldProps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTyping = useCallback(
        debounce((value) => {
            if (setValueDebounce) setValueDebounce(value);
        }, debounceInterval),
        [],
    );

    return (
        <Controller
            name={name}
            render={({ field: { onChange, ...fieldProps }, fieldState: { error } }) => (
                <FormField name={name}>
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <Input
                        id={name}
                        type={type}
                        error={!!error}
                        placeholder={placeholder || label}
                        onChange={(e) => {
                            if (type === 'number') {
                                const value = e.target.valueAsNumber;
                                onChange(!Number.isNaN(value) ? value : null);
                            } else {
                                onChange(e.target.value);
                                if (setValueDebounce) handleTyping(e.target.value);
                            }
                        }}
                        {...fieldProps}
                        {...props}
                    />
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
