import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config } from 'config';
import { useDocumentTitle } from 'core/application';
import { setToken } from 'core/auth/services';
import { useAppDispatch, useQuery } from 'hooks';
import jwt_decode from 'jwt-decode';
import {
    useGetCheckOnboardingTokenQuery,
    useGetOneDispatcherQuery,
    usePostNewDispatcherMutation,
    usePutUpdatedDispatcherMutation,
} from 'modules/onboarding/services';
import { transformOnboardingData } from 'modules/onboarding/utils';
import { Toast } from 'modules/toast';
import { Button, Spinner, Typography } from 'modules/ui';

import { DecodedQueryParams, OnboardingSchema } from '../../types';
import { OnboardingPageForm } from '../OnboardingPageForm';

import styles from './OnboardingPage.module.scss';

export const OnboardingPage = () => {
    const { t } = useTranslation();
    const query = useQuery();
    useDocumentTitle(t('onboarding.title'));
    const navigate = useNavigate();

    const [postNewDispatcher] = usePostNewDispatcherMutation();
    const [putUpdatedDispatcher] = usePutUpdatedDispatcherMutation();

    const dispatch = useAppDispatch();
    const token = query.get('token');

    const language = query.get('lang') as 'cs' | 'de' | 'en';
    const decoded: DecodedQueryParams | null = token ? jwt_decode(token) : null;
    const { isFetching: isValidatingTokenFetching, isError: isValidationTokenError } =
        useGetCheckOnboardingTokenQuery(null);

    if (!decoded?.carrier_id) navigate(config.routes.auth.login);

    dispatch(setToken(token));

    const { data: dispatcherData, isLoading: isDispatcherLoading } = useGetOneDispatcherQuery(
        { id: decoded?.dispatcher_id || 0 },
        { skip: !decoded?.dispatcher_id },
    );

    const prepareData = async (data: OnboardingSchema, oldData?: OnboardingSchema) => {
        if (data.password !== data.confirmPassword || data.password.trim().length < 8) return;

        const formatted = transformOnboardingData({ currentData: data, oldData });

        try {
            decoded?.dispatcher_id
                ? await putUpdatedDispatcher({ data: formatted, id: decoded?.dispatcher_id }).unwrap()
                : await postNewDispatcher({ data: formatted }).unwrap();

            decoded?.dispatcher_id ? toast.success(t('onboarding.updated')) : toast.success(t('onboarding.created'));

            const enquiryId = query.get('enquiryId');
            navigate(`${config.routes.auth.login}?email=${data.email}&${enquiryId ? `enquiryId=${enquiryId}` : ''}`);
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    if (
        (decoded?.dispatcher_id && isDispatcherLoading) ||
        !decoded?.carrier_id ||
        !language ||
        isValidatingTokenFetching
    )
        return <Spinner fullScreen />;

    return (
        <>
            {isValidationTokenError ? (
                <div className={styles.notActiveLinkContainer}>
                    <div className={styles.notActiveLink}>
                        <Typography variant="h2">{t('onboarding.linkIsNotActive')}</Typography>
                        <Typography variant="p">{t('onboarding.linkIsNotActiveInfo')}</Typography>
                        <Button onClick={() => navigate(config.routes.auth.login)}>{t('auth.login.title')}</Button>
                    </div>
                </div>
            ) : (
                <OnboardingPageForm
                    fetchedData={dispatcherData}
                    carrier_id={decoded.carrier_id}
                    language={language}
                    prepareData={prepareData}
                />
            )}
            <Toast />
        </>
    );
};
