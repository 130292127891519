import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useLazyGetCompanyDataQuery } from 'core/api';
import { selectAuth } from 'core/auth/services/selectors';
import { User } from 'core/auth/types';
import { useNonTypedTranslation } from 'core/translation';
import { useAppSelector } from 'hooks';
import useChange from 'hooks/useChange';
import { createFullName } from 'modules/carriers/utils';
import { Countries, getCountryOptions } from 'modules/common';
import {
    ContactCustomerSchema,
    CustomerDetailSchema,
    customerDetailSchema,
    LocationCustomerSchema,
} from 'modules/customers';
import {
    ComboBoxField,
    Form,
    FormActionButton,
    FormGrid,
    FormSection,
    LocationItemModal,
    LocationsTable,
    RenderArgs,
    TextAreaField,
    TextField,
    VerticalFormArray,
    VisualFormInputsContext,
} from 'modules/form';
import { Typography } from 'modules/ui';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { FormCard } from '../FormCard/FormCard';
import { NumberField } from '../NumberField';

import styles from './CustomersBasicForm.module.scss';

export const CustomersBasicForm: React.FC<{
    fetchedData?: CustomerDetailSchema;
    prepareData: (data: CustomerDetailSchema, editedBy: User, oldData?: CustomerDetailSchema) => void;
}> = ({ fetchedData, prepareData }) => {
    const { user } = useAppSelector(selectAuth);
    const [getCompanyData] = useLazyGetCompanyDataQuery();

    const data = useMemo(() => transformIncomingData(fetchedData), [fetchedData]);

    const formDefaultValues = {
        defaultDueDate: 45,
        companyRegistrationNumber: null,
        note: null,
        places: {},
    };

    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    const methods = useForm<CustomerDetailSchema>({
        defaultValues: data || formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(customerDetailSchema(t)),
    });
    const { handleSubmit, control, reset, setValue } = methods;
    const { customercontact, countryCode, companyRegistrationNumber } = useWatch({ control });

    const contactsFieldArray = useFieldArray({
        control,
        name: 'customercontact',
    });
    const locationFieldArray = useFieldArray({
        control,
        name: 'location',
    });

    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    // fetch the company info
    // api docs: https://wwwinfo.mfcr.cz/ares/ares_xml_basic.html.cz
    useChange(() => {
        const fetchCompanyData = async (companyRegistrationNumber: number) => {
            const { data, isSuccess } = await getCompanyData({ companyRegistrationNumber });
            if (!isSuccess) return;

            setValue('company', data.name);
            setValue('postalCode', String(data.address.zip));
            setValue('city', data.address.city);
            setValue('street', `${data.address.street} ${data.address.houseNumber}`);
            setValue('taxId', data.dic);
            setValue('countryCode', data.address.country);
        };
        if (!companyRegistrationNumber || String(companyRegistrationNumber).length < 7) return;

        fetchCompanyData(Number(companyRegistrationNumber));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyRegistrationNumber]);

    // update the country when countryCode changes
    useEffect(() => {
        if (!countryCode) return;
        setValue('country', Countries[countryCode]);
    }, [countryCode, setValue]);

    // we need these values for PUT request, but they are auto removed 'cause we are not using them in the form
    useEffect(() => {
        data?.customercontact.forEach((item, index) => {
            setValue(`customercontact.${index}.customerContact_id`, item.customerContact_id);
        });
        data?.location.forEach((item, index) => {
            setValue(`location.${index}.location_id`, item.location_id);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getContactNameByTableIdx = (index: number) => {
        const item = customercontact?.[index];
        return createFullName(item?.firstName, item?.lastName);
    };

    const onSubmit = (currentData: CustomerDetailSchema) => {
        if (!user) return;
        prepareData(currentData, user, data);
    };

    // number -- existing location, null - empty (nothing will be displayed), undefined - create new location
    const [selectedAddress, setSelectedAddress] = useState<LocationCustomerSchema | null | undefined>(null);

    return (
        <>
            <FormProvider {...methods}>
                <VisualFormInputsContext.Provider
                    value={{
                        visualInputsList,
                        setVisualInputsList,
                    }}
                >
                    <Form
                        id="customers-form"
                        onSubmit={handleSubmit(
                            (data: CustomerDetailSchema) => {
                                onSubmit({
                                    ...data,
                                    place: {
                                        ...data.place,
                                        city: data.city,
                                        street: data.street,
                                        country: data.country,
                                        postalCode: data.postalCode,
                                        countryCode: data.countryCode,
                                    },
                                });
                            },
                            (error) => handleErrorsWithNoInputs(error, visualInputsList),
                        )}
                        onReset={() => reset()}
                    >
                        <FormSection title={t('customers.form.basicInfo.sectionTitle')}>
                            <FormGrid columns={4} rows={2}>
                                <TextField name="company" label={t('customers.form.basicInfo.company')} />
                                <ComboBoxField
                                    name="countryCode"
                                    label={t('customers.fields.country')}
                                    options={getCountryOptions(tnt)}
                                />
                                <TextField name="postalCode" label={t('customers.form.basicInfo.postalCode')} />
                                <TextField name="city" label={t('customers.form.basicInfo.city')} />
                                <TextField name="street" label={t('customers.form.basicInfo.street')} />
                                <TextField
                                    name="companyRegistrationNumber"
                                    label={t('customers.form.basicInfo.companyRegistrationNumber')}
                                />
                                <TextField name="taxId" label={t('customers.form.basicInfo.taxId')} />
                            </FormGrid>
                        </FormSection>
                        <FormSection title={t('customers.form.invoicing.sectionTitle')}>
                            <FormGrid columns={4}>
                                <TextField name="email" label={t('customers.form.invoicing.invoiceEmail')} />
                                <NumberField
                                    name="defaultDueDate"
                                    label={t('customers.form.invoicing.defaultDueDate')}
                                    placeholder={t('customers.form.invoicing.defaultDueDatePlaceholder')}
                                />
                            </FormGrid>
                        </FormSection>
                        <VerticalFormArray
                            {...contactsFieldArray}
                            name="customercontact"
                            title={t('customers.form.contacts.sectionTitle')}
                            addTitle={t('customers.form.contacts.addContact')}
                            defaultValues={{
                                firstName: '',
                                lastName: '',
                                phone: null,
                                email: null,
                            }}
                            render={({
                                index,
                                getFieldProps,
                                remove,
                            }: RenderArgs<CustomerDetailSchema, ContactCustomerSchema>) => {
                                return (
                                    <FormCard
                                        title={getContactNameByTableIdx(index)}
                                        headerEndSlot={
                                            <FormActionButton variant="danger" onClick={() => remove(index)}>
                                                {t('form.removeItem')}
                                            </FormActionButton>
                                        }
                                    >
                                        <FormGrid columns={4}>
                                            <TextField
                                                {...getFieldProps('firstName')}
                                                label={t('customers.form.contacts.firstName')}
                                            />
                                            <TextField
                                                {...getFieldProps('lastName')}
                                                label={t('customers.form.contacts.lastName')}
                                            />
                                            <TextField
                                                {...getFieldProps('phone')}
                                                label={t('customers.form.contacts.phone')}
                                            />
                                            <TextField
                                                {...getFieldProps('email')}
                                                label={t('customers.form.contacts.email')}
                                            />
                                        </FormGrid>
                                    </FormCard>
                                );
                            }}
                        />
                        <div className={styles.placesContainer}>
                            <div className={styles.locationItemHeader}>
                                <Typography variant="h4">{t('customers.form.locations.sectionTitle')}</Typography>
                                {selectedAddress === null && (
                                    <FormActionButton onClick={() => setSelectedAddress(undefined)}>
                                        {t('customers.form.locations.addLocation')}
                                    </FormActionButton>
                                )}
                            </div>
                            <LocationsTable
                                data={locationFieldArray.fields || []}
                                setSelectedLocationId={(location_id: number) =>
                                    setSelectedAddress(
                                        locationFieldArray.fields.find(
                                            (address) => address.location_id === location_id,
                                        ),
                                    )
                                }
                            />
                        </div>
                        <FormSection title={t('customers.form.note.sectionTitle')}>
                            <TextAreaField
                                name="note"
                                label={t('customers.form.note.sectionTitle')}
                                placeholder={t('customers.form.note.placeholder')}
                            />
                        </FormSection>
                    </Form>
                </VisualFormInputsContext.Provider>
            </FormProvider>
            {selectedAddress !== null && (
                <LocationItemModal
                    data={selectedAddress}
                    isNew={selectedAddress === undefined}
                    onRemoveItem={() => {
                        setSelectedAddress(null);
                        const selectedAddressIndex = locationFieldArray.fields.findIndex(
                            (address) => address.location_id === selectedAddress?.location_id,
                        );
                        locationFieldArray.remove(selectedAddressIndex);
                    }}
                    onCloseItem={() => setSelectedAddress(null)}
                    onSaveItem={(data: LocationCustomerSchema) => {
                        data.countryCode && (data.country = Countries[data.countryCode]);
                        const selectedAddressIndex = locationFieldArray.fields.findIndex(
                            (address) => address.location_id === selectedAddress?.location_id,
                        );

                        selectedAddress === undefined
                            ? locationFieldArray.append(data)
                            : locationFieldArray.update(selectedAddressIndex, data);
                        setSelectedAddress(null);
                    }}
                />
            )}
        </>
    );
};

function transformIncomingData(data?: CustomerDetailSchema): CustomerDetailSchema | undefined {
    const fetchedData = structuredClone(data);
    if (!fetchedData) return;

    fetchedData.location = fetchedData.location.map((location) => {
        let countryCode = location.countryCode;

        // back compatibility -- here we set the countryCode, if there is only the country name
        if (!countryCode && location.country) {
            const countryInfo = Object.entries(Countries).find(([_key, value]) => fetchedData.country === value);
            if (countryInfo) {
                countryCode = countryInfo[0] as keyof typeof Countries;
            }
        }

        return {
            // return location but longitude and latitude are transformed to numbers
            ...location,
            countryCode,
            longitude: Number(location.longitude),
            latitude: Number(location.latitude),
        };
    });

    fetchedData.companyRegistrationNumber = fetchedData.companyRegistrationNumber?.padStart(8, '0') || null;

    // back compatibility
    fetchedData.place = fetchedData.place ? fetchedData.place : {};

    fetchedData.customercontact = fetchedData.customercontact.map((contact) => ({
        ...contact,
        phone: contact.phone?.trim() || '',
    }));

    // here we set the countryCode, if there is only the country name
    let countryInfo: [string, Countries] | undefined = undefined;
    if ((!fetchedData.place.countryCode || fetchedData.place.countryCode.length > 2) && fetchedData.place.country) {
        countryInfo = Object.entries(Countries).find(([_key, value]) => fetchedData.place?.country === value);
    } else if ((!fetchedData.countryCode || fetchedData.countryCode.length > 2) && fetchedData.country) {
        countryInfo = Object.entries(Countries).find(([_key, value]) => fetchedData.country === value);
    }

    if (countryInfo) {
        const countryCode = countryInfo[0] as keyof typeof Countries;
        fetchedData.countryCode = countryCode;
        fetchedData.place.countryCode = countryCode;
    }

    fetchedData.country = fetchedData.place.country ? fetchedData.place.country : fetchedData.country;
    fetchedData.countryCode = fetchedData.place.countryCode ? fetchedData.place.countryCode : fetchedData.countryCode;
    fetchedData.postalCode = fetchedData.place.postalCode ? fetchedData.place.postalCode : fetchedData.postalCode;
    fetchedData.city = fetchedData.place.city ? fetchedData.place.city : fetchedData.city;
    fetchedData.street = fetchedData.place.street ? fetchedData.place.street : fetchedData.street;

    return fetchedData;
}
