import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TrashIcon } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { User } from 'core/auth/types';
import {
    useDeleteCustomerMutation,
    useGetOneCustomerQuery,
    usePutUpdatedCustomerMutation,
} from 'modules/customers/services';
import { CustomerDetailSchema } from 'modules/customers/types';
import { transformCustomerData } from 'modules/customers/utils';
import { CustomersBasicForm } from 'modules/form/components/CustomersBasicForm';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button, NotFoundPage, Spinner } from 'modules/ui';

import { Modal } from '../../../ui/components/Modal/Modal';

import styles from './CustomersDetailPage.module.scss';

export const CustomersDetailPage = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.customers'));
    const [searchParams] = useSearchParams();
    const [showRemoveConfirmationPopup, setShowRemoveConfirmationPopup] = useState(false);

    const { data: response, isLoading, isFetching } = useGetOneCustomerQuery({ id: id ?? '' });

    const [putUpdatedCustomer, { isLoading: isSubmittingFormLoading }] = usePutUpdatedCustomerMutation();
    const [deleteCustomer, { isLoading: isSubmittingDeleting }] = useDeleteCustomerMutation();

    if (isLoading || isFetching || !id) return <Spinner fullScreen />;

    const fetchedData: CustomerDetailSchema | undefined = response;
    const prepareData = async (data: CustomerDetailSchema, editedBy: User, oldData?: CustomerDetailSchema) => {
        if (!oldData) return;
        const formatted = transformCustomerData({ currentData: data, editedBy, oldData });

        try {
            await putUpdatedCustomer({ data: formatted, id }).unwrap();
            toast.success(t('customers.updated'));

            const pageToReturn = searchParams.get('pageToReturn');
            if (pageToReturn) {
                const [pathname, search] = pageToReturn.split('?');
                navigate({
                    pathname: pathname,
                    search: search,
                });
                return;
            }
            navigate(config.routes.customers.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    if (fetchedData?.deleted === true) {
        return <NotFoundPage routeKey="customers" />;
    }

    const onRemoveItem = async () => {
        try {
            await deleteCustomer({
                id: Number(id),
            }).unwrap();

            toast.success(t('customers.removed'));
            navigate(config.routes.customers.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        } finally {
            setShowRemoveConfirmationPopup(false);
        }
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                onClick={() => setShowRemoveConfirmationPopup(true)}
                                isLoading={isSubmittingDeleting}
                                disabled={isSubmittingDeleting}
                                className={styles.deleteBtn}
                                type="button"
                                variant="primary"
                                danger
                            >
                                <TrashIcon />
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="customers-form"
                                type="submit"
                            >
                                {t('customers.form.save')}
                            </Button>
                        </div>
                    }
                    title={response?.company ?? ''}
                />
            }
        >
            {fetchedData && <CustomersBasicForm fetchedData={fetchedData} prepareData={prepareData} />}
            {/* if we remove the item */}
            {showRemoveConfirmationPopup && (
                <Modal
                    onClick={() => setShowRemoveConfirmationPopup(false)}
                    label={t('customers.modalRemove.label')}
                    cancelComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            variant="secondary"
                            onClick={() => setShowRemoveConfirmationPopup(false)}
                        >
                            {t('customers.modalRemove.cancel')}
                        </Button>
                    }
                    approveComponent={
                        <Button
                            className={styles.modalBtn}
                            type="button"
                            danger
                            variant="primary"
                            onClick={onRemoveItem}
                        >
                            {t('customers.modalRemove.approve')}
                        </Button>
                    }
                />
            )}
        </PageContent>
    );
};
