import type { IconProps } from './types';

export const LogoIcon: React.FC<IconProps & { color?: string }> = ({ color = '#1e2124', ...props }) => {
    return (
        <svg
            {...props}
            id="logo-extended"
            xmlns="http://www.w3.org/2000/svg"
            width="158.019"
            height="48"
            viewBox="0 0 158.019 48"
        >
            <g id="Group_12025" data-name="Group 12025">
                <rect id="Rectangle_3677" data-name="Rectangle 3677" width="48" height="48" rx="12" fill="#1770fe" />
                <g id="Group_11354" data-name="Group 11354" transform="translate(12.313 13.633)">
                    <path
                        id="Rectangle_41"
                        data-name="Rectangle 41"
                        d="M4.778-1.25H16.124a6.182,6.182,0,0,1,6.028,6.312v8.911a6.182,6.182,0,0,1-6.028,6.312H4.778A6.182,6.182,0,0,1-1.25,13.973V5.062A6.182,6.182,0,0,1,4.778-1.25ZM16.124,16.572a2.545,2.545,0,0,0,2.482-2.6V5.062a2.545,2.545,0,0,0-2.482-2.6H4.778A2.545,2.545,0,0,0,2.3,5.062v8.911a2.545,2.545,0,0,0,2.482,2.6Z"
                        transform="translate(1.25 1.25)"
                        fill="#fff"
                    />
                    <path
                        id="Path_2"
                        data-name="Path 2"
                        d="M37.822-1242.935a1.589,1.589,0,0,1-1.4-.831l-3.86-7.106a1.589,1.589,0,0,1,.638-2.154,1.588,1.588,0,0,1,2.154.638l3.86,7.106a1.589,1.589,0,0,1-.638,2.154A1.58,1.58,0,0,1,37.822-1242.935Z"
                        transform="translate(-21.716 1267.883)"
                        fill="#fff"
                    />
                </g>
            </g>
            <path
                id="Path_5486"
                data-name="Path 5486"
                d="M.151,1.559A1.983,1.983,0,0,1,.65.625,1.894,1.894,0,0,1,1.572.139,6.376,6.376,0,0,1,3.017,0h8.9a6.381,6.381,0,0,1,1.445.137,1.755,1.755,0,0,1,1.408,1.42A6.683,6.683,0,0,1,14.9,3.042V9.82a6.677,6.677,0,0,1-.137,1.483,1.755,1.755,0,0,1-1.408,1.42,6.352,6.352,0,0,1-1.445.137h-1.47L11.963,16H9.92L8.549,12.86H3.017a6.348,6.348,0,0,1-1.445-.137,1.886,1.886,0,0,1-.922-.486,1.979,1.979,0,0,1-.5-.935A6.13,6.13,0,0,1,0,9.82V3.042a6.134,6.134,0,0,1,.15-1.483m12.635,9.507V1.746H2.145v9.32H7.727L6.406,8.1l1.445-.573L9.6,11.066ZM22.87,0h2.293L32.315,12.86H29.947L28.3,9.7h-8.7L17.961,12.86H15.843Zm4.56,8.049L23.967,1.472,20.5,8.051ZM33.154,0H43.521a3.077,3.077,0,0,1,2.068.573,2.453,2.453,0,0,1,.648,1.919V5.359a2.453,2.453,0,0,1-.648,1.919,3.077,3.077,0,0,1-2.068.573H35.3V12.86H33.154ZM35.3,1.671V6.157h7.376A1.727,1.727,0,0,0,43.732,5.9a1.338,1.338,0,0,0,.336-1.084V3.017a1.342,1.342,0,0,0-.336-1.072,1.667,1.667,0,0,0-1.059-.274ZM47.934,0h2.143V11.066h9.744V12.86H47.934ZM61.465,0h2.143V12.86H61.465Zm4.087,0H66.9l9.619,8.6a12.356,12.356,0,0,1,1.47,1.62,12.733,12.733,0,0,1-.174-1.682q-.025-.785-.025-1.483V0h1.969V12.86h-1.2L68.667,3.914q-.274-.274-.623-.586a3.581,3.581,0,0,1-.673-.86A14.038,14.038,0,0,1,67.5,3.877q.025.685.025,1.408V12.86H65.552ZM81.534,0H93.92V1.721H83.678V5.31h6.255V7H83.678v4.087H94.019V12.86H81.534Z"
                transform="translate(64 17)"
                fill={color}
                fillRule="evenodd"
            />
        </svg>
    );
};
