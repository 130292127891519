// LOADINGS

import { z } from 'zod';

const loadingCreateSchema = z.object({
    refNumber: z.string().nullable(),
    date: z.number(),
    dateTo: z.number().nullable(),
    time: z.string().nullable(),
    location_id: z.number().nullable(),
    number: z.number().nullable(),
});

const loadingUpdateSchema = loadingCreateSchema.partial().extend({
    commissionLoading_id: z.number(),
});

const loadingDeleteSchema = z.object({
    commissionLoading_id: z.number(),
});

export const transformedLoadingsSchema = z.object({
    toCreate: z.array(loadingCreateSchema).optional(),
    toUpdate: z.array(loadingUpdateSchema).optional(),
    toDelete: z.array(loadingDeleteSchema).optional(),
});

export type LoadingCreateSchema = z.infer<typeof loadingCreateSchema>;
export type LoadingUpdateSchema = z.infer<typeof loadingUpdateSchema>;
export type LoadingDeleteSchema = z.infer<typeof loadingDeleteSchema>;
export type TransformedLoadingsSchema = z.infer<typeof transformedLoadingsSchema>;
