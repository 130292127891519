import classNames from 'classnames';

import styles from './Autocomplete.module.scss';

export interface AutocompleteOptionProps<T> {
    setOpen: (val: boolean) => void;
    setSelected: (val: T) => void;
    option: T;
    selected: boolean;
    labelProperty: keyof T;
    valueProperty: keyof T;
}
export const AutocompleteOption = <T,>({
    option,
    setOpen,
    setSelected,
    selected,
    labelProperty,
}: AutocompleteOptionProps<T>) => {
    return (
        <li
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                setSelected(option);
                setOpen(false);
            }}
            className={classNames(styles.option, {
                [styles['option-selected']]: selected,
            })}
        >
            {String(option[labelProperty])}
        </li>
    );
};
