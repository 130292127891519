// BASIC

import { z } from 'zod';

export const transformedBasicDataSchema = z.object({
    user_id: z.number(),
    constantSymbol: z.string(),
    issueDate: z.number(),
    orderDate: z.number(),
    dueDate: z.number(),
    pointDate: z.number(),
    paymentMethod: z.string(),
    language: z.string(),
    invoiceSent: z.boolean(),
    paid: z.boolean(),
    valueAddedTax: z.number().nullable(),
    canceled: z.boolean(),
    reverseCharge: z.boolean().optional(),
    exported: z.boolean(),
});

export type TransformedBasicDataSchema = z.infer<typeof transformedBasicDataSchema>;
