import 'react-pdf/dist/Page/TextLayer.css';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { IconPrint } from 'assets/icons';
import { useLazyGetAttachedFileQuery } from 'core/api';
import { Button, Spinner, Typography } from 'modules/ui';
import { printURI } from 'utils';

import styles from './ContractPdfModal.module.scss';

interface SendCommissionInquiryModalProps {
    // qid and oldid
    qid: [string | null, string | null];
    onClose: () => void;
}

export const ContractPdfModal: React.FC<SendCommissionInquiryModalProps> = ({ qid, onClose }) => {
    const { t } = useTranslation();

    const [windowHeight, setWindowHeight] = useState(window.innerHeight * 0.85);
    const [blobUrl, setBlobUrl] = useState<string | null>(null);
    const [isError, setIsError] = useState(false);

    const [getFile] = useLazyGetAttachedFileQuery();

    useEffect(() => {
        const fetchPdf = async () => {
            let blob: Blob | undefined = undefined;

            if (qid[0]) {
                const { data } = await getFile({ filePath: `sent/${qid[0]}.pdf` });
                blob = data;
            }

            if (!blob && qid[1]) {
                const { data } = await getFile({ filePath: `order/${qid[1]}.pdf` });
                blob = data;
            }

            if (!blob) return setIsError(true);

            // Create a Blob from the PDF Stream
            const file = new Blob([blob], { type: 'application/pdf' });
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            setBlobUrl(fileURL);
        };
        fetchPdf();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qid]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight * 0.85);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handlePrint = () => {
        if (blobUrl) printURI(blobUrl);
    };

    return (
        <div className={styles.modal} onClick={onClose}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.documentContainer}>
                    {isError ? (
                        <div className={styles.noDocument}>
                            <Typography variant="h5">{t('commissions.form.noCommissionPdf')}</Typography>
                        </div>
                    ) : (
                        <Document
                            file={blobUrl}
                            noData={() => (
                                <div className={styles.loading}>
                                    <Spinner fullScreen />
                                </div>
                            )}
                        >
                            <Page
                                pageNumber={1}
                                devicePixelRatio={2}
                                height={windowHeight}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    )}
                </div>
                <div className={styles.buttonContainer}>
                    <Button onClick={handlePrint} disabled={!blobUrl} variant="secondary">
                        <IconPrint />
                        {t('common.subheader.print')}
                    </Button>
                </div>
            </div>
        </div>
    );
};
