import React, { useEffect } from 'react';
import { useImperativeHandle, useLayoutEffect } from 'react';
import { useButton, useComboBox, useFilter } from 'react-aria';
import { Item, useComboBoxState } from 'react-stately';
import type { ComboBoxProps as ComboBoxBaseProps } from '@react-types/combobox';
import { ChevronDownIcon } from 'assets/icons';
import classnames from 'classnames';

import { ComboBoxPopover } from '../ComboBoxPopover';
import { ListBox } from '../ListBox';

import styles from './ComboBox.module.scss';

export { ComboBoxBaseProps };

export type ComboBoxProps<T extends object> = Omit<ComboBoxBaseProps<T>, 'children'> & {
    id?: string;
    error?: boolean;
    options: {
        value: string;
        label: string;
        endLabel?: string;
    }[];
    onChange: (value: string) => void;
    value: string;
    isNumber?: boolean;
} & React.HTMLAttributes<HTMLInputElement>;

export const ComboBoxInner = <T extends object>(
    { id, error, options, onChange, isNumber, ...props }: ComboBoxProps<T>,
    ref: React.ForwardedRef<HTMLInputElement>,
) => {
    const { contains } = useFilter({ sensitivity: 'base' });
    const state = useComboBoxState({
        ...props,
        defaultFilter: contains,
        defaultSelectedKey: props.value ? String(props.value) : props.value,
        children: options.map((option) => (
            <Item key={option.value} textValue={option.label}>
                <p className={styles.option}>
                    <span>{option.label}</span>
                    <span>{option.endLabel}</span>
                </p>
            </Item>
        )),
    });
    const [popoverWidth, setPopoverWidth] = React.useState<number | undefined>(undefined);

    const buttonRef = React.useRef(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const listBoxRef = React.useRef(null);
    const popoverRef = React.useRef(null);

    const {
        buttonProps: triggerProps,
        inputProps,
        listBoxProps,
    } = useComboBox(
        {
            ...props,
            inputRef,
            buttonRef,
            listBoxRef,
            popoverRef,
            label: id,
        },
        state,
    );

    useEffect(() => {
        // when the local state changes, update the form state
        if (state.selectedKey) onChange(String(state.selectedKey));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectedKey]);

    const { buttonProps } = useButton(triggerProps, buttonRef);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    useLayoutEffect(() => {
        const handleResize = () => {
            if (inputRef.current) {
                const { width } = inputRef.current.getBoundingClientRect();
                setPopoverWidth(width);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [inputRef]);

    // on first render set value
    useEffect(() => {
        if (props.value) state.setSelectedKey(isNumber ? Number(props.value) : String(props.value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    return (
        <div className={styles['combo-box']}>
            <div className={styles['input-wrapper']}>
                <input
                    id={id}
                    {...inputProps}
                    ref={inputRef}
                    className={classnames(styles.input, {
                        [styles['input-error']]: error,
                    })}
                />
                <button {...buttonProps} ref={buttonRef} className={styles['input-icon']}>
                    <ChevronDownIcon aria-hidden="true" />
                </button>
            </div>
            {state.isOpen && (
                <ComboBoxPopover
                    popoverRef={popoverRef}
                    triggerRef={inputRef}
                    state={state}
                    isNonModal
                    width={popoverWidth}
                    placement="bottom start"
                >
                    <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
                </ComboBoxPopover>
            )}
        </div>
    );
};

export const ComboBox = React.forwardRef(ComboBoxInner) as <T extends object>(
    props: ComboBoxProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof ComboBoxInner>;
