import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNonTypedTranslation } from 'core/translation';
import { useAppDispatch } from 'hooks';
import { debounce } from 'lodash';
import { CarrierNewSchema, VehicleTypes } from 'modules/carriers';
import { useLazySearchCarriersQuery } from 'modules/carriers/services';
import { DispatcherSearchParameters } from 'modules/commissions';
import { setDispatcherSearchParameters } from 'modules/commissions/store';
import {
    CheckboxField,
    FormGrid,
    MultiSelectField,
    SelectField,
    SliderField,
    VisualFormInputsContext,
} from 'modules/form';
import { getVehicleFeatureOptions } from 'modules/onboarding/utils/vehicleFeature';
import { Typography } from 'modules/ui';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';
import { z } from 'zod';

import { VehicleOptions, VehicleProperties } from '../VehicleProperties';

import styles from './CommissionCarrierForm.module.scss';

const searchCarrierSchema = () =>
    z.object({
        searchType: z.enum(['dispatcher', 'commission', 'hq', 'all']).optional(),
        directions: z.boolean(),
        vehicleType: z.array(z.number().min(1).max(4)).optional(),
        vehicleFeaturesMustHaveOne: z.array(z.number().min(1).max(14)).optional(),
        vehicleFeaturesMustHaveAll: z.array(z.number().min(1).max(14)).optional(),
        minLength: z.number(),
        minWidth: z.number(),
        minWeight: z.number(),
        minHeight: z.number(),
        locations: z.object({
            loading: z.object({ lat: z.number(), lon: z.number(), radius: z.number() }),
            discharge: z.object({ lat: z.number(), lon: z.number(), radius: z.number() }),
        }),
    });

export type SearchCarrierSchema = z.infer<ReturnType<typeof searchCarrierSchema>>;

interface CommissionCarrierFormProps {
    locations?: {
        loading: {
            lat: number;
            lon: number;
            radius: number;
        };
        discharge: {
            lat: number;
            lon: number;
            radius: number;
        };
    };
    defaultValues?: DispatcherSearchParameters;
    hiddenFields?: string[];
    onFetchedData: (carriers: CarrierNewSchema[]) => void;
}

export const CommissionCarrierForm: React.FC<CommissionCarrierFormProps> = ({
    locations,
    defaultValues,
    hiddenFields,
    onFetchedData,
}) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    const dispatch = useAppDispatch();

    const [getCarriers] = useLazySearchCarriersQuery();
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    // FORM's INITIALIZATION & STATE

    const locationDefaultValue = {
        lat: 0,
        lon: 0,
        radius: 10,
    };

    const formDefaultValues: SearchCarrierSchema = {
        searchType: 'all',
        directions: false,
        vehicleType: [],
        vehicleFeaturesMustHaveOne: [],
        vehicleFeaturesMustHaveAll: [],
        minLength: 1,
        minWidth: 1,
        minWeight: 1,
        minHeight: 1,
        locations: {
            loading: locations?.loading ? locations.loading : { ...locationDefaultValue },
            discharge: locations?.discharge ? locations.discharge : { ...locationDefaultValue },
        },
        ...defaultValues,
    };

    const methods = useForm<SearchCarrierSchema>({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(searchCarrierSchema()),
    });
    const { handleSubmit, watch, control, getValues, setValue } = methods;

    const {
        minHeight,
        minWidth,
        minLength,
        minWeight,
        vehicleFeaturesMustHaveAll,
        vehicleFeaturesMustHaveOne,
        vehicleType,
        locations: localLocations,
    } = useWatch({ control });

    useEffect(() => {
        dispatch(
            setDispatcherSearchParameters({
                minHeight: minHeight || 0,
                minWidth: minWidth || 0,
                minLength: minLength || 0,
                minWeight: minWeight || 0,
                vehicleFeaturesMustHaveAll: vehicleFeaturesMustHaveAll || [],
                vehicleFeaturesMustHaveOne: vehicleFeaturesMustHaveOne || [],
                vehicleType: vehicleType || [],
            }),
        );
    }, [
        dispatch,
        minHeight,
        minWidth,
        minLength,
        minWeight,
        JSON.stringify(locations),
        vehicleFeaturesMustHaveAll,
        vehicleFeaturesMustHaveOne,
        vehicleType,
    ]);

    // on first render fetch some data
    useEffect(() => {
        onSubmit(getValues());
    }, []);

    // 1. handle submit on every change -- 'cause there is no submit button
    useEffect(() => {
        const subscription = watch(() =>
            handleSubmit(handleDataChangeDelay, (error) => handleErrorsWithNoInputs(error, visualInputsList))(),
        );
        return () => subscription.unsubscribe();
    }, []);

    // 2. debounce with 300ms delay on every change -- so that the server does not burn down
    const handleDataChangeDelay = useCallback(
        debounce((data) => {
            onSubmit(data);
        }, 300),
        [],
    );

    // 3. finally fetch the specific carriers
    const onSubmit = async (data: SearchCarrierSchema) => {
        const {
            minHeight,
            minLength,
            minWeight,
            minWidth,
            vehicleFeaturesMustHaveAll,
            vehicleFeaturesMustHaveOne,
            vehicleType,
        } = getValues();
        dispatch(
            setDispatcherSearchParameters({
                minHeight,
                minLength,
                minWeight,
                minWidth,
                vehicleFeaturesMustHaveAll: vehicleFeaturesMustHaveAll || [],
                vehicleFeaturesMustHaveOne: vehicleFeaturesMustHaveOne || [],
                vehicleType: vehicleType || [],
            }),
        );

        const transformData = (data: SearchCarrierSchema) => {
            if (!locations) return;

            const template = {
                ...data,
                minHeight: data.minHeight * 100,
                minWidth: data.minWidth * 100,
                minLength: data.minLength * 100,
                searchType: data.searchType,
            } as SearchCarrierSchema;

            return template;
        };

        const formattedRequest = transformData(data);
        if (!formattedRequest) return;

        const { data: carriers, isSuccess } = await getCarriers(formattedRequest);
        isSuccess && onFetchedData(carriers);
    };

    const handleRadiusChange = (type: 'loading' | 'discharge', value: number | undefined) => {
        const newValue = {
            loading: {
                ...(localLocations?.loading ? localLocations.loading : { ...locationDefaultValue }),
                ...(type === 'loading' ? { radius: value || 40 } : {}),
            },
            discharge: {
                ...(localLocations?.discharge ? localLocations.discharge : { ...locationDefaultValue }),
                ...(type === 'discharge' ? { radius: value || 40 } : {}),
            },
        };
        setValue('locations', newValue as SearchCarrierSchema['locations']);
    };

    return (
        <FormProvider {...methods}>
            <VisualFormInputsContext.Provider
                value={{
                    visualInputsList,
                    setVisualInputsList,
                }}
            >
                <FormGrid columns={4} rows={2}>
                    {!hiddenFields?.includes('searchType') && (
                        <SelectField
                            name="searchType"
                            label={t('commissions.form.carrier.searchCarrier.form.searchType.label')}
                            options={[
                                { label: t('commissions.form.carrier.searchCarrier.form.all'), value: 'all' },
                                {
                                    label: t('commissions.form.carrier.searchCarrier.form.searchType.dispatcher'),
                                    value: 'dispatcher',
                                },
                                {
                                    label: t('commissions.form.carrier.searchCarrier.form.searchType.commission'),
                                    value: 'commission',
                                },
                                { label: t('commissions.form.carrier.searchCarrier.form.searchType.hq'), value: 'hq' },
                            ]}
                        />
                    )}
                    {!hiddenFields?.includes('directions') && (
                        <div>
                            <Typography className={styles['label']} fontWeight="medium" variant="p">
                                {t('commissions.form.carrier.searchCarrier.form.directions')}
                            </Typography>
                            <CheckboxField
                                name="directions"
                                label={t('commissions.form.carrier.searchCarrier.form.directions')}
                            />
                        </div>
                    )}
                    <SliderField
                        name="locations.loading.radius"
                        onChange={(value: number | undefined) => {
                            handleRadiusChange('loading', value);
                        }}
                        label={t('commissions.form.cargoLoad.radius', { context: 'loading' })}
                        min={10}
                        max={200}
                    />
                    <SliderField
                        name="locations.discharge.radius"
                        onChange={(value: number | undefined) => {
                            handleRadiusChange('discharge', value);
                        }}
                        label={t('commissions.form.cargoLoad.radius', { context: 'discharge' })}
                        min={10}
                        max={200}
                    />
                    <MultiSelectField
                        name="vehicleType"
                        label={t('commissions.form.carrier.searchCarrier.form.vehicleType')}
                        placeholder={t('commissions.form.carrier.searchCarrier.form.vehicleType')}
                        options={Object.entries(VehicleTypes).map(([value, vehicleType]) => ({
                            label: tnt(`carriers.form.dispatchervehicle.vehicleTypes.${vehicleType}`),
                            value: Number(value),
                        }))}
                    />
                    <MultiSelectField
                        name="vehicleFeaturesMustHaveOne"
                        label={t('commissions.form.carrier.searchCarrier.form.vehicleFeaturesMustHaveOne')}
                        placeholder={t('commissions.form.carrier.searchCarrier.form.all')}
                        options={getVehicleFeatureOptions(t).filter(({ label }) => label)}
                    />
                    <MultiSelectField
                        name="vehicleFeaturesMustHaveAll"
                        label={t('commissions.form.carrier.searchCarrier.form.vehicleFeaturesMustHaveAll')}
                        placeholder={t('commissions.form.carrier.searchCarrier.form.all')}
                        options={getVehicleFeatureOptions(t).filter(({ label }) => label)}
                    />
                </FormGrid>

                <Typography className={styles['vehicleParameters-label']} variant="p" fontWeight="normal">
                    {t('commissions.form.carrier.searchCarrier.form.vehicleParameters')}
                </Typography>

                <FormGrid columns={4}>
                    <VehicleProperties name="minLength" option={VehicleOptions.carLength} order="min" />
                    <VehicleProperties name="minWidth" option={VehicleOptions.carWidth} order="min" />
                    <VehicleProperties name="minWeight" option={VehicleOptions.carLoad} order="min" />
                    <VehicleProperties name="minHeight" option={VehicleOptions.carHeight} order="min" />
                </FormGrid>
            </VisualFormInputsContext.Provider>
        </FormProvider>
    );
};
