import classNames from 'classnames';

import styles from './FormActionButton.module.scss';

export type FormActionProps = React.ComponentProps<'button'> & {
    variant?: 'primary' | 'danger';
};

export const FormActionButton = ({ children, className, variant = 'primary', ...props }: FormActionProps) => {
    return (
        <button
            type="button"
            className={classNames(styles.button, className, {
                [styles.primary]: variant === 'primary',
                [styles.danger]: variant === 'danger',
            })}
            {...props}
        >
            {children}
        </button>
    );
};
