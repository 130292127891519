import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'react-map-gl';
import { useRefreshTokenMutation } from 'core/api';
import { useDocumentTitle } from 'core/application';
import { useAuth } from 'core/auth';
import { createFullName } from 'modules/carriers/utils';
import { PageContent, Subheader } from 'modules/layout';
import { DispatcherBasicForm } from 'modules/onboarding';
import { useGetOneDispatcherQuery, usePutUpdatedDispatcherMutation } from 'modules/onboarding/services';
import { OnboardingSchema } from 'modules/onboarding/types';
import { transformOnboardingData } from 'modules/onboarding/utils';
import { Button, Spinner } from 'modules/ui';

import styles from './Profile.module.scss';

export const Profile = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.profile'));

    const { user } = useAuth();
    const [putUpdatedDispatcher, { isLoading: isSubmittingFormLoading }] = usePutUpdatedDispatcherMutation();
    const [refreshToken] = useRefreshTokenMutation();

    const { data: dispatcherData } = useGetOneDispatcherQuery(
        { id: user?.dispatcher_id || 0 },
        { skip: !user?.dispatcher_id },
    );

    const prepareData = async (data: OnboardingSchema, oldData?: OnboardingSchema) => {
        if (!dispatcherData) return;
        const formatted = transformOnboardingData({ currentData: data, oldData });

        try {
            await putUpdatedDispatcher({ data: formatted, id: dispatcherData.dispatcher_id }).unwrap();
            // get access token with actual user credentials
            refreshToken({});

            toast.success(t('onboarding.updated'));
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    if (!dispatcherData) return <Spinner fullScreen />;

    return (
        <PageContent
            subheader={
                <Subheader
                    title={createFullName(dispatcherData.firstName, dispatcherData.lastName)}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="dispatcher-form"
                                type="submit"
                            >
                                {t('onboarding.save')}
                            </Button>
                        </div>
                    }
                />
            }
        >
            <MapProvider>
                <DispatcherBasicForm viewSide="profile" fetchedData={dispatcherData} prepareData={prepareData} />
            </MapProvider>
        </PageContent>
    );
};
