import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconInfo } from 'assets/icons';
import { useNonTypedTranslation } from 'core/translation';
import useChange from 'hooks/useChange';
import { debounce } from 'lodash';
import { getLanguageOptions } from 'modules/common/utils/language';
import { CheckboxField, FormGrid, FormSection, SelectField, TextField } from 'modules/form';
import { useLazyGetCheckDispatcherMailQuery } from 'modules/onboarding/services';
import { Spinner, Typography } from 'modules/ui';

import styles from './BasicInfoForm.module.scss';

interface IBasicInfoFormProps {
    viewSide: 'onboarding' | 'profile' | 'detail';
    originalUserEmail?: string;
    setIsEmailCheckFetching: (isFetching: boolean) => void;
    setIsEmailAvailable: (isAvailable: boolean) => void;
}

export const BasicInfoForm = ({
    viewSide,
    originalUserEmail,
    setIsEmailCheckFetching,
    setIsEmailAvailable,
}: IBasicInfoFormProps) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();

    const { control, trigger, setError } = useFormContext();
    const [getIsEmailAvailable, { isFetching }] = useLazyGetCheckDispatcherMailQuery();

    const { email, password, confirmPassword } = useWatch({ control });

    // check is email available
    const fetchIsEmailAvailable = async (email: string) => {
        // do not check the email availability, if email is not valid
        const isEmailValid = await trigger('email');
        if (!isEmailValid) return;

        const response = await getIsEmailAvailable({ email }).unwrap();

        if (response.available || originalUserEmail === email) {
            setIsEmailAvailable(true);
        } else {
            setError('email', { message: t('auth.email.error.RECORD_NOT_UNIQUE') });
            setIsEmailAvailable(false);
        }
        setIsEmailCheckFetching(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchDelay = useCallback(
        debounce((email: string) => {
            fetchIsEmailAvailable(email);
        }, 1500),
        [],
    );

    // handle the email change & check email after delay
    useChange(() => {
        setIsEmailCheckFetching(true);
        fetchDelay(email);
    }, [email]);

    useChange(() => {
        if (password) trigger('password');
        if (confirmPassword) trigger('confirmPassword');
    }, [password, confirmPassword]);

    return (
        <div className={styles.container}>
            <FormSection
                title={
                    viewSide === 'onboarding'
                        ? t('onboarding.basicInfoForm.personalInfo.sectionTitle')
                        : t('onboarding.basicInfoForm.title')
                }
            >
                <FormGrid columns={4}>
                    <TextField name="firstName" label={t('onboarding.basicInfoForm.personalInfo.firstName')} />
                    <TextField name="lastName" label={t('onboarding.basicInfoForm.personalInfo.lastName')} />
                    <TextField
                        name="phone"
                        label={
                            viewSide === 'onboarding'
                                ? t('onboarding.basicInfoForm.personalInfo.phone')
                                : t('carriers.form.dispatcher.phone')
                        }
                    />
                    <TextField
                        name="email"
                        label={t('onboarding.basicInfoForm.personalInfo.email')}
                        endIcon={isFetching ? <Spinner className={styles.emailSpinner} /> : null}
                    />
                    {viewSide !== 'onboarding' && (
                        <SelectField
                            name="language_id"
                            label={
                                viewSide === 'detail'
                                    ? t('onboarding.basicInfoForm.personalInfo.languageByCompany')
                                    : t('onboarding.basicInfoForm.personalInfo.languageForCommunication')
                            }
                            options={getLanguageOptions(tnt)}
                            isNumber
                        />
                    )}
                </FormGrid>
                {viewSide !== 'detail' && (
                    <div className={styles.infoContainer}>
                        <IconInfo color="#738291" />
                        <Typography className={styles.infoText} variant="subheading">
                            {t('onboarding.basicInfoForm.personalInfo.info')}
                        </Typography>
                    </div>
                )}
            </FormSection>
            {viewSide !== 'detail' && (
                <>
                    <FormSection
                        title={t('onboarding.basicInfoForm.authInfo.sectionTitle')}
                        className={styles.formSection}
                    >
                        <FormGrid columns={4}>
                            <TextField
                                name="password"
                                type="password"
                                label={
                                    viewSide === 'onboarding'
                                        ? t('onboarding.basicInfoForm.authInfo.password')
                                        : t('onboarding.basicInfoForm.authInfo.newPassword')
                                }
                            />
                            <TextField
                                name="confirmPassword"
                                type="password"
                                label={
                                    viewSide === 'onboarding'
                                        ? t('onboarding.basicInfoForm.authInfo.confirmPassword')
                                        : t('onboarding.basicInfoForm.authInfo.confirmNewPassword')
                                }
                            />
                        </FormGrid>
                        <div className={styles.infoContainer}>
                            <IconInfo color="#738291" />
                            <Typography className={styles.infoText} variant="subheading">
                                {viewSide === 'onboarding'
                                    ? t('onboarding.basicInfoForm.authInfo.info')
                                    : t('onboarding.basicInfoForm.authInfo.infoSecond')}
                            </Typography>
                        </div>
                    </FormSection>
                    <FormSection
                        title={t('onboarding.basicInfoForm.offersNotifications.sectionTitle')}
                        className={styles.formSection}
                    >
                        <div>
                            <Typography className={styles.label} variant="p">
                                {t('onboarding.basicInfoForm.offersNotifications.getOffersFrom')}
                            </Typography>
                            <FormGrid columns={4}>
                                <CheckboxField
                                    name="notificationEmail"
                                    label={t('onboarding.basicInfoForm.offersNotifications.notificationEmail')}
                                />
                                <CheckboxField
                                    name="notificationWhatsapp"
                                    label={t('onboarding.basicInfoForm.offersNotifications.notificationWhatsapp')}
                                />
                            </FormGrid>
                        </div>
                    </FormSection>
                </>
            )}
        </div>
    );
};
