import React from 'react';
import * as SentryMethods from '@sentry/react';
import { config } from 'config';
import { useMounted } from 'hooks/useMounted';

export type TSentryProps = {
    children?: React.ReactNode;
};

export const Sentry: React.FC<TSentryProps> = ({ children }) => {
    useMounted(() => {
        if (!['development', 'main'].includes(process.env.VITE_MODE || 'local')) return;
        SentryMethods.init({
            dsn: 'https://8cd57bab3852088ebcf909b387b5bf34@sentry.k42.dev/28',
            tunnel: `${config.api.url}/${config.api.endpoints.sentryTunnel}`,
            integrations: [SentryMethods.browserTracingIntegration(), SentryMethods.replayIntegration()],
            tracesSampleRate: 1.0,
            environment: process.env.VITE_MODE,
            tracePropagationTargets: ['localhost', config.api.url],
            replaysSessionSampleRate: 1.0,
            replaysOnErrorSampleRate: 1.0,
            transportOptions: {
                headers: {
                    'x-auth-token': localStorage.getItem('accessToken') || '',
                },
            },
        });
    });
    return <>{children}</>;
};
