import { DispatcherVehicleFeature } from 'modules/carriers/types';

import {
    FeaturesCreate,
    FeaturesDelete,
    FeaturesUpdate,
    IFeaturesCheckingValues,
    IGetFeaturesChanges,
    TransformedVehicleFeatures,
} from './types';

export function transformVehicleFeatures(
    features: DispatcherVehicleFeature[],
    oldData: DispatcherVehicleFeature[],
): TransformedVehicleFeatures {
    const { added, changed, removed } = getFeaturesChanges(features, oldData);

    return {
        toCreate: added && (added.map((item) => ({ vehicleFeature_id: item.vehicleFeature_id })) as FeaturesCreate[]),
        toUpdate: changed && (changed.map((item) => item) as FeaturesUpdate[]),
        toDelete:
            removed &&
            (removed.map((item) => ({
                dispatcherVehicleFeature_id: item.dispatcherVehicleFeature_id,
            })) as FeaturesDelete[]),
    };
}

const checkingFeaturesValues: IFeaturesCheckingValues[] = ['vehicleFeature_id'];
function getFeaturesChanges(
    oldFeatures: DispatcherVehicleFeature[],
    newFeatures: DispatcherVehicleFeature[],
): IGetFeaturesChanges {
    // "newFeatures" does not mean features "toCreate", but all features which are in actual/current form.
    // unchanged features do not need to be sent to the server.

    const transformedNewFeatures: DispatcherVehicleFeature[] = newFeatures.filter((item) => item.vehicleFeature_id);

    // features which are new, to create
    const added = transformedNewFeatures.filter((item) => !item.dispatcherVehicleFeature_id);
    // features which are updated or unchanged
    const others = transformedNewFeatures.filter((item) => item.dispatcherVehicleFeature_id);
    const othersIDs = others.map((item) => item.dispatcherVehicleFeature_id) as number[];
    // old features which not exist in others
    const removed = oldFeatures.filter((item) =>
        item.dispatcherVehicleFeature_id ? !othersIDs.includes(item?.dispatcherVehicleFeature_id) : false,
    );
    // old features which still exist
    const stayedOld = oldFeatures.filter((item) =>
        item.vehicleFeature_id ? othersIDs.includes(item?.vehicleFeature_id) : false,
    );
    // new features which still exist
    const stayedNew = transformedNewFeatures.filter((item) =>
        item.vehicleFeature_id ? othersIDs.includes(item?.vehicleFeature_id) : false,
    );
    // updated features - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingFeaturesValues.some((value) => item[value] !== stayedOld[index][value]),
    );

    const template: IGetFeaturesChanges = {};

    // add only if they are not empty
    if (added.length) {
        template.added = added;
    }
    if (changed.length) {
        template.changed = changed;
    }
    if (removed.length) {
        template.removed = removed;
    }
    return template;
}
