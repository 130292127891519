import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import useChange from 'hooks/useChange';
import { CarrierDispatcherSchema, CarrierNewSchema } from 'modules/carriers';
import { createFullName } from 'modules/carriers/utils';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { createSelectColumn, getContainsFilterMeta, Table, TableContainer, useTableState } from 'modules/table';
import moment from 'moment';
import { tableSortingFilteringPagination } from 'utils';

import { getDateRangeFilterMeta } from '../../../table/utils/getFilter';

import styles from './CommissionCarrierTable.module.scss';

const columnHelper = createColumnHelper<ITransformedTableLocation>();

type ITransformedTableLocation = {
    dispatcher_id: number;
    country: string;
    company: string;
    postalCode: string;
    fullName: string;
    lastRequestTimeSent: number;
    routes: number;
};

export type CommissionCarrierTableItem = Pick<CarrierNewSchema, 'carrier_id' | 'company' | 'place'> & {
    dispatcher: Pick<
        CarrierDispatcherSchema,
        'dispatcher_id' | 'firstName' | 'lastName' | 'lastRequestTimeSent' | 'email'
    >[];
};

interface ICommissionCarrierTableProps {
    data: CommissionCarrierTableItem[];
    // ids of dispatchers we want to disable (show gray)
    disabledIds?: number[];
    // ids of already contacted dispatchers they do not satisfy the new params (show red)
    notSatisfyParamsIds?: number[];
    tableName: string;
}

export const CommissionCarrierTable: React.FC<ICommissionCarrierTableProps> = ({
    data,
    disabledIds,
    notSatisfyParamsIds,
    tableName,
}) => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.customers'));
    const { selectedDispatchers, setSelectedDispatchers } = useSelectedDispatchers();

    const { tableProps, queryParams, paginationParams } = useTableState<ITransformedTableLocation>({
        rowIdKey: 'dispatcher_id',
        getCellStyles: ({ column, row }) => {
            const cellStyles: string[] = [];

            if (['company', 'routes'].includes(column.id)) {
                cellStyles.push(Table.CellTextFormat.SemiBold);
            }
            if (column.id === 'routes') cellStyles.push(Table.CellTextColor.Primary);

            if (disabledIds?.includes(row.original.dispatcher_id)) {
                cellStyles.push(styles.disabled);
            }

            if (notSatisfyParamsIds?.includes(row.original.dispatcher_id)) {
                cellStyles.push(styles.notSatisfyParamsIds);
            }

            return cellStyles;
        },
        getIsRowSelectionDisabled: (row) => disabledIds?.includes(row.original.dispatcher_id) || false,
        tableName,
    });
    const tableData = useMemo(() => {
        const changedData = transformIncomingData(data);
        const { filtered, result, sorted } = tableSortingFilteringPagination<ITransformedTableLocation>({
            data: changedData,
            paginationParams,
            queryParams,
            treatAsDate: ['lastRequestTimeSent'],
        });

        return { filtered, sorted, paginated: result, changedData };
    }, [data, queryParams, paginationParams]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('company', {
                header: t('commissions.form.carrier.searchCarrier.table.company'),
                cell: (props) => {
                    const value = props.getValue() || '';
                    return value.length > 37 ? `${value.substring(0, 37)}...` : value;
                },
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('country', {
                header: t('commissions.form.carrier.searchCarrier.table.country'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('postalCode', {
                header: t('commissions.form.carrier.searchCarrier.table.postalCode'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('fullName', {
                header: t('commissions.form.carrier.searchCarrier.table.fullName'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('lastRequestTimeSent', {
                header: t('commissions.form.carrier.searchCarrier.table.lastRequestTimeSent'),
                cell: (props) => {
                    const value = Number(props.getValue());
                    return value ? moment.unix(Math.floor(value / 1000)).format('DD. MM. YYYY') : '---';
                },
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('routes', {
                header: t('commissions.form.carrier.searchCarrier.table.routes'),
                cell: (props) => (
                    <Link
                        to={`${config.routes.dispatcher.detail.replace(
                            ':id',
                            String(props.getValue()),
                        )}?scrollTo=routes`}
                    >
                        {t('commissions.form.carrier.searchCarrier.table.route')}
                    </Link>
                ),
                meta: {
                    align: 'center',
                },
                enableSorting: false,
            }),
            createSelectColumn(
                columnHelper,
                undefined,
                undefined,
                data.flatMap((carrier) =>
                    carrier.dispatcher.map((dispatcher, index) => dispatcher.dispatcher_id || index),
                ),
            ),
        ],
        [t, data],
    );

    // set default row selection (from fetched data)
    useEffect(() => {
        const rowSelection: Record<string, any> = {};
        selectedDispatchers.forEach((item) => (rowSelection[String(item.dispatcherId)] = true));
        tableProps.setRowSelection(rowSelection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // when selecting the dispatchers, pass the emails to the HOC
    useChange(() => {
        if (!data) return;
        const selectedDispatchersIds = tableProps.rowSelection;
        const allDispatchers = data.flatMap((carrier) => carrier.dispatcher.map((dispatcher) => dispatcher));
        const selectedDispatchers = allDispatchers.filter(
            ({ dispatcher_id }) => dispatcher_id && selectedDispatchersIds?.[dispatcher_id] === true,
        );

        const selectedDispatchersShort = selectedDispatchers.map(({ dispatcher_id, email }) => ({
            dispatcherId: dispatcher_id as number,
            email,
        }));

        setSelectedDispatchers(selectedDispatchersShort);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(tableProps.rowSelection)]);

    return (
        <div className={styles.tableBox}>
            <TableContainer>
                <Table
                    data={tableData.paginated}
                    filtered={tableData.filtered}
                    columns={columns}
                    totalCount={tableData.changedData.length}
                    bordered={true}
                    {...tableProps}
                    tableName={tableName}
                />
            </TableContainer>
        </div>
    );
};

// table has another fields than fetched addresses (locations)
function transformIncomingData(data: CommissionCarrierTableItem[]): ITransformedTableLocation[] {
    const newData: ITransformedTableLocation[] = data.flatMap((carrier) =>
        carrier.dispatcher.map((dispatcher) => ({
            dispatcher_id: dispatcher.dispatcher_id || 0,
            company: carrier.company,
            country: carrier?.place?.country || '',
            postalCode: carrier?.place?.postalCode || '',
            fullName: createFullName(dispatcher.firstName, dispatcher.lastName),
            lastRequestTimeSent: dispatcher.lastRequestTimeSent || 0,
            routes: dispatcher.dispatcher_id || 0,
        })),
    );

    return newData;
}
