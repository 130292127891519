import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { IconInfo } from 'assets/icons';
import { FormActionButton, FormSection } from 'modules/form';
import { AttachedFileListSchema } from 'modules/invoicing';

import { Typography } from '../../../ui/components/Typography/Typography';

import styles from './FileFormArray.module.scss';

export type FileFormArrayProps = {
    title: string;
    files: AttachedFileListSchema[];
    onAttachFiles: (files: FileList) => void;
    addTitle?: string;
    render: ({ uri, index }: { uri: string; index: number }) => React.ReactElement;
};

export const FileFormArray: React.FC<FileFormArrayProps> = ({ title, files, onAttachFiles, addTitle, render }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null) onAttachFiles(e.target.files);
        e.target.value = '';
    };

    const { t } = useTranslation();
    return (
        <FormSection
            title={title}
            headerEndSlot={
                <>
                    <FormActionButton onClick={() => fileInputRef.current?.click()}>
                        {addTitle ? addTitle : t('form.addItem')}
                    </FormActionButton>
                    <input onChange={handleFileChange} ref={fileInputRef} type="file" className={styles.hidden} />
                </>
            }
        >
            {files.length > 0 ? (
                <ul className={styles.container}>
                    {files.map((file, index) => (
                        <li key={index}>{render({ uri: file.uri, index })}</li>
                    ))}
                </ul>
            ) : (
                <div className={styles.noCommissions}>
                    <IconInfo color="gray" />
                    <Typography variant="p" className="">
                        {t('invoicing.form.attachedFiles.noItems')}
                    </Typography>
                </div>
            )}
        </FormSection>
    );
};
