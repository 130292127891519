import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { User } from 'core/auth/types';
import { Countries } from 'modules/common';
import { usePostNewCustomerMutation } from 'modules/customers/services';
import { CustomerDetailSchema } from 'modules/customers/types';
import { transformCustomerData } from 'modules/customers/utils';
import { CustomersBasicForm } from 'modules/form/components/CustomersBasicForm';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button } from 'modules/ui';

export const CustomersNewPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.customers'));

    const [postNewCustomer, { isLoading: isSubmittingFormLoading }] = usePostNewCustomerMutation();
    const [searchParams] = useSearchParams();

    const prepareData = async (data: CustomerDetailSchema, editedBy: User) => {
        const oldData: CustomerDetailSchema = {
            city: '',
            company: '',
            companyRegistrationNumber: null,
            countryCode: 'CZ',
            country: Countries['CZ'],
            postalCode: '',
            street: '',
            defaultDueDate: 0,
            taxId: '',
            note: '',
            email: '',
            customercontact: [],
            location: [],
            deleted: null
        };

        const formatted = transformCustomerData({ currentData: data, editedBy, oldData });

        try {
            await postNewCustomer({ data: formatted }).unwrap();
            toast.success(t('customers.created'));
            const pageToReturn = searchParams.get('pageToReturn');
            if (pageToReturn) {
                const [pathname, search] = pageToReturn.split('?');
                navigate({
                    pathname: pathname,
                    search: search,
                });
                return;
            }
            navigate(config.routes.customers.list);
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <Button
                            isLoading={isSubmittingFormLoading}
                            disabled={isSubmittingFormLoading}
                            form="customers-form"
                            type="submit"
                        >
                            {t('customers.form.save')}
                        </Button>
                    }
                    title={t('customers.newCustomer')}
                />
            }
        >
            <CustomersBasicForm prepareData={prepareData} />
        </PageContent>
    );
};
