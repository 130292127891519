import type { IconProps } from './types';

export const LogoSquareIcon: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} id="logo-icon" xmlns="http://www.w4.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <rect id="Rectangle_42" data-name="Rectangle 42" width="40" height="40" rx="8" fill="#1770fe" />
            <g id="Group_3" data-name="Group 3" transform="translate(-14 -6)">
                <rect
                    id="Rectangle_41"
                    data-name="Rectangle 41"
                    width="14"
                    height="12"
                    rx="3"
                    transform="translate(27 20)"
                    fill="none"
                    stroke="#fff"
                    strokeWidth="2.5"
                />
                <path
                    id="Path_2"
                    data-name="Path 2"
                    d="M33.622-1251.969l3.037,5.591"
                    transform="translate(0.378 1280.695)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeWidth="2.5"
                />
            </g>
        </svg>
    );
};
