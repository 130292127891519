import 'react-pdf/dist/Page/TextLayer.css';

import { useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconDownload, IconEmail, IconPrint } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { User } from 'core/auth/types';
import { CustomerDetailSchema } from 'modules/customers';
import { useLazyGetOneCustomerQuery } from 'modules/customers/services';
import { InvoiceBasicForm } from 'modules/form';
import { useLazyGetInvoicePreviewPdfQuery, usePostNewInvoiceMutation } from 'modules/invoicing/services';
import { InvoiceDetailSchema } from 'modules/invoicing/types';
import { transformInvoicingData } from 'modules/invoicing/utils';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button } from 'modules/ui';
import { simplifyGetInvoicePdfRequestBody } from 'utils';

import { InvoiceDocument } from '../InvoiceDocument';

import styles from './InvoicingNewPage.module.scss';

export const InvoicingNewPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.invoicing'));

    // fetch hooks
    const [getPdf] = useLazyGetInvoicePreviewPdfQuery();
    const [postNewInvoice, { isLoading: isSubmittingFormLoading }] = usePostNewInvoiceMutation();
    const [getOneCustomer] = useLazyGetOneCustomerQuery();

    // state hooks
    const [blobUrl, setBlobUrl] = useState<string | null>(null);
    const [customer, setCustomer] = useState<CustomerDetailSchema | null>(null);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);

    const fetchPdf = async (invoice: InvoiceDetailSchema, user: User, customer: CustomerDetailSchema) => {
        setIsLoadingPdf(true);

        if (invoice.commission.length && Number(invoice.pointDate) <= new Date().setHours(0, 0, 0, 0)) {
            const simplifiedBody = simplifyGetInvoicePdfRequestBody(invoice, customer, user);
            const { data: blob } = await getPdf({
                body: simplifiedBody,
            });

            if (!blob) return;
            // Create a Blob from the PDF Stream
            const file = new Blob([blob], { type: 'application/pdf' });
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            setBlobUrl(fileURL);
        }
        setIsLoadingPdf(false);
    };

    const prepareData = async (data: InvoiceDetailSchema, editedBy: User, isAlreadyCreated: boolean) => {
        const formatted = transformInvoicingData({ currentData: data, editedBy, isAlreadyCreated });
        if (data) {
            const response = await postNewInvoice({ data: formatted }).unwrap();
            try {
                navigate(`${config.routes.invoicing.list}/${response.invoice_id}`);
                toast.success(t('invoicing.created'));
            } catch (error) {
                toast.error(t('form.saveError'));
            }
        }
    };

    const fetchCustomer = async (customer_id: number) => {
        const { data: customer, isSuccess } = await getOneCustomer({ id: String(customer_id) });
        if (isSuccess) setCustomer(customer);
    };

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button disabled form="invoice-form" variant="secondary">
                                <IconPrint />
                                {t('common.subheader.print')}
                            </Button>
                            <Button disabled form="invoice-form" variant="secondary">
                                <IconDownload />
                                {t('common.subheader.download')}
                            </Button>
                            <Button disabled form="invoice-form" variant="secondary">
                                <IconEmail iconBackgroundColor="#1E2124" />
                                {t('common.subheader.email')}
                            </Button>
                            <Button
                                isLoading={isSubmittingFormLoading}
                                disabled={isSubmittingFormLoading}
                                form="invoice-form"
                                type="submit"
                            >
                                {t('invoicing.form.save')}
                            </Button>
                        </div>
                    }
                    title={t('invoicing.newInvoice')}
                />
            }
        >
            <div className={styles.container}>
                <div className={styles.form}>
                    <InvoiceBasicForm
                        prepareData={prepareData}
                        customer={customer}
                        fetchCustomer={fetchCustomer}
                        fetchPdf={fetchPdf}
                    />
                </div>
                <InvoiceDocument blobUrl={blobUrl} loading={isLoadingPdf} />
            </div>
        </PageContent>
    );
};
