import { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { debounce } from 'lodash';
import { ComboBox, ComboBoxProps } from 'modules/combo-box';
import { BaseFieldProps } from 'modules/form/types';
import { FormError, FormField, FormLabel } from 'modules/ui';

export type ComboBoxFieldProps<T extends object> = BaseFieldProps & Omit<ComboBoxProps<T>, 'onChange' | 'value'>;

export const ComboBoxField = <T extends object>({
    name,
    label,
    placeholder,
    setValueDebounce,
    isNumber = false,
    ...props
}: ComboBoxFieldProps<T>) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTyping = useCallback(
        debounce((value) => {
            if (setValueDebounce) setValueDebounce(value);
        }, 300),
        [],
    );

    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => {
                return (
                    <FormField name={name}>
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <ComboBox
                            id={name}
                            error={!!error}
                            placeholder={placeholder || label}
                            {...props}
                            {...field}
                            onChange={(value) => {
                                field.onChange(isNumber ? Number(value) : value);
                                handleTyping(value);
                            }}
                        />
                        <FormError>{error?.message}</FormError>
                    </FormField>
                );
            }}
        />
    );
};
