import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import { config } from 'config';
import { selectAuth } from 'core/auth/services/selectors';
import { User } from 'core/auth/types';
import { format } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { useAppSelector } from 'hooks';
import useChange from 'hooks/useChange';
import useUpdateEffect from 'hooks/useUpdateEffect';
import { debounce } from 'lodash';
import { NotInvoicedCommission } from 'modules/commissions';
import { Currency, invoicingLanguages } from 'modules/common';
import { CustomerDetailSchema } from 'modules/customers';
import {
    CheckboxField,
    ComboBoxField,
    CommissionTableFormArray,
    DateField,
    FileButton,
    Form,
    FormGrid,
    FormSection,
    RenderArgs,
    SelectField,
    TextField,
    VisualFormInputsContext,
} from 'modules/form';
import {
    AttachedFileListSchema,
    CommissionInvoiceSchema,
    InvoiceDetailSchema,
    invoiceDetailSchema,
    useGetUninvoicedCountsQuery,
    useLazyGetUninvoicedItemsQuery,
} from 'modules/invoicing';
import { getInvoiceOrderLanguageOptions } from 'modules/invoicing/utils/orderLanguage';
import { getInvoicePaymentMethodOptions } from 'modules/invoicing/utils/paymentMethod';
import { formatPrice } from 'modules/table';
import { getInvoiceOrderDate, getInvoicePointDate } from 'utils';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { FileFormArray } from '../FileFormArray';
import { InvoiceCommissionTablePopup } from '../InvoiceCommissionTablePopup';

import styles from './InvoiceBasicForm.module.scss';

export const InvoiceBasicForm: React.FC<{
    fetchedData?: InvoiceDetailSchema;
    attachedFilesList?: AttachedFileListSchema[];
    onAttachFile?: (files: FileList) => void;
    onDeleteFile?: (uri: string) => void;
    setIsSomeValueChanged?: (isSomeValueChanged: boolean) => void;
    customer: CustomerDetailSchema | null;
    prepareData: (
        data: InvoiceDetailSchema,
        editedBy: User,
        isAlreadyCreated: boolean,
        oldData?: InvoiceDetailSchema,
    ) => void;
    forceSubmitForm?: boolean;
    fetchCustomer?: (customer_id: number) => void;
    fetchPdf: (invoice: InvoiceDetailSchema, user: User, customer: CustomerDetailSchema) => void;
}> = ({
    fetchedData,
    attachedFilesList = [],
    onAttachFile,
    onDeleteFile,
    setIsSomeValueChanged,
    customer,
    prepareData,
    fetchCustomer,
    forceSubmitForm = false,
    fetchPdf,
}) => {
    const { user } = useAppSelector(selectAuth);
    const [totalPrice, setTotalPrice] = useState(0);
    const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null);
    const data = useMemo(() => transformIncomingData(fetchedData), [fetchedData]);

    const [getUninvoicedItems] = useLazyGetUninvoicedItemsQuery();
    const { data: uninvoicedCounts } = useGetUninvoicedCountsQuery(null); // eslint-disable-next-line react-hooks/exhaustive-deps
    const [availableCommissions, setAvailableCommissions] = useState<CommissionInvoiceSchema[]>([]);
    const [uninvoicedCommissions, setUninvoicedCommissions] = useState<NotInvoicedCommission>([]);
    const [showCommissionsPreview, setShowCommissionsPreview] = useState<boolean>(false);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const isAlreadyCreated = Boolean(fetchedData);

    const { customersCompany } = useMemo(() => {
        const customersCompany: { label: string; value: string; endLabel: string }[] = uninvoicedCounts
            ? uninvoicedCounts
                  .filter(({ customer_company, CZK, EUR }) => customer_company && (+CZK || +EUR))
                  .map((item) => ({
                      label: item.customer_company || '',
                      value: item.customer_company || '',
                      endLabel: String(+item.CZK + +item.EUR),
                  }))
            : [];

        return { customersCompany };
    }, [uninvoicedCounts]);

    // FORM's INITIALIZATION & STATE

    const formDefaultValues = {
        paid: false,
        invoiceSent: false,
        exported: false,
        canceled: false,
        // basic data
        invoiceNumber: 'xxxxxxxx',
        customer_company: '',
        // currency: Currency['CZK'],
        // dates
        issueDate: '',
        orderDate: '',
        pointDate: '',
        dueDate: '',
        // order
        constantSymbol: '',
        paymentMethod: 'order',
        language: invoicingLanguages['čeština'],
        // commissions
        commission: [],
        // other
        deleted: true,
        reverseCharge: true,
        valueAddedTax: null,
        customer_id: null,
        companyRegistrationNumber: null,
    };

    const { t } = useTranslation();
    const methods = useForm<InvoiceDetailSchema>({
        defaultValues: data || formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(invoiceDetailSchema(t)),
    });
    const { handleSubmit, control, reset, setValue, formState, getValues } = methods;
    // check if the form values was changed

    useEffect(
        () => setIsSomeValueChanged && setIsSomeValueChanged(Object.keys(formState.dirtyFields).length !== 0),
        [JSON.stringify(formState.dirtyFields), setIsSomeValueChanged],
    );

    const { currency, customer_company, commission, issueDate } = useWatch({
        control,
    }) as InvoiceDetailSchema;

    // set the issue date only when fetching the customer (which includes defaultDueDate), so then we can calculate the dueDate
    useEffect(() => {
        if (!customer || issueDate) return;

        setValue('issueDate', String(new Date().setHours(0, 0, 0, 0)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer, issueDate]);

    // when issue date changes, update the due date
    useChange(
        () => {
            if (!customer?.defaultDueDate || !issueDate) return;

            const dueDate = addDays(Number(issueDate), customer.defaultDueDate);
            setValue('dueDate', String(dueDate.getTime()));
        },
        [customer?.defaultDueDate, issueDate],
        [customer?.defaultDueDate],
    );

    // we can get the customer_id only after fetching some uninvoiced items (only then we can get the dueDate)
    useEffect(() => {
        if (!customer_company || !fetchCustomer || !uninvoicedCommissions[0]) return;
        fetchCustomer(uninvoicedCommissions[0].customer_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer_company, uninvoicedCommissions.length]);

    const commissionsFieldArray = useFieldArray({
        control,
        name: 'commission',
    });

    // HOOKS THAT DEPEND ON FORM's STATE

    // when currency changes, remove all commissions;
    const [currCurrency, setCurrCurrency] = useState<Currency>(currency);
    useEffect(() => {
        if (currency !== currCurrency) {
            setCurrCurrency(currency);

            commissionsFieldArray.remove();
            data?.commission.forEach((item, index) => {
                setValue(`commission.${index}.commission_id`, item.commission_id);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency]);

    // when company of its currency changes, fetch new uninvoiced commissions
    useEffect(() => {
        if (!customer_company || !currency) return;
        const fetchUninvoicedItems = async () => {
            const { data: commissions } = await getUninvoicedItems({
                customer_company,
                currency,
                limit: 100000,
                offset: 0,
            });
            if (!commissions) return;
            setUninvoicedCommissions(commissions);
        };
        fetchUninvoicedItems(); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer_company, currency]);

    const { currencyOptions } = useMemo(() => {
        const currencyOptions: { label: string; value: string; endLabel: string }[] = [];
        const currentCustomer = uninvoicedCounts?.find((item) => item.customer_company === customer_company);

        if (currentCustomer && currentCustomer?.EUR !== '0') {
            currencyOptions.push({
                label: t('common.currency.EUR'),
                value: 'EUR',
                endLabel: currentCustomer.EUR,
            });
        }

        if (currentCustomer && currentCustomer?.CZK !== '0') {
            currencyOptions.push({
                label: t('common.currency.CZK'),
                value: 'CZK',
                endLabel: currentCustomer.CZK,
            });
        }

        return { currencyOptions };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer_company]);

    useEffect(() => {
        if (uninvoicedCommissions && customer_company) {
            // transform fetched commissions to format, used in the form for further probable POST / PUT request

            const transformedAdditionalCommissions: CommissionInvoiceSchema[] = uninvoicedCommissions.map(
                ({
                    commission_id,
                    number,
                    loading_city_string,
                    discharge_city_string,
                    orderDate,
                    vat,
                    priceCustomerOriginal,
                    currency,
                    customer_company,
                    loading_date,
                    discharge_date,
                    qid,
                }) => ({
                    commission_id,
                    commissionNumber: number,
                    pickupDate: loading_date.reduce<number | null>(
                        (prev, next) => (Number(prev) < Number(next) ? Number(prev) : Number(next)),
                        loading_date[0] ? Number(loading_date[0]) : null,
                    ),
                    pointDate: discharge_date.reduce<number | null>(
                        (prev, next) => (Number(prev) > Number(next) ? Number(prev) : Number(next)),
                        null,
                    ),
                    orderDate,
                    loading_city_string,
                    discharge_city_string,
                    vat: vat === '21' || vat === '0' ? vat : '21',
                    priceCustomer: Number(priceCustomerOriginal),
                    currencyCustomer: currency,
                    customer_company,
                    loading_date: loading_date.map((date) => Number(date)),
                    discharge_date: discharge_date.map((date) => Number(date)),
                    qid,
                }),
            );

            setAvailableCommissions(transformedAdditionalCommissions.concat(data?.commission || []));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currency, customer_company, uninvoicedCommissions]);

    // set the commission_id, loading_date and lowest orderData
    useEffect(() => {
        data?.commission.forEach((item, index) => {
            setValue(`commission.${index}.commission_id`, item.commission_id);
            setValue(`commission.${index}.loading_date`, item.loading_date);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this is the best solution for updating the totalPrice when updating commissions and their VATs
    // other solutions, such as setValue() and so on did not working because of weird mutations
    useUpdateEffect(() => {
        const newTotalPrice = commission.reduce((acc, commission) => {
            // Apply 21% VAT if vat is 21, else use the original price
            const price = Number(commission.priceCustomer);
            const vat = commission.vat;
            return acc + (vat === '21' ? price * 1.21 : price);
        }, 0);

        setTotalPrice(newTotalPrice);
    }, [commission]);

    // 1. handle submit on every change -- to fetch PDF
    useEffect(() => {
        if (!user || !customer) return;
        handleDataChangeDelay(getValues(), user, customer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(getValues()), customer]);

    // 2. debounce with 300ms delay on every change -- so that the server does not burn down
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleDataChangeDelay = useCallback(
        debounce((data, user, customer) => {
            fetchPdf(data, user, customer);
        }, 300),
        [],
    );
    const onSubmit = (currentData: InvoiceDetailSchema) => {
        if (!user) {
            return;
        } else if (currentData.commission.length < 1) {
            return toast.error(t('invoicing.emptyCommissions'));
        } else if (Number(currentData.issueDate) - Number(currentData.pointDate) > 14 * 24 * 60 * 60 * 1000)
            return toast.error(t('invoicing.maxIssueDate'));
        else if (Number(currentData.pointDate) > new Date().setHours(0, 0, 0, 0)) {
            return toast.error(t('invoicing.pointDateMustBeToday'));
        }

        prepareData(currentData, user, isAlreadyCreated, data);
    };
    useEffect(() => {
        if (forceSubmitForm) {
            formSubmitBtnRef.current?.click();
        }
    }, [forceSubmitForm]);

    return (
        <FormProvider {...methods}>
            <VisualFormInputsContext.Provider
                value={{
                    visualInputsList,
                    setVisualInputsList,
                }}
            >
                {showCommissionsPreview && (
                    <InvoiceCommissionTablePopup
                        checked={commission.map((commission) => commission.commission_id)}
                        availableCommissions={availableCommissions}
                        onCancel={() => setShowCommissionsPreview(false)}
                        onApprove={(newCommissionsList) => {
                            commissionsFieldArray.replace(newCommissionsList);
                            setValue('commission', newCommissionsList);

                            const newOrderDate = getInvoiceOrderDate(newCommissionsList);
                            newOrderDate && setValue('orderDate', newOrderDate);

                            const newPointDate = getInvoicePointDate(newCommissionsList);
                            newPointDate && setValue('pointDate', newPointDate);
                        }}
                    />
                )}
                <Form
                    id="invoice-form"
                    onSubmit={handleSubmit(onSubmit, (error) => handleErrorsWithNoInputs(error, visualInputsList))}
                    onReset={() => reset()}
                >
                    <button ref={formSubmitBtnRef} type="submit" className={styles.hidden}></button>
                    <FormGrid columns={4}>
                        <CheckboxField name="paid" label={t('invoicing.form.invoiceStatus.paid')} />
                        <CheckboxField name="invoiceSent" label={t('invoicing.form.invoiceStatus.invoiceSent')} />
                        <CheckboxField name="exported" label={t('invoicing.form.invoiceStatus.exported')} />
                        <CheckboxField name="canceled" label={t('invoicing.form.invoiceStatus.canceled')} />
                    </FormGrid>
                    <FormSection title={t('invoicing.form.basicInfo.sectionTitle')}>
                        <FormGrid columns={3}>
                            <TextField
                                name="invoiceNumber"
                                label={t('invoicing.form.basicInfo.invoiceNumber')}
                                disabled={true}
                            />
                            <ComboBoxField
                                name="customer_company"
                                label={t('invoicing.form.basicInfo.customer_company')}
                                options={
                                    isAlreadyCreated
                                        ? [{ label: customer_company, value: customer_company }]
                                        : customersCompany
                                }
                                isDisabled={isAlreadyCreated}
                            />
                            <ComboBoxField
                                name="currency"
                                label={t('invoicing.form.basicInfo.currency')}
                                options={
                                    isAlreadyCreated
                                        ? [{ label: t(`common.currency.${currency}`), value: currency }]
                                        : currencyOptions
                                }
                                isDisabled={isAlreadyCreated || !customer_company}
                            />
                        </FormGrid>
                    </FormSection>
                    <CommissionTableFormArray
                        {...commissionsFieldArray}
                        name="commission"
                        title={t('invoicing.form.commissions.sectionTitle')}
                        addTitle={t('invoicing.form.commissions.addCommission')}
                        onAddCommissionBtnClick={() => setShowCommissionsPreview((prev) => !prev)}
                        totalPriceText={`${formatPrice(Number(totalPrice))} ${t(`common.currency.${currency}`)}`}
                        defaultValues={{
                            commissionNumber: 0,
                            orderDate: '0',
                            loading_city_string: '',
                            discharge_city_string: '',
                            vat: '21',
                            priceCustomer: 0,
                            // properties which are not used in the form item ↓
                            commission_id: 0,
                            currencyCustomer: Currency['CZK'],
                            customer_company: '',
                            loading_date: [],
                            discharge_date: [],
                        }}
                        render={({
                            getFieldProps,
                            index,
                        }: RenderArgs<InvoiceDetailSchema, CommissionInvoiceSchema>) => {
                            return (
                                <div className={styles.commissionTableRow}>
                                    <CommissionTableCell
                                        className={styles.commissionNumberCell}
                                        onClick={() => {
                                            window.open(
                                                `${config.routes.commissions.list}/${commissionsFieldArray.fields[index].commission_id}`,
                                                '_blank',
                                            );
                                        }}
                                        {...getFieldProps('commissionNumber')}
                                    />
                                    <CommissionTableCell
                                        {...getFieldProps('pickupDate')}
                                        modificator={(value) =>
                                            value ? format(new Date(Number(value)), 'd. M. yyyy') : ''
                                        }
                                    />
                                    <CommissionTableCell {...getFieldProps('loading_city_string')} />
                                    <CommissionTableCell {...getFieldProps('discharge_city_string')} />
                                    <CommissionTableCellVat {...getFieldProps('vat')} />
                                    <CommissionTableCell
                                        {...getFieldProps('priceCustomer')}
                                        modificator={(value) =>
                                            `${formatPrice(Number(value))} ${t(`common.currency.${currency}`)}`
                                        }
                                    />
                                </div>
                            );
                        }}
                    />
                    <FormSection title={t('invoicing.form.dates.sectionTitle')}>
                        <FormGrid columns={2} rows={2}>
                            {/* Datum vystavení */}
                            <DateField name="issueDate" label={t('invoicing.form.dates.issueDate')} />
                            {/* Datum objednávky */}
                            <DateField name="orderDate" label={t('invoicing.form.dates.orderDate')} />
                            {/* Datum plnění */}
                            <DateField name="pointDate" label={t('invoicing.form.dates.pointDate')} />
                            {/* Datum splatnosti */}
                            <DateField name="dueDate" label={t('invoicing.form.dates.dueDate')} />
                        </FormGrid>
                    </FormSection>
                    <FormSection title={t('invoicing.form.order.sectionTitle')}>
                        <FormGrid columns={3}>
                            <TextField name="constantSymbol" label={t('invoicing.form.order.constantSymbol')} />
                            <SelectField
                                name="paymentMethod"
                                label={t('invoicing.form.order.paymentMethod')}
                                options={getInvoicePaymentMethodOptions(t)}
                            />
                            <SelectField
                                name="language"
                                label={t('invoicing.form.order.language')}
                                options={getInvoiceOrderLanguageOptions(t)}
                            />
                        </FormGrid>
                    </FormSection>
                    {data?.invoice_id && onAttachFile && onDeleteFile && (
                        <FileFormArray
                            {...attachedFilesList}
                            title={t('invoicing.form.attachedFiles.sectionTitle')}
                            files={attachedFilesList}
                            addTitle={t('invoicing.form.attachedFiles.addFile')}
                            onAttachFiles={onAttachFile}
                            render={({ uri, index }: { uri: string; index: number }) => (
                                <FileButton index={index} uri={uri} onDeleteFile={onDeleteFile} />
                            )}
                        />
                    )}
                </Form>
            </VisualFormInputsContext.Provider>
        </FormProvider>
    );
};

function transformIncomingData(data?: InvoiceDetailSchema): InvoiceDetailSchema | undefined {
    if (!data) return;
    const fetchedData = structuredClone(data);

    fetchedData.commission = fetchedData.commission.map((item) => ({
        ...item,
        pickupDate: item.loading_date.reduce<number | null>(
            (prev, next) => (Number(prev) < Number(next) ? Number(prev) : Number(next)),
            item.loading_date[0] ? Number(item.loading_date[0]) : null,
        ),
        pointDate: item.discharge_date.reduce<number | null>(
            (prev, next) => (Number(prev) > Number(next) ? Number(prev) : Number(next)),
            null,
        ),
    }));
    fetchedData.paymentMethod = 'order';
    fetchedData.orderDate = fetchedData.orderDate || getInvoiceOrderDate(fetchedData.commission);

    return fetchedData;
}

const CommissionTableCell = ({
    name,
    modificator,
    className,
    ...props
}: {
    name: string;
    modificator?: (value: string) => string;
} & React.HTMLAttributes<HTMLDivElement>) => {
    return (
        <Controller
            name={name}
            render={({ field }) => {
                return (
                    <div className={classNames([styles.CommissionTableFormArrayCell, className])} {...props}>
                        {modificator ? modificator(field.value) : field.value}
                    </div>
                );
            }}
        />
    );
};

export const invoicingVatNumbers = ['0', '21'];

// special row item for 'vat' property; we can increase it or decrease
const CommissionTableCellVat = ({ name }: { name: string }) => (
    <Controller
        name={name}
        render={({ field: { value, onChange } }) => {
            const onIncrease = () => {
                if (value !== invoicingVatNumbers[invoicingVatNumbers.length - 1]) {
                    const indexOfCurrentIndex = invoicingVatNumbers.indexOf(value);
                    onChange(invoicingVatNumbers[+indexOfCurrentIndex + 1]);
                }
            };
            const onDecrease = () => {
                if (value !== invoicingVatNumbers[0]) {
                    const indexOfCurrentIndex = invoicingVatNumbers.indexOf(value);
                    onChange(invoicingVatNumbers[indexOfCurrentIndex - 1]);
                }
            };

            return (
                <div className={`${styles.CommissionTableFormArrayCell} ${styles.vatPicker}`}>
                    {value}%
                    <div className={styles.icons}>
                        <button type="button" className={styles.chevron} onClick={onIncrease}>
                            <ChevronUpIcon />
                        </button>
                        <button type="button" className={styles.chevron} onClick={onDecrease}>
                            <ChevronDownIcon />
                        </button>
                    </div>
                </div>
            );
        }}
    />
);
