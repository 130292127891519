import { OnboardingBasicInfoSchema } from 'modules/onboarding/types';

import { TransformedBasicDataSchema, transformedBasicDataSchema } from './types';

export function transformOnboardingBasicData(basicData: OnboardingBasicInfoSchema): TransformedBasicDataSchema {
    const template: TransformedBasicDataSchema = structuredClone(basicData);

    // if update request, remove the carrier_id
    if (basicData.dispatcher_id) {
        delete template?.carrier_id;
    }

    if (basicData.password?.length === 0) {
        delete template.password;
    }

    return transformedBasicDataSchema().parse(template);
}
