import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CommissionSchema } from 'modules/commissions';

import styles from './CommissionInformationHeader.module.scss';

export const CommissionInformationHeader: React.FC = () => {
    const { t } = useTranslation();
    const { control } = useFormContext<CommissionSchema>();

    const { week, year, number, relation, enteredCarrierByNumber, enteredCarrierBy } = useWatch({
        control,
    });

    return (
        <div className={styles.commissionInfoWrapper}>
            <div>
                {t('commissions.form.qaplineId.author')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>
                    {enteredCarrierBy ? `${enteredCarrierBy} (${enteredCarrierByNumber})` : '---'}
                </span>
            </div>
            <div>
                {t('commissions.form.qaplineId.relation')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>{relation || '---'}</span>
            </div>
            <div>
                {t('commissions.form.qaplineId.number')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>{number || '---'}</span>
            </div>
            <div>
                {t('commissions.form.qaplineId.week')}:{' '}
                <span className={styles.commissionInfoHeaderValue}>
                    {week ? week : '---'}/{year ? year : '---'}
                </span>
            </div>
        </div>
    );
};
