import { createContext } from 'react';
import classNames from 'classnames';

import styles from './Form.module.scss';

export const VisualFormInputsContext = createContext<{
    visualInputsList: string[];
    setVisualInputsList: React.Dispatch<React.SetStateAction<string[]>>;
}>({
    visualInputsList: [],
    setVisualInputsList: () => {
        return;
    },
});

export const Form = ({
    children,
    actions,
    className,
    ...props
}: React.ComponentProps<'form'> & { actions?: React.ReactNode }) => {
    return (
        <form className={classNames(styles.form, className)} {...props}>
            {children}
            {actions && <div className={styles.actions}>{actions}</div>}
        </form>
    );
};
