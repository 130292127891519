import classNames from 'classnames';

import styles from './DebouncedAutocomplete.module.scss';

export interface IDebouncedAutocompleteOptionProps<T extends object> {
    option: T;
    labelProperty: keyof T;
    valueProperty: keyof T;
    hideOptions: () => void;
    setSelected: (val: T) => void;
}
export const DebouncedAutocompleteOption = <T extends object>({
    option,
    hideOptions,
    setSelected,
    labelProperty,
}: IDebouncedAutocompleteOptionProps<T>) => {
    return (
        <li
            onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                setSelected(option);
                hideOptions();
            }}
            className={classNames(styles.option, {
                [styles['option-selected']]: false,
            })}
        >
            {String(option[labelProperty])}
        </li>
    );
};
