import { OnboardingState } from 'modules/onboarding/components/OnboardingState';

import styles from './DispatchersOnboardingStates.module.scss';

export interface DispatchersOnboardingStatesProps {
    registered: number;
    unregistered: number;
    pending: number;
}
export const DispatchersOnboardingStates = ({
    pending = 0,
    registered = 0,
    unregistered = 0,
}: DispatchersOnboardingStatesProps) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.stateWrapper}>
                <OnboardingState state="unregistered" size="small" />
                <span className={styles.state}>{unregistered}</span>
            </div>
            <div className={styles.stateWrapper}>
                <OnboardingState state="pending" size="small" />
                <span className={styles.state}>{pending}</span>
            </div>
            <div className={styles.stateWrapper}>
                <OnboardingState state="registered" size="small" />
                <span className={styles.state}>{registered}</span>
            </div>
        </div>
    );
};
