import React from 'react';
import { Controller } from 'react-hook-form';
import { CarHeightIcon, CarLengthIcon, CarLoadIcon, CarWidthIcon } from 'assets/icons';
import classNames from 'classnames';
import { useNonTypedTranslation } from 'core/translation';
import { BaseFieldProps } from 'modules/form/types';
import { FormError, FormField, Slider } from 'modules/ui';

import styles from './VehicleProperties.module.scss';

export enum VehicleOptions {
    carLength = 'carLength',
    carWidth = 'carWidth',
    carLoad = 'carLoad',
    carHeight = 'carHeight',
}

//? max values are strings because of input width with decimal numbers (input is one digit wider thanks to that)
const VehicleInfo = {
    carLength: { img: <CarLengthIcon />, max: 20, step: 0.1 },
    carWidth: { img: <CarWidthIcon />, max: 3, step: 0.1 },
    carLoad: { img: <CarLoadIcon />, max: 35, step: 0.5 },
    carHeight: {
        img: <CarHeightIcon />,
        max: 4,
        step: 0.1,
    },
};

export type VehiclePropertiesProps = Omit<BaseFieldProps, 'label'> & {
    option: VehicleOptions;
    order: 'max' | 'min';
};

export const VehicleProperties = ({ name, option, order, ...props }: VehiclePropertiesProps) => {
    const { tnt } = useNonTypedTranslation();

    return (
        <Controller
            name={name}
            render={({ field: { value = null, ...field }, fieldState: { error } }) => (
                <FormField name={name}>
                    <div
                        className={classNames(styles.field, {
                            [styles['field-error']]: error,
                        })}
                    >
                        <div className={styles['wrapper']}>
                            <div className={styles['image']}>{VehicleInfo[option].img}</div>
                            <div className={styles['box']}>
                                <div className={styles['descripton']}>
                                    {tnt(`carriers.form.dispatchervehicle.vehicleProperties.${option}`, {
                                        order: order === 'max' ? 'Max.' : 'Min.',
                                    })}
                                </div>
                                <Slider
                                    step={VehicleInfo[option].step}
                                    min={0}
                                    max={VehicleInfo[option].max}
                                    {...field}
                                    value={value}
                                    {...props}
                                    onChange={field.onChange}
                                />
                            </div>
                        </div>
                    </div>
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
