import { Controller } from 'react-hook-form';
import { UnixDatePicker, UnixDatePickerProps } from 'modules/datepicker';
import { type BaseFieldProps } from 'modules/form';
import { FormError, FormField, FormLabel } from 'modules/ui';

export type DateFieldProps = BaseFieldProps & Omit<UnixDatePickerProps, 'value' | 'onChange'>;

export const DateField = ({ label, name, ...props }: DateFieldProps) => {
    return (
        <Controller
            name={name}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
                <FormField name={name}>
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <UnixDatePicker
                        aria-labelledby={name}
                        popoverId={name}
                        error={!!error}
                        value={value}
                        onChange={onChange}
                        {...props}
                    />
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
