import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { config } from 'config';
import { createFullName } from 'modules/carriers/utils';
import { tableNames } from 'modules/common';
import { DEFAULT_LANGUAGE_ID, languageIdToLang } from 'modules/common';
import { EnquiryDispatcherSchema, OfferSchema } from 'modules/offers/types';
import { Table, TableContainer, useTableState } from 'modules/table';
import { Typography } from 'modules/ui';
import { tableSortingFilteringPagination } from 'utils';

import { getContainsFilterMeta, getNumberRangeFilterMeta } from '../../../../table/utils/getFilter';
import { IContactedDispatchersListTableItem } from './types';

import styles from './ContactedDispatchersSection.module.scss';

const columnHelper = createColumnHelper<IContactedDispatchersListTableItem>();

interface IContactedDispatchersListTableProps {
    data: EnquiryDispatcherSchema[];
    offers: OfferSchema[];
    isOfferClosed: boolean;
    setShowFindCarrieModal: (value: boolean) => void;
}

export const ContactedDispatchersSection: React.FC<IContactedDispatchersListTableProps> = ({
    data,
    offers,
    isOfferClosed,
    setShowFindCarrieModal,
}) => {
    const { t } = useTranslation();

    const offersIds = offers.map((item) => item.dispatcher_id);

    const { tableProps, queryParams, paginationParams } = useTableState<IContactedDispatchersListTableItem>({
        rowIdKey: 'dispatcher_id',
        getCellStyles: ({ column }) => {
            const cellStyles: string[] = [styles.noBorder];

            if (column.id === 'carrierCompanyName') {
                cellStyles.push(Table.CellTextFormat.SemiBold);
            }

            return cellStyles;
        },
        tableName: tableNames.offers.contactedSection,
    });
    const carriersColumns = useMemo(
        () => [
            columnHelper.accessor('carrierCompanyName', {
                header: t('offers.form.addressedSection.carrier'),
                cell: (info) => (
                    <Link to={config.routes.carriers.detail.replace(':id', String(info.row.original.carrier_id))}>
                        <Typography variant="p" fontWeight="semibold" color="primary" link>
                            {info.getValue()}
                        </Typography>
                    </Link>
                ),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('number', {
                header: t('offers.form.addressedSection.number'),
                meta: {
                    ...getNumberRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('dispatcher', {
                header: t('offers.form.addressedSection.dispatcher'),
                cell: (info) => (
                    <Link to={config.routes.dispatcher.detail.replace(':id', String(info.row.original.dispatcher_id))}>
                        <Typography variant="p" fontWeight="semibold" color="primary" link>
                            {info.getValue()}
                        </Typography>
                    </Link>
                ),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('phone', {
                header: t('offers.form.addressedSection.phone'),
                meta: { ...getContainsFilterMeta() },
            }),
            columnHelper.accessor('language', {
                header: t('offers.form.addressedSection.language'),
                meta: { ...getContainsFilterMeta() },
            }),
            columnHelper.accessor('city', {
                header: t('offers.form.addressedSection.city'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('street', {
                header: t('offers.form.addressedSection.street'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('postalCode', {
                header: t('offers.form.addressedSection.postalCode'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('country', {
                header: t('offers.form.addressedSection.country'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('vehicleCount', {
                header: t('offers.form.addressedSection.vehicleCount'),
                meta: {
                    ...getNumberRangeFilterMeta(),
                },
            }),
        ],
        [t],
    );

    const formattedData: IContactedDispatchersListTableItem[] = data
        .filter((item) => !offersIds.includes(item.dispatcher_id))
        .map((item) => ({
            carrier_id: item.dispatcher.carrier_id,
            dispatcher_id: item.dispatcher_id,
            carrierCompanyName: item.dispatcher?.carrier?.company || '',
            number: item.dispatcher?.carrier_id || 0,
            dispatcher: createFullName(item.dispatcher?.firstName, item.dispatcher?.lastName),
            phone: item.dispatcher?.phone || '',
            language: t(`common.language.${languageIdToLang[item.dispatcher.language_id || DEFAULT_LANGUAGE_ID]}`),
            city: item.dispatcher?.carrier?.city || '',
            street: item.dispatcher?.carrier?.street || '',
            postalCode: item.dispatcher?.carrier?.postalCode || '',
            country: item.dispatcher?.carrier?.country || '',
            vehicleCount: item.dispatcher?._count?.dispatchervehicle || 0,
        }));
    const { result, sortedCount } = tableSortingFilteringPagination({
        data: formattedData,
        queryParams,
        paginationParams,
    });

    return (
        <section className={styles.contactDispatcherSection}>
            <div className={styles.text}>
                <Typography variant="headline-h4">{t('offers.form.addressedSection.title')}</Typography>
                <button
                    className={styles.contactDispatcherBtn}
                    disabled={isOfferClosed}
                    onClick={() => setShowFindCarrieModal(true)}
                    type="button"
                >
                    <Typography variant="p" color="primary">
                        {t('offers.form.addressedSection.contactCarrier')}
                    </Typography>
                </button>
            </div>
            <TableContainer>
                <Table
                    data={result}
                    columns={carriersColumns}
                    totalCount={sortedCount}
                    {...tableProps}
                    pageSizes={[10, 20, 50, 100]}
                    hideBorders
                />
            </TableContainer>
        </section>
    );
};
