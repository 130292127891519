import { useTranslation } from 'react-i18next';
import { AddressesInfoIcon, PersonalInfoIcon, VehiclesInfoIcon } from 'assets/icons';
import { useNonTypedTranslation } from 'core/translation';
import { Button, Typography } from 'modules/ui';

import styles from './WelcomePage.module.scss';

interface IWelcomePageProps {
    onRegisterButtonClick: () => void;
}

export const WelcomePage = ({ onRegisterButtonClick }: IWelcomePageProps) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();

    const stepsInfo = [
        { stepName: 'personalInfo', stepIcon: <PersonalInfoIcon /> },
        { stepName: 'vehiclesInfo', stepIcon: <VehiclesInfoIcon /> },
        { stepName: 'addressesInfo', stepIcon: <AddressesInfoIcon /> },
    ];

    return (
        <div className={styles.container}>
            <Typography className={styles.title} variant="h1">
                {t('onboarding.welcomePage.title')}
            </Typography>
            <Typography className={styles.description} variant="subheading">
                {t('onboarding.welcomePage.description')}
            </Typography>
            <div className={styles.stepper}>
                <div className={styles.circle}>1</div>
                <div className={styles.line} />
                <div className={styles.circle}>2</div>
                <div className={styles.line} />
                <div className={styles.circle}>3</div>
            </div>
            <div className={styles.stepsContainer}>
                {stepsInfo.map((stepInfo) => (
                    <div className={styles.stepCard} key={stepInfo.stepName}>
                        {stepInfo.stepIcon}
                        <Typography className={styles.stepCardTitle} variant="headline-h4" color="primary">
                            {tnt(`onboarding.welcomePage.${stepInfo.stepName}.title`)}
                        </Typography>
                        <Typography className={styles.stepCardTitleDescription} variant="subheading">
                            {tnt(`onboarding.welcomePage.${stepInfo.stepName}.description`)}
                        </Typography>
                    </div>
                ))}
            </div>
            <Button onClick={onRegisterButtonClick}>{t('auth.signup.submit')}</Button>
        </div>
    );
};
