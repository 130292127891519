import { useTranslation } from 'react-i18next';
import {
    PendingOnboardingStateIcon,
    RegisteredOnboardingStateIcon,
    UnregisteredOnboardingStateIcon,
} from 'assets/icons';
import { GetOneDispatcherResponseSchema } from 'modules/onboarding/types';
import { Typography } from 'modules/ui';

import styles from './OnboardingState.module.scss';

export interface OnboardingStateProps {
    state: GetOneDispatcherResponseSchema['onboardingState'];
    size?: 'big' | 'small';
}

export const OnboardingState = ({ state, size = 'small' }: OnboardingStateProps) => {
    const { t } = useTranslation();
    const stateIcon = {
        pending: <PendingOnboardingStateIcon />,
        registered: <RegisteredOnboardingStateIcon />,
        unregistered: <UnregisteredOnboardingStateIcon />,
    };

    return (
        <div className={styles.stateWrapper} data-state={state} data-size={size}>
            {stateIcon[state]}
            {size === 'big' && (
                <Typography className={styles.text} variant="p">
                    {t(`onboarding.state.${state}`)}
                </Typography>
            )}
        </div>
    );
};
