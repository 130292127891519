// the import order must be strictly followed
// eslint-disable-next-line  simple-import-sort/imports
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { commissionsApi } from 'modules/commissions';
import { customersApi } from 'modules/customers';
import { carrierApi } from 'modules/carriers';
import { invoicingApi } from 'modules/invoicing';
import { OnboardingSchema } from 'modules/onboarding/types';
import { Location } from 'react-router-dom';

interface AppState {
    // tables, which are currently fetching because of websocket or another updates;
    // we are using it, 'cause we don't want to show a big loading spinner, when there is only websocket update
    backstageFetching: ('Commissions' | 'Carriers' | 'Customers' | 'Invoices')[];
    // used to remember the commission
    savedDispatchers: OnboardingSchema[];
    routerHistory: Location[];
}

const initialState: AppState = {
    backstageFetching: [],
    savedDispatchers: [],
    routerHistory: [],
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(commissionsApi.endpoints.getCommissions.matchFulfilled, (state) => {
            state.backstageFetching = state.backstageFetching = state.backstageFetching.filter(
                (tableRoute) => tableRoute !== 'Commissions',
            );
        });
        builder.addMatcher(carrierApi.endpoints.getCarriers.matchFulfilled, (state) => {
            state.backstageFetching = state.backstageFetching = state.backstageFetching.filter(
                (tableRoute) => tableRoute !== 'Carriers',
            );
        });
        builder.addMatcher(customersApi.endpoints.getCustomers.matchFulfilled, (state) => {
            state.backstageFetching = state.backstageFetching = state.backstageFetching.filter(
                (tableRoute) => tableRoute !== 'Customers',
            );
        });
        builder.addMatcher(invoicingApi.endpoints.getInvoices.matchFulfilled, (state) => {
            state.backstageFetching = state.backstageFetching = state.backstageFetching.filter(
                (tableRoute) => tableRoute !== 'Invoices',
            );
        });
    },
    reducers: {
        removeLastRecordFromRouterHistory: (state, { payload }: PayloadAction<number | undefined>) => {
            state.routerHistory = state.routerHistory.slice(0, -(payload ?? 1));
        },
        addRecordToRouterHistory: (state, { payload }: PayloadAction<Location>) => {
            const recordAlreadyExists = state.routerHistory.some(({ key }) => key === payload.key);
            if (recordAlreadyExists) return;
            state.routerHistory = [...state.routerHistory, payload];
        },
        addBackstageFetchingTableRoute: (
            state,
            { payload }: PayloadAction<'Commissions' | 'Carriers' | 'Customers' | 'Invoices'>,
        ) => {
            state.backstageFetching = [...state.backstageFetching, payload];
        },
        addDispatcher: (state, { payload }: PayloadAction<OnboardingSchema>) => {
            state.savedDispatchers = [...state.savedDispatchers, payload];
        },
        updateDispatcher: (state, { payload }: PayloadAction<{ dispatcher: OnboardingSchema; index: number }>) => {
            console.log(payload.index);

            state.savedDispatchers[payload.index] = payload.dispatcher;
        },
        removeDispatcher: (state, { payload }: PayloadAction<{ indexes: number[] }>) => {
            state.savedDispatchers = state.savedDispatchers.filter((item, index) => !payload.indexes.includes(index));
        },
        emptyDispatchers: (state) => {
            state.savedDispatchers = [];
        },
    },
});

export const {
    addBackstageFetchingTableRoute,
    addDispatcher,
    updateDispatcher,
    removeDispatcher,
    emptyDispatchers,
    addRecordToRouterHistory,
    removeLastRecordFromRouterHistory,
} = appSlice.actions;
