import { FieldErrors } from 'react-hook-form';
import toast from 'react-hot-toast';

type IError = { [key: string]: IError | string };

/**
 * Toast for the errors without visual inputs.
 *
 * @param validationErrors form error object.
 * @param visualInputsList list of all visual inputs (use VisualFormInputsContextd).
 */
export function handleErrorsWithNoInputs(validationErrors: FieldErrors<any>, visualInputsList: string[]) {
    const pathsWithErrors: { path: string; message: string }[] = [];

    const getErrorPaths = (errors: IError, currentPath: string | null) => {
        if ('message' in errors && typeof errors['message'] === 'string') {
            return pathsWithErrors.push({
                path: currentPath || '',
                message: errors['message'],
            });
        }

        for (const propertyName in errors) {
            const path = (currentPath ? `${currentPath}.` : '') + propertyName;

            getErrorPaths(errors[propertyName] as IError, path);
        }
    };
    getErrorPaths(validationErrors as IError, null);
    const errorsWithoutVisualFields = pathsWithErrors.filter((error) => !visualInputsList.includes(error.path));

    if (!errorsWithoutVisualFields.length) return;
    toast.error(
        <ul>
            {errorsWithoutVisualFields.map((error, index) => (
                <li key={index}>{`Error[${index + 1}] ${error.path} -> ${error.message}`}</li>
            ))}
        </ul>,
        { duration: 6000 },
    );
}
