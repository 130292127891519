import { T } from 'core/translation/types';
import { CarrierPlaceSchema } from 'modules/carriers';
import { Countries } from 'modules/common';

export type ITransformedTablePlace = {
    index: number;
    country: string;
    postalCode: string;
    city: string;
    latitude: number;
    longitude: number;
    routeDirection: string;
};

// table has different columns than fetched addresses (locations)
export function transformPlacesIncomingData(data: CarrierPlaceSchema[], t: T): ITransformedTablePlace[] {
    const newData: ITransformedTablePlace[] = data.map((address, index) => {
        // address can have routeDirection = loading or discharge
        // if both loading and discharge, use "Nakládka, Vykládka"
        // if only some of them, use "Nakládka" or "Vykládka"
        // if nothing of them, use "—"
        const routeDirection =
            address.directionLoading && address.directionDischarge
                ? t('customers.form.locations.routeDirection.loading') +
                  ', ' +
                  t('customers.form.locations.routeDirection.discharge')
                : (address.directionLoading && t('customers.form.locations.routeDirection.loading')) ||
                  (address.directionDischarge && t('customers.form.locations.routeDirection.discharge')) ||
                  '—';

        return {
            index,
            country: Countries[address.countryCode],
            postalCode: address.postalCode,
            city: address.city,
            latitude: address.latitude,
            longitude: address.longitude,
            routeDirection,
        };
    });

    return newData;
}
