import classNames from 'classnames';

import styles from './Container.module.scss';

export type ContainerProps = React.ComponentProps<'div'> & {
    // true = max-width: 1920px;
    // false = max-width: 1200px;
    fullWidth?: boolean;
};

export const Container: React.FC<ContainerProps> = ({ children, className, fullWidth = false, ...props }) => {
    return (
        <div
            className={classNames(styles.container, className, {
                [styles['full-width']]: fullWidth,
            })}
            {...props}
        >
            {children}
        </div>
    );
};
