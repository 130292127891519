import React from 'react';
import { BaseFieldProps } from 'modules/form';

import styles from './MultiSwitch.module.scss';

export type MultiSwitchProps = BaseFieldProps & {
    value: string | number;
    onChange: (value: string | number) => void;
    // options: { [key: string | number]: string };
    options: { label: string; value: string | number }[];
    isNumber?: boolean;
    onToggle?: () => void;
};

export const MultiSwitch: React.FC<MultiSwitchProps> = ({
    options,
    onToggle,
    isNumber = false,
    value: fieldValue,
    onChange,
    ...props
}) => (
    <div className={styles['wrapper']} {...props}>
        {options.map(({ label, value }, index) => (
            <button
                type="button"
                className={styles['button']}
                role="radio"
                value={value}
                key={index}
                onClick={() => {
                    isNumber ? onChange(Number(value)) : onChange(value);
                    onToggle && onToggle();
                }}
                data-state={value == fieldValue ? 'on' : 'off'}
                {...props}
            >
                {label}
            </button>
        ))}
    </div>
);
