import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from 'assets/icons';
import { config } from 'config';
import { Avatar, AvatarGroup, Currency } from 'modules/common';
import { IOfferItemAdmin, OfferSchema } from 'modules/offers/types';
import { formatPrice } from 'modules/table';
import { Typography } from 'modules/ui';

import { OfferStatusAdmin } from '../OfferStatusAdmin';

import styles from './OfferItemAdmin.module.scss';

interface IOfferItemAdminProps {
    offerData: IOfferItemAdmin;
}

export const OfferItemAdmin: React.FC<IOfferItemAdminProps> = ({
    offerData: {
        id,
        number,
        commission_id,
        commissionNumber,
        city,
        dispatchers,
        offerStatusValue,
        offers,
        exchangeRate,
    },
}) => {
    const { t } = useTranslation();

    const selectedOffer = offers ? offers.find((item) => item.preferenced) : null;

    const lowestPriceOffer = offers.reduce<OfferSchema | undefined>((prev, curr) => {
        return (prev?.currency === Currency.CZK ? Number(prev?.price) : Number(prev?.price) * exchangeRate) <
            (curr?.currency === Currency.CZK ? Number(curr?.price) : Number(curr?.price) * exchangeRate)
            ? prev
            : curr;
    }, offers[0]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.infoTop}>
                        <Typography className={styles.idNumber} variant="subheading">
                            {number}
                        </Typography>
                        <Link
                            to={`${config.routes.commissions.list}/${commission_id}`}
                            className={styles.commissionLink}
                        >
                            <Typography variant="p" color="primary" fontWeight="semibold" link>
                                {t('commissions.form.title')} {commissionNumber || ''}
                            </Typography>
                            <ArrowRightIcon />
                        </Link>
                    </div>
                    <Typography variant="h4">{t('offers.offerCard.title', { city })}</Typography>
                </div>
                <div className={styles.addressed}>
                    <div className={styles.addressedInfo}>
                        <Typography variant="p" fontWeight="semibold">
                            {t('offers.offerCard.addressed')}
                        </Typography>
                        <Typography className={styles.carriersCount} variant="subheading">
                            {dispatchers.length} {t('offers.offerCard.carriers', { count: dispatchers.length })}
                        </Typography>
                    </div>
                    <AvatarGroup
                        avatars={dispatchers.map((dispatcher) => (
                            <Avatar fallback={dispatcher} medium />
                        ))}
                    />
                </div>
                <div className={styles.bestOffer}>
                    <div className={styles.bestOfferTitle}>
                        <Typography variant="p" fontWeight="semibold">
                            {selectedOffer ? t('offers.offerCard.carrier') : t('offers.offerCard.bestOffer')}
                        </Typography>
                        {/* <StarCheckbox isChecked={isFavorite} onCheckedChange={setIsFavorite} showBlueShadow /> */}
                    </div>
                    {selectedOffer ? (
                        <div className={styles.bestOfferInfo}>
                            <Link
                                to={config.routes.carriers.detail.replace(
                                    ':id',
                                    String(selectedOffer.dispatcher.carrier_id),
                                )}
                            >
                                <Typography className={styles.company} variant="p" link>
                                    {selectedOffer?.dispatcher.carrier?.company || ''}
                                </Typography>
                            </Link>
                            {Number(selectedOffer?.price) ? (
                                <Typography variant="p" fontWeight="medium">
                                    {formatPrice(Number(selectedOffer?.price))}{' '}
                                    {t(`common.currency.${selectedOffer.currency}`)}
                                </Typography>
                            ) : (
                                <Typography variant="p" fontWeight="medium">
                                    {t('offers.noSuggestedPrice')}
                                </Typography>
                            )}
                        </div>
                    ) : lowestPriceOffer ? (
                        <div className={styles.bestOfferInfo}>
                            <Link
                                to={config.routes.carriers.detail.replace(
                                    ':id',
                                    String(lowestPriceOffer.dispatcher.carrier_id),
                                )}
                            >
                                <Typography className={styles.company} variant="p" link>
                                    {lowestPriceOffer?.dispatcher.carrier?.company || ''}
                                </Typography>
                            </Link>
                            {Number(lowestPriceOffer?.price) ? (
                                <Typography variant="p" fontWeight="medium">
                                    {formatPrice(Number(lowestPriceOffer?.price))}{' '}
                                    {t(`common.currency.${lowestPriceOffer.currency}`)}
                                </Typography>
                            ) : (
                                <Typography variant="p" fontWeight="medium">
                                    {t('offers.noSuggestedPrice')}
                                </Typography>
                            )}
                        </div>
                    ) : (
                        <div className={styles.noOfferYet}>
                            <Typography variant="p" fontWeight="medium" color="secondary">
                                {t('offers.offerCard.noOfferYet')}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
            <div className={styles.detail}>
                <Link to={`${config.routes.offers.list}/${id}`}>
                    <Typography variant="p" color="primary" fontWeight="semibold" link>
                        {t('offers.offerCard.offerDetail')}
                    </Typography>
                </Link>
                <OfferStatusAdmin value={offerStatusValue} />
            </div>
        </div>
    );
};
