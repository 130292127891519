import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

import { baseQuery } from './baseQuery';

export const filesApi = createApi({
    reducerPath: 'filesApi',
    baseQuery,
    tagTypes: ['Files'],
    endpoints: (builder) => ({
        postAttachedFile: builder.query({
            query: ({ file, directory, id }: { file: File; directory: string; id: number }) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('directory', directory);
                formData.append('id', String(id));
                return {
                    url: config.api.endpoints.files,
                    method: 'POST',
                    body: formData,
                    formData: true,
                };
            },
        }),
        getAttachedFilesList: builder.query<string[], { directory: string; id: number }>({
            providesTags: ['Files'],
            query: ({ directory, id }) => ({
                url: config.api.endpoints.filesList,
                method: 'GET',
                params: { directory, id },
            }),
        }),
        getAttachedFile: builder.query<Blob, { filePath: string }>({
            providesTags: ['Files'],
            query: ({ filePath }: { filePath: string }) => ({
                url: config.api.endpoints.files,
                method: 'GET',
                params: { filePath },
                responseHandler: (response) => response.blob(),
            }),
        }),
        deleteAttachedFile: builder.query<any, { filePath: string }>({
            providesTags: ['Files'],
            query: ({ filePath }: { filePath: string }) => ({
                url: config.api.endpoints.files,
                method: 'DELETE',
                params: { filePath },
            }),
        }),
    }),
});

export const {
    useLazyPostAttachedFileQuery,
    useLazyGetAttachedFilesListQuery,
    useLazyGetAttachedFileQuery,
    useLazyDeleteAttachedFileQuery,
} = filesApi;
