import { Controller } from 'react-hook-form';
import classNames from 'classnames';
import { BaseFieldProps } from 'modules/form/types';
import { FormError, FormField, FormLabel } from 'modules/ui';

import styles from './TextAreaField.module.scss';

export type TextAreaProps = BaseFieldProps & React.ComponentProps<'textarea'>;

export const TextAreaField = ({ name, label, placeholder, ...props }: TextAreaProps) => {
    return (
        <Controller
            name={name}
            render={({ field: { value, onChange, ...fieldProps }, fieldState: { error } }) => (
                <FormField name={name}>
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <textarea
                        id={name}
                        className={classNames(styles.textarea, {
                            [styles['textarea-error']]: !!error,
                        })}
                        placeholder={placeholder || label}
                        value={value || ''}
                        onChange={(e) => onChange(e.target.value)}
                        {...fieldProps}
                        {...props}
                    />
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
