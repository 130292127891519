import React from 'react';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import { CheckIcon } from 'assets/icons';
import classnames from 'classnames';
import { BaseFieldProps } from 'modules/form';

import { Typography } from '../Typography';

import styles from './MultiSelect.module.scss';

export type MultiSelectOptions = {
    value: number;
    label: string;
}[];

export type MultiSelectProps = BaseFieldProps & {
    id: string;
    selectedValues: number[];
    setSelectedValues: (selected: number[]) => void;
    options: MultiSelectOptions;
    error?: boolean;
    placeholder: string;
};

export const MultiSelect = React.forwardRef<HTMLButtonElement, MultiSelectProps>(
    ({ selectedValues, setSelectedValues, options, error, placeholder }, ref) => {
        const handleCheckboxChange = (value: number) => {
            if (selectedValues.includes(value)) {
                setSelectedValues(selectedValues.filter((item) => item !== value));
            } else {
                setSelectedValues([...selectedValues, value]);
            }
        };

        const selectedOptionsString = options
            .filter(({ value }) => selectedValues.includes(value))
            .map(({ label }) => label)
            .join(', ');

        return (
            <Dropdown.DropdownMenu>
                <Dropdown.Trigger
                    ref={ref}
                    className={classnames(styles.trigger, {
                        [styles['trigger-error']]: error,
                    })}
                >
                    {selectedValues.length > 0 ? (
                        selectedOptionsString.length > 34 ? (
                            selectedOptionsString.slice(0, 34) + '...'
                        ) : (
                            selectedOptionsString
                        )
                    ) : (
                        <Typography variant="text-disabled">{placeholder}</Typography>
                    )}
                </Dropdown.Trigger>
                <Dropdown.Content className={styles.content}>
                    <Dropdown.DropdownMenu>
                        {options.map(({ value, label }) => (
                            <Dropdown.Item
                                className={classnames(styles.item, {})}
                                key={value}
                                onClick={() => handleCheckboxChange(value)}
                            >
                                {selectedValues.includes(value) && (
                                    <span className={styles['item-indicator']}>
                                        <CheckIcon />
                                    </span>
                                )}

                                {label}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.DropdownMenu>
                </Dropdown.Content>
            </Dropdown.DropdownMenu>
        );
    },
);
