import { useTranslation } from 'react-i18next';
import { AdminOfferStatusValue } from 'modules/offers/types';
import { InfoContainer } from 'modules/ui/components/InfoContainer';

export interface ICommissionEnquiryStateProps {
    enquiryState: AdminOfferStatusValue;
}
export const CommissionEnquiryState = ({ enquiryState }: ICommissionEnquiryStateProps) => {
    const { t } = useTranslation();
    const getCorrespondingText = () => {
        switch (enquiryState) {
            case AdminOfferStatusValue.Opened:
                return t('commissions.form.carrier.searchCarrier.enquiryOpened');
            case AdminOfferStatusValue.Closed:
                return t('commissions.form.carrier.searchCarrier.enquiryClosed');
            default:
                return '';
        }
    };
    return <InfoContainer>{getCorrespondingText()}</InfoContainer>;
};
