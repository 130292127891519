import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLogout } from 'core/auth';
import { selectAuth } from 'core/auth/services/selectors';
import { useAppSelector } from 'hooks';
import useWebsocket from 'hooks/useWebsocket';
import { UserInfo } from 'modules/common';
import { Toast } from 'modules/toast';
import { Button, Modal } from 'modules/ui/components';
import { LanguageSwitch } from 'modules/ui/components/LanguageSwitch';

import { Header } from '../Header';

import styles from './Page.module.scss';

export type PageProps = {
    children: React.ReactNode;
};

export const Page = ({ children }: PageProps) => {
    const logout = useLogout();
    useWebsocket();
    const { user } = useAppSelector(selectAuth);
    const [showLogoutConfirmationPopup, setShowLogoutConfirmationPopup] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <div className={styles.page}>
                <Header>
                    <LanguageSwitch />
                    <div className={styles.artificialGap} />
                    <UserInfo
                        firstName={user?.name || ''}
                        lastName={user?.surname || ''}
                        email={user?.email || ''}
                        imgSrc="https://images.unsplash.com/photo-1518020382113-a7e8fc38eac9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8
                        &auto=format&fit=crop&w=2776&q=80"
                        onLogout={() => setShowLogoutConfirmationPopup(true)}
                    />
                </Header>
                <div className={styles['artificial-gap']} />
                {children}
                {showLogoutConfirmationPopup && (
                    <Modal
                        onClick={() => setShowLogoutConfirmationPopup(false)}
                        label={t('auth.logout.label')}
                        description=""
                        cancelComponent={
                            <Button
                                className={styles.btn}
                                type="button"
                                color="green"
                                variant="secondary"
                                onClick={() => setShowLogoutConfirmationPopup(false)}
                            >
                                {t('auth.logout.cancel')}
                            </Button>
                        }
                        approveComponent={
                            <Button className={styles.btn} type="button" color="red" variant="primary" onClick={logout}>
                                {t('auth.logout.approve')}
                            </Button>
                        }
                    />
                )}
            </div>
            <Toast />
        </>
    );
};
