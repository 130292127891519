import { createFullName } from 'modules/carriers/utils';

import { CommissionCarrierTableItem } from '../../CommissionCarrierTable';
import { DispatcherSearchTableItem } from '../hooks/useDispatcherSearchTable';

export const transformIncomingData = (data: CommissionCarrierTableItem[]): DispatcherSearchTableItem[] => {
    const newData: DispatcherSearchTableItem[] = data.flatMap((carrier) =>
        carrier.dispatcher.map((dispatcher) => ({
            dispatcher_id: dispatcher.dispatcher_id || 0,
            company: carrier.company,
            country: carrier?.place?.country || '',
            postalCode: carrier?.place?.postalCode || '',
            fullName: createFullName(dispatcher.firstName, dispatcher.lastName),
            lastRequestTimeSent: dispatcher.lastRequestTimeSent || 0,
            routes: dispatcher.dispatcher_id || 0,
        })),
    );
    return newData;
};
