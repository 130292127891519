import { ArrayPath, FieldArray, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormActionButton, FormArray, type FormArrayProps, FormSection } from 'modules/form';

import styles from './VerticalFormArray.module.scss';

type VerticalFormArrayProps<T extends FieldValues, V extends FieldValues> = FormArrayProps<T, V> & {
    title: string;
    defaultValues: FieldArray<T, ArrayPath<T>>;
    addTitle?: string;
    endSlot?: React.ReactNode;
    headerEndSlot?: React.ReactNode;
    onAddItem?: () => void;
};

export const VerticalFormArray = <T extends FieldValues, V extends FieldValues>({
    title,
    append,
    defaultValues,
    addTitle,
    endSlot,
    onAddItem,
    headerEndSlot,
    ...props
}: VerticalFormArrayProps<T, V>) => {
    const { t } = useTranslation();

    const onClick = () => {
        onAddItem ? onAddItem() : append(defaultValues);
    };

    return (
        <FormSection title={title} headerEndSlot={headerEndSlot}>
            <FormArray append={append} className={styles.container} {...props} />
            <div className={styles.endSlot}>
                <FormActionButton className={styles['action-button']} onClick={onClick}>
                    {addTitle ? addTitle : t('form.addItem')}
                </FormActionButton>
                {endSlot}
            </div>
        </FormSection>
    );
};
