import { NonTypedTranslation } from 'core/translation';

export const vehicleFeature = {
    1: 'Plachta',
    2: 'Skříň',
    3: 'Chladírenská skříň',
    4: 'Plato',
    5: 'Mulda',
    6: 'Sklápěč',
    7: 'Klanice',
    8: 'Cisterna',
    9: 'Dvoupodlažní',
    10: 'Hydraulické čelo',
    11: 'Hydraulická ruka',
    12: 'Posuvná podlaha',
    13: 'ADR',
    14: 'Vysokozdvižný vozík',
    15: '',
};

export const getVehicleFeatureOptions = (
    t: NonTypedTranslation,
): {
    value: keyof typeof vehicleFeature;
    label: string;
}[] => {
    return Object.keys(vehicleFeature).map((key) => ({
        value: Number(key) as keyof typeof vehicleFeature,
        label: t(`onboarding.vehicleInfoForm.vehicleFeature.${key}`),
    }));
};
