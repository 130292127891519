import { NonTypedTranslation } from 'core/translation';

export const invoicePaymentMethod = {
    order: 'order',
};

export const getInvoicePaymentMethodOptions = (
    t: NonTypedTranslation,
): {
    value: (typeof invoicePaymentMethod)[keyof typeof invoicePaymentMethod];
    label: string;
}[] => {
    return Object.keys(invoicePaymentMethod).map((key) => ({
        value: invoicePaymentMethod[key as keyof typeof invoicePaymentMethod],
        label: t(`common.invoicePaymentMethod.${key}`),
    }));
};
