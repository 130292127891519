// docs: https://www.exchangerate-api.com/docs/free,

/**
 * Fetching the exchange rate by currency.
 *
 * @param base code of base currency.
 * @param target code of target currency.
 * @returns the exchange rate
 */
export const fetchExchangeRate = async ({
    base,
    target,
}: {
    base: 'CZK' | 'EUR';
    target: 'CZK' | 'EUR';
}): Promise<number | undefined> => {
    const response = await fetch(`https://open.er-api.com/v6/latest/${base}`, { method: 'GET' });

    if (response.status === 200) {
        const result: {
            rates: {
                CZK: number;
                EUR: number;
            };
        } = await response.json();

        return result.rates[target];
    }
};
