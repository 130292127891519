import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNonTypedTranslation } from 'core/translation';
import useChange from 'hooks/useChange';
import { CarrierNewSchema } from 'modules/carriers';
import { useGetOneCarrierQuery } from 'modules/carriers/services';
import {
    ActualCommissionSchema,
    CommissionDischargeSchema,
    CommissionExportSchema,
    commissionExportSchema,
    CommissionGoodsSchema,
    commissionLanguages,
    CommissionLoadingSchema,
    CommissionSchema,
} from 'modules/commissions/types';
import { countryCodeToLanguage, languageIdToLanguage } from 'modules/common';
import { getLanguageOptions } from 'modules/common/utils/language';
import { CustomerDetailSchema, LocationCustomerSchema } from 'modules/customers';
import { useGetOneCustomerQuery } from 'modules/customers/services';
import { CheckboxField, Form, FormGrid, FormSection, SelectField, TextAreaField, TextField } from 'modules/form';
import { transformCommissionIncomingData } from 'modules/form/components/CommissionsBasicForm';
import { getMessageAndSubject, mergeDischargesWithLocations, mergeLoadingsWithLocations } from 'utils';

import { useEmailMessageSaver } from '../../../commissions/hooks/useEmailMessageSaver';

export const CommissionExportForm: React.FC<{
    fetchedData: ActualCommissionSchema;
    prepareData: (
        commission: CommissionSchema,
        options: CommissionExportSchema,
        loadings: (CommissionLoadingSchema & LocationCustomerSchema)[],
        discharges: (CommissionDischargeSchema & LocationCustomerSchema)[],
        goods: CommissionGoodsSchema[],
        carrier: CarrierNewSchema,
        customer: CustomerDetailSchema,
    ) => void;
    onOptionsChange: (
        commission: CommissionSchema,
        options: CommissionExportSchema,
        loadings: (CommissionLoadingSchema & LocationCustomerSchema)[],
        discharges: (CommissionDischargeSchema & LocationCustomerSchema)[],
        goods: CommissionGoodsSchema[],
        carrier: CarrierNewSchema,
        customer: CustomerDetailSchema,
    ) => void;
}> = ({ fetchedData, prepareData, onOptionsChange }) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();

    const [companyLoadingList, setCompanyLoadingList] = useState<(CommissionLoadingSchema & LocationCustomerSchema)[]>(
        [],
    );
    const [companyDischargeList, setCompanyDischargeList] = useState<
        (CommissionDischargeSchema & LocationCustomerSchema)[]
    >([]);

    const { messages, subjects, setMessage, setSubject } = useEmailMessageSaver();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = useMemo(() => transformCommissionIncomingData(fetchedData), [fetchedData?.number]);

    const formDefaultValues: CommissionExportSchema = {
        // commission status
        createOrderCarrier: data.carrierOrderSent,
        createOrderConfirmation: data.orderConfirmationSent,
        createLoadingConfirmation: data.loadingConfirmationSent,
        createDeliveryConfirmation: data.dischargeConfirmationSent,
        // document settings
        template: 'createOrderCarrier',
        format: 'pdf',
        language: commissionLanguages['čeština'],
        companyName: '',
        // email
        recipient: '',
        subject: '',
        message: '',
    };

    const methods = useForm<CommissionExportSchema>({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(commissionExportSchema(t)),
    });

    const { control, handleSubmit, setValue, reset, getValues } = methods;
    const {
        template = 'createOrderCarrier',
        language = 'cs',
        companyName,
        message: formMessage,
        subject: formSubject,
    } = useWatch({ control });

    const { data: carrier } = useGetOneCarrierQuery({ id: String(data?.carrier_id) });
    const { data: customer } = useGetOneCustomerQuery({ id: String(data?.customer_id) });

    // when the company of loading/discharge changes, update the recipient according to that company email
    useChange(() => {
        if (['createLoadingConfirmation', 'createDeliveryConfirmation', 'neutralization'].includes(template)) {
            setValue(
                'recipient',
                customer?.location.find((location) => location.location_id === Number(companyName))?.email ||
                    t('commissions.export.form.emailSettings.noEmail'),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, companyName]);

    // set the default subject and message, according to the template and language
    useEffect(() => {
        if (!carrier?.dispatcher || !customer || !data) return; // find dispatcher
        const selectedDispatcher = carrier.dispatcher.find(
            (dispatcher) => dispatcher.dispatcher_id === Number(data.dispatcher_id),
        );
        
        // find contact
        const selectedContact = customer.customercontact.find(
            (contact) => contact.customerContact_id === data.customerContact_id,
        );

        const loadingCompanies: CommissionLoadingSchema[] = [];
        data.commissionLoadingDischarge.filter(function (item) {
            if (!item.isLoading) return false;
            const i = loadingCompanies.findIndex((x) => x.location_id == item.location_id);
            if (i <= -1) {
                loadingCompanies.push(item as CommissionLoadingSchema);
            }
            return null;
        });

        const dischargeCompanies: CommissionDischargeSchema[] = [];

        data.commissionLoadingDischarge.filter(function (item) {
            if (item.isLoading) return false;
            const i = dischargeCompanies.findIndex((x) => x.location_id == item.location_id);
            if (i <= -1) {
                dischargeCompanies.push(item as CommissionDischargeSchema);
            }
            return null;
        });

        // join the loadings / discharges with the locations to get the company name
        const loadingsWithLocation = mergeLoadingsWithLocations(loadingCompanies, customer.location);
        const dischargesWithLocation = mergeDischargesWithLocations(dischargeCompanies, customer.location);

        setCompanyLoadingList(loadingsWithLocation);
        setCompanyDischargeList(dischargesWithLocation);
        switch (template) {
            case 'createOrderCarrier':
                setValue('recipient', selectedDispatcher?.email || t('commissions.export.form.emailSettings.noEmail'));
                setValue(
                    'language',
                    commissionLanguages[languageIdToLanguage[selectedDispatcher?.language_id || '41'] || 'angličtina'],
                );
                break;

            case 'createOrderConfirmation':
                setValue('recipient', selectedContact?.email || t('commissions.export.form.emailSettings.noEmail'));
                setValue(
                    'language',
                    commissionLanguages[countryCodeToLanguage[customer.countryCode || 'EN'] || 'angličtina'],
                );
                break;

            case 'createLoadingConfirmation':
                setValue('companyName', String(loadingsWithLocation[0].location_id));
                setValue('recipient', '');
                setValue(
                    'language',
                    commissionLanguages[countryCodeToLanguage[customer.countryCode || 'EN'] || 'angličtina'],
                );
                break;

            case 'createDeliveryConfirmation':
                setValue('companyName', String(dischargesWithLocation[0].location_id));
                setValue('recipient', '');
                setValue(
                    'language',
                    commissionLanguages[countryCodeToLanguage[customer.countryCode || 'EN'] || 'angličtina'],
                );
                break;

            case 'neutralization':
                setValue(
                    'companyName',
                    String(dischargesWithLocation.filter((discharge) => discharge.neutralization)[0].location_id),
                );
                // setValue('recipient', 'recipient');
                setValue(
                    'language',
                    commissionLanguages[countryCodeToLanguage[customer.countryCode || 'EN'] || 'angličtina'],
                );
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, carrier?.dispatcher, customer, data]);

    // if template or language change, generate new message and PDF
    useEffect(() => {
        if (!data) return;
        if (!messages[template]?.[language]) {
            const { message: generatedMessage, subject: generatedSubject } = getMessageAndSubject({
                format: template,
                language: language,
                commissionNumber: data.qid || '—',
                loadings: companyLoadingList,
                discharges: companyDischargeList,
                goods: data.commissionitem,
                driverPhoneNumber: data?.carrierGsm || '',
                driverName: data?.carrierDriver || '',
                commission: data,
            });
            setValue('message', generatedMessage);
            setValue('subject', generatedSubject);
        }
        if (messages[template]?.[language]) setValue('message', messages[template][language]);
        if (subjects[template]?.[language]) setValue('subject', subjects[template][language]);

        if (!data || !carrier || !customer) return;
        onOptionsChange(
            data,
            getValues(),
            companyLoadingList,
            companyDischargeList,
            data.commissionitem,
            carrier,
            customer,
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [template, language, data, carrier, customer, companyLoadingList, companyDischargeList, companyName]);

    useEffect(() => {
        setMessage(formMessage as string, language, template);
        setSubject(formSubject as string, language, template);
    }, [formMessage, formSubject]);

    const onSubmit = (formData: CommissionExportSchema) => {
        if (!data || !carrier || !customer) return;

        // 1. split the loadings and discharges
        const loadings = data.commissionLoadingDischarge.filter(
            ({ isLoading }) => isLoading,
        ) as CommissionLoadingSchema[];
        const discharges = data.commissionLoadingDischarge.filter(
            ({ isLoading }) => !isLoading,
        ) as CommissionDischargeSchema[];

        // 2. join the loadings / discharges with the locations
        const loadingsWithLocation = mergeLoadingsWithLocations(loadings, customer.location);
        const dischargesWithLocation = mergeDischargesWithLocations(discharges, customer.location);
        prepareData(
            data,
            formData,
            loadingsWithLocation,
            dischargesWithLocation,
            data.commissionitem,
            carrier,
            customer,
        );
    };

    const templateOptions = [
        {
            label: t('commissions.export.form.documentSettings.template.createOrderCarrier'),
            value: 'createOrderCarrier',
        },
        {
            label: t('commissions.export.form.documentSettings.template.createOrderConfirmation'),
            value: 'createOrderConfirmation',
        },
        {
            label: t('commissions.export.form.documentSettings.template.createLoadingConfirmation'),
            value: 'createLoadingConfirmation',
        },
        {
            label: t('commissions.export.form.documentSettings.template.createDeliveryConfirmation'),
            value: 'createDeliveryConfirmation',
        },
    ];

    if (
        data?.commissionLoadingDischarge.filter((item) => !item.isLoading).some((discharge) => discharge.neutralization)
    ) {
        templateOptions.push({
            label: t('commissions.export.form.documentSettings.template.neutralization'),
            value: 'neutralization',
        });
    }

    return (
        <FormProvider {...methods}>
            <Form
                id="commission-export-form"
                onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
                onReset={() => reset()}
            >
                <FormSection title={t('commissions.form.commissionStatus.sectionTitle')}>
                    <FormGrid columns={4}>
                        <CheckboxField
                            name="createOrderCarrier"
                            label={t('commissions.export.form.documentSettings.template.createOrderCarrier')}
                        />
                        <CheckboxField
                            name="createOrderConfirmation"
                            label={t('commissions.export.form.documentSettings.template.createOrderConfirmation')}
                        />
                        <CheckboxField
                            name="createLoadingConfirmation"
                            label={t('commissions.export.form.documentSettings.template.createLoadingConfirmation')}
                        />
                        <CheckboxField
                            name="createDeliveryConfirmation"
                            label={t('commissions.export.form.documentSettings.template.createDeliveryConfirmation')}
                        />
                    </FormGrid>
                </FormSection>
                <FormSection title={t('commissions.export.form.documentSettings.sectionTitle')}>
                    <FormGrid columns={3}>
                        <SelectField
                            name="template"
                            label={t('commissions.export.form.documentSettings.template.label')}
                            options={templateOptions}
                        />
                        <SelectField
                            name="language"
                            label={t('commissions.export.form.documentSettings.language')}
                            options={getLanguageOptions(tnt, 'code')}
                        />
                        {(template === 'createLoadingConfirmation' ||
                            template === 'createDeliveryConfirmation' ||
                            template === 'neutralization') && (
                            <SelectField
                                name="companyName"
                                label={t('commissions.export.form.documentSettings.companyName')}
                                options={
                                    template === 'createLoadingConfirmation'
                                        ? companyLoadingList.map((item) => ({
                                              label: item.company || '—',
                                              value: String(item.location_id),
                                          }))
                                        : template === 'createDeliveryConfirmation'
                                        ? companyDischargeList.map((item) => ({
                                              label: item.company || '—',
                                              value: String(item.location_id),
                                          }))
                                        : companyDischargeList
                                              .filter((item) => item.neutralization)
                                              .map((item) => ({
                                                  label: item.company || '—',
                                                  value: String(item.location_id),
                                              }))
                                }
                            />
                        )}
                    </FormGrid>
                </FormSection>
                <FormSection title={t('commissions.export.form.emailSettings.sectionTitle')}>
                    <FormGrid columns={1}>
                        <TextField name="recipient" label={t('commissions.export.form.emailSettings.recipient')} />
                        <TextField name="subject" label={t('commissions.export.form.emailSettings.subject')} />
                        <TextAreaField
                            rows={12}
                            name="message"
                            label={t('commissions.export.form.emailSettings.message')}
                        />
                    </FormGrid>
                </FormSection>
            </Form>
        </FormProvider>
    );
};
