import { NonTypedTranslation } from 'core/translation';

export const commissionOrderSource = {
    notEntered: 'nezadáno',
    orderNumber: 'číslo objednávky',
    email: 'e-mail',
    phone: 'telefon',
};

export const getOrderSourceOptions = (
    t: NonTypedTranslation,
): {
    value: (typeof commissionOrderSource)[keyof typeof commissionOrderSource];
    label: string;
}[] => {
    return Object.keys(commissionOrderSource).map((key) => ({
        value: commissionOrderSource[key as keyof typeof commissionOrderSource],
        label: t(`common.commissionOrderSource.${key}`),
    }));
};
