import 'react-pdf/dist/Page/TextLayer.css';

import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { useNavigate, useParams } from 'react-router-dom';
import { EyeIOffIcon, IconDownload, IconPrint } from 'assets/icons';
import { config } from 'config';
import { useDocumentTitle } from 'core/application';
import { useAuth } from 'core/auth';
import { useAbortController } from 'hooks/useAbortController';
import { CarrierNewSchema } from 'modules/carriers';
import {
    useGetOneCommissionQuery,
    useLazyPostDeliveryConfirmationEmailQuery,
    useLazyPostDeliveryPdfQuery,
    useLazyPostDeliveryQuery,
    useLazyPostLoadingConfirmationEmailQuery,
    useLazyPostLoadingPdfQuery,
    useLazyPostLoadingQuery,
    useLazyPostNeutralizationConfirmationEmailQuery,
    useLazyPostNeutralizationPdfQuery,
    useLazyPostOrderCarrierConfirmationEmailQuery,
    useLazyPostOrderCarrierPdfQuery,
    useLazyPostOrderCarrierQuery,
    useLazyPostOrderConfirmationEmailQuery,
    usePutUpdatedCommissionMutation,
} from 'modules/commissions/services';
import {
    CommissionDischargeSchema,
    CommissionExportSchema,
    CommissionGoodsSchema,
    CommissionLoadingSchema,
    CommissionSchema,
} from 'modules/commissions/types';
import { CustomerDetailSchema, LocationCustomerSchema } from 'modules/customers';
import { CommissionExportForm } from 'modules/form';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button, Spinner } from 'modules/ui';
import {
    downloadURI,
    printURI,
    simplifyCreateDeliveryConfirmationPdfRequestBody,
    simplifyCreateLoadingConfirmationPdfRequestBody,
    simplifyCreateNeutralizationPdfRequestBody,
    simplifyCreateOrderCarrierPdfRequestBody,
    simplifyCreateOrderConfirmationRequestBody,
} from 'utils';

import styles from './CommissionsExport.module.scss';

export const CommissionsExport = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    useDocumentTitle(t('nav.commissions'));

    // fetch hooks
    const [postOrderCarrier] = useLazyPostOrderCarrierQuery();
    const [postOrderCarrierPdf] = useLazyPostOrderCarrierPdfQuery();
    const [postOrderCarrierConfirmationEmail] = useLazyPostOrderCarrierConfirmationEmailQuery();
    const [postOrderConfirmationEmail] = useLazyPostOrderConfirmationEmailQuery();
    const [postLoading] = useLazyPostLoadingQuery();
    const [postLoadingConfirmationEmail] = useLazyPostLoadingConfirmationEmailQuery();
    const [postLoadingPdf] = useLazyPostLoadingPdfQuery();
    const { abort: abortPdfRequest } = useAbortController();
    const [postDelivery] = useLazyPostDeliveryQuery();
    const [postDeliveryPdf] = useLazyPostDeliveryPdfQuery();
    const [postDeliveryConfirmationEmail] = useLazyPostDeliveryConfirmationEmailQuery();
    const [postNeutralizationPdf] = useLazyPostNeutralizationPdfQuery();
    const [postNeutralizationConfirmationEmail] = useLazyPostNeutralizationConfirmationEmailQuery();

    const [putUpdatedCommission] = usePutUpdatedCommissionMutation();
    const { data: response, isLoading, isFetching, refetch } = useGetOneCommissionQuery({ id: id || '' });

    // state hooks
    const [blob, setBlob] = useState<any>(null);
    const [options, setOptions] = useState<CommissionExportSchema>();
    const [pageWidth, setPageWidth] = useState(0);
    const [isLoadingPdf, setIsLoadingPdf] = useState(false);
    const [isSendingForm, setIsSendingForm] = useState(false);

    // other hooks
    const { user } = useAuth();
    const documentPageRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const fetchPdf = async (
        commission: CommissionSchema,
        options: CommissionExportSchema,
        loadings: (CommissionLoadingSchema & LocationCustomerSchema)[],
        discharges: (CommissionDischargeSchema & LocationCustomerSchema)[],
        goods: CommissionGoodsSchema[],
        carrier: CarrierNewSchema,
        customer: CustomerDetailSchema,
    ) => {
        if (!user) return;
        setBlob(null);
        setIsLoadingPdf(true);

        switch (options.template) {
            case 'createOrderCarrier': {
                const requestBody = simplifyCreateOrderCarrierPdfRequestBody({
                    options,
                    commission,
                    loadings,
                    discharges,
                    goods,
                    carrier,
                    user,
                });
                const { data: blob } = await postOrderCarrierPdf({ body: requestBody, aborter: abortPdfRequest });
                if (!blob) return;
                // Create a Blob from the PDF Stream
                const file = new Blob([blob], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                setBlob(fileURL);
                break;
            }
            case 'createOrderConfirmation': {
                // don't fetch PDF
                break;
            }
            case 'createLoadingConfirmation': {
                const requestBody = simplifyCreateLoadingConfirmationPdfRequestBody({
                    options,
                    commission,
                    loadings,
                    goods,
                    customer,
                });
                const { data: blob } = await postLoadingPdf({ body: requestBody, aborter: abortPdfRequest });
                if (!blob) return;
                // Create a Blob from the PDF Stream
                const file = new Blob([blob], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                setBlob(fileURL);
                break;
            }
            case 'createDeliveryConfirmation': {
                const requestBody = simplifyCreateDeliveryConfirmationPdfRequestBody({
                    options,
                    commission,
                    discharges,
                    goods,
                    customer,
                });

                const { data: blob } = await postDeliveryPdf({ body: requestBody, aborter: abortPdfRequest });
                if (!blob) return;
                // Create a Blob from the PDF Stream
                const file = new Blob([blob], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                setBlob(fileURL);
                break;
            }
            case 'neutralization': {
                const discharge = discharges.find((discharge) => String(discharge.location_id) === options.companyName);
                if (!discharge) return;

                const requestBody = simplifyCreateNeutralizationPdfRequestBody({
                    options,
                    commission,
                    discharge,
                    customer,
                });

                const { data: blob } = await postNeutralizationPdf({ body: requestBody, aborter: abortPdfRequest });
                if (!blob) return;
                // Create a Blob from the PDF Stream
                const file = new Blob([blob], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                setBlob(fileURL);
                break;
            }
        }
        setIsLoadingPdf(false);
    };

    useEffect(() => {
        if (!response) return;
        // refetch in the first render, due to caching
        refetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);

    // adjusting the PDF width to the page width
    useEffect(() => {
        const getDimensions = () => documentPageRef.current?.offsetWidth || 0;
        const handleResize = () => {
            setPageWidth(getDimensions());
        };
        if (documentPageRef.current) {
            setPageWidth(getDimensions());
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [blob]);

    if (isLoading || isFetching || !id || !response) return <Spinner fullScreen />;

    // on submit send email
    const prepareData = async (
        commission: CommissionSchema,
        options: CommissionExportSchema,
        loadings: (CommissionLoadingSchema & LocationCustomerSchema)[],
        discharges: (CommissionDischargeSchema & LocationCustomerSchema)[],
        goods: CommissionGoodsSchema[],
        carrier: CarrierNewSchema,
        customer: CustomerDetailSchema,
    ) => {
        // ? instruction if we want to send the email with attached PDF
        // 1. save that PDF (POST api/v3/delivery)
        // 2. send the email (POST api/v3/delivery/email)
        // 3. update the commission status

        if (!user) return;

        // every template describes appropriate status
        const properties: {
            [key: string]:
                | 'carrierOrderSent'
                | 'orderConfirmationSent'
                | 'loadingConfirmationSent'
                | 'dischargeConfirmationSent';
        } = {
            createOrderCarrier: 'carrierOrderSent',
            createOrderConfirmation: 'orderConfirmationSent',
            createLoadingConfirmation: 'loadingConfirmationSent',
            createDeliveryConfirmation: 'dischargeConfirmationSent',
        };

        let isEmailSent = false;

        setIsSendingForm(true);

        // send email
        switch (options.template) {
            case 'createOrderCarrier': {
                const requestBody = simplifyCreateOrderCarrierPdfRequestBody({
                    options,
                    commission,
                    loadings,
                    discharges,
                    goods,
                    carrier,
                    user,
                });
                const { isSuccess: sentSuccessfully } = await postOrderCarrier({ body: requestBody });
                if (!sentSuccessfully) return;

                const emailBody = {
                    to: options.recipient,
                    dispatcher: {
                        name: user.name,
                        surname: user.surname,
                        phone: user.mobilePhone,
                        email: user.email,
                    },
                    subject: options.subject,
                    body: options.message,
                    qid: commission.qid || '—',
                    lang: options.language,
                };
                const { isSuccess: isEmailSentSuccessfully } = await postOrderCarrierConfirmationEmail({
                    body: emailBody,
                });
                isEmailSent = isEmailSentSuccessfully;
                break;
            }
            case 'createOrderConfirmation': {
                const requestBody = simplifyCreateOrderConfirmationRequestBody({
                    user,
                    options,
                    commission,
                    loadings,
                    discharges,
                    goods,
                });
                const { isSuccess: isEmailSentSuccessfully } = await postOrderConfirmationEmail({ body: requestBody });
                isEmailSent = isEmailSentSuccessfully;
                break;
            }
            case 'createLoadingConfirmation': {
                const requestBody = simplifyCreateLoadingConfirmationPdfRequestBody({
                    options,
                    commission,
                    loadings,
                    goods,
                    customer,
                });
                const { isSuccess: sentSuccessfully } = await postLoading({ body: requestBody });
                if (!sentSuccessfully) return;

                const emailBody = {
                    to: options.recipient,
                    dispatcher: {
                        name: user.name,
                        surname: user.surname,
                        phone: user.mobilePhone,
                        email: user.email,
                    },
                    subject: options.subject,
                    body: options.message,
                    qid: commission.qid || '—',
                    lang: options.language,
                };
                const { isSuccess: isEmailSentSuccessfully } = await postLoadingConfirmationEmail({ body: emailBody });
                isEmailSent = isEmailSentSuccessfully;
                break;
            }
            case 'createDeliveryConfirmation': {
                const requestBody = simplifyCreateDeliveryConfirmationPdfRequestBody({
                    options,
                    commission,
                    discharges,
                    goods,
                    customer,
                });
                const { isSuccess: sentSuccessfully } = await postDelivery({ body: requestBody });
                if (!sentSuccessfully) return;

                const emailBody = {
                    to: options.recipient,
                    dispatcher: {
                        name: user.name,
                        surname: user.surname,
                        phone: user.mobilePhone,
                        email: user.email,
                    },
                    subject: options.subject,
                    body: options.message,
                    qid: commission.qid || '—',
                    lang: options.language,
                };
                const { isSuccess: isEmailSentSuccessfully } = await postDeliveryConfirmationEmail({ body: emailBody });
                isEmailSent = isEmailSentSuccessfully;
                break;
            }
            case 'neutralization': {
                const discharge = discharges.find((discharge) => String(discharge.location_id) === options.companyName);
                if (!discharge) return;

                const emailBody = {
                    to: options.recipient,
                    dispatcher: {
                        name: user.name,
                        surname: user.surname,
                        phone: user.mobilePhone,
                        email: user.email,
                    },
                    subject: options.subject,
                    body: options.message,
                    qid: commission.qid || '—',
                    lang: options.language,
                    dischargeNumber: discharge.commissionDischarge_id || 0,
                };
                const { isSuccess: isEmailSentSuccessfully } = await postNeutralizationConfirmationEmail({
                    body: emailBody,
                });
                isEmailSent = isEmailSentSuccessfully;
                break;
            }
        }

        if (isEmailSent) {
            toast.success(t('commissions.export.success'));

            const updatedCommission = {
                id: Number(id),
                data: {
                    orderConfirmationSent: options.createOrderConfirmation,
                    carrierOrderSent: options.createOrderCarrier,
                    loadingConfirmationSent: options.createLoadingConfirmation,
                    dischargeConfirmationSent: options.createDeliveryConfirmation,
                    commissionItems: {},
                    commissionLoadings: {},
                    commissionDischarges: {},
                },
            };

            if (options.template !== 'neutralization') {
                // set the value for the status that matches the selected pattern to true, regardless of what value the user entered
                updatedCommission.data[properties[options.template]] = true;
            }

            try {
                await putUpdatedCommission(updatedCommission);

                navigate(`${config.routes.commissions.list}/${id}`);
            } catch (error) {
                toast.error(t('commissions.export.error'));
            }
        } else toast.error(t('commissions.export.error'));

        setIsSendingForm(false);
    };

    const onOptionsChange = (
        commission: CommissionSchema,
        options: CommissionExportSchema,
        loadings: (CommissionLoadingSchema & LocationCustomerSchema)[],
        discharges: (CommissionDischargeSchema & LocationCustomerSchema)[],
        goods: CommissionGoodsSchema[],
        carrier: CarrierNewSchema,
        customer: CustomerDetailSchema,
    ) => {
        fetchPdf(commission, options, loadings, discharges, goods, carrier, customer);
        setOptions(options);
    };

    const handlePrint = () => {
        printURI(blob);
    };

    const handleDownload = () => {
        downloadURI(blob, `${t('commissions.form.title')} ${response.qid}`);
        toast.success(t('table.actions.export.message.success'));
    };

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    startSlot={<BackButton link={config.routes.commissions.detail.replace(':id', id)} />}
                    endSlot={
                        <div className={styles.actionButtons}>
                            <Button
                                onClick={handlePrint}
                                variant="secondary"
                                disabled={options?.template === 'createOrderConfirmation' || !blob}
                            >
                                <IconPrint />
                                {t('common.subheader.print')}
                            </Button>
                            <Button
                                onClick={handleDownload}
                                variant="secondary"
                                disabled={options?.template === 'createOrderConfirmation' || !blob}
                            >
                                <IconDownload />
                                {t('common.subheader.download')}
                            </Button>
                            <Button
                                form="commission-export-form"
                                type="submit"
                                isLoading={isSendingForm}
                                disabled={isSendingForm}
                            >
                                {t('commissions.export.form.send')}
                            </Button>
                        </div>
                    }
                    title={`${t('commissions.export.title')} ${response?.qid ?? ''}`}
                />
            }
        >
            <div className={styles.container}>
                <div className={styles.form}>
                    <CommissionExportForm
                        fetchedData={response}
                        prepareData={prepareData}
                        onOptionsChange={onOptionsChange}
                    />
                </div>
                <div className={styles.documentBox}>
                    <div className={styles.documentWrapper}>
                        {options?.template === 'createOrderConfirmation' ? (
                            <div className={styles.noPdf}>
                                <EyeIOffIcon />
                            </div>
                        ) : (
                            <div className={styles.documentContainer} ref={documentPageRef}>
                                <Document
                                    className={styles.document}
                                    file={blob}
                                    noData={() => (
                                        <div className={styles.noDocument}>
                                            {isLoadingPdf ? (
                                                <Spinner fullScreen />
                                            ) : (
                                                <div>{t('commissions.export.noDocument')}</div>
                                            )}
                                        </div>
                                    )}
                                >
                                    <Page
                                        width={pageWidth}
                                        pageNumber={1}
                                        devicePixelRatio={2}
                                        renderAnnotationLayer={false}
                                    />
                                </Document>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PageContent>
    );
};
