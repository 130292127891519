import { User } from 'core/auth/types';
import { BasicCarrierSchema } from 'modules/carriers/types';

import { TransformedBasicData } from './types';

export function transformCarrierBasicData(basicData: BasicCarrierSchema, editedBy: User): TransformedBasicData {
    // basic carrier data

    const template = {
        ...basicData,
        companyRegistrationNumber: !basicData.companyRegistrationNumber ? null : Number(basicData.companyRegistrationNumber),
        editedBy: editedBy.email,
        ts_added: basicData.carrier_id && basicData.ts_added ? basicData.ts_added : new Date().getTime(),
        ts_edited: basicData.ts_added ? new Date().getTime() : undefined,
    };
    'carrier_id' in template && delete template.carrier_id;

    return template;
}
