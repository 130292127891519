import { useTranslation } from 'react-i18next';
import { ArrowTriangleRightIcon } from 'assets/icons';
import { CheckIcon } from 'assets/icons';
import classNames from 'classnames';
import { AdminOfferStatusValue } from 'modules/offers/types';

import styles from './OfferStatusAdmin.module.scss';

export const AdminOfferStatusNext = {
    // Next status mapping
    // Current status (key) -> next status (value)
    [AdminOfferStatusValue.Waiting]: AdminOfferStatusValue.Opened,
    [AdminOfferStatusValue.Opened]: AdminOfferStatusValue.Closed,
    [AdminOfferStatusValue.Closed]: AdminOfferStatusValue.Opened,
};

export type OfferStatusProps = {
    value: AdminOfferStatusValue;
    onChange?: (value: AdminOfferStatusValue) => void;
};

export const OfferStatusAdmin = ({ value, onChange }: OfferStatusProps) => {
    const { t } = useTranslation();

    const AdminOfferStatusIcon: Record<AdminOfferStatusValue, React.ReactElement> = {
        [AdminOfferStatusValue.Waiting]: <ArrowTriangleRightIcon />,
        [AdminOfferStatusValue.Opened]: <ArrowTriangleRightIcon />,
        [AdminOfferStatusValue.Closed]: <CheckIcon />,
    };

    return (
        <div
            onClick={() => {
                onChange && onChange(AdminOfferStatusNext[value]);
            }}
            className={classNames(styles.wrapper, {
                [styles.waiting]: value === AdminOfferStatusValue.Waiting,
                [styles.opened]: value === AdminOfferStatusValue.Opened,
                [styles.closed]: value === AdminOfferStatusValue.Closed,
            })}
        >
            {t(`common.label.value.${value}`)}
            {AdminOfferStatusIcon[value]}
        </div>
    );
};
