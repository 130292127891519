import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { CheckIcon, CrossIcon, IconContact } from 'assets/icons';
import { config } from 'config';
import { createFullName } from 'modules/carriers/utils';
import { tableNames } from 'modules/common';
import { OfferSchema } from 'modules/offers/types';
import { Table, TableContainer, useTableState } from 'modules/table';
import { SimpleTextarea, Tooltip, Typography } from 'modules/ui';
import { tableSortingFilteringPagination, universalLanguageFinder } from 'utils';
import { timestamp } from 'utils/formatTimestamp';

import {
    getContainsFilterMeta,
    getDateRangeFilterMeta,
    getNumberRangeFilterMeta,
} from '../../../../table/utils/getFilter';
import { IOfferListTableItem } from './types';

import styles from './OffersSection.module.scss';

const columnHelper = createColumnHelper<IOfferListTableItem>();

interface IOfferListTableProps {
    data: OfferSchema[];
    isOfferClosed: boolean;
    setShowRemoveWinnerModal: (value: boolean) => void;
    onNoteChange: (value: string, offer_id: number) => void;
    setDispatcherIdToContact: (dispatcherId: number) => void;
    setOfferIdToWin: (offerId: number) => void;
}

export const OffersSection: React.FC<IOfferListTableProps> = ({
    data,
    isOfferClosed,
    setShowRemoveWinnerModal,
    setDispatcherIdToContact,
    setOfferIdToWin,
}) => {
    const { t } = useTranslation();

    const { tableProps, queryParams, paginationParams } = useTableState<IOfferListTableItem>({
        rowIdKey: 'dispatcher_id',
        getCellStyles: ({ row }) => {
            const cellStyles: string[] = [styles.noBorder];

            if (row.original.isWinner) {
                cellStyles.push(Table.CellColor.Green);
            }

            return cellStyles;
        },
        tableName: tableNames.offers.offersSection,
        defaultSorting: { id: 'price', desc: false },
    });

    const offersColumns = useMemo(
        () => [
            columnHelper.accessor('carrier', {
                header: t('offers.form.offersSection.carriers'),
                cell: (info) => (
                    <Link to={config.routes.carriers.detail.replace(':id', String(info.row.original.carrier_id))}>
                        <Typography variant="p" fontWeight="semibold" color="primary" link>
                            {info.getValue()}
                        </Typography>
                    </Link>
                ),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('dispatcher', {
                header: t('offers.form.offersSection.dispatcher'),
                cell: (info) => (
                    <Link to={config.routes.dispatcher.detail.replace(':id', String(info.row.original.dispatcher_id))}>
                        <Typography variant="p" fontWeight="semibold" color="primary" link>
                            {info.getValue()}
                        </Typography>
                    </Link>
                ),
            }),
            columnHelper.accessor('phone', {
                header: t('offers.form.offersSection.phone'),
                meta: { ...getContainsFilterMeta() },
            }),
            columnHelper.accessor('language', {
                header: t('offers.form.offersSection.language'),
                meta: { ...getContainsFilterMeta() },
            }),
            columnHelper.accessor('answerDate', {
                header: t('offers.form.offersSection.answerDate'),
                cell: (info) => timestamp(info.getValue())?.format('DD. MM. YYYY') || '',
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('price', {
                header: t('offers.form.offersSection.price'),
                cell: (info) =>
                    Number(info.getValue())
                        ? `${info.getValue().toLocaleString('cs-CZ')} ${t(
                              `common.currency.${info.row.original.currency}`,
                          )}`
                        : t('offers.notSpecified'),
                meta: {
                    ...getNumberRangeFilterMeta(),
                    align: 'right',
                },
            }),
            columnHelper.accessor('note', {
                header: t('offers.form.offersSection.note'),
                cell: (info) => (
                    <Controller
                        // use index stored in the form, not in the table, 'cause filters may change index
                        name={`offers.${info.row.original.originalIndex}.note`}
                        render={({ field: { value, onChange } }) => {
                            const onTextChange = (value: string) => {
                                onChange(value);
                            };
                            return <SimpleTextarea value={value} onChange={onTextChange} />;
                        }}
                    />
                ),
                enableSorting: false,
            }),
            columnHelper.display({
                id: 'action-columns',
                cell: ({ row }) => (
                    <div className={styles.actionButtons}>
                        <Tooltip content={t('offers.form.offersSection.tooltips.contact')}>
                            <button
                                onClick={() => setDispatcherIdToContact(row.original.dispatcher_id)}
                                type="button"
                                className={styles.contactBtn}
                            >
                                <IconContact />
                            </button>
                        </Tooltip>
                        {isOfferClosed ? (
                            row.original.isWinner && (
                                <Tooltip content={t('offers.form.offersSection.tooltips.remove')}>
                                    <button
                                        onClick={() => setShowRemoveWinnerModal(true)}
                                        type="button"
                                        className={styles.removeBtn}
                                    >
                                        <CrossIcon color="#fe173b" />
                                    </button>
                                </Tooltip>
                            )
                        ) : (
                            <Tooltip content={t('offers.form.offersSection.tooltips.select')}>
                                <button
                                    onClick={() => setOfferIdToWin(row.original.offer_id)}
                                    type="button"
                                    className={styles.selectBtn}
                                >
                                    <CheckIcon color="#1770ff" />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                ),
                meta: {
                    align: 'right',
                    disableRowClick: true,
                },
                enableSorting: false,
                enableColumnFilter: false,
            }),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t],
    );
    const formattedData: IOfferListTableItem[] = data.map((item, index) => ({
        offer_id: item.offer_id || 0,
        carrier_id: item.dispatcher.carrier_id || 0,
        dispatcher_id: item.dispatcher_id || 0,
        carrier: item.dispatcher?.carrier?.company || '',
        dispatcher: createFullName(item.dispatcher?.firstName, item.dispatcher?.lastName),
        phone: item.dispatcher?.phone || '',
        language: t(`common.language.${universalLanguageFinder(String(item.dispatcher.language_id))?.lang || 'en'}`),
        answerDate: Number(item.tsAdded) * 1000,
        price: Number(item.price),
        currency: item.currency || 'CZK',
        note: item.note || '',
        originalIndex: index,
        isWinner: item.preferenced,
    }));
    const { result, sortedCount } = tableSortingFilteringPagination({
        data: formattedData,
        queryParams,
        paginationParams,
    });

    return (
        <section className={styles.offersSection}>
            <div className={styles.text}>
                <Typography variant="headline-h4">{t('offers.form.offersSection.title')}</Typography>
            </div>
            <TableContainer>
                <Table
                    data={result}
                    columns={offersColumns}
                    totalCount={sortedCount}
                    {...tableProps}
                    pageSizes={[10, 20, 50, 100]}
                    hideBorders
                />
            </TableContainer>
        </section>
    );
};
