import { fetchLocationQuery } from 'core/api';

export const getProbableFetchLocationQueryResult = (
    results: Awaited<ReturnType<typeof fetchLocationQuery>>,
    countryCode: string | undefined = 'CZ',
) => {
    if(!results.length) {
        return null;
    }
    const probableResults = results.filter((result) => result.countryCode === countryCode);
    if (probableResults.length) {
        return probableResults[0];
    }
    return results[0];
};
