import React from 'react';
import { useTranslation } from 'react-i18next';
import { CommissionLoadingDischargeSchema } from 'modules/commissions';
import { Typography } from 'modules/ui';

import { LocationListItem } from '../LocationListItem';
import { LocationsMap } from '../LocationsMap';

import styles from './PlacesSection.module.scss';

interface IPlacesSectionProps {
    commissionLoadingDischarge: CommissionLoadingDischargeSchema[];
    selectedLocationIdx: number | null;
    setSelectedLocationIdx: (index: number | null) => void;
    onLoadingDischargeClick: (index: number) => void;
}

export const PlacesSection: React.FC<IPlacesSectionProps> = ({
    commissionLoadingDischarge,
    selectedLocationIdx,
    setSelectedLocationIdx,
    onLoadingDischargeClick,
}) => {
    const { t } = useTranslation();
    return (
        <section className={styles.placesSection}>
            <div className={styles.placesListContainer}>
                <div className={styles.placesSectionText}>
                    <Typography variant="headline-h4">{t('offers.form.placesSection.title')}</Typography>
                </div>
                <ul className={styles.placesList}>
                    {commissionLoadingDischarge.map((item, index) => (
                        <LocationListItem
                            onClick={() => {
                                onLoadingDischargeClick(index);
                            }}
                            selected={index === selectedLocationIdx}
                            data={item}
                            key={item.commissionLoading_id || item.commissionDischarge_id}
                        />
                    ))}
                </ul>
            </div>
            <div className={styles.placesMap}>
                <LocationsMap
                    data={commissionLoadingDischarge}
                    selectedLocationIdx={selectedLocationIdx}
                    setSelectedLocationIdx={setSelectedLocationIdx}
                />
            </div>
        </section>
    );
};
