import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { baseQueryWithAuthRefresh } from 'core/api/baseQuery';
import { GlobalFilterParams, PaginationParams } from 'types/query-params';

import {
    EnquirySchema,
    EnquirySchemaComplete,
    OfferSchema,
    PostOfferRequest,
    SendEnquiryEmailRequestSchema,
    UpdateEnquiryRequestSchema,
} from './../types';

export const enquiryApi = createApi({
    reducerPath: 'enquiryApi',
    baseQuery: baseQueryWithAuthRefresh,
    tagTypes: ['Enquiries', 'EnquiryItem'],
    endpoints: (builder) => ({
        getAllEnquiries: builder.query<
            { data: EnquirySchemaComplete[]; totalCount: number },
            GlobalFilterParams & PaginationParams & { state?: string }
        >({
            providesTags: ['Enquiries'],
            query: ({ state, ...params }) => ({
                url: config.api.endpoints.enquiry,
                params: { ...params, 'state[]': state },
            }),
        }),
        getOneEnquiry: builder.query<EnquirySchemaComplete, { id: number }>({
            providesTags: (_result, _error, arg) => [{ type: 'EnquiryItem', id: arg.id }],
            query: ({ id }) => ({
                url: `${config.api.endpoints.enquiry}/${id}`,
            }),
        }),
        putEnquiry: builder.mutation<EnquirySchema, { data: Partial<UpdateEnquiryRequestSchema>; id: number }>({
            query: ({ data, id }) => ({
                url: `${config.api.endpoints.enquiry}/${id}`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (_result, _error, arg) => [{ type: 'EnquiryItem', id: arg.id }],
        }),
        postEnquiryEmail: builder.mutation<Record<string, boolean>, SendEnquiryEmailRequestSchema>({
            query: (body) => ({
                url: config.api.endpoints.enquiryContact,
                method: 'POST',
                body,
            }),
            invalidatesTags: (_result, _error, arg) => ['Enquiries', { type: 'EnquiryItem', id: arg.enquiryId }],
        }),
        closeEnquiry: builder.mutation<EnquirySchemaComplete, { offerId: number; enquiryId: number }>({
            query: ({ offerId }) => ({
                url: config.api.endpoints.closeEnquiry,
                method: 'PUT',
                body: { offerId },
            }),
            invalidatesTags: (_result, _error, arg) => ['Enquiries', { type: 'EnquiryItem', id: arg.enquiryId }],
        }),
        reopenEnquiry: builder.mutation<EnquirySchemaComplete, { id: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.reopenEnquiry}/${id}`,
                method: 'PUT',
            }),
            invalidatesTags: (_result, _error, arg) => ['Enquiries', { type: 'EnquiryItem', id: arg.id }],
        }),
        deleteEnquiry: builder.mutation<EnquirySchemaComplete, { id: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.enquiry}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, arg) => ['Enquiries', { type: 'EnquiryItem', id: arg.id }],
        }),
        // Offer endpoints part
        putOffer: builder.mutation<OfferSchema, { data: Partial<OfferSchema>; id: number }>({
            query: ({ data, id }) => ({
                method: 'PUT',
                url: `${config.api.endpoints.offer}/${id}`,
                body: data,
            }),
        }),
        postOffer: builder.mutation<OfferSchema, PostOfferRequest>({
            query: (data) => ({
                method: 'POST',
                url: config.api.endpoints.offer,
                body: data,
            }),
            invalidatesTags: (_result, _error, arg) => ['Enquiries', { type: 'EnquiryItem', id: arg.enquiryId }],
        }),
        deleteOffer: builder.mutation<OfferSchema, { id: number; enquiryId: number }>({
            query: ({ id }) => ({
                url: `${config.api.endpoints.offer}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, arg) => ['Enquiries', { type: 'EnquiryItem', id: arg.enquiryId }],
        }),
    }),
});

export const {
    useGetAllEnquiriesQuery,
    useGetOneEnquiryQuery,
    usePutEnquiryMutation,
    usePostEnquiryEmailMutation,
    useCloseEnquiryMutation,
    useReopenEnquiryMutation,
    useDeleteEnquiryMutation,
    usePutOfferMutation,
    usePostOfferMutation,
    useDeleteOfferMutation,
} = enquiryApi;
