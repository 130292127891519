import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { T } from 'core/translation/types';
import { createFullName } from 'modules/carriers/utils';
import { DEFAULT_LANGUAGE_ID, languageIdToLang } from 'modules/common';
import { FormGrid, TextAreaField, TextField } from 'modules/form';
import { OfferSchema } from 'modules/offers/types';
import { Button, Typography } from 'modules/ui';
import { z } from 'zod';

import styles from './ContactDispatcherModal.module.scss';

interface IContactCarrierModalProps {
    offer: OfferSchema;
    enquiryNumber: number;
    onClose: () => void;
    handleEmail: (subject: string, message: string) => void;
}

const sendEmailForm = (t: T) =>
    z.object({
        subject: z.string().min(1, { message: t('form.errors.required') }),
        message: z.string().min(10, { message: t('form.errors.minLengthOther', { minLength: 10 }) }),
    });

export type SendEmailForm = z.infer<ReturnType<typeof sendEmailForm>>;

export const ContactDispatcherModal: React.FC<IContactCarrierModalProps> = ({
    offer,
    enquiryNumber,
    onClose,
    handleEmail,
}) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'offers.contactDispatcherModal',
    });

    const { t: classicTranslation } = useTranslation();

    const { t: dispatcherTranslation } = useTranslation('translation', {
        lng: languageIdToLang[offer.dispatcher.language_id || DEFAULT_LANGUAGE_ID],
        keyPrefix: 'offers.contactDispatcherModal',
    });

    const methods = useForm<SendEmailForm>({
        defaultValues: {
            subject: dispatcherTranslation('subjectPlaceholder', { number: enquiryNumber }),
            message: '',
        },
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        resolver: zodResolver(sendEmailForm(classicTranslation)),
    });
    const { handleSubmit } = methods;

    const onSubmit = (data: SendEmailForm) => {
        handleEmail(data.subject, data.message);
    };

    const handleButtonSubmit = () => {
        handleSubmit(onSubmit, (error) => console.log(error))();
    };

    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                <FormProvider {...methods}>
                    <Form
                        id="contact-dispatcher-offer-form"
                        onSubmit={handleSubmit(onSubmit, (error) => console.log(error))}
                    >
                        <div className={styles.title}>
                            <Typography variant="h4">{t('label')} - </Typography>
                            <Typography variant="h4" fontWeight="medium">
                                {createFullName(offer?.dispatcher.firstName, offer?.dispatcher.lastName)}
                            </Typography>
                        </div>
                        <FormGrid columns={1}>
                            <TextField name="subject" label={t('subject')} />
                            <TextAreaField name="message" rows={14} label={t('message')} />
                            <div className={styles.buttons}>
                                <Button
                                    className={styles.btn}
                                    type="button"
                                    color="green"
                                    variant="secondary"
                                    onClick={onClose}
                                >
                                    {t('cancel')}
                                </Button>
                                <Button
                                    onClick={handleButtonSubmit}
                                    className={styles.btn}
                                    color="red"
                                    variant="primary"
                                >
                                    {t('approve')}
                                </Button>
                            </div>
                        </FormGrid>
                    </Form>
                </FormProvider>
            </div>
        </div>
    );
};
