import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDocumentTitle } from 'core/application';
import useChange from 'hooks/useChange';
import { createFullName } from 'modules/carriers/utils';
import { useSelectedDispatchers } from 'modules/commissions/hooks/useSelectedDispatchers';
import { tableNames } from 'modules/common';
import { EnquiryDispatcherSchema } from 'modules/offers/types';
import { Typography } from 'modules/ui';

import { CommissionCarrierTableItem } from '../CommissionCarrierTable';
import { SearchedDispatchersTable } from './components/SearchedDispatchersTable';
import { SelectedDispatchersTable } from './components/SelectedDispatchersTable';
import { DispatcherSearchTableItem } from './hooks/useDispatcherSearchTable';

import styles from './DispatcherSearchTables.module.scss';

export interface IDispatcherSearchTablesProps {
    data: CommissionCarrierTableItem[];
    disabledIds?: number[];
    defaultSelectedDispatchers?: EnquiryDispatcherSchema[];
}
export const DispatcherSearchTables: React.FC<IDispatcherSearchTablesProps> = ({
    data,
    disabledIds,
    defaultSelectedDispatchers = [],
}) => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.customers'));

    const formattedSelectedDispatchers = defaultSelectedDispatchers.map((item) => ({
        dispatcher_id: item.dispatcher_id,
        country: item.dispatcher.carrier.country,
        company: item.dispatcher.carrier.company,
        postalCode: item.dispatcher.carrier.postalCode,
        fullName: createFullName(item.dispatcher.firstName, item.dispatcher.lastName),
        lastRequestTimeSent: item.dispatcher.lastRequestTimeSent || 0,
        routes: item.dispatcher_id,
    }));

    const [selectedDispatchersLocal, setSelectedDispatchersLocal] =
        useState<DispatcherSearchTableItem[]>(formattedSelectedDispatchers);
    const { setSelectedDispatchers } = useSelectedDispatchers();

    useChange(() => {
        setSelectedDispatchers(selectedDispatchersLocal.map(({ dispatcher_id }) => ({ dispatcherId: dispatcher_id })));
    }, [selectedDispatchersLocal]);

    return (
        <>
            <div className={styles.selectCarrierTitle}>
                <Typography variant="headline-h4">{t('offers.modalFindCarriers.selectCarrier')}</Typography>
            </div>
            <SearchedDispatchersTable
                data={data}
                selectedDispatchers={selectedDispatchersLocal}
                setSelectedDispatchers={setSelectedDispatchersLocal}
                tableName={tableNames.commissions.searched}
            />
            <div className={styles.selectCarrierTitle}>
                <Typography variant="headline-h4">{t('offers.modalFindCarriers.selectedCarriers')}</Typography>
            </div>
            <SelectedDispatchersTable
                selectedDispatchers={selectedDispatchersLocal}
                setSelectedDispatchers={setSelectedDispatchersLocal}
                disabledIds={disabledIds}
                tableName={tableNames.commissions.selected}
            />
        </>
    );
};
