import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from 'assets/icons';
import debounce from 'lodash/debounce';
import { Input } from 'modules/ui';

export type SearchBarProps = {
    value: string;
    onChange: (e: string) => void;
};

export const SearchBar = ({ value, onChange }: SearchBarProps) => {
    const { t } = useTranslation();
    const [curValue, setCurValue] = useState<string>(value);

    const onSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        handleSearch(e.currentTarget.value);
        setCurValue(e.currentTarget.value);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(
        debounce((value) => {
            onChange(value);
        }, 300),
        [],
    );

    return (
        <Input
            value={curValue}
            onChange={onSearchChange}
            placeholder={t('common.searchBar.search')}
            endIcon={<SearchIcon />}
        />
    );
};
