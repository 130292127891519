import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import { Spinner } from 'modules/ui';

import styles from './InvoiceDocument.module.scss';

export type TInvoiceDocumentProps = {
    blobUrl: string | null;
    loading: boolean;
};

export const InvoiceDocument: React.FC<TInvoiceDocumentProps> = ({ blobUrl, loading }) => {
    const { t } = useTranslation();
    const [pdfPages, setPdfPages] = useState<number>(0);
    const [pageWidth, setPageWidth] = useState(0);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const documentPageRef = useRef<HTMLDivElement>(null);

    const handleOnLoad = ({ numPages }: { numPages: number }) => {
        setPdfPages(numPages);
    };
    const handleChangePage = (direction: 'next' | 'prev') => {
        if (direction === 'next') {
            if (currentPage < pdfPages) setCurrentPage(currentPage + 1);
            return;
        }
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    useEffect(() => {
        const getDimensions = () => documentPageRef.current?.offsetWidth || 0;
        const handleResize = () => {
            setPageWidth(getDimensions());
        };
        if (documentPageRef.current) {
            setPageWidth(getDimensions());
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [blobUrl]);
    return (
        <div className={styles.documentBox}>
            <div className={styles.documentWrapper}>
                {pdfPages > 1 && (
                    <ChevronLeftIcon
                        className={classNames(styles.arrowLeft, currentPage === 1 && styles.disabled)}
                        onClick={() => handleChangePage('prev')}
                    />
                )}
                <div className={styles.documentContainer} ref={documentPageRef}>
                    <Document
                        className={styles.document}
                        file={blobUrl}
                        onLoadSuccess={handleOnLoad}
                        noData={() => (
                            <div className={styles.noDocument}>
                                {loading ? <Spinner fullScreen /> : <div>{t('commissions.export.noDocument')}</div>}
                            </div>
                        )}
                    >
                        <Page
                            width={pageWidth}
                            pageNumber={currentPage}
                            devicePixelRatio={2}
                            renderAnnotationLayer={false}
                        />
                    </Document>
                </div>
                {pdfPages > 1 && (
                    <ChevronRightIcon className={classNames(styles.arrowRight, currentPage === pdfPages && styles.disabled)} onClick={() => handleChangePage('next')} />
                )}
            </div>
        </div>
    );
};
