import React, { useEffect, useMemo, useState } from 'react';
import { Layer, Map, Marker, Source } from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import { LocationMarkIcon } from 'assets/icons';
import classNames from 'classnames';
import { config } from 'config';
import { fetchRoutesQuery } from 'core/api';
import { FeatureCollection } from 'geojson';
import { CommissionLoadingDischargeSchema } from 'modules/commissions';
import { Typography } from 'modules/ui';

import styles from './LocationsMap.module.scss';

interface ILocationsMapProps {
    data: CommissionLoadingDischargeSchema[];
    selectedLocationIdx: number | null;
    setSelectedLocationIdx: (index: number | null) => void;
}

export const LocationsMap: React.FC<ILocationsMapProps> = ({ data, selectedLocationIdx, setSelectedLocationIdx }) => {
    const onMarkerClick = (index: number) => {
        if (index === selectedLocationIdx) return setSelectedLocationIdx(null);
        setSelectedLocationIdx(index);
    };
    const [routePoints, setRoutePoints] = useState<[latitude: number, longitude: number][]>([]);
    const [routeDistance, setRouteDistance] = useState<number>(0);

    // fetch route points
    useEffect(() => {
        const points: [latitude: number, longitude: number][] = data.map((item) => [
            item.location?.longitude || 0,
            item.location?.latitude || 0,
        ]);
        const getRoutes = async () => {
            const result = await fetchRoutesQuery(points);
            if (result?.routes?.[0]?.geometry?.coordinates) setRoutePoints(result.routes[0].geometry.coordinates);
            if (result?.routes?.[0]?.distance) setRouteDistance(Math.floor(result.routes[0].distance / 1000));
        };
        getRoutes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const geoJsonLayer: FeatureCollection = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: { name: 'Route' },
                geometry: {
                    type: 'MultiLineString',
                    coordinates: [routePoints],
                },
            },
        ],
    };

    const centroid = useMemo(() => {
        const x = routePoints.reduce((acc, point) => acc + point[0], 0) / routePoints.length;
        const y = routePoints.reduce((acc, point) => acc + point[1], 0) / routePoints.length;
        return { longitude: x, latitude: y };
    }, [routePoints]);

    return (
        <DeckGL
            initialViewState={{
                longitude: centroid.longitude || 14.81084786751657,
                latitude: centroid.latitude || 48.82111444207089,
                zoom: 6,
            }}
            controller
        >
            <Map
                id="map"
                mapLib={import('mapbox-gl')}
                mapboxAccessToken={config.api.mapboxToken}
                mapStyle="mapbox://styles/mapbox/streets-v12"
            >
                {routePoints.length > 0 && (
                    <Source data={geoJsonLayer} type="geojson">
                        <Layer id="route" type="line" paint={{ 'line-width': 7, 'line-color': '#1770ff' }} />
                    </Source>
                )}
                {data.map((loadingDischarge, index) => (
                    <Marker
                        onClick={(e) => {
                            e.originalEvent.stopPropagation();
                            onMarkerClick(index);
                        }}
                        key={routePoints.length + index}
                        latitude={loadingDischarge?.location?.latitude || 0}
                        longitude={loadingDischarge?.location?.longitude || 0}
                        anchor="bottom"
                        pitchAlignment="map"
                    >
                        <div className={styles.container}>
                            <div className={styles.marker}>
                                <LocationMarkIcon color={index === selectedLocationIdx ? '#1770ff' : 'transparent'} />
                            </div>
                            <div
                                className={classNames(styles.point, {
                                    [styles.discharge]: !loadingDischarge.isLoading,
                                })}
                            ></div>
                            <div className={styles.popup}>
                                <Typography variant="h6">{loadingDischarge?.location?.city}</Typography>
                                {index === data.length - 1 && <Typography className={styles.distance} variant="p">{routeDistance} km</Typography>}
                            </div>
                        </div>
                    </Marker>
                ))}
            </Map>
        </DeckGL>
    );
};
