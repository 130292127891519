import { useTranslation } from 'react-i18next';
import { ArrowRightIcon } from 'assets/icons';
import { AdminOfferStatusValue } from 'modules/offers/types';
import { PillLinkButton } from 'modules/ui/components/PillLinkButton';
export interface IEnquiryLinkProps {
    state: AdminOfferStatusValue;
    enquiryId: number;
}
export const EnquiryLink = ({ state, enquiryId }: IEnquiryLinkProps) => {
    const { t } = useTranslation();
    const statusProps: Record<AdminOfferStatusValue, Record<string, string>> = {
        OPENED: {
            color: 'success',
        },
        CLOSED: {
            color: 'info',
        },
        WAITING: {
            color: 'info',
        },
    };
    return (
        <PillLinkButton
            link={`/offers/${enquiryId}`}
            endIcon={<ArrowRightIcon color="inherit" />}
            {...statusProps[state]}
        >
            {t('offers.offer')} {enquiryId}
        </PillLinkButton>
    );
};
