import React from 'react';
import classNames from 'classnames';

import styles from './Stepper.module.scss';

export interface IStepperProps {
    stepIndex: number;
    maxStepIndexes: number;
}

export const Stepper = ({ stepIndex, maxStepIndexes }: IStepperProps) => {
    return (
        <div className={styles.stepperContainer}>
            <div className={classNames(styles.circle, styles.active)}>1</div>
            {Array.from({ length: maxStepIndexes }).map((_, index) => {
                return (
                    <>
                        <div
                            key={index}
                            className={classNames(styles.line, { [styles.active]: index + 1 <= stepIndex })}
                        />
                        <div
                            key={index + 0.5}
                            className={classNames(styles.circle, { [styles.active]: index + 1 <= stepIndex })}
                        >
                            {index + 2}
                        </div>
                    </>
                );
            })}
        </div>
    );
};
