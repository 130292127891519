import { User } from 'core/auth/types';
import { CommissionDischargeSchema, CommissionLoadingSchema } from 'modules/commissions';
import { useEmailMessageSaverType } from 'modules/commissions/hooks/useEmailMessageSaver';
import { SelectedDispatcher } from 'modules/commissions/hooks/useSelectedDispatchers';
import { Subset } from 'types/helpers';
import { timestamp } from 'utils/formatTimestamp';

import {
    EnquirySchema,
    EnquirySchemaComplete,
    ITransformedOfferItem,
    OffersDetailAdminFormSchema,
    SendEnquiryEmailRequestSchema,
    UpdateEnquiryRequestSchema,
} from '../types';

export function transformEnquiryIncomingData(
    data: EnquirySchemaComplete[],
    exchangeRate: number,
): ITransformedOfferItem[] {
    return (
        data?.map((item) => {
            const firstLoading = item.commission.commissionloading[0];
            const lastDischarge = item.commission.commissiondischarge[item.commission.commissiondischarge.length - 1];
            const transformedEnquiry: ITransformedOfferItem = {
                id: item.enquiry_id,
                number: item.number,
                commission_id: item.commission_id,
                commissionNumber: item.commission.number,
                city: `${firstLoading.location?.city || ' '} - ${lastDischarge.location?.city || ' '}`,
                dispatchers: item?.contactedDispatchers
                    ? item.contactedDispatchers.map(
                          (enquiryDispatcher) =>
                              `${enquiryDispatcher.dispatcher.firstName?.charAt(0) || ''}${
                                  enquiryDispatcher.dispatcher.lastName?.charAt(0) || ''
                              }`,
                      )
                    : [],
                offerDate: Number(item.tsAdded),
                totalLoadingDischargeCount: [
                    ...item.commission.commissionloading,
                    ...item.commission.commissiondischarge,
                ].length,
                totalLoadingMeters:
                    item.commission.commissionitem?.length > 0
                        ? item.commission.commissionitem.reduce(
                              (acc, item) => acc + (item.loadingMeters ? Number(item.loadingMeters) : 0),
                              0,
                          )
                        : 0,
                totalWeight:
                    item.commission.commissionitem?.length > 0
                        ? item.commission.commissionitem.reduce(
                              (acc, item) => acc + (item.weightBrutto ? Number(item.weightBrutto) : 0),
                              0,
                          ) / 1000
                        : 0,
                offerStatusValue: item.state,
                firstLoading: {
                    city: firstLoading.location?.city || '',
                    countryCode: firstLoading.location?.countryCode || 'CZ',
                    date: firstLoading.date ? Number(firstLoading.date) : 0,
                    dateTo: firstLoading.dateTo ? Number(firstLoading.dateTo) : 0,
                    time: firstLoading.time || '',
                },
                lastDischarge: {
                    city: lastDischarge.location?.city || '',
                    countryCode: lastDischarge.location?.countryCode || 'CZ',
                    date: lastDischarge.date ? Number(lastDischarge.date) : 0,
                    dateTo: lastDischarge.dateTo ? Number(lastDischarge.dateTo) : 0,
                    time: firstLoading.time || '',
                },
                offers: item.offers,
                exchangeRate,
            };
            return transformedEnquiry;
        }) || []
    );
}

export const buildEnquiryEmailBody = ({
    user,
    data,
    dispatchers,
    loadings,
    discharges,
    enquiry_id,
    parameters,
}: {
    user: User;
    data: useEmailMessageSaverType['messages']['enquiry'];
    dispatchers: SelectedDispatcher[];
    loadings: Subset<CommissionLoadingSchema & { companyName: string }>[];
    discharges: Subset<CommissionDischargeSchema & { companyName: string }>[];
    enquiry_id: number;
    parameters: {
        minLength: number;
        minWidth: number;
        minWeight: number;
        minHeight: number;
        vehicleFeaturesMustHaveAll: number[];
        vehicleFeaturesMustHaveOne: number[];
        vehicleType: number[];
    };
}): SendEnquiryEmailRequestSchema => {
    const firstLoading = loadings[0];
    const lasDischarge = discharges[discharges.length - 1];

    return {
        enquiryId: enquiry_id,
        dispatcherIds: dispatchers.map(({ dispatcherId }) => dispatcherId),
        dispatcher: {
            name: user.name,
            surname: user.surname,
            phone: user.mobilePhone,
            email: user.email,
        },
        parameters,
        body: {
            cs: data?.cs || '',
            en: data?.en || '',
            de: data?.de || '',
        },
        subject: `${firstLoading?.location?.countryCode || '-'} ${firstLoading?.location?.city || '-'} -> ${
            lasDischarge?.location?.countryCode || '-'
        } ${lasDischarge?.location?.city || '-'}`,
    };
};

// TODO: complete logic
export function buildUpdateEnquiryRequest({
    data,
    selectedDispatchers,
    oldData,
}: {
    data: Partial<OffersDetailAdminFormSchema>;
    selectedDispatchers?: number[];
    oldData: EnquirySchema;
}): Partial<UpdateEnquiryRequestSchema> {
    return {
        note: data.note,
        parameters: data.parameters,
        contactedDispatchers: {
            add: [],
            remove: [],
        },
    };
}

export const getFormattedDateTimeForLoadingDischarge = (
    date: string | number,
    dateTo: string | null | number,
    time: string | null,
) => {
    const startDate = timestamp(date);
    const endDate = dateTo ? timestamp(dateTo) : null;

    const isTimeCompounded = time && time?.split('-').length === 2;
    const startTime = isTimeCompounded ? ` ${time?.split('-')[0].trim()} ` : ' ';
    const endTime = isTimeCompounded ? time?.split('-')[1].trim() : time?.trim();

    if (startDate?.isValid() && endDate?.isValid()) {
        return `${startDate.format('DD. MM. YYYY')}${startTime}— ${endDate.format('DD. MM. YYYY')} ${endTime || ''}`;
    }
    if (startDate?.isValid()) {
        return `${startDate.format('DD. MM. YYYY')} ${time || ''}`;
    }
    if (endDate?.isValid()) {
        return `${endDate.format('DD. MM. YYYY')} ${time || ''}`;
    }
    return time || '';
};
