import { CommissionInvoiceSchema } from 'modules/invoicing/types';

import { transformInvoicingBasicData } from './Basic';
import { TransformedCommissionInvoice } from './Commissions';
import { InvoicingDataFormattingProps, TransformedInvoicingData } from './types';

/**
 * Converting the data into the format required for the API query.
 *
 * @param currentData data received after submitting the form
 * @param editedBy currently logged in user.
 * @param oldData data we fetched before, or empty template.
 * @return transformed object.
 */
export function transformInvoicingData({
    currentData,
    editedBy,
}: InvoicingDataFormattingProps): TransformedInvoicingData {
    const { commission: commissions, ...basicValues } = currentData;

    const simplyTransformCommission = (commissions: CommissionInvoiceSchema[]): TransformedCommissionInvoice => {
        return commissions.map(({ commission_id, vat }) => ({
            commission_id,
            vat,
        })) as TransformedCommissionInvoice;
    };

    const commission = structuredClone(simplyTransformCommission(commissions));

    const basic = transformInvoicingBasicData(basicValues, editedBy);

    // build all main parts together
    return { ...basic, commission };
}
