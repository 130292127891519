import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { useDocumentTitle } from 'core/application/hooks';
import { useAppSelector } from 'hooks';
import { SearchBar, tableNames } from 'modules/common';
import { PageContent, Subheader } from 'modules/layout';
import { getContainsFilterMeta, Table, TableActionsContainer, TableContainer, useTableState } from 'modules/table';
import { Button, Spinner } from 'modules/ui';
import { getBackstageFetchingList } from 'store/appSlice/selectors';

import { useGetCustomersQuery } from '../../services';
import { CustomerSchema } from '../../types';

const columnHelper = createColumnHelper<CustomerSchema>();

export const CustomersPage = () => {
    const { t } = useTranslation();
    useDocumentTitle(t('nav.customers'));

    const navigate = useNavigate();
    const isBackstageFetching = useAppSelector(getBackstageFetchingList).includes('Customers');

    const { tableProps, queryParams, paginationParams } = useTableState<CustomerSchema>({
        rowIdKey: 'customer_id',
        onRowClick: (row) => navigate(row.original.customer_id.toString()),
        defaultSorting: { id: 'customer_id', desc: true },
        tableName: tableNames.customers.list,
    });

    const { globalFilter, setGlobalFilter } = tableProps;

    const {
        data: response,
        isLoading,
        isFetching,
    } = useGetCustomersQuery({
        ...paginationParams,
        ...queryParams,
    });
    const { data, totalRows } = response || {};

    const columns = useMemo(
        () => [
            columnHelper.accessor('customer_id', {
                header: t('customers.fields.customerId'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('company', {
                header: t('customers.fields.company'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('country', {
                header: t('customers.fields.country'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('postalCode', {
                header: t('customers.fields.postalCode'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('city', {
                header: t('customers.fields.city'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('note', {
                header: t('customers.fields.note'),
                meta: {
                    ...getContainsFilterMeta(),
                },
                cell: (props) => {
                    const value = props.getValue() || '';
                    return value.length > 37 ? `${value.substring(0, 37)}...` : value;
                },
            }),
        ],
        [t],
    );

    if (isLoading) return <Spinner fullScreen />;

    return (
        <PageContent
            fullWidth
            subheader={
                <Subheader
                    title={t('nav.customers')}
                    endSlot={
                        <TableActionsContainer>
                            <Button onClick={() => navigate('new')}>{t('customers.newCustomer')}</Button>
                        </TableActionsContainer>
                    }
                >
                    <SearchBar value={globalFilter} onChange={setGlobalFilter} />
                </Subheader>
            }
        >
            <TableContainer limit>
                <Table
                    data={data}
                    columns={columns}
                    isLoading={(isLoading || isFetching) && !isBackstageFetching}
                    isFetching={isBackstageFetching}
                    totalCount={Number(totalRows)}
                    getCellStyles={({ column }) => {
                        return column.id === 'customer_id'
                            ? [Table.CellTextFormat.Bold, Table.CellTextColor.Primary]
                            : [];
                    }}
                    {...tableProps}
                />
            </TableContainer>
        </PageContent>
    );
};
