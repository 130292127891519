import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { config } from 'config';
import { createSelectColumn, getContainsFilterMeta, getDateRangeFilterMeta, Table, useTableState } from 'modules/table';
import moment from 'moment';
import { tableSortingFilteringPagination } from 'utils';

import { CommissionCarrierTableItem } from '../../CommissionCarrierTable';
import { transformIncomingData } from '../helpers/transformIncomingData';

import styles from '../DispatcherSearchTables.module.scss';

export type DispatcherSearchTableItem = {
    dispatcher_id: number;
    country: string;
    company: string;
    postalCode: string;
    fullName: string;
    lastRequestTimeSent: number;
    routes: number;
};

const columnHelper = createColumnHelper<DispatcherSearchTableItem>();

export const useDispatcherSearchTable = (
    data: CommissionCarrierTableItem[] | DispatcherSearchTableItem[],
    tableName: string,
    selectedDispatchers?: DispatcherSearchTableItem[],
    disabledIds?: number[],
    onRowSelect?: (rows: Row<DispatcherSearchTableItem>[]) => void,
    onRowDeselect?: (rows: Row<DispatcherSearchTableItem>[]) => void,
) => {
    const { t } = useTranslation();

    const { tableProps, queryParams, paginationParams } = useTableState<DispatcherSearchTableItem>({
        rowIdKey: 'dispatcher_id',
        getCellStyles: ({ column, row }) => {
            const cellStyles: string[] = [];

            if (['company', 'routes'].includes(column.id)) {
                cellStyles.push(Table.CellTextFormat.SemiBold);
            }
            if (column.id === 'routes') cellStyles.push(Table.CellTextColor.Primary);

            if (disabledIds?.includes(row.original.dispatcher_id)) {
                cellStyles.push(styles.disabled);
            }

            return cellStyles;
        },
        getIsRowSelectionDisabled: (row) => disabledIds?.includes(row.original.dispatcher_id) || false,
        tableName,
    });

    const tableData = useMemo(() => {
        if (!data || data.length === 0) {
            return { filtered: [], sorted: [], paginated: [], changedData: [] };
        }

        let changedData =
            'carrier_id' in data[0]
                ? transformIncomingData(data as CommissionCarrierTableItem[])
                : (data as DispatcherSearchTableItem[]) || [];

        selectedDispatchers &&
            (changedData = changedData.filter(
                ({ dispatcher_id }) =>
                    !selectedDispatchers.find((dispatcher) => dispatcher?.dispatcher_id === dispatcher_id),
            ));

        const { filtered, result, sorted } = tableSortingFilteringPagination<DispatcherSearchTableItem>({
            data: changedData,
            paginationParams,
            queryParams,
            treatAsDate: ['lastRequestTimeSent'],
        });

        return { filtered, sorted, paginated: result, changedData };
    }, [data, queryParams, paginationParams, selectedDispatchers]);

    const columns = useMemo(
        () => [
            columnHelper.accessor('company', {
                header: t('commissions.form.carrier.searchCarrier.table.company'),
                cell: (props) => {
                    const value = props.getValue() || '';
                    return value.length > 37 ? `${value.substring(0, 37)}...` : value;
                },
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('country', {
                header: t('commissions.form.carrier.searchCarrier.table.country'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('postalCode', {
                header: t('commissions.form.carrier.searchCarrier.table.postalCode'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('fullName', {
                header: t('commissions.form.carrier.searchCarrier.table.fullName'),
                meta: {
                    ...getContainsFilterMeta(),
                },
            }),
            columnHelper.accessor('lastRequestTimeSent', {
                header: t('commissions.form.carrier.searchCarrier.table.lastRequestTimeSent'),
                cell: (props) => {
                    const value = Number(props.getValue());
                    return value ? moment.unix(Math.floor(value / 1000)).format('DD. MM. YYYY') : '---';
                },
                meta: {
                    ...getDateRangeFilterMeta(),
                },
            }),
            columnHelper.accessor('routes', {
                header: t('commissions.form.carrier.searchCarrier.table.routes'),
                cell: (props) => (
                    <Link
                        to={`${config.routes.dispatcher.detail.replace(
                            ':id',
                            String(props.getValue()),
                        )}?scrollTo=routes`}
                    >
                        {t('commissions.form.carrier.searchCarrier.table.route')}
                    </Link>
                ),
                meta: {
                    align: 'center',
                },
                enableSorting: false,
            }),
            createSelectColumn(
                columnHelper,
                undefined,
                undefined,
                data.flatMap((carrier) => {
                    if ('carrier_id' in carrier) {
                        return carrier.dispatcher.map((dispatcher, index) => dispatcher.dispatcher_id || index);
                    }
                    return (carrier as DispatcherSearchTableItem).dispatcher_id;
                }),
                onRowSelect,
                onRowDeselect,
            ),
        ],
        [t, data],
    );

    return {
        tableProps,
        columns,
        paginatedData: tableData.paginated,
        filteredData: tableData.filtered,
        sortedData: tableData.sorted,
        changedData: tableData.changedData,
    };
};
