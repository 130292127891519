import React, { useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import MapGl, { MapRef, Marker, MarkerDragEvent } from 'react-map-gl';
import { CustomerAddressMarkIcon } from 'assets/icons';
import { config } from 'config';
import mapboxgl from 'mapbox-gl';
import { LocationCustomerSchema } from 'modules/customers';

import styles from './Map.module.scss';

export interface IMapProps {}

export const Map: React.FC<IMapProps> = () => {
    const { control, setValue } = useFormContext<LocationCustomerSchema>();
    const { longitude, latitude } = useWatch({ control: control });
    const mapRef = useRef<MapRef>(null);

    const handleDragEnd = (e: MarkerDragEvent) => {
        setValue('longitude', e.lngLat.lng);
        setValue('latitude', e.lngLat.lat);
    };

    const handleDblClick = (e: mapboxgl.MapLayerMouseEvent) => {
        setValue('longitude', e.lngLat.lng);
        setValue('latitude', e.lngLat.lat);
    };

    useEffect(() => {
        if (!mapRef.current || !longitude || !latitude) return;
        const currentZoom = mapRef.current.getMap().getZoom();
        mapRef.current.getMap().flyTo({
            center: [longitude, latitude],
            zoom: currentZoom > 14 ? currentZoom : 14,
            duration: 1000,
        });
    }, [longitude, latitude, mapRef.current]);

    return (
        <div className={styles.wrapper}>
            <MapGl
                id="customer-address-map"
                mapboxAccessToken={config.api.mapboxToken}
                initialViewState={{
                    longitude: longitude || 15.596,
                    latitude: latitude || 49.737,
                    zoom: 6,
                }}
                ref={mapRef}
                mapStyle="mapbox://styles/mapbox/streets-v12"
                style={{ width: '100%', height: '100%' }}
                onDblClick={handleDblClick}
            >
                {longitude && latitude && (
                    <Marker
                        latitude={latitude}
                        longitude={longitude}
                        anchor="bottom"
                        draggable
                        onDragEnd={handleDragEnd}
                    >
                        <CustomerAddressMarkIcon />
                    </Marker>
                )}
            </MapGl>
        </div>
    );
};
