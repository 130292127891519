import { CommissionLoadingSchema } from 'modules/commissions/types';

import {
    LoadingCreateSchema,
    LoadingDeleteSchema,
    LoadingUpdateSchema,
    TransformedLoadingsSchema,
    transformedLoadingsSchema,
} from './types';

export function transformLoadings(
    loadings: CommissionLoadingSchema[],
    oldData: CommissionLoadingSchema[],
): TransformedLoadingsSchema {
    const { added, changed, removed } = getLoadingsChanges(oldData, loadings);

    function modifyAddedLoadings(items: CommissionLoadingSchema[]): LoadingCreateSchema[] {
        return items.map(({ date, dateTo, ...item }) => ({
            ...item,
            date: Number(date),
            dateTo: dateTo ? Number(dateTo) : null,
        })) as LoadingCreateSchema[];
    }
    function modifyUpdatedLoadings(items: CommissionLoadingSchema[]): LoadingUpdateSchema[] {
        return items.map(({ date, dateTo, ...item }) => ({
            ...item,
            date: Number(date),
            dateTo: dateTo ? Number(dateTo) : null,
        })) as LoadingUpdateSchema[];
    }
    function modifyRemovedLoadings(items: CommissionLoadingSchema[]): LoadingDeleteSchema[] {
        return items.map(({ commissionLoading_id }) => ({ commissionLoading_id })) as LoadingDeleteSchema[];
    }

    const template: TransformedLoadingsSchema = {};
    const modificatedAddedLoadings = added ? modifyAddedLoadings(added) : null;
    const modificatedUpdatedLoadings = changed ? modifyUpdatedLoadings(changed) : null;
    const modificatedRemovedLoadings = removed ? modifyRemovedLoadings(removed) : null;

    // add only if they are not empty
    if (modificatedAddedLoadings?.length) {
        template.toCreate = modificatedAddedLoadings;
    }
    if (modificatedUpdatedLoadings?.length) {
        template.toUpdate = modificatedUpdatedLoadings;
    }
    if (modificatedRemovedLoadings?.length) {
        template.toDelete = modificatedRemovedLoadings;
    }

    // validate the data & remove redundant properties
    return transformedLoadingsSchema.parse(template);
}

const checkingLoadingsValues: (keyof CommissionLoadingSchema)[] = [
    'date',
    'dateTo',
    'time',
    'refNumber',
    'location_id',
    'number',
];
interface IGetLoadingsChanges {
    added?: CommissionLoadingSchema[];
    changed?: CommissionLoadingSchema[];
    removed?: CommissionLoadingSchema[];
}

function getLoadingsChanges(
    oldLoadings: CommissionLoadingSchema[],
    newLoadings: CommissionLoadingSchema[],
): IGetLoadingsChanges {
    // "newLoadings" does not mean dispatcher "toCreate", but all loadings (commission items) which are in actual/current form.
    // unchanged loadings (commission items) do not need to be sent to the server.
    // loadings which are new, to create;
    const added = newLoadings.filter((item) => !item.commissionLoading_id);
    // dispatchers which are updated or unchanged
    const others = newLoadings.filter((item) => item.commissionLoading_id);
    const othersIDs = others.map((item) => item.commissionLoading_id) as number[];
    // old loadings which not exist in others
    const removed = oldLoadings.filter((item) =>
        item.commissionLoading_id ? !othersIDs.includes(item?.commissionLoading_id) : false,
    );
    // old loadings which still exist
    const stayedOld = oldLoadings.filter((item) =>
        item.commissionLoading_id ? othersIDs.includes(item?.commissionLoading_id) : false,
    );
    // new loadings which still exist
    const stayedNew = newLoadings.filter((item) =>
        item.commissionLoading_id ? othersIDs.includes(item?.commissionLoading_id) : false,
    );
    // updated loadings - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingLoadingsValues.some((value) => item[value] !== stayedOld[index][value]),
    );

    const template: IGetLoadingsChanges = {};

    // add only if they are not empty
    if (added.length) {
        template.added = added;
    }
    if (changed.length) {
        template.changed = changed;
    }
    if (removed.length) {
        template.removed = removed;
    }

    return template;
}
