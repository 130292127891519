// BASIC

import { z } from 'zod';

export const transformedBasicDataSchema = () =>
    z.object({
        // personal info
        firstName: z.string().nullable(),
        lastName: z.string().nullable(),
        phone: z.string().nullable(),
        email: z.string(),
        // auth info
        password: z.string().optional(),
        // offers notifications
        notificationEmail: z.boolean(),
        notificationWhatsapp: z.boolean(),
        // other
        carrier_id: z.number().optional(),
        language_id: z.number().nullable(),
    });

export type TransformedBasicDataSchema = z.infer<ReturnType<typeof transformedBasicDataSchema>>;
