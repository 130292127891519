import { NonTypedTranslation } from 'core/translation';

export const invoiceOrderLanguages = {
    cs: 'čeština',
    en: 'angličtina',
    de: 'němčina',
}

export const getInvoiceOrderLanguageOptions = (
    t: NonTypedTranslation,
): {
    value: (typeof invoiceOrderLanguages)[keyof typeof invoiceOrderLanguages];
    label: string;
}[] => {
    return Object.keys(invoiceOrderLanguages).map((key) => ({
        value: invoiceOrderLanguages[key as keyof typeof invoiceOrderLanguages],
        label: t(`invoicing.form.languages.${key}`),
    }));
};
