import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { CrossIcon, IconFile } from 'assets/icons';
import { useLazyGetAttachedFileQuery } from 'core/api/filesApi';
import { Tooltip } from 'modules/ui';
import { downloadURI } from 'utils';

import { Typography } from '../../../ui/components/Typography/Typography';

import styles from './FileButton.module.scss';

export const FileButton = ({
    index,
    uri,
    onDeleteFile,
}: {
    index: number;
    uri: string;
    onDeleteFile: (uri: string) => void;
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_directory, _id, fileName] = uri.split('/');

    const { t } = useTranslation();
    const [getFile] = useLazyGetAttachedFileQuery();

    const onUpload = async (uri: string, name: string) => {
        try {
            const { data: blob } = await getFile({ filePath: uri });
            if (!blob) {
                return toast.error(t('table.actions.export.message.notUploadedYet'));
            }
            const file = window.URL.createObjectURL(blob);
            downloadURI(file, name);
            toast.success(t('table.actions.export.message.success'));
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className={styles.container}>
            <Tooltip content={t('invoicing.form.attachedFiles.tooltips.delete')}>
                <button onClick={() => onDeleteFile(uri)} className={styles.crossIcon} type="button">
                    <CrossIcon />
                </button>
            </Tooltip>
            <div className={styles.fileIconBox}>
                <IconFile />
            </div>
            <Tooltip content={t('invoicing.form.attachedFiles.tooltips.upload')}>
                <button onClick={() => onUpload(uri, fileName)} type="button">
                    <Typography variant="p" className={styles.fileText}>
                        {uri ? fileName : `${t('invoicing.form.attachedFiles.placeholder')}-${index}`}
                    </Typography>
                </button>
            </Tooltip>
        </div>
    );
};
