import 'mapbox-gl/dist/mapbox-gl.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CarrierPlaceSchema } from 'modules/carriers';
import { Button, Checkbox, Typography } from 'modules/ui';

import styles from './MapPreferenceModal.module.scss';

export interface IMapPreferenceModalProps
    extends Omit<CarrierPlaceSchema, 'carrier_id' | 'dispatcher_id' | 'isLoading' | 'isDischarge'> {}

export const MapPreferenceModal = ({
    savePlace,
    ...props
}: IMapPreferenceModalProps & {
    savePlace: (place: Omit<CarrierPlaceSchema, 'carrier_id' | 'dispatcher_id'>) => void;
}) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(props.directionLoading);
    const [isDischarge, setIsDischarge] = useState(props.directionDischarge);

    const onSavePlace = () => {
        const newLocation = {
            ...props,
            directionLoading: isLoading,
            directionDischarge: isDischarge,
            note: null,
        };
        savePlace(newLocation);
    };

    return (
        <div className={styles.modal}>
            <Typography className={styles.modalTitle} variant="p">
                {t('onboarding.locationsInfoForm.modal.title')}
            </Typography>
            <Typography className={styles.cityText} variant="p">
                {props.city}
            </Typography>
            <Typography className={styles.countryText} variant="subheading">
                {props.country}
            </Typography>
            <div className={styles.actions}>
                <div className={styles.checkboxWithLabel}>
                    <label htmlFor="isLoading">
                        <Typography className={styles['label']} variant="p">
                            {t('onboarding.locationsInfoForm.modal.loading')}
                        </Typography>
                    </label>
                    <Checkbox
                        id="isLoading"
                        checked={isLoading}
                        onCheckedChange={(value) => {
                            setIsLoading(Boolean(value));
                        }}
                    />
                </div>
                <div className={styles.checkboxWithLabel}>
                    <label htmlFor="isDischarge">
                        <Typography className={styles['label']} variant="p">
                            {t('onboarding.locationsInfoForm.modal.discharge')}
                        </Typography>
                    </label>
                    <Checkbox
                        id="isDischarge"
                        checked={isDischarge}
                        onCheckedChange={(value) => {
                            setIsDischarge(Boolean(value));
                        }}
                    />
                </div>
            </div>
            <Button className={styles.saveBtn} onClick={onSavePlace}>
                {t('onboarding.locationsInfoForm.modal.save')}
            </Button>
        </div>
    );
};
