import { Dispatch, SetStateAction } from 'react';
import { Row } from '@tanstack/react-table';
import { CommissionCarrierTableItem } from 'modules/form/components/CommissionCarrierTable';
import { Table, TableContainer } from 'modules/table';

import { DispatcherSearchTableItem, useDispatcherSearchTable } from '../../hooks/useDispatcherSearchTable';

export interface ISearchedDispatchersTableProps {
    data: CommissionCarrierTableItem[];
    selectedDispatchers: DispatcherSearchTableItem[];
    setSelectedDispatchers: Dispatch<SetStateAction<DispatcherSearchTableItem[]>>;
    tableName: string;
}
export const SearchedDispatchersTable: React.FC<ISearchedDispatchersTableProps> = ({
    data,
    selectedDispatchers,
    setSelectedDispatchers,
    tableName,
}) => {
    const onRowSelect = (rows: Row<DispatcherSearchTableItem>[]) => {
        setSelectedDispatchers((prev) => [...prev, ...rows.map((row) => row.original)]);
    };

    const { paginatedData, filteredData, tableProps, columns } = useDispatcherSearchTable(
        data,
        tableName,
        selectedDispatchers,
        undefined,
        onRowSelect,
    );

    // set row selection to be always empty
    tableProps.rowSelection = {};
    tableProps.allRowsSelected = false;

    return (
        <>
            <TableContainer>
                <Table
                    data={paginatedData}
                    filtered={filteredData}
                    columns={columns}
                    totalCount={filteredData?.length}
                    bordered={true}
                    maxHeight={700}
                    {...tableProps}
                />
            </TableContainer>
        </>
    );
};
