import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { createColumnHelper } from '@tanstack/react-table';
import { CrossIcon } from 'assets/icons';
import * as geolib from 'geolib';
import { useQuery } from 'hooks';
import { CarrierPlaceSchema } from 'modules/carriers';
import { tableNames } from 'modules/common';
import { FormSection } from 'modules/form';
import { ITransformedTablePlace, transformPlacesIncomingData } from 'modules/onboarding/utils/';
import { Table, TableContainer, useTableState } from 'modules/table';
import { Typography } from 'modules/ui';

import { OnboardingSchema } from '../../types';
import { IMapPreferenceModalProps } from '../MapPreferenceModal';
import { OnboardingMap } from '../OnboardingMap';

import styles from './PlacesInfoForm.module.scss';

const columnHelper = createColumnHelper<ITransformedTablePlace>();

export const PlacesInfoForm = ({ methods }: { methods: UseFormReturn<OnboardingSchema> }) => {
    const { t } = useTranslation();
    const [selectedLocationIdx, setSelectedLocationIdx] = useState<number | null>(null);
    const [modalLocation, setModalLocation] = useState<IMapPreferenceModalProps | null>(null);
    const { map } = useMap();
    const query = useQuery();

    useEffect(() => {
        if (query.get('scrollTo') === 'routes') window.scrollTo(0, document.body.scrollHeight);
    }, []);

    const { control, getValues } = methods;

    const placesFieldArray = useFieldArray({
        control,
        name: 'places',
    });

    const onSavePlace = (location: CarrierPlaceSchema, index: number | null) => {
        index !== null ? placesFieldArray.update(index, location) : placesFieldArray.append(location);
    };
    const onRemovePlace = (index: number) => {
        if (index === selectedLocationIdx) {
            setSelectedLocationIdx(null);
            setModalLocation(null);
        }
        placesFieldArray.remove(index);
    };

    const { tableProps } = useTableState<ITransformedTablePlace>({
        rowIdKey: 'index',
        getCellStyles: ({ column, row }) => {
            const cellStyles: string[] = [styles.noBorder];

            if (column.id === 'country') {
                cellStyles.push(Table.CellTextFormat.SemiBold);
            }
            if (row.index === selectedLocationIdx) {
                cellStyles.push(styles.selectedRow);
            }

            return cellStyles;
        },
        onRowClick: (row) => {
            if (row.index === selectedLocationIdx) {
                setSelectedLocationIdx(null);
                setModalLocation(null);
                return;
            }

            setSelectedLocationIdx(row.index);
            setModalLocation(placesFieldArray.fields[row.index]);
            map?.flyTo({ center: [row.original.longitude, row.original.latitude] });
        },
        tableName: tableNames.dispatchers.places,
    });

    const onPostalCodeChange = (postalCode: string, index: number) => {
        placesFieldArray.update(index, { ...getValues(`places.${index}`), postalCode });
    };

    const onCityChange = (city: string, index: number) => {
        placesFieldArray.update(index, { ...getValues(`places.${index}`), city });
    };

    const locationsColumns = useMemo(
        () => [
            columnHelper.accessor('country', {
                header: t('onboarding.locationsInfoForm.listOfPlaces.country'),
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('postalCode', {
                header: t('onboarding.locationsInfoForm.listOfPlaces.postalCode'),
                cell: ({ getValue, row: { index } }) => (
                    <input
                        className={styles.editableInput}
                        value={getValue()}
                        onChange={({ target }) => onPostalCodeChange(target.value, index)}
                        type="text"
                    />
                ),
                meta: {
                    disableRowClick: true,
                },
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('city', {
                header: t('onboarding.locationsInfoForm.listOfPlaces.city'),
                cell: ({ getValue, row: { index } }) => (
                    <input
                        className={styles.editableInput}
                        value={getValue()}
                        onChange={({ target }) => onCityChange(target.value, index)}
                        type="text"
                    />
                ),
                meta: {
                    disableRowClick: true,
                },
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('latitude', {
                header: t('onboarding.locationsInfoForm.listOfPlaces.latitude'),
                cell: ({ getValue }) =>
                    `${geolib.decimalToSexagesimal(getValue())}${getValue() >= 0 ? 'N' : 'S'}`.replace(/\s/g, ''),
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('longitude', {
                header: t('onboarding.locationsInfoForm.listOfPlaces.longitude'),
                cell: ({ getValue }) =>
                    `${geolib.decimalToSexagesimal(getValue())}${getValue() >= 0 ? 'E' : 'W'}`.replace(/\s/g, ''),
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.accessor('routeDirection', {
                header: t('onboarding.locationsInfoForm.listOfPlaces.preference'),
                enableSorting: false,
                enableColumnFilter: false,
            }),
            columnHelper.display({
                id: 'remove-column',
                cell: ({ row }) => (
                    <button type="button" className={styles.removeBtn} onClick={() => onRemovePlace(row.index)}>
                        <CrossIcon />
                    </button>
                ),
                meta: {
                    align: 'right',
                    disableRowClick: true,
                },
                enableSorting: false,
                enableColumnFilter: false,
            }),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [t],
    );

    const changedData = transformPlacesIncomingData(placesFieldArray.fields, t);

    return (
        <FormSection title={t('onboarding.locationsInfoForm.placesPreselection.sectionTitle')}>
            <div className={styles.mapContainer}>
                <OnboardingMap
                    locationsData={placesFieldArray.fields}
                    selectedLocationIdx={selectedLocationIdx}
                    modalLocation={modalLocation}
                    savePlace={onSavePlace}
                    setSelectedLocationIdx={setSelectedLocationIdx}
                    setModalLocation={setModalLocation}
                />
            </div>
            <div className={styles.listOfPlacesContainer}>
                <div className={styles.listOfPlacesText}>
                    <Typography variant="h2">{t('onboarding.locationsInfoForm.listOfPlaces.title')}</Typography>
                </div>
                <TableContainer>
                    <Table
                        data={changedData}
                        columns={locationsColumns}
                        totalCount={changedData.length}
                        {...tableProps}
                        hidePaginationPanel
                        hideBorders
                        hideResizing
                    />
                </TableContainer>
            </div>
        </FormSection>
    );
};
