import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconClock } from 'assets/icons';
import classNames from 'classnames';
import { CommissionLoadingDischargeSchema } from 'modules/commissions';
import { getFormattedDateTimeForLoadingDischarge } from 'modules/offers/utils';
import { Typography } from 'modules/ui';

import styles from './LocationListItem.module.scss';

interface ILocationListItemProps {
    data: CommissionLoadingDischargeSchema;
    selected: boolean;
    onClick: () => void;
}

export const LocationListItem: React.FC<ILocationListItemProps> = ({ data, selected, onClick }) => {
    const { t } = useTranslation();

    return (
        <li onClick={onClick} className={classNames(styles.container, { [styles.selected]: selected })}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <Typography variant="h4" fontWeight="semibold">
                        {data.location?.city} - {data.location?.countryCode}
                    </Typography>
                    <div className={classNames(styles.label, { [styles.discharge]: !data.isLoading })}>
                        {data.isLoading
                            ? t('commissions.form.goods.cargoLoad')
                            : t('commissions.form.goods.cargoUnload')}
                    </div>
                </div>
                <div className={styles.time}>
                    <IconClock />
                    <Typography variant="p" fontWeight="normal" color="secondary">
                        {getFormattedDateTimeForLoadingDischarge(data.date, data.dateTo, data.time)}
                    </Typography>
                </div>
            </div>
        </li>
    );
};
