import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { ChevronDownIcon } from 'assets/icons';
import classNames from 'classnames';
import { FormError, FormLabel } from 'modules/ui';

import { AutocompleteProps } from './Autocomplete';
import { AutocompleteOption } from './AutocompleteOption';

import styles from './Autocomplete.module.scss';

export interface AutocompleteInnerProps<T> extends AutocompleteProps<T> {
    errorMessage?: string;
    formValue: string | number;
    fieldOnChange: (val: any) => void;
}
export const AutocompleteInner = <T,>({
    fieldOnChange,
    formValue,
    label,
    labelProperty,
    name,
    options,
    valueProperty,
    errorMessage,
    isNumber,
    isDisabled,
    actionIcon,
    actionLabel,
    actionOnClick,
    onChange,
    actionCloseOnClick = false,
    placeholder,
}: AutocompleteInnerProps<T>) => {
    const [value, setValue] = useState<string>('');
    const [selected, setSelected] = useState<T | undefined>(undefined);
    const [selectedPrev, setSelectedPrev] = useState<T | undefined>(undefined);
    const [open, setOpen] = useState(false);
    const [forced, force] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const filteredOptions = useMemo(() => {
        const filtered = options.filter((option) =>
            String(option[labelProperty]).toLowerCase().includes(value.toLowerCase()),
        );
        return filtered.splice(0, 9);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, options]);

    useLayoutEffect(() => {
        if (options.length === 0 && selected === undefined) return;
        selected && setValue(String(selected[labelProperty]));
        if (selected?.[valueProperty] === selectedPrev?.[valueProperty]) return;
        selected ? onChange?.(selected) : onChange?.(null);
        selected
            ? fieldOnChange(isNumber ? Number(selected[valueProperty]) : selected[valueProperty])
            : fieldOnChange(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected, forced]);

    useLayoutEffect(() => {
        const selectedOption = options.find((option) => String(option[valueProperty]) === String(formValue));
        setSelected((state) => {
            setSelectedPrev(state);
            return selectedOption;
        });
        if (selectedOption === undefined) setValue('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValue, options]);

    useLayoutEffect(() => {
        if (Boolean(isDisabled)) {
            setValue('');
            setSelected(undefined);
        }
    }, [isDisabled]);

    return (
        <Popover.Root
            open={open}
            onOpenChange={(state) => {
                setOpen(state);
            }}
        >
            <div className={styles['field']}>
                <FormLabel htmlFor={name}>{label}</FormLabel>
                <div className={styles['combo-box']}>
                    <div className={styles['input-wrapper']}>
                        <input
                            id={name}
                            name={name}
                            disabled={Boolean(isDisabled)}
                            placeholder={placeholder || label}
                            autoComplete="off"
                            value={value}
                            onChange={(e) => {
                                e.target.value.length === 0 && setSelected(undefined);
                                setValue(e.target.value);
                                setOpen(e.target.value.length > 0);
                            }}
                            onFocus={() => {
                                value.length > 0 && setOpen(true);
                            }}
                            ref={inputRef}
                            className={classNames(styles.input, {
                                [styles['input-error']]: errorMessage,
                            })}
                        />
                        <Popover.Trigger>
                            <div className={classNames(styles['input-icon'], [{ [styles['input-icon-up']]: open }])}>
                                <ChevronDownIcon />
                            </div>
                        </Popover.Trigger>
                    </div>
                </div>
                <FormError>{errorMessage}</FormError>
                <Popover.Anchor style={{ width: '100%' }} />
                <Popover.Portal>
                    <Popover.Content
                        avoidCollisions={false}
                        style={{ minWidth: inputRef.current ? `${inputRef.current.offsetWidth}px` : 'unset', zIndex: 10 }}
                        align="start"
                        onFocusOutside={(e) => {
                            e.preventDefault();
                        }}
                        onInteractOutside={() => {
                            if (selected) {
                                force((state) => !state);
                            }
                        }}
                        onOpenAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                        onCloseAutoFocus={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <ul className={styles['list-box']}>
                            {filteredOptions.map((option) => (
                                <AutocompleteOption<typeof option>
                                    option={option}
                                    selected={
                                        isNumber
                                            ? Number(option[valueProperty]) === formValue
                                            : option[valueProperty] === formValue
                                    }
                                    setOpen={setOpen}
                                    setSelected={setSelected}
                                    labelProperty={labelProperty}
                                    valueProperty={valueProperty}
                                    key={String(option[valueProperty])}
                                />
                            ))}
                            {actionLabel && (
                                <li
                                    onClick={(e) => {
                                        actionCloseOnClick === false ? undefined : setOpen(false);
                                        actionOnClick?.(e);
                                    }}
                                    className={classNames(styles.option, styles['action-option'])}
                                >
                                    {actionIcon && actionIcon}
                                    {actionLabel}
                                </li>
                            )}
                        </ul>
                    </Popover.Content>
                </Popover.Portal>
            </div>
        </Popover.Root>
    );
};
