import { User } from 'core/auth/types';
import { BasicCustomerDetailSchema } from 'modules/customers/types';

import { TransformedBasicData } from './types';

export function transformCustomerBasicData(basicData: BasicCustomerDetailSchema, editedBy: User): TransformedBasicData {
    // basic customer data

    const template: TransformedBasicData = {
        ...basicData,
        companyRegistrationNumber: Number(basicData.companyRegistrationNumber),
        editedBy: editedBy.email,
        taxId: String(basicData.taxId),
    };

    return template;
}
