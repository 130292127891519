import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MapProvider } from 'react-map-gl';
import { zodResolver } from '@hookform/resolvers/zod';
import { passwordMinLength } from 'core/auth/config';
import { languageToLanguageId } from 'modules/common';
import { Form, VisualFormInputsContext } from 'modules/form';
import { Header, PageContent, Subheader } from 'modules/layout';
import { Button, Stepper, Typography } from 'modules/ui';
import { LanguageSwitch } from 'modules/ui/components/LanguageSwitch';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';
import { z } from 'zod';

import { GetOneDispatcherResponseSchema, OnboardingSchema, onboardingSchema } from '../../types';
import { BasicInfoForm } from '../BasicInfoForm';
import { PlacesInfoForm } from '../PlacesInfoForm';
import { VehiclesInfoForm } from '../VehiclesInfoForm';
import { WelcomePage } from '../WelcomePage';

import styles from './OnboardingPageForm.module.scss';

interface IOnboardingPageForm {
    fetchedData?: GetOneDispatcherResponseSchema;
    carrier_id: number;
    language: 'cs' | 'de' | 'en';
    prepareData: (data: OnboardingSchema, oldData?: OnboardingSchema) => void;
}

export const OnboardingPageForm = ({ fetchedData, carrier_id, language, prepareData }: IOnboardingPageForm) => {
    const { t } = useTranslation();

    const transformIncomingData = (data?: GetOneDispatcherResponseSchema): OnboardingSchema | undefined => {
        if (!data) return;

        const { dispatchervehicle, place, ...rest } = structuredClone(data);
        const availableVehicleTypeIds = dispatchervehicle.map((vehicle) => vehicle.vehicleType_id);

        //? Convert from cm to m
        dispatchervehicle.forEach((vehicle) => {
            vehicle.maxHeight = vehicle.maxHeight / 100;
            vehicle.maxWidth = vehicle.maxWidth / 100;
            vehicle.maxLength = vehicle.maxLength / 100;
        });

        // if there are more vehicles with one type, get one with the lowest ID
        const deliveryVehicleWithLowestId = dispatchervehicle
            .filter(({ vehicleType_id }) => vehicleType_id === 1)
            .sort((a, b) => (a.dispatcher_id < b.dispatcher_id ? -1 : 1))[0];

        const soloVehicleWithLowestId = dispatchervehicle
            .filter(({ vehicleType_id }) => vehicleType_id === 2)
            .sort((a, b) => (a.dispatcher_id < b.dispatcher_id ? -1 : 1))[0];

        const semiTrailerVehicleWithLowestId = dispatchervehicle
            .filter(({ vehicleType_id }) => vehicleType_id === 3)
            .sort((a, b) => (a.dispatcher_id < b.dispatcher_id ? -1 : 1))[0];

        const combinationVehicleWithLowestId = dispatchervehicle
            .filter(({ vehicleType_id }) => vehicleType_id === 4)
            .sort((a, b) => (a.dispatcher_id < b.dispatcher_id ? -1 : 1))[0];

        const template = {
            ...rest,
            dispatcherVehicles: [
                deliveryVehicleWithLowestId,
                soloVehicleWithLowestId,
                semiTrailerVehicleWithLowestId,
                combinationVehicleWithLowestId,
            ]
                .filter((item) => item)
                .map(({ maxLength, maxWidth, maxWeight, maxHeight, ...rest }) => ({
                    ...rest,
                    maxLength: Number(maxLength),
                    maxWidth: Number(maxWidth),
                    maxWeight: Number(maxWeight),
                    maxHeight: Number(maxHeight),
                })),
            places: place.map(({ latitude, longitude, ...rest }) => ({
                latitude: Number(latitude),
                longitude: Number(longitude),
                ...rest,
            })),
            password: '',
            confirmPassword: '',
            deliveryVehicle: availableVehicleTypeIds.includes(1),
            soloVehicle: availableVehicleTypeIds.includes(2),
            semiTrailerVehicle: availableVehicleTypeIds.includes(3),
            combinationVehicle: availableVehicleTypeIds.includes(4),
            language_id: languageToLanguageId[language || 'cs'],
        };

        return template;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const data = useMemo(() => transformIncomingData(fetchedData), [fetchedData]);
    const [currentFormIndex, setCurrentFormIndex] = useState(1);
    const [isEmailCheckFetching, setIsEmailCheckFetching] = useState(false);
    const [isEmailAvailable, setIsEmailAvailable] = useState(true);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const methods = useForm<OnboardingSchema>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(
            onboardingSchema(t)
                .extend({
                    password: z
                        .string()
                        .min(passwordMinLength, {
                            message: t('auth.password.error.length', { minLength: passwordMinLength }),
                        })
                        .min(passwordMinLength, {
                            message: t('auth.password.error.length', { minLength: passwordMinLength }),
                        }),
                })
                .refine((data) => data.password === data.confirmPassword, {
                    message: t('auth.confirmPassword.error.dontMatch'),
                    path: ['confirmPassword'],
                }),
        ),
        defaultValues: data || {
            carrier_id,
            language_id: languageToLanguageId[language || 'cs'],
            email: '',
            firstName: '',
            lastName: '',
            password: '',
            confirmPassword: '',
            phone: null,
            notificationEmail: false,
            notificationWhatsapp: false,
            deliveryVehicle: false,
            soloVehicle: false,
            semiTrailerVehicle: false,
            combinationVehicle: false,
            dispatcherVehicles: [],
            places: [],
        },
    });

    const onSubmit = async (currentData: OnboardingSchema) => {
        prepareData(currentData, data);
    };

    const getCurrentFormStep = (step: number) => {
        switch (step) {
            case 1: {
                return <WelcomePage onRegisterButtonClick={() => setCurrentFormIndex((prev) => prev + 1)} />;
            }
            case 2: {
                return (
                    <>
                        <div className={styles.formHeaderContainer}>
                            <div>
                                <Typography variant="subheading">{t('onboarding.step')} 1</Typography>
                                <Typography className={styles.title} variant="h2">
                                    {t('onboarding.basicInfoForm.title')}
                                </Typography>
                            </div>
                            <Stepper stepIndex={0} maxStepIndexes={2} />
                        </div>
                        <BasicInfoForm
                            viewSide="onboarding"
                            originalUserEmail={fetchedData?.email}
                            setIsEmailCheckFetching={setIsEmailCheckFetching}
                            setIsEmailAvailable={setIsEmailAvailable}
                        />
                        <div className={styles.buttons}>
                            <Button
                                onClick={async () => {
                                    const triggerFields = [
                                        'firstName',
                                        'lastName',
                                        'password',
                                        'confirmPassword',
                                        'phone',
                                        'notificationEmail',
                                        'notificationWhatsapp',
                                    ] as (keyof OnboardingSchema)[];

                                    // force validate before opening the next form step
                                    if (isEmailCheckFetching) {
                                        return toast.error(t('auth.email.error.waitCheckEmail'));
                                    }

                                    if (!isEmailAvailable) {
                                        return methods.setError('email', {
                                            message: t('auth.email.error.RECORD_NOT_UNIQUE'),
                                        });
                                    }
                                    const result = await methods.trigger(triggerFields);
                                    if (result) setCurrentFormIndex((prev) => prev + 1);
                                }}
                            >
                                {t('onboarding.nextStep')}
                            </Button>
                        </div>
                    </>
                );
            }
            case 3: {
                return (
                    <>
                        <div className={styles.formHeaderContainer}>
                            <div>
                                <Typography variant="subheading">{t('onboarding.step')} 2</Typography>
                                <Typography className={styles.title} variant="h2">
                                    {t('onboarding.vehicleInfoForm.title')}
                                </Typography>
                            </div>
                            <Stepper stepIndex={1} maxStepIndexes={2} />
                        </div>
                        <VehiclesInfoForm viewSide="onboarding" methods={methods} />
                        <div className={styles.buttons}>
                            <Button
                                onClick={() => {
                                    setCurrentFormIndex((prev) => prev + 1);
                                }}
                            >
                                {t('onboarding.nextStep')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setCurrentFormIndex((prev) => prev - 1);
                                }}
                                variant="secondary"
                            >
                                {t('onboarding.prevStep')}
                            </Button>
                        </div>
                    </>
                );
            }
            case 4: {
                return (
                    <MapProvider>
                        <div className={styles.formHeaderContainer}>
                            <div>
                                <Typography variant="subheading">{t('onboarding.step')} 3</Typography>
                                <Typography className={styles.title} variant="h2">
                                    {t('onboarding.locationsInfoForm.title')}
                                </Typography>
                            </div>
                            <Stepper stepIndex={2} maxStepIndexes={2} />
                        </div>
                        <PlacesInfoForm methods={methods} />
                        <div className={styles.buttons}>
                            <Button type="submit">{t('onboarding.saveInfo')}</Button>
                            <Button onClick={() => setCurrentFormIndex((prev) => prev - 1)} variant="secondary">
                                {t('onboarding.prevStep')}
                            </Button>
                        </div>
                    </MapProvider>
                );
            }
        }
    };

    return (
        <div className={styles.page}>
            <Header hideNavigation>
                <LanguageSwitch />
            </Header>
            {currentFormIndex !== 1 && <div className={styles['artificial-gap']} />}
            <PageContent subheader={currentFormIndex === 1 ? null : <Subheader title={t('onboarding.title')} />}>
                <FormProvider {...methods}>
                    <VisualFormInputsContext.Provider
                        value={{
                            visualInputsList,
                            setVisualInputsList,
                        }}
                    >
                        <Form
                            id="onboarding-basic-form"
                            onSubmit={methods.handleSubmit(onSubmit, (error) =>
                                handleErrorsWithNoInputs(error, visualInputsList),
                            )}
                        >
                            <div>{getCurrentFormStep(currentFormIndex)}</div>
                        </Form>
                    </VisualFormInputsContext.Provider>
                </FormProvider>
            </PageContent>
        </div>
    );
};
