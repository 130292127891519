import { Controller } from 'react-hook-form';
import { type BaseFieldProps } from 'modules/form';
import { FormError, FormField, FormLabel, Slider, type SliderProps } from 'modules/ui';

export type SliderFieldProps = BaseFieldProps &
    Pick<SliderProps, 'min' | 'max'> & {
        onChange?: (value: number | undefined) => void;
    };

export const SliderField = ({ name, placeholder, label, onChange: onChangeCustom, ...props }: SliderFieldProps) => {
    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={name}>
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <Slider
                        id={name}
                        error={!!error}
                        placeholder={placeholder || label}
                        onChangeCustom={onChangeCustom}
                        {...field}
                        {...props}
                    />
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
