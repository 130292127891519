import { transformVehicleFeatures } from 'modules/carriers/utils/FormattingCarrierData/Features';
import { DispatcherVehicleSchema, OnboardingSchema } from 'modules/onboarding/types';

import {
    DispatcherVehicleCreate,
    DispatcherVehicleDelete,
    DispatcherVehicleUpdate,
    IDispatcherVehicleCheckingValues,
    IGetDispatcherVehiclesChanges,
    TransformedDispatcherVehicles,
} from './types';

interface transformDispatcherVehiclesArgs {
    dispatcherVehicles: DispatcherVehicleSchema[];
    oldData?: OnboardingSchema;
}

export function transformDispatcherVehicles({
    dispatcherVehicles,
    oldData,
}: transformDispatcherVehiclesArgs): TransformedDispatcherVehicles {
    //? Convert from m to cm
    dispatcherVehicles.forEach((vehicle) => {
        vehicle.maxHeight = vehicle.maxHeight * 100;
        vehicle.maxWidth = vehicle.maxWidth * 100;
        vehicle.maxLength = vehicle.maxLength * 100;
    })
    const { added, changed, removed } = getDispatcherVehiclesChanges(
        oldData?.dispatcherVehicles || [],
        dispatcherVehicles,
    );
    function modifyAddedVehicles(items: DispatcherVehicleSchema[]): DispatcherVehicleCreate[] {
        return items.map(({ dispatchervehiclefeature, ...item }) => {
            return {
                ...item,
                dispatcherVehicleFeatures: transformVehicleFeatures([], dispatchervehiclefeature),
            } as DispatcherVehicleCreate;
        });
    }
    function modifyUpdatedVehicles(items: DispatcherVehicleSchema[]): DispatcherVehicleUpdate[] {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return items.map(({ dispatchervehiclefeature, dispatcher_id, ...item }) => {
            const thisOldVehicle = oldData?.dispatcherVehicles.find(
                (vehicle) => vehicle.dispatcherVehicle_id === item.dispatcherVehicle_id,
            );

            return {
                ...item,
                dispatcherVehicleFeatures: transformVehicleFeatures(
                    thisOldVehicle?.dispatchervehiclefeature || [],
                    dispatchervehiclefeature,
                ),
            } as DispatcherVehicleUpdate;
        });
    }
    function modifyRemovedVehicles(items: DispatcherVehicleSchema[]): DispatcherVehicleDelete[] {
        return items.map(({ dispatcherVehicle_id }) => ({
            dispatcherVehicle_id,
        })) as DispatcherVehicleDelete[];
    }

    const template: TransformedDispatcherVehicles = {};
    const modifiedAddedVehicles = added ? modifyAddedVehicles(added) : null;
    const modifiedUpdatedVehicles = changed ? modifyUpdatedVehicles(changed) : null;
    const modifiedRemovedVehicles = removed ? modifyRemovedVehicles(removed) : null;

    // add only if they are not empty
    if (modifiedAddedVehicles?.length) {
        template.toCreate = modifiedAddedVehicles;
    }
    if (modifiedUpdatedVehicles?.length) {
        template.toUpdate = modifiedUpdatedVehicles;
    }
    if (modifiedRemovedVehicles?.length) {
        template.toDelete = modifiedRemovedVehicles;
    }
    return template;
}

const checkingVehicleValues: IDispatcherVehicleCheckingValues[] = [
    'vehicleType_id',
    'dispatchervehiclefeature',
    'maxHeight',
    'maxWidth',
    'maxLength',
    'maxWeight',
];
function getDispatcherVehiclesChanges(
    oldVehicles: DispatcherVehicleSchema[],
    newDispatcherVehicles: DispatcherVehicleSchema[],
): IGetDispatcherVehiclesChanges {
    // "newDispatcherVehicles" does not mean vehicle "toCreate", but all dispatcher vehicles which are in actual/current form.
    // unchanged dispatcher vehicles do not need to be sent to the server.
    const transformedNewDispatcherVehicles: DispatcherVehicleSchema[] = newDispatcherVehicles.map((item) => {
        const template = {
            ...item,
            dispatchervehiclefeature: item.dispatchervehiclefeature.filter((item) => item.vehicleFeature_id),
        };
        return template;
    });

    // dispatcher vehicles which are new, to create
    const added = transformedNewDispatcherVehicles.filter((item) => !item.dispatcherVehicle_id);
    // dispatcher vehicles which are updated or unchanged
    const others = transformedNewDispatcherVehicles.filter((item) => item.dispatcherVehicle_id);
    const othersIDs = others.map((item) => item.dispatcherVehicle_id) as number[];
    // dispatcher old vehicles which not exist in others
    const removed = oldVehicles.filter((item) =>
        item.dispatcherVehicle_id ? !othersIDs.includes(item?.dispatcherVehicle_id) : false,
    );
    // old dispatcher vehicles which still exist
    const stayedOld = oldVehicles.filter((item) =>
        item.dispatcherVehicle_id ? othersIDs.includes(item?.dispatcherVehicle_id) : false,
    );
    // new dispatcher vehicles which still exist
    const stayedNew = transformedNewDispatcherVehicles.filter((item) =>
        item.dispatcherVehicle_id ? othersIDs.includes(item?.dispatcherVehicle_id) : false,
    );
    // updated dispatcher vehicles - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingVehicleValues.some((value) => {
            // if array changed
            if (value === 'dispatchervehiclefeature') {
                const oldVehicleFeaturesIDs = stayedOld[index][value].map((feature) => feature.vehicleFeature_id);
                if (item[value].length !== oldVehicleFeaturesIDs.length) return true;
                return item[value].some((feature) => !oldVehicleFeaturesIDs.includes(feature.vehicleFeature_id));
            }
            return item[value] !== stayedOld[index][value];
        }),
    );

    return { added, changed, removed };
}
