import { useEffect, useRef } from 'react';

type Callback = () => void | Promise<void> | (() => void | Promise<void>);
export const useMounted = (callback: Callback): void => {
    const hasMounted = useRef(false);

    useEffect(() => {
        const executeCallback = async () => {
            if (!hasMounted.current) {
                const result = callback();
                if (result instanceof Promise) {
                    await result;
                }
                hasMounted.current = true;
            }
        };

        executeCallback();
    }, []);
};
