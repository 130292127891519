import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from 'assets/icons';
import { useHistory } from 'hooks/useHistory';

import styles from './BackButton.module.scss';
interface BackButtonProps extends React.ComponentProps<'button'> {
    link?: string;
    search?: string;
    onClick?: () => void;
}

export const BackButton: React.FC<BackButtonProps> = ({ link, onClick, search, ...props }) => {
    const navigate = useNavigate();
    const { goBack } = useHistory();

    const onButtonClick = () => {
        onClick ? onClick() : link ? navigate({ pathname: link, search }) : goBack();
    };

    return (
        <button {...props} type="button" className={styles['back-icon']} onClick={onButtonClick}>
            <ChevronLeftIcon />
        </button>
    );
};
