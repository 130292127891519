import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconInfo } from 'assets/icons';
import { T } from 'core/translation/types';
import { FormGrid, TextAreaField, TextField, VisualFormInputsContext } from 'modules/form';
import { Button, FormLabel, Typography } from 'modules/ui';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';
import { z } from 'zod';

import styles from './SendInvoiceToEmailModal.module.scss';

interface SendInvoiceToEmailModalProps {
    onClose: () => void;
    handleEmail: ({ recipient, message }: { recipient: string; message: string }) => void;
    dispatcherEmail: string;
    attachedFiles: string;
}

const sendEmailForm = (t: T) =>
    z.object({
        recipient: z
            .string()
            .min(1, { message: t('auth.email.error.required') })
            .email({
                message: t('auth.email.error.validEmail'),
            }),
        message: z.string().min(10, { message: t('form.errors.minLengthOther', { minLength: 10 }) }),
    });

export type SendEmailForm = z.infer<ReturnType<typeof sendEmailForm>>;

export const SendInvoiceToEmailModal: React.FC<SendInvoiceToEmailModalProps> = ({
    onClose,
    handleEmail,
    dispatcherEmail,
    attachedFiles,
}) => {
    const { t } = useTranslation();
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const formDefaultValues = {
        recipient: dispatcherEmail,
        message: `Dobrý den, 

Posílám Vám fakturu.`,
    };

    const methods = useForm<SendEmailForm>({
        defaultValues: formDefaultValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(sendEmailForm(t)),
    });
    const { handleSubmit } = methods;

    const onSubmit = (data: SendEmailForm) => {
        handleEmail(data);
    };

    return (
        <div className={styles.modal}>
            <div className={styles.container}>
                <FormProvider {...methods}>
                    <VisualFormInputsContext.Provider
                        value={{
                            visualInputsList,
                            setVisualInputsList,
                        }}
                    >
                        <Form
                            id="send-invoice-email-form"
                            onSubmit={handleSubmit(onSubmit, (error) =>
                                handleErrorsWithNoInputs(error, visualInputsList),
                            )}
                        >
                            <div className={styles.title}>
                                <Typography variant="h4" className={styles.type}>
                                    {t('invoicing.sendEmailModal.label')}
                                </Typography>
                            </div>

                            <FormGrid columns={1} rows={2}>
                                <TextField name="recipient" label={t('invoicing.sendEmailModal.recipient')} />
                                <div>
                                    <FormLabel>{t('invoicing.sendEmailModal.attachedFiles')}</FormLabel>
                                    <p className={styles.attachedFiles}>{attachedFiles}</p>
                                </div>
                                <TextAreaField name="message" rows={8} label={t('invoicing.sendEmailModal.message')} />
                                <div className={styles.info}>
                                    <IconInfo color="#738291" /> {t('invoicing.sendEmailModal.info')}
                                </div>
                                <div className={styles.buttons}>
                                    <Button
                                        className={styles.btn}
                                        type="button"
                                        color="green"
                                        variant="secondary"
                                        onClick={onClose}
                                    >
                                        {t('invoicing.sendEmailModal.cancel')}
                                    </Button>
                                    <Button
                                        form="send-invoice-email-form"
                                        type="submit"
                                        className={styles.btn}
                                        color="red"
                                        variant="primary"
                                    >
                                        {t('invoicing.sendEmailModal.approve')}
                                    </Button>
                                </div>
                            </FormGrid>
                        </Form>
                    </VisualFormInputsContext.Provider>
                </FormProvider>
            </div>
        </div>
    );
};
