import React from 'react';
import { ArrayPath, FieldArray, type FieldValues, type Path, type UseFieldArrayReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IconInfo } from 'assets/icons';
import { FormActionButton, FormSection } from 'modules/form';
import { Typography } from 'modules/ui';

import styles from './CommissionTableFormArray.module.scss';

export type VerticalFormArrayProps<T extends FieldValues, V extends FieldValues> = FormArrayProps<T, V> & {
    title: string;
    defaultValues: FieldArray<T, ArrayPath<T>>;
    totalPriceText: string;
    onAddCommissionBtnClick: () => void;
    addTitle?: string;
};

export const CommissionTableFormArray = <T extends FieldValues, V extends FieldValues>({
    title,
    append,
    totalPriceText,
    onAddCommissionBtnClick,
    addTitle,
    ...props
}: VerticalFormArrayProps<T, V>) => {
    const { t } = useTranslation();
    return (
        <FormSection
            title={title}
            headerEndSlot={
                <FormActionButton onClick={onAddCommissionBtnClick}>
                    {addTitle ? addTitle : t('form.addItem')}
                </FormActionButton>
            }
        >
            {Boolean(props.fields.length) ? (
                <>
                    <ul className={styles.columnDescription}>
                        <li>{t('invoicing.form.commissions.commissionNumber')}</li>
                        <li>{t('invoicing.form.commissions.pickupDate')}</li>
                        <li>{t('invoicing.form.commissions.loading_city_string')}</li>
                        <li>{t('invoicing.form.commissions.discharge_city_string')}</li>
                        <li>{t('invoicing.form.commissions.vat')}</li>
                        <li>{t('invoicing.form.commissions.priceCustomer')}</li>
                    </ul>
                    <FormArray append={append} className={styles.container} {...props} />
                    <div className={styles.totalPriceText}>
                        <Typography variant="h5">{t('invoicing.form.commissions.totalPrice')}</Typography>
                        <Typography variant="h5">{totalPriceText}</Typography>
                    </div>
                </>
            ) : (
                <div className={styles.noCommissions}>
                    <IconInfo color="gray" />
                    <Typography variant="p" className="">
                        {t('invoicing.form.commissions.noItems')}
                    </Typography>
                </div>
            )}
        </FormSection>
    );
};

type RenderArgs<T extends FieldValues, V extends FieldValues> = Omit<UseFieldArrayReturn<T, any>, 'fields'> & {
    index: number;
    getFieldProps: (field: Extract<keyof V, string>) => { name: Path<T> };
};

type FormArrayProps<T extends FieldValues, V extends FieldValues> = UseFieldArrayReturn<T, any> & {
    name: string;
    className?: string;
    render: (args: RenderArgs<T, V>) => JSX.Element;
};

const FormArrayInner = <T extends FieldValues = FieldValues, V extends FieldValues = FieldValues>(
    { name, render, fields, className, ...fieldArrayProps }: FormArrayProps<T, V>,
    ref: React.ForwardedRef<HTMLUListElement>,
) => {
    return (
        <ul ref={ref} className={className}>
            {fields.map((field, index) => {
                return (
                    <li key={field.id}>
                        {render({
                            index,
                            getFieldProps: (field) => ({ name: `${name}.${index}.${field}` as Path<T> }),
                            ...fieldArrayProps,
                        })}
                    </li>
                );
            })}
        </ul>
    );
};

const FormArray = React.forwardRef(FormArrayInner) as <T extends FieldValues, V extends FieldValues>(
    props: FormArrayProps<T, V> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof FormArrayInner>;
