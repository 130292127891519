import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';
import { Countries } from 'modules/common';
import { TGetGeoCodedAddressResponse } from 'types/api';

import { baseQuery } from './baseQuery';

export const appApi = createApi({
    reducerPath: 'appApi',
    baseQuery,
    tagTypes: ['Companies'],
    endpoints: (builder) => ({
        getCompanyData: builder.query<
            {
                ico: string;
                dic: string;
                name: string;
                address: {
                    street: string;
                    city: string;
                    houseNumber: string;
                    zip: number;
                    country: keyof typeof Countries;
                };
            },
            { companyRegistrationNumber: number }
        >({
            providesTags: ['Companies'],
            query: ({ companyRegistrationNumber }) => ({
                url: config.api.endpoints.ares,
                method: 'GET',
                params: { ico: String(companyRegistrationNumber).padStart(8, '0') },
            }),
        }),
        getGeoCodedAddress: builder.query<TGetGeoCodedAddressResponse, string>({
            query: (searchTerm) => ({
                url: config.api.endpoints.geocodingForward,
                method: 'GET',
                params: {
                    search: searchTerm,
                },
            }),
        }),
    }),
});

export const { useLazyGetCompanyDataQuery, useLazyGetGeoCodedAddressQuery, useGetGeoCodedAddressQuery } = appApi;
