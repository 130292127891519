import { LogoutIcon } from 'assets/icons';

import styles from './UserInfo.module.scss';

type UserInfoProps = {
    email: string;
    firstName: string;
    lastName: string;
    imgSrc?: string;
    onLogout: () => void;
};

export const UserInfo: React.FC<UserInfoProps> = ({ firstName, lastName, email, onLogout }) => {
    return (
        <div className={styles.container}>
            <div className={styles.details}>
                <p className={styles.name}>{`${firstName} ${lastName}`}</p>
                <p className={styles.email}>{email}</p>
            </div>
            <button className={styles.logoutBtn}>
                <LogoutIcon onClick={onLogout} />
            </button>
            {/* <Avatar
                imgSrc={imgSrc}
                imgAlt={t('header.avatar.imgAlt')}
                fallback={`${firstName.charAt(0)}${lastName.charAt(0)}`}
            /> */}
        </div>
    );
};
