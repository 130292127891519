import * as RadixAvatar from '@radix-ui/react-avatar';
import classNames from 'classnames';

import styles from './Avatar.module.scss';

export type AvatarProps = {
    imgSrc?: string;
    imgAlt?: string;
    fallback: string;
    small?: boolean;
    medium?: boolean;
};

export const Avatar: React.FC<AvatarProps> = ({ imgSrc, imgAlt, fallback, small = false, medium = false }) => {
    return (
        <RadixAvatar.Root className={classNames(styles.root, { [styles.small]: small, [styles.medium]: medium })}>
            <RadixAvatar.Image src={imgSrc} alt={imgAlt} className={styles.image} />
            <RadixAvatar.Fallback
                className={classNames(styles.fallback, { [styles.small]: small, [styles.medium]: medium })}
            >
                {fallback}
            </RadixAvatar.Fallback>
        </RadixAvatar.Root>
    );
};
