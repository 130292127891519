import { User } from 'core/auth/types';
import { BasicInvoiceDetailSchema } from 'modules/invoicing';

import { TransformedBasicDataSchema, transformedBasicDataSchema } from './types';

export function transformInvoicingBasicData(
    basicData: BasicInvoiceDetailSchema,
    editedBy: User,
): TransformedBasicDataSchema {
    const template: TransformedBasicDataSchema = {
        ...basicData,
        user_id: Number(editedBy.id),
        issueDate: Number(basicData.issueDate),
        dueDate: Number(basicData.dueDate),
        pointDate: Number(basicData.pointDate),
        orderDate: Number(basicData.orderDate),
    };

    return transformedBasicDataSchema.parse(template);
}
