import { transformCustomerBasicData } from './Basic';
import { transformContacts } from './Contacts';
import { transformLocations } from './Locations';
import { CarrierDataFormattingProps, TransformedCustomerData } from './types';

/**
 * Converting the data into the format required for the API query.
 *
 * @param currentData data received after submitting the form
 * @param editedBy currently logged in user.
 * @param oldData data we fetched before, or empty template.
 * @return transformed object.
 */
export function transformCustomerData({
    currentData,
    editedBy,
    oldData,
}: CarrierDataFormattingProps): TransformedCustomerData {
    // split into three parts
    const { customercontact, location, ...basicValues } = currentData;

    // modify every part separately
    const basic = transformCustomerBasicData(basicValues, editedBy);
    const customerContacts = structuredClone(transformContacts({ contacts: customercontact, oldData }));
    const locations = structuredClone(transformLocations({ locations: location, oldData }));

    // build all main parts together
    return { ...basic, customerContacts, locations };
}
