import React from 'react';
import { useTranslation } from 'react-i18next';
import { getLocaleByLanguage } from 'utils/locale';

import { Language } from '../../../../core/translation/i18next/i18next';
import { Typography } from '../../../ui';
import { CommissionSummarySchema } from '../../types';

import styles from './CommissionsSummary.module.scss';

export type TCommissionsSummaryProps = {
    data?: CommissionSummarySchema;
};

export const CommissionsSummary: React.FC<TCommissionsSummaryProps> = ({ data }) => {
    const { totalPriceCarrier, totalPriceCustomer, totalProvision } = data || {};
    const { t, i18n } = useTranslation();

    const summaryData = [
        { value: totalPriceCustomer, title: t('commissions.summary.priceCustomer') },
        { value: totalPriceCarrier, title: t('commissions.summary.priceCarrier') },
        { value: totalProvision, title: t('commissions.summary.provision') },
    ];

    const renderValue = (value?: number) => {
        if (!value) return '-';
        return `${value.toLocaleString(getLocaleByLanguage(i18n.language as Language))} ${t('common.currency.CZK')}`;
    };

    return (
        <>
            {data && (
                <div className={styles.summaryContainer}>
                    {summaryData.map(({ title, value }) => (
                        <div key={title} className={styles.group}>
                            <Typography variant="p" className={styles.title}>
                                {title}
                            </Typography>
                            <Typography variant="p" fontWeight="bold">
                                {renderValue(value)}
                            </Typography>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
