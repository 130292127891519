import { transformPlaces } from 'modules/carriers/utils/FormattingCarrierData/Places';
import { OnboardingSchema } from 'modules/onboarding/types';

import { transformOnboardingBasicData } from './Basic';
import { transformDispatcherVehicles } from './DispatcherVehicles';
import { TransformedOnboardingData } from './types';

interface transformOnboardingDataArgs {
    currentData: OnboardingSchema;
    oldData?: OnboardingSchema;
}

/**
 * Converting the data into the format required for the API query.
 *
 * @param currentData data received after submitting the form
 * @param editedBy currently logged in user.
 * @param oldData data we fetched before, or empty template.
 * @return transformed object.
 */
export function transformOnboardingData({
    currentData,
    oldData,
}: transformOnboardingDataArgs): TransformedOnboardingData {
    // split into three parts
    const { dispatcherVehicles, places, ...basicValues } = currentData;

    // build all main parts together
    return {
        ...transformOnboardingBasicData(basicValues),
        dispatcherVehicles: transformDispatcherVehicles({ dispatcherVehicles, oldData }),
        places: transformPlaces({ places, oldData: oldData?.places }),
    };
}
