import type { IconProps } from './types';

export const RegisteredOnboardingStateIcon: React.FC<IconProps> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clipPath="url(#clip0_120_613)">
                <path
                    d="M6.41009 11.5511L3.18809 8.3261C3.09648 8.23264 3.04517 8.10698 3.04517 7.9761C3.04517 7.84523 3.09648 7.71957 3.18809 7.6261L3.88809 6.9261C3.98156 6.83449 4.10722 6.78318 4.23809 6.78318C4.36897 6.78318 4.49463 6.83449 4.58809 6.9261L6.76109 9.0981L11.4101 4.4481C11.5036 4.35649 11.6292 4.30518 11.7601 4.30518C11.891 4.30518 12.0166 4.35649 12.1101 4.4481L12.8101 5.1481C12.9017 5.24157 12.953 5.36723 12.953 5.4981C12.953 5.62898 12.9017 5.75464 12.8101 5.8481L7.11009 11.5481C7.01702 11.6401 6.89158 11.692 6.76071 11.6925C6.62983 11.6931 6.50395 11.6423 6.41009 11.5511Z"
                    fill="#00A720"
                />
            </g>
            <defs>
                <clipPath id="clip0_120_613">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
