import React from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import styles from './Tooltip.module.scss';

export type TooltipProps = RadixTooltip.TooltipContentProps & { children: React.ReactNode; content: React.ReactNode };

export const Tooltip = ({ children, content, ...props }: TooltipProps) => {
    return (
        <RadixTooltip.Provider>
            <RadixTooltip.Root delayDuration={100} {...props}>
                <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
                <RadixTooltip.Portal>
                    <RadixTooltip.Content className={styles.content} {...props}>
                        {content}
                        <RadixTooltip.Arrow className={styles.arrow} />
                    </RadixTooltip.Content>
                </RadixTooltip.Portal>
            </RadixTooltip.Root>
        </RadixTooltip.Provider>
    );
};
