import { Location, useNavigate, useSearchParams } from 'react-router-dom';
import { addRecordToRouterHistory, removeLastRecordFromRouterHistory } from 'store/appSlice';

import { useAppDispatch, useAppSelector } from './redux';

export const useHistory = () => {
    const { routerHistory } = useAppSelector((state) => state.app);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const goBack = () => {
        const pageToReturn = searchParams.get('pageToReturn');
        if (pageToReturn) {
            dispatch(removeLastRecordFromRouterHistory(2));
            const [pathname, search] = pageToReturn.split('?');
            return navigate({
                pathname: pathname,
                search: search,
            });
        }
        const destination = routerHistory?.[routerHistory.length - 2];
        if (!destination) return navigate(-1);
        dispatch(removeLastRecordFromRouterHistory(2));
        navigate({ pathname: destination.pathname, search: destination.search });
    };

    const logNavigation = (location: Location) => {
        dispatch(addRecordToRouterHistory(location));
    };

    return { routerHistory, goBack, logNavigation };
};
