import { useEffect } from 'react';
import { config } from 'config';
import { carrierApi } from 'modules/carriers/services';
import { commissionsApi } from 'modules/commissions/services';
import { customersApi } from 'modules/customers/services';
import { invoicingApi } from 'modules/invoicing';
import { addBackstageFetchingTableRoute } from 'store/appSlice';

import { useAppDispatch } from './redux';

/**
 * A custom hook, which connects to the websocket, handles messages amd invalidates tags
 */

interface IWebsocketInvalidateTags {
    type: 'invalidate';
    tag: 'Commissions' | 'Carriers' | 'Customers' | 'Invoices';
    error: null | any;
}

export default function useWebsocket() {
    const token = localStorage.getItem('accessToken');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!token) return;

        const apisToInvalidate: { [key: string]: any } = {
            Commissions: commissionsApi.util.invalidateTags,
            Carriers: carrierApi.util.invalidateTags,
            Customers: customersApi.util.invalidateTags,
            Invoices: invoicingApi.util.invalidateTags,
        };

        const socket = new WebSocket(config.api.websocketUrl, ['Authorization', token]);

        socket.onmessage = function (event) {
            try {
                const parsed: IWebsocketInvalidateTags = JSON.parse(event.data);
                if (parsed.error) return;
                dispatch(addBackstageFetchingTableRoute(parsed.tag));
                dispatch(apisToInvalidate[parsed.tag]([parsed.tag]));
            } catch (error) {
                console.error(error);
            }
        };

        socket.onerror = (event) => {
            console.error(`[error] Error received from server: `);
            console.log(event);
        };

        return () => socket.close();
    }, [token, dispatch]);
}
