import { IconInfo } from 'assets/icons';
import classNames from 'classnames';
import { Typography } from 'modules/ui';

import styles from './InfoContainer.module.scss';

export interface IInfoContainerProps {
    children: React.ReactNode;
    icon?: React.ReactNode;
    background?: 'gray' | 'blue';
}
export const InfoContainer = ({ icon, children, background = 'gray' }: IInfoContainerProps) => {
    return (
        <div className={classNames(styles.infoContainer, { [styles[background]]: background })}>
            {icon || <IconInfo color="#738291" />}
            <Typography className={styles.infoText} variant="subheading">
                {children}
            </Typography>
        </div>
    );
};
