import { t } from 'i18next';
import { CarrierPlaceSchema, carrierPlaceSchema } from 'modules/carriers';

import { IGetCarrierPlacesChanges, PlaceCreate, PlaceDelete, PlaceUpdate, TransformedCarrierPlaces } from './types';

export interface TransformPlacesProps {
    places: CarrierPlaceSchema[];
    oldData?: CarrierPlaceSchema[];
}
export function transformPlaces({ places, oldData = [] }: TransformPlacesProps) {
    const { added, changed, removed } = getPlacesChanges(oldData, places);
    const template: TransformedCarrierPlaces = {};
    if (changed?.length) {
        template.toUpdate = changed;
    }
    if (added?.length) {
        template.toCreate = added;
    }
    if (removed?.length) {
        template.toDelete = removed.map((item) => ({ place_id: item.place_id })) as PlaceDelete[];
    }
    return template;
}

function getPlacesChanges(oldPlaces: CarrierPlaceSchema[], newPlaces: CarrierPlaceSchema[]): IGetCarrierPlacesChanges {
    const added: PlaceCreate[] = newPlaces.filter((item) => !item.place_id);
    const others = newPlaces.filter((item) => item.place_id) as PlaceUpdate[];
    const othersIDs = others.map((item) => item.place_id) as number[];
    const removed = oldPlaces.filter((item) => (item.place_id ? !othersIDs.includes(item?.place_id) : false));

    const stayedOld = oldPlaces.filter((item) => (item.place_id ? othersIDs.includes(item?.place_id) : false));
    const stayedNew = newPlaces.filter((item) => (item.place_id ? othersIDs.includes(item?.place_id) : false));
    const changed = stayedNew.filter((item, index) => {
        const keys = Object.keys(carrierPlaceSchema(t).shape) as (keyof CarrierPlaceSchema)[];
        keys.some((value) => item[value] !== stayedOld[index]?.[value]);
    }) as unknown as PlaceUpdate[];

    return { added, changed, removed };
}
