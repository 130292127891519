import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconRoute } from 'assets/icons';
import { config } from 'config';
import { IOfferItemDispatcher } from 'modules/offers/types';
import { getFormattedDateTimeForLoadingDischarge } from 'modules/offers/utils';
import { Typography } from 'modules/ui';
import { getFormattedDate } from 'utils';
import { timestamp } from 'utils/formatTimestamp';

import { OfferStatusDispatcher } from '../OfferStatusDispatcher';

import styles from './OfferItemDispatcher.module.scss';

interface IOfferItemDispatcherProps {
    offerData: IOfferItemDispatcher;
}

export const OfferItemDispatcher: React.FC<IOfferItemDispatcherProps> = ({
    offerData: {
        id,
        number,
        offerDate,
        totalLoadingDischargeCount,
        totalLoadingMeters,
        totalWeight,
        offerStatusValue,
        firstLoading,
        lastDischarge,
    },
}) => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <div className={styles.infoTop}>
                        <Typography className={styles.idNumber} variant="subheading">
                            {number}
                        </Typography>
                        <Typography variant="p" color="secondary" fontWeight="medium">
                            {t('offers.offerCard.posted')} {getFormattedDate(timestamp(offerDate)?.toDate() || 0)}
                        </Typography>
                    </div>
                </div>
                <div className={styles.loadingDischarge}>
                    <div className={styles.loadingDischargeInfo}>
                        <div>
                            <Typography variant="p" fontWeight="medium" color="secondary">
                                {t('commissions.form.goods.cargoLoad')}
                            </Typography>
                            <Typography variant="p" fontWeight="bold">
                                {firstLoading.city} - {firstLoading.countryCode}
                            </Typography>
                            <Typography variant="p" fontWeight="normal" color="primary">
                                {getFormattedDateTimeForLoadingDischarge(
                                    firstLoading.date,
                                    firstLoading.dateTo,
                                    firstLoading.time,
                                )}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="p" fontWeight="medium" color="secondary">
                                {t('commissions.form.goods.cargoUnload')}
                            </Typography>
                            <Typography variant="p" fontWeight="bold">
                                {lastDischarge.city} - {lastDischarge.countryCode}
                            </Typography>
                            <Typography variant="p" fontWeight="normal" color="primary">
                                {getFormattedDateTimeForLoadingDischarge(
                                    lastDischarge.date,
                                    lastDischarge.dateTo,
                                    lastDischarge.time,
                                )}
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.route}>
                        {totalLoadingDischargeCount > 2 && (
                            <Typography variant="p" fontWeight="medium">
                                +{totalLoadingDischargeCount - 2}
                            </Typography>
                        )}
                        <IconRoute dotted={totalLoadingDischargeCount > 2} />
                    </div>
                </div>
                <div className={styles.transportationParameters}>
                    <div className={styles.transportationParametersTitle}>
                        <Typography variant="p" fontWeight="semibold">
                            {t('offers.offerCard.transportationParameters')}
                        </Typography>
                        {/* <StarCheckbox isChecked={isFavorite} onCheckedChange={setIsFavorite} showBlueShadow /> */}
                    </div>
                    <ul className={styles.parametersList}>
                        <li>
                            <Typography variant="p" fontWeight="medium">
                                {t('offers.offerCard.totalLoadingMeters')}
                            </Typography>
                            <Typography variant="p" fontWeight="semibold" color="primary">
                                {totalLoadingMeters} m
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="p" fontWeight="medium">
                                {t('offers.offerCard.totalWeight')}
                            </Typography>
                            <Typography variant="p" fontWeight="semibold" color="primary">
                                {totalWeight} t
                            </Typography>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.detail}>
                <Link to={`${config.routes.offers.list}/${id}`}>
                    <Typography variant="p" color="primary" fontWeight="semibold">
                        {t('offers.offerCard.offerDetail')}
                    </Typography>
                </Link>
                <OfferStatusDispatcher value={offerStatusValue} />
            </div>
        </div>
    );
};
