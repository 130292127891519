import React from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { config } from 'config';
import { useDocumentTitle } from 'core/application/hooks';
import { User } from 'core/auth/types';
import { useAppDispatch } from 'hooks';
import { usePostNewCarrierMutation } from 'modules/carriers/services';
import { CarrierNewSchema } from 'modules/carriers/types';
import { transformCarrierData } from 'modules/carriers/utils/FormattingCarrierData';
import { PageContent, Subheader } from 'modules/layout';
import { BackButton, Button } from 'modules/ui';
import { emptyDispatchers } from 'store/appSlice';

import { CarrierBasicForm } from '../../../form/components/CarrierBasicForm';

export const CarriersNewPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useDocumentTitle(t('nav.carriers'));
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    const [postNewCarrier, { isLoading: isSubmittingFormLoading }] = usePostNewCarrierMutation();

    const prepareData = async (data: CarrierNewSchema, editedBy: User) => {
        const formatted = transformCarrierData({ currentData: data, editedBy });

        try {
            await postNewCarrier({ data: formatted }).unwrap();
            dispatch(emptyDispatchers());
            toast.success(t('carriers.created'));

            const pageToReturn = searchParams.get('pageToReturn');

            if (pageToReturn) {
                const [path, query] = pageToReturn.split('?');

                navigate({
                    pathname: path,
                    search: createSearchParams({
                        dataSource: 'localStorage',
                        ...Object.fromEntries(new URLSearchParams(query)),
                    }).toString(),
                });
            } else {
                navigate(config.routes.carriers.list);
            }
        } catch (error) {
            toast.error(t('form.saveError'));
        }
    };

    return (
        <PageContent
            subheader={
                <Subheader
                    startSlot={<BackButton />}
                    endSlot={
                        <Button
                            isLoading={isSubmittingFormLoading}
                            disabled={isSubmittingFormLoading}
                            form="carrier-form"
                            type="submit"
                        >
                            {t('carriers.form.save')}
                        </Button>
                    }
                    title={t('carriers.newCarrier')}
                />
            }
        >
            <CarrierBasicForm prepareData={prepareData} />
        </PageContent>
    );
};
