import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './SimpleTextarea.module.scss';

interface ISimpleTextareaProps {
    value: string;
    onChange: (value: string) => void;
}

export const SimpleTextarea: React.FC<ISimpleTextareaProps> = ({ value, onChange }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { t } = useTranslation();

    // auto resizing textarea
    useLayoutEffect(() => {
        if (!textareaRef.current) return;
        textareaRef.current.style.height = 'inherit'; // Reset the height
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // set the height to scroll height
    }, [value]); // dependency array includes text, so effect runs on text change

    return (
        <textarea
            rows={1}
            ref={textareaRef}
            value={value || ''}
            onChange={(e) => {
                onChange(e.target.value);
            }}
            className={styles.textarea}
            placeholder={t('offers.form.offersSection.notePlaceholder')}
        />
    );
};
