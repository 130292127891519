import { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { config } from 'config';
import { useRefreshTokenMutation } from 'core/api';
import { setToken } from 'core/auth/services';
import { useAppDispatch } from 'hooks';
import { useHistory } from 'hooks/useHistory';
import { defaultRedirects } from 'modules/common';
import { AuthenticatedLayout } from 'modules/layout';
import { Spinner } from 'modules/ui';

import { useAuth } from '../../hooks';

export const Authenticated = () => {
    const dispatch = useAppDispatch();
    const { isAuthenticated, user } = useAuth();
    const { logNavigation, routerHistory } = useHistory();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        logNavigation(location);
    }, [location.pathname]);

    const [refreshToken] = useRefreshTokenMutation();
    useEffect(() => {
        if (!isAuthenticated) {
            refreshToken({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated && location.pathname === '/' && user) {
            navigate(defaultRedirects[user.role]);
        }
    }, [isAuthenticated, location.pathname, user, navigate]);

    if (isAuthenticated === null) {
        const token = localStorage.getItem('accessToken');
        if (token) {
            dispatch(setToken(token));
            return <Spinner fullScreen />;
        }
        return <Navigate to={config.routes.auth.login} replace={true} />;
    }

    if (isAuthenticated === false) {
        return <Navigate to={config.routes.auth.login} replace={true} />;
    }

    return <AuthenticatedLayout />;
};
