import { useTranslation } from 'react-i18next';
import { CrossIcon, StarIcon } from 'assets/icons';
import { CheckIcon } from 'assets/icons';
import classNames from 'classnames';
import { DispatcherOfferStatusValue } from 'modules/offers/types';

import styles from './OfferStatusDispatcher.module.scss';

export const DispatcherOfferStatusNext = {
    // Next status mapping
    // Current status (key) -> next status (value)
    [DispatcherOfferStatusValue.New]: DispatcherOfferStatusValue.New,
    [DispatcherOfferStatusValue.Responded]: DispatcherOfferStatusValue.Responded,
    [DispatcherOfferStatusValue.Won]: DispatcherOfferStatusValue.Won,
    [DispatcherOfferStatusValue.Closed]: DispatcherOfferStatusValue.Closed,
};

export interface DispatcherOfferStatusProps {
    value: DispatcherOfferStatusValue;
    onChange?: (value: DispatcherOfferStatusValue) => void;
}

export const OfferStatusDispatcher = ({ value, onChange }: DispatcherOfferStatusProps) => {
    const { t } = useTranslation();

    const DispatcherOfferStatusIcon: Record<DispatcherOfferStatusValue, React.ReactElement> = {
        [DispatcherOfferStatusValue.New]: <StarIcon isChecked />,
        [DispatcherOfferStatusValue.Responded]: <CheckIcon />,
        [DispatcherOfferStatusValue.Won]: <CheckIcon />,
        [DispatcherOfferStatusValue.Closed]: <CrossIcon />,
    };

    return (
        <div
            onClick={() => {
                onChange && onChange(DispatcherOfferStatusNext[value]);
            }}
            className={classNames(styles.wrapper, {
                [styles.new]: value === DispatcherOfferStatusValue.New,
                [styles.responded]: value === DispatcherOfferStatusValue.Responded,
                [styles.won]: value === DispatcherOfferStatusValue.Won,
                [styles.closed]: value === DispatcherOfferStatusValue.Closed,
            })}
        >
            {t(`common.label.value.${value}`)}
            {DispatcherOfferStatusIcon[value]}
        </div>
    );
};
