import { CommissionDischargeSchema, CommissionLoadingSchema } from 'modules/commissions/types';

import { transformCommissionBasicData } from './Basic/Basic';
import { transformDischarges } from './Discharges/Discharges';
import { transformGoods } from './Goods';
import { transformLoadings } from './Loadings';
import { CommissionDataFormattingProps, TransformedCommissionData } from './types';

/**
 * Converting the data into the format required for the API query.
 *
 * @param currentData data received after submitting the form
 * @param editedBy currently logged in user.
 * @param oldData data we fetched before, or empty template.
 * @return transformed object.
 */
export function transformCommissionData({
    currentData,
    editedBy,
    oldData,
}: CommissionDataFormattingProps): TransformedCommissionData {
    // split into three parts

    const { commissionitem, commissionLoadingDischarge, ...basicValues } = currentData;
    const basic = transformCommissionBasicData(basicValues, editedBy);

    // get the loadings from array with mixed loadings and discharges
    const newLoadings: CommissionLoadingSchema[] | undefined = commissionLoadingDischarge
        .filter((item) => item.isLoading)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ isLoading, refNumber = null, ...item }) => ({ refNumber, ...item }));
    const oldLoadings = oldData.commissionLoadingDischarge.filter(
        (item) => item.isLoading,
    ) as CommissionLoadingSchema[];

    // get the discharges from array with mixed loadings and discharges
    const newDischarges: CommissionDischargeSchema[] | undefined = commissionLoadingDischarge
        .filter((item) => !item.isLoading)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ isLoading, neutralization = false, ...item }) => ({ neutralization, ...item }));
    const oldDischarges = oldData.commissionLoadingDischarge.filter(
        (item) => !item.isLoading,
    ) as CommissionDischargeSchema[];

    const commissionItems = transformGoods(commissionitem, oldData, newLoadings, newDischarges);
    const commissionLoadings = transformLoadings(newLoadings, oldLoadings);
    const commissionDischarges = transformDischarges(newDischarges, oldDischarges);

    return {
        ...basic,
        commissionItems,
        // customer_id: Number(basic.customer_id),
        commissionLoadings,
        commissionDischarges,
    };
}
