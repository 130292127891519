// GOODS (items)

import { z } from 'zod';

const goodsCreateSchema = z.object({
    name: z.string(),
    quantity: z.number().nullable(),
    weight: z.string().nullable(),
    weightBrutto: z.number().nullable(),
    packaging: z.string().nullable(),
    loadingMeters: z.number().nullable(),
    commissionLoading_id: z.number().optional(),
    commissionDischarge_id: z.number().optional(),
    // width: z.number().nullable(),
    // length: z.number().nullable(),
    // height: z.number().nullable(),
    size: z.string().nullable(),
    loadingIdx: z.number().optional(),
    dischargeIdx: z.number().optional(),
});

const goodsUpdateSchema = goodsCreateSchema.partial().extend({
    commissionItem_id: z.number(),
});

const goodsDeleteSchema = z.object({
    commissionItem_id: z.number(),
});

export const transformedGoodsSchema = z.object({
    toCreate: z.array(goodsCreateSchema).optional(),
    toUpdate: z.array(goodsUpdateSchema).optional(),
    toDelete: z.array(goodsDeleteSchema).optional(),
});

export type GoodsCreateSchema = z.infer<typeof goodsCreateSchema>;
export type GoodsUpdateSchema = z.infer<typeof goodsUpdateSchema>;
export type GoodsDeleteSchema = z.infer<typeof goodsDeleteSchema>;
export type TransformedGoodsSchema = z.infer<typeof transformedGoodsSchema>;
