import { ContactCustomerSchema, CustomerDetailSchema } from 'modules/customers/types';

import {
    ContactCreate,
    ContactDelete,
    ContactUpdate,
    IContactCheckingValues,
    IGetContactsChanges,
    TransformedContacts,
} from './types';

export function transformContacts({
    contacts,
    oldData,
}: {
    contacts: ContactCustomerSchema[];
    oldData: CustomerDetailSchema;
}): TransformedContacts | undefined {
    const { added, changed, removed } = getContactsChanges(oldData.customercontact, contacts);
    function modifyAddedContacts(items: ContactCustomerSchema[]): ContactCreate[] {
        return items.map((item) => item as ContactCreate);
    }
    function modifyUpdatedContacts(items: ContactCustomerSchema[]): ContactUpdate[] {
        return items.map(({ customerContact_id, ...item }) => ({ customerContact_id, ...item } as ContactUpdate));
    }
    function modifyRemovedContacts(items: ContactCustomerSchema[]): ContactDelete[] {
        return items.map(({ customerContact_id }) => ({ customerContact_id } as ContactDelete));
    }

    const template: TransformedContacts = {};
    const modificatedAddedContacts = added ? modifyAddedContacts(added) : null;
    const modificatedUpdatedContacts = changed ? modifyUpdatedContacts(changed) : null;
    const modificatedRemovedContacts = removed ? modifyRemovedContacts(removed) : null;

    // add only if they are not empty
    if (modificatedAddedContacts?.length) {
        template.toCreate = modificatedAddedContacts;
    }
    if (modificatedUpdatedContacts?.length) {
        template.toUpdate = modificatedUpdatedContacts;
    }
    if (modificatedRemovedContacts?.length) {
        template.toDelete = modificatedRemovedContacts;
    }
    return template;
}

const checkingContactValues: IContactCheckingValues[] = ['email', 'firstName', 'lastName', 'phone'];
function getContactsChanges(
    oldContacts: ContactCustomerSchema[],
    newContacts: ContactCustomerSchema[],
): IGetContactsChanges {
    // "newContacts" does not mean vehicle "toCreate", but all contacts which are in actual/current form.
    // unchanged contacts do not need to be sent to the server.

    // contacts which are new, to create
    const added = newContacts.filter((item) => !item.customerContact_id);
    // contacts which are updated or unchanged
    const others = newContacts.filter((item) => item.customerContact_id);
    const othersIDs = others.map((item) => item.customerContact_id) as number[];
    // old contacts which not exist in others
    const removed = oldContacts.filter((item) =>
        item.customerContact_id ? !othersIDs.includes(item?.customerContact_id) : false,
    );
    // old contacts which still exist
    const stayedOld = oldContacts.filter((item) =>
        item.customerContact_id ? othersIDs.includes(item?.customerContact_id) : false,
    );
    // new contacts which still exist
    const stayedNew = newContacts.filter((item) =>
        item.customerContact_id ? othersIDs.includes(item?.customerContact_id) : false,
    );
    // updated contacts - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingContactValues.some((value) => {
            return item[value] !== stayedOld[index][value];
        }),
    );

    const template: IGetContactsChanges = {};
    // add only if they are not empty
    if (added.length) {
        template.added = added;
    }
    if (changed.length) {
        template.changed = changed;
    }
    if (removed.length) {
        template.removed = removed;
    }
    return template;
}
