import React from 'react';
import { Typography } from 'modules/ui';

import styles from './FormCard.module.scss';

export const FormCard = ({
    title,
    headerStartSlot,
    headerEndSlot,
    children,
}: {
    title: string;
    headerStartSlot?: React.ReactNode;
    headerEndSlot?: React.ReactNode;
    children: React.ReactNode;
}) => {
    return (
        <div className={styles.card}>
            <div className={styles.header}>
                {headerStartSlot}
                <Typography className={styles['header-title']} variant="p">
                    {title}
                </Typography>
                {headerEndSlot}
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};
