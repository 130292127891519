import { NonTypedTranslation } from 'core/translation';

import { languageToLanguageId } from '../enums';

export const getLanguageOptions = (
    t: NonTypedTranslation,
    valueType: 'code' | 'id' = 'id',
): {
    value: string;
    label: string;
}[] => {
    if (valueType === 'code') {
        return Object.keys(languageToLanguageId).map((key) => ({
            value: key,
            label: t(`common.language.${key.toLowerCase()}`),
        }));
    }
    return Object.keys(languageToLanguageId).map((key) => ({
        value: languageToLanguageId[key].toString() as string,
        label: t(`common.language.${key.toLowerCase()}`),
    }));
};
