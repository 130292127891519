import { Language, supportedLanguages } from 'core/translation/i18next/i18next';

export const getLocaleByLanguage = (lang: Language) => {
    switch (lang) {
        case Language.cs:
            return 'cs-CZ';
        case Language.en:
            return 'en-GB';
        case Language.de:
            return 'de-DE';
        case Language['cs-CZ']:
            return 'cs-CZ';
        default:
            return 'cs-CZ';
    }
};
