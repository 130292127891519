import React, { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconInfo } from 'assets/icons';
import { AuthRole } from 'core/auth/types';
import { Currency, getCurrencyOptions } from 'modules/common';
import { Form, FormGrid, FormSection, SelectField, TextField, VisualFormInputsContext } from 'modules/form';
import { NumberField } from 'modules/form/components/NumberField';
import {
    DispatcherOfferStatusValue,
    EnquirySchemaComplete,
    OffersDetailDispatcherFormSchema,
    offersDetailDispatcherFormSchema,
} from 'modules/offers/types';
import { formatPrice } from 'modules/table';
import { Button, Typography } from 'modules/ui';
import { InfoContainer } from 'modules/ui/components/InfoContainer';
import { handleErrorsWithNoInputs } from 'utils/handleErrorsWithNoInputs';

import { PlacesSection } from '../PlacesSection';
import { VehicleParametersSection } from '../VehicleParametersSection';

import styles from './OffersDetailDispatcherForm.module.scss';

interface IOffersDetailDispatcherFormProps {
    fetchedData: EnquirySchemaComplete & { state: DispatcherOfferStatusValue };
    prepareData: (data: OffersDetailDispatcherFormSchema) => void;
}

export const OffersDetailDispatcherForm: React.FC<IOffersDetailDispatcherFormProps> = ({
    fetchedData,
    prepareData,
}) => {
    const { t, i18n } = useTranslation();
    const { map } = useMap();

    // STATE HOOKS
    const [selectedLocationIdx, setSelectedLocationIdx] = useState<number | null>(null);
    const [visualInputsList, setVisualInputsList] = useState<string[]>([]);

    const methods = useForm<OffersDetailDispatcherFormSchema>({
        defaultValues: {
            totalLoadingMeters:
                fetchedData.commission.commissionitem?.length > 0
                    ? fetchedData.commission.commissionitem.reduce(
                          (acc, item) => acc + (item.loadingMeters ? Number(item.loadingMeters) : 0),
                          0,
                      )
                    : undefined,
            totalWeight:
                fetchedData.commission.commissionitem?.length > 0
                    ? fetchedData.commission.commissionitem.reduce(
                          (acc, item) => acc + (item.weightBrutto ? Number(item.weightBrutto) : 0),
                          0,
                      ) / 1000
                    : undefined,
            suggestedPrice: null,
            suggestedCurrency: Currency.CZK,
        },
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(offersDetailDispatcherFormSchema(t)),
    });

    const { handleSubmit } = methods;

    const { commissionLoadingDischarge } = useMemo(() => {
        return {
            commissionLoadingDischarge: [
                ...fetchedData.commission.commissionloading.map((item) => ({
                    ...item,
                    isLoading: true,
                    location: item.location
                        ? {
                              ...item.location,
                              latitude: Number(item.location.latitude),
                              longitude: Number(item.location.longitude),
                          }
                        : null,
                })),
                ...fetchedData.commission.commissiondischarge.map((item) => ({
                    ...item,
                    isLoading: false,
                    location: item.location
                        ? {
                              ...item.location,
                              latitude: Number(item.location.latitude),
                              longitude: Number(item.location.longitude),
                          }
                        : null,
                })),
            ],
        };
    }, [fetchedData]);
    const isOfferNew = fetchedData.state === DispatcherOfferStatusValue.New;

    const onLoadingDischargeClick = (index: number) => {
        if (selectedLocationIdx === index) return setSelectedLocationIdx(null);
        const item = commissionLoadingDischarge[index];
        if (!item.location || !map) return;

        setSelectedLocationIdx(index);
        map.flyTo({
            center: [item.location.longitude || 0, item.location.latitude || 0],
        });
    };

    const onSubmit = (data: OffersDetailDispatcherFormSchema) => {
        prepareData(data);
    };

    return (
        <>
            <FormProvider {...methods}>
                <VisualFormInputsContext.Provider
                    value={{
                        visualInputsList,
                        setVisualInputsList,
                    }}
                >
                    <div className={styles.container}>
                        <div className={styles.content}>
                            <InfoContainer icon={<IconInfo color="#1770ff" />} background="blue">
                                <p className={styles.offerStateInfoContainer}>
                                    <Typography variant="p" color="primary">
                                        {t(`offers.offerInfo.${fetchedData.state}`)}
                                    </Typography>
                                    {isOfferNew ? (
                                        ''
                                    ) : Number(fetchedData?.offers?.[0]?.price) ? (
                                        <Typography variant="p" color="primary">
                                            {t('offers.suggestedPrice', {
                                                price: Number(fetchedData.offers[0].price).toLocaleString(
                                                    i18n.language,
                                                ),
                                                currency: t(`common.currency.${fetchedData.offers[0].currency}`),
                                            })}
                                        </Typography>
                                    ) : (
                                        <Typography variant="p" color="primary">
                                            {t('offers.noSuggestedPrice')}
                                        </Typography>
                                    )}
                                </p>
                            </InfoContainer>
                            <Form
                                id="offer-detail-form"
                                onSubmit={handleSubmit(onSubmit, (error) =>
                                    handleErrorsWithNoInputs(error, visualInputsList),
                                )}
                                className={styles.form}
                            >
                                <PlacesSection
                                    commissionLoadingDischarge={commissionLoadingDischarge}
                                    selectedLocationIdx={selectedLocationIdx}
                                    setSelectedLocationIdx={setSelectedLocationIdx}
                                    onLoadingDischargeClick={onLoadingDischargeClick}
                                />
                                <VehicleParametersSection
                                    vehicleType={fetchedData.parameters.vehicleType}
                                    vehicleFeaturesMustHaveOne={fetchedData.parameters.vehicleFeaturesMustHaveOne}
                                    vehicleFeaturesMustHaveAll={fetchedData.parameters.vehicleFeaturesMustHaveAll}
                                    role={AuthRole.DISPATCHER}
                                />
                                {isOfferNew && (
                                    <>
                                        <FormSection
                                            title={t('offers.form.suggestPriceSection.title')}
                                            headerStartSlot={
                                                <Typography variant="subheading" fontWeight="normal">
                                                    {t('offers.form.suggestPriceSection.optional')}
                                                </Typography>
                                            }
                                        >
                                            <div className={styles.infoContainer}>
                                                <IconInfo color="#738291" />
                                                <Typography className={styles.infoText} variant="subheading">
                                                    {t('offers.form.suggestPriceSection.description')}
                                                </Typography>
                                            </div>
                                            <FormGrid columns={4}>
                                                <NumberField
                                                    name="suggestedPrice"
                                                    label={t('offers.form.suggestPriceSection.suggestedPrice')}
                                                />
                                                <SelectField
                                                    options={getCurrencyOptions(t)}
                                                    name="suggestedCurrency"
                                                    label={t('offers.form.suggestPriceSection.suggestedCurrency')}
                                                />
                                            </FormGrid>
                                        </FormSection>
                                        <div className={styles.sendOfferBtn}>
                                            <Button type="submit">{t('offers.form.sendOffer')}</Button>
                                        </div>
                                    </>
                                )}
                            </Form>
                        </div>
                    </div>
                </VisualFormInputsContext.Provider>
            </FormProvider>
        </>
    );
};
