import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config } from 'config';

import { Button } from '../Button';

import styles from './NotFoundPage.module.scss';

type RouteKeys = 'commissions' | 'invoices' | 'carriers' | 'customers';

const routeMap: Record<RouteKeys, string> = {
  commissions: config.routes.commissions.list,
  invoices: config.routes.invoicing.list,
  carriers: config.routes.carriers.list,
  customers: config.routes.customers.list,
};

interface NotFoundPageProps {
/** Key of the home page to return to from the 404 page */
  routeKey: RouteKeys;
}

export const NotFoundPage: React.FC<NotFoundPageProps> = ({ routeKey }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBtnClick = () => {
    const url = routeMap[routeKey];
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.mainHeader}>404</div>
      <p className={styles.description}>{t('404page.description')}</p>

      <Button onClick={handleBtnClick} style={{ margin: 'auto' }}>
        {t('404page.buttonLabel')}
      </Button>
    </div>
  );
};
