import React from 'react';
import { Controller } from 'react-hook-form';
import { FormError, FormField, FormLabel, MultiSelect, type MultiSelectProps } from 'modules/ui';

export type MultiSelectFieldProps = Omit<MultiSelectProps, 'id' | 'selectedValues' | 'setSelectedValues'>;

export const MultiSelectField = ({ name, label, placeholder, ...props }: MultiSelectFieldProps) => {
    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => {
                return (
                    <FormField name={name}>
                        <FormLabel htmlFor={name}>{label}</FormLabel>
                        <MultiSelect
                            id={name}
                            error={!!error}
                            placeholder={placeholder}
                            label={label}
                            {...field}
                            {...props}
                            selectedValues={field.value}
                            setSelectedValues={(values) => {
                                field.onChange(values);
                            }}
                            ref={field.ref}
                        />
                        <FormError>{error?.message}</FormError>
                    </FormField>
                );
            }}
        />
    );
};
