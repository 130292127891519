import * as React from 'react';
import { useListBox, useOption } from 'react-aria';
import type { ListState } from 'react-stately';
import type { AriaListBoxOptions } from '@react-aria/listbox';
import type { Node } from '@react-types/shared';
import { CheckIcon } from 'assets/icons';
import classnames from 'classnames';

import styles from './ListBox.module.scss';

interface ListBoxProps extends AriaListBoxOptions<unknown> {
    listBoxRef?: React.RefObject<HTMLUListElement>;
    state: ListState<unknown>;
}

interface OptionProps {
    item: Node<unknown>;
    state: ListState<unknown>;
}

export const ListBox = (props: ListBoxProps) => {
    const ref = React.useRef<HTMLUListElement>(null);
    const { listBoxRef = ref, state } = props;
    const { listBoxProps } = useListBox(props, state, listBoxRef);

    return (
        <ul {...listBoxProps} ref={listBoxRef} className={styles['list-box']}>
            {[...state.collection].slice(0, 10).map((item) => (
                <Option key={item.key} item={item} state={state} />
            ))}
        </ul>
    );
};

const Option = ({ item, state }: OptionProps) => {
    const ref = React.useRef<HTMLLIElement>(null);
    const { optionProps, isSelected, isFocused } = useOption(
        {
            key: item.key,
        },
        state,
        ref,
    );

    return (
        <li
            {...optionProps}
            ref={ref}
            className={classnames(styles.option, {
                [styles['option-focused']]: isFocused,
                [styles['option-selected']]: isSelected,
            })}
        >
            {isSelected && <CheckIcon className={styles['option-indicator']} />}
            {item.rendered}
        </li>
    );
};
