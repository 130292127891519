import React, { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Language } from 'core/translation/i18next/i18next';
import { BaseFieldProps } from 'modules/form/types';
import { FormError, FormField, FormLabel, Input, type InputProps } from 'modules/ui';
import { getLocaleByLanguage } from 'utils/locale';

import styles from './NumberField.module.scss';

export type TNumberFieldProps = BaseFieldProps &
    Omit<InputProps, 'ref' | 'type'> & {
        nonDecimal?: boolean;
    };

export const NumberField: React.FC<TNumberFieldProps> = ({ name, label, placeholder, nonDecimal, ...props }) => {
    const { control } = useFormContext();
    const { i18n } = useTranslation();
    const {
        field: { onChange, value: currentFieldValue, ...fieldProps },
        fieldState: { error },
    } = useController({ control, name });

    const handleInputChange = (value: string) => {
        //? eliminate all non-numeric characters except comma and dot
        value = value.replaceAll(/[^0-9,.]/g, '');

        //? if nonDecimal is true, eliminate all decimal characters (paste proof)
        if (nonDecimal) {
            value = value.split(/[.,]/)[0];
        }

        if (value === '') {
            return onChange(null);
        }

        //? if last character is comma or dot, do not parse value to number, user is currently inserting decimal part
        if (/[.,]$/.test(value)) {
            return onChange(value);
        }

        //? this time is has to be number because we eliminated all non-numeric characters
        const valueAsNumber = Number(value.replaceAll(/,/g, '.'));
        if (!Number.isNaN(valueAsNumber)) {
            return onChange(valueAsNumber);
        }
    };

    const currentValue = useMemo(() => {
        //? if currentFieldValue is number, format it to locale string
        if (typeof currentFieldValue === 'number')
            return currentFieldValue.toLocaleString(getLocaleByLanguage(i18n.language as Language));

        return currentFieldValue || '';
    }, [currentFieldValue, i18n.language]);

    return (
        <FormField name={name}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Input
                id={name}
                error={!!error}
                placeholder={placeholder || label}
                onChange={({ target: { value } }) => handleInputChange(value)}
                value={currentValue}
                {...fieldProps}
                {...props}
            />
            <FormError>{error?.message}</FormError>
        </FormField>
    );
};
