import { T } from 'core/translation/types';
import { Currency } from 'modules/common';
import { locationCustomerSchema } from 'modules/customers';
import { AdminOfferStatusValue, EnquirySchema, enquirySchema } from 'modules/offers/types';
import { z } from 'zod';

// TODO: Update according to actual values.

export type PostEnquiryEmailResponse = {
    sentStates: Record<string, boolean>;
    enquiry: EnquirySchema;
};

export type DispatcherSearchParameters = {
    minLength: number;
    minHeight: number;
    minWeight: number;
    minWidth: number;
    vehicleFeaturesMustHaveAll: number[];
    vehicleFeaturesMustHaveOne: number[];
    vehicleType: number[];
};

export enum commissionLanguages {
    čeština = 'cs',
    angličtina = 'en',
    němčina = 'de',
}

const locationSchema = () =>
    z.object({
        city: z.string(),
        countryCode: z.string(),
        postalCode: z.string(),
        longitude: z.number(),
        latitude: z.number(),
    });

const sendInquirySchema = () =>
    z.object({
        to: z.array(z.object({ dispatcherId: z.number() })),
        dispatcher: z.object({
            name: z.string(),
            surname: z.string(),
            phone: z.string(),
            email: z.string(),
        }),
        body: z.object({
            cs: z.string(),
            en: z.string(),
            de: z.string(),
        }),
        parameters: z.object({
            minLength: z.number().nonnegative(),
            minHeight: z.number().nonnegative(),
            minWeight: z.number().nonnegative(),
            minWidth: z.number().nonnegative(),
            vehicleFeaturesMustHaveAll: z.array(z.number()),
            vehicleFeaturesMustHaveOne: z.array(z.number()),
            vehicleType: z.array(z.number()),
        }),
        subject: z.string(),
        commissionId: z.number(),
    });

// createOrderCarrier - Objednávka na dopravce
const sendCreateOrderCarrierPdfSchema = () =>
    z.object({
        lang: z.string(),
        qid: z.string(),
        carrierDispatcherName: z.string(),
        carrierCompany: z.string(),
        carrierDispatcherGsm: z.string(),
        carrierStreet: z.string(),
        carrierDispatcherEmail: z.string(),
        carrierCountry: z.string(),
        carrierPostalCode: z.string(),
        carrierCity: z.string(),
        carrierDriver: z.string(),
        carrierRegistrationNumber: z.string(),
        carrierGsm: z.string(),
        carrierTaxNumber: z.string(),
        carrierRegistrationPlate: z.string(),
        loadings: z.array(
            z.object({
                loadingNumber: z.number(),
                loadingDate: z.string().min(1),
                loadingTime: z.string(),
                loadingCompany: z.string(),
                loadingStreet: z.string(),
                loadingCountry: z.string(),
                loadingPostalCode: z.string(),
                loadingCity: z.string(),
                loadingGps: z.string(),
                loadingRefNumber: z.string(),
            }),
        ),
        discharges: z.array(
            z.object({
                dischargeNumber: z.number(),
                dischargeDate: z.string().min(1),
                dischargeTime: z.string(),
                dischargeCompany: z.string(),
                dischargeStreet: z.string(),
                dischargeCountry: z.string(),
                dischargePostalCode: z.string(),
                dischargeCity: z.string(),
                dischargeGps: z.string(),
                neutralization: z.boolean(),
            }),
        ),
        items: z.array(
            z.object({
                itemLoading: z.string(),
                itemDischarge: z.string(),
                itemName: z.string(),
                itemPackage: z.string(),
                itemQuantity: z.string(),
                itemSize: z.string(),
                itemLoadingMeters: z.string(),
                itemWeight: z.string(),
                itemWeightBrutto: z.string(),
            }),
        ),
        disposition: z.string().min(1),
        carrierPrice: z.number(),
        orderCurrency: z.string(),
        date: z.string().min(1),
        createdBy: z.string(),
        createdByPhoneNumber: z.string(),
    });

//  createOrderConfirmation - Potvrzení objednávky
const sendCreateOrderConfirmationEmailSchema = () =>
    z.object({
        to: z.string(),
        dispatcher: z.object({
            name: z.string(),
            surname: z.string(),
            phone: z.string(),
            email: z.string(),
        }),
        subject: z.string(),
        body: z.string(),
        itemName: z.string(),
        itemPackage: z.string(),
        itemWeight: z.string(),
        itemLoadingMeters: z.string(),
        loadingDate: z.string(),
        loadingAddress: z.string(),
        dischargeAddress: z.string(),
        dischargeDate: z.string(),
        carrierPrice: z.string(),
        driverPhone: z.string(),
        carrierRegistrationPlate: z.string(),
        qid: z.string(),
        lang: z.string(),
    });

const sendUniversalOrderEmailSchema = () =>
    z.object({
        to: z.string(),
        dispatcher: z.object({
            name: z.string(),
            surname: z.string(),
            phone: z.string(),
            email: z.string(),
        }),
        subject: z.string(),
        body: z.string(),
        qid: z.string(),
        lang: z.string(),
    });

// createLoadingConfirmation - Potvrzení nakládky
const sendCreateLoadingConfirmationPdfSchema = () =>
    z.object({
        lang: z.string(),
        qid: z.string(),
        loadingRefNumber: z.string(),
        customerCompany: z.string(),
        items: z.string(),
        loadingDate: z.string(),
        loadingTime: z.string(),
        carrierRegistrationPlate: z.string(),
        carrierDriver: z.string(),
        carrierGsm: z.string(),
    });

// createDeliveryConfirmation - Potvrzení doručení zboží
const sendCreateDeliveryConfirmationPdfSchema = () =>
    z.object({
        lang: z.string(),
        qid: z.string(),
        customerCompany: z.string(),
        items: z.string(),
        dischargeDate: z.string(),
        dischargeTime: z.string(),
        carrierRegistrationPlate: z.string(),
        carrierDriver: z.string(),
        carrierGsm: z.string(),
    });

// neutralization - Neutralizace
const sendCreateNeutralizationPdfSchema = () =>
    z.object({
        lang: z.string(),
        date: z.string(),
        qid: z.string(),
        dischargeNumber: z.number(),
        senderCompany: z.string(),
        senderStreet: z.string(),
        senderCountry: z.string(),
        senderPostalCode: z.string(),
        senderCity: z.string(),
        receiverCompany: z.string(),
        receiverStreet: z.string(),
        receiverCountry: z.string(),
        receiverPostalCode: z.string(),
        receiverCity: z.string(),
    });

const sendEmailSchema = () =>
    z.object({
        to: z.string(),
        dispatcher: z.object({
            name: z.string(),
            surname: z.string(),
            phone: z.string(),
            email: z.string(),
        }),
        body: z.string(),
        attachments: z.array(z.string()),
        lang: z.nativeEnum(commissionLanguages),
    });

export const commissionLoadingSchema = (t: T) =>
    z.object({
        // basic
        commissionLoading_id: z.number().optional(),
        commission_id: z.number().nullish(),
        date: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        dateTo: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        time: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        refNumber: z
            .string({
                required_error: t('form.errors.required'),
            })
            .nullable(),
        location_id: z
            .number({
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        location: locationCustomerSchema(t).partial().nullable(),

        number: z.number({}).nullable(),
    });

export const commissionDischargeSchema = (t: T) =>
    z.object({
        // basic
        commissionDischarge_id: z.number().optional(),
        commission_id: z.number().nullish(),
        date: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        dateTo: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        time: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        neutralization: z.boolean(),
        location_id: z
            .number({
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        location: locationCustomerSchema(t).partial().nullable(),

        number: z.number({}).nullable(),
    });

// Universal scheme for both loading and discharge
// const commissionLoadingDischargeSchema = (t: T) => commissionLoadingSchema(t).merge(commissionDischargeSchema(t));

export const commissionLoadingDischargeSchema = (t: T) =>
    commissionLoadingSchema(t).merge(commissionDischargeSchema(t)).extend({
        isLoading: z.boolean(),
        neutralization: z.boolean().optional(),
        refNumber: z.string().nullish(),
    });

const commissionGoodsSchema = (t: T) =>
    z.object({
        commissionItem_id: z.number().optional(),
        commissionLoading_id: z.number().nullable(),
        commissionDischarge_id: z.number().nullable(),
        // first row
        name: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        loadingIdx: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        dischargeIdx: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        quantity: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nonnegative({ message: t('form.errors.minValue', { value: 0 }) })
            .nullable(),
        weight: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nonnegative({ message: t('form.errors.minValue', { value: 0 }) })
            .nullable(),
        // second row
        weightBrutto: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nonnegative({ message: t('form.errors.minValue', { value: 0 }) })
            .nullable(),
        packaging: z.string().nullable(),
        loadingMeters: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nonnegative({ message: t('form.errors.minValue', { value: 0 }) })
            .nullable(),
        // third row - size is actually note
        size: z.string().nullable(),

        // the following properties does not exist when fetching from server, but are created manually
        commissionloading: commissionLoadingSchema(t).nullish(),
        commissiondischarge: commissionDischargeSchema(t).nullish(),
    });

const basicCommissionSchema = (t: T) =>
    z.object({
        // basic information
        relation: z.string().optional(),
        week: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        number: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        enteredCarrierByNumber: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        year: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        // commission status
        orderConfirmationSent: z.boolean(),
        carrierOrderSent: z.boolean(),
        loadingConfirmationSent: z.boolean(),
        dischargeConfirmationSent: z.boolean(),
        // customer
        customer_id: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        priceCustomer: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nonnegative({ message: t('form.errors.minValue', { value: 0 }) })
            .nullable(),
        currencyCustomer: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        invNumber: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        orderNumber: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        orderDate: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        customerContact_id: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        // carrier assignment
        carrier_id: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        dispatcher_id: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nullable(),
        enteredCarrierBy: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        carrierDriver: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        carrierGsm: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        carrierRegistrationPlate: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        priceCarrier: z
            .number({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.numberFormat'),
            })
            .nonnegative({ message: t('form.errors.minValue', { value: 0 }) })
            .nullable(),
        currencyCarrier: z.nativeEnum(Currency).nullable(),
        carrierInvoiceNumber: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        // other
        disposition: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        note: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullable(),
        notification: z.boolean(),
        // another
        commission_id: z.number().optional(),
        qid: z.string().nullish(),
        addedBy: z.string().optional(),
        editedBy: z.string().optional(),
        invoice_id: z.number().nullable(),
        vat: z.enum(['0', '21']),
        exchangeRateCustomer: z.number().nonnegative({ message: t('form.errors.minValue', { value: 0 }) }),
        exchangeRateCarrier: z.number().nonnegative({ message: t('form.errors.minValue', { value: 0 }) }),
        state: z.number(),
        // auxiliary properties
        totalloadingIdx: z.string().optional(),
        totaldischargeIdx: z.string().optional(),
        // when orderNumber is not "telefon" nor "e-mail", show this field
        fakeOrderNumber: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .optional(),
        // when priceCustomer is EUR, show these field
        priceCustomerInCZK: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .optional(),
        // when carrierCurrency is EUR, show these field
        priceCarrierInCZK: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .optional(),
        totalWeight: z.string().optional(),
        totalLoadingMeters: z.string().optional(),
    });

export const commissionSchema = (t: T) =>
    basicCommissionSchema(t).extend({
        // Goods
        commissionitem: z.array(commissionGoodsSchema(t)),
        // the following properties does not exist when fetching from server, but are created manually
        commissionLoadingDischarge: z.array(commissionLoadingDischargeSchema(t)),
    });

// actual server response, not suitable with the form
export const actualCommissionSchema = (t: T) =>
    basicCommissionSchema(t)
        .omit({
            invNumber: true,
        })
        .extend({
            priceCustomer: z.string().nullable(),
            priceCarrier: z.string().nullable(),
            exchangeRateCustomer: z.string(),
            exchangeRateCarrier: z.string(),
            // Goods
            commissionitem: z.array(
                commissionGoodsSchema(t)
                    .omit({
                        loadingIdx: true,
                        dischargeIdx: true,
                    })
                    .extend({
                        weight: z.string().nullable(),
                        weightBrutto: z.string().nullable(),
                        loadingMeters: z.string().nullable(),
                        quantity: z.string().nullable(),
                    }),
            ),
            // loadings
            commissionloading: z.array(
                commissionLoadingSchema(t).extend({
                    location: locationCustomerSchema(t)
                        .extend({
                            longitude: z.string(),
                            latitude: z.string(),
                        })
                        .nullable(),
                }),
            ),
            // discharges
            commissiondischarge: z.array(
                commissionDischargeSchema(t).extend({
                    location: locationCustomerSchema(t)
                        .extend({
                            longitude: z.string(),
                            latitude: z.string(),
                        })
                        .nullable(),
                }),
            ),
            enquiry: z.array(enquirySchema()),
        });

export const commissionExportSchema = (t: T) =>
    z.object({
        // commission status
        createOrderCarrier: z.boolean(),
        createOrderConfirmation: z.boolean(),
        createLoadingConfirmation: z.boolean(),
        createDeliveryConfirmation: z.boolean(),
        // document settings
        template: z.enum([
            'createOrderCarrier',
            'createOrderConfirmation',
            'createLoadingConfirmation',
            'createDeliveryConfirmation',
            'neutralization',
        ]),
        format: z.enum(['pdf', 'excel']),
        language: z.nativeEnum(commissionLanguages),
        companyName: z.string(),
        // email settings
        recipient: z
            .string()
            .min(1, { message: t('auth.email.error.required') })
            .email({
                message: t('auth.email.error.validEmail'),
            }),
        subject: z.string().min(10, { message: t('form.errors.minLengthOther', { minLength: 10 }) }),
        message: z.string().min(1, { message: t('auth.email.error.required', { minLength: 10 }) }),
    });

export const commissionsTableSchema = () =>
    z
        .object({
            addedBy: z.string(),
            commission_id: z.number(),
            state: z.number(),
            relation: z.string(),
            week: z.number(),
            number: z.number().nullable(),
            year: z.number(),
            customer_company: z.string().nullable(),
            loading_date: z.string().nullable(),
            loading_city: z.array(z.string()).nullable(),
            loading_zip: z.array(z.string()).nullable(),
            discharge_date: z.string().nullable(),
            discharge_city: z.array(z.string()).nullable(),
            discharge_zip: z.array(z.string()).nullable(),
            total_weight: z.string().nullable(),
            total_loading_meters: z.string().nullable(),
            priceCustomer: z.string().nullable(),
            invNumber: z.string().nullish(),
            carrier_company: z.string().nullable(),
            priceCarrier: z.string().nullable(),
            carrierInvoiceNumber: z.string().nullable(),
            provision: z.string().nullable(),
            enteredCarrierBy: z.string().nullable(),
            notification: z.boolean(),
            note: z.string().nullable(),
            customer_id: z.number().nullable(),
            invoice_id: z.number().nullable(),
            carrier_id: z.number().nullable(),
            currency: z.string().optional(),
            currencyCarrier: z.string(),
            qid: z.string().nullable(),
            carrierOrderSent: z.boolean(),
            orderConfirmationSent: z.boolean(),
            loadingConfirmationSent: z.boolean(),
            dischargeConfirmationSent: z.boolean(),
            oldid: z.string().nullable(),
            enquiry_id: z.number().nullable(),
            enquiryState: z.nativeEnum(AdminOfferStatusValue).nullable(),
        })
        .strict();

// used when fetching commission endpoint with invoiced = false parameter;
// this type uses "currency" and "number" instead of "currencyCustomer" and "commissionNumber"
export const notInvoicedCommission = () =>
    z.array(
        z.object({
            commission_id: z.number(),
            number: z.number(),
            orderDate: z.string(),
            loading_city_string: z.string(),
            discharge_city_string: z.string(),
            vat: z.string(),
            priceCustomerOriginal: z.string(),
            currency: z.nativeEnum(Currency),
            customer_company: z.string(),
            // used to fetch customer and then get defaultDueNumber
            customer_id: z.number(),
            loading_date: z.array(z.string()),
            discharge_date: z.array(z.string()),
            qid: z.string().optional(),
            state: z.number(),
        }),
    );

export const commissionsPageSchema = () =>
    z.object({
        data: z.array(commissionsTableSchema()),
        totalRows: z.number(),
    });

export const commissionSummarySchema = () =>
    z.object({
        totalPriceCustomer: z.number(),
        totalPriceCarrier: z.number(),
        totalProvision: z.number(),
    });

export type LocationSchema = z.infer<ReturnType<typeof locationSchema>>;
export type SendInquirySchema = z.infer<ReturnType<typeof sendInquirySchema>>;
export type SendUniversalOrderEmailSchema = z.infer<ReturnType<typeof sendUniversalOrderEmailSchema>>;
export type SendCreateOrderCarrierPdfSchema = z.infer<ReturnType<typeof sendCreateOrderCarrierPdfSchema>>;
export type SendCreateOrderConfirmationEmailSchema = z.infer<ReturnType<typeof sendCreateOrderConfirmationEmailSchema>>;
export type SendCreateLoadingConfirmationPdfSchema = z.infer<ReturnType<typeof sendCreateLoadingConfirmationPdfSchema>>;
export type SendCreateDeliveryConfirmationPdfSchema = z.infer<
    ReturnType<typeof sendCreateDeliveryConfirmationPdfSchema>
>;
export type SendCreateNeutralizationPdfSchema = z.infer<ReturnType<typeof sendCreateNeutralizationPdfSchema>>;
export type SendEmailSchema = z.infer<ReturnType<typeof sendEmailSchema>>;
export type CommissionLoadingSchema = z.infer<ReturnType<typeof commissionLoadingSchema>>;
export type CommissionDischargeSchema = z.infer<ReturnType<typeof commissionDischargeSchema>>;
export type CommissionLoadingDischargeSchema = z.infer<ReturnType<typeof commissionLoadingDischargeSchema>>;
export type CommissionGoodsSchema = z.infer<ReturnType<typeof commissionGoodsSchema>>;
export type BasicCommissionSchema = z.infer<ReturnType<typeof basicCommissionSchema>>;
export type CommissionSchema = z.infer<ReturnType<typeof commissionSchema>>;
export type ActualCommissionSchema = z.infer<ReturnType<typeof actualCommissionSchema>>;
export type CommissionExportSchema = z.infer<ReturnType<typeof commissionExportSchema>>;
export type CommissionsTableSchema = z.infer<ReturnType<typeof commissionsTableSchema>>;
export type NotInvoicedCommission = z.infer<ReturnType<typeof notInvoicedCommission>>;
export type CommissionsPageSchema = z.infer<ReturnType<typeof commissionsPageSchema>>;
export type CommissionSummarySchema = z.infer<ReturnType<typeof commissionSummarySchema>>;
