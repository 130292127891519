import { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { VisualFormInputsContext } from 'modules/form';

import styles from './FormField.module.scss';

export const FormField = ({ className, children, name, ...props }: React.ComponentProps<'div'> & { name: string }) => {
    const { setVisualInputsList } = useContext(VisualFormInputsContext);

    useEffect(() => {
        name && setVisualInputsList((prev) => [...prev, name]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classNames(className, styles.field)} {...props}>
            {children}
        </div>
    );
};
