import { Countries } from 'modules/common';
import { CustomerDetailSchema, LocationCustomerSchema } from 'modules/customers/types';
import { isNegative } from 'utils';

import {
    IGetLocationsChanges,
    ILocationCheckingValues,
    LocationCreate,
    LocationDelete,
    LocationUpdate,
    TransformedLocations,
} from './types';

export function transformLocations({
    locations,
    oldData,
}: {
    locations: LocationCustomerSchema[];
    oldData: CustomerDetailSchema;
}): TransformedLocations | undefined {
    const { added, changed, removed } = getLocationsChanges(oldData.location, locations);
    function modifyAddedLocations(items: LocationCustomerSchema[]): LocationCreate[] {
        return items.map(
            ({ location_id, ...item }) =>
                ({
                    ...item,
                    deleted: false,
                    gps: null,
                    longitude: Number(item.longitude),
                    latitude: Number(item.latitude),
                    country: item.countryCode ? Countries[item.countryCode] : item.countryCode,
                } as LocationCreate),
        );
    }
    function modifyUpdatedLocations(items: LocationCustomerSchema[]): LocationUpdate[] {
        return items.map(
            (item) =>
                ({
                    ...item,
                    deleted: false,
                    gps: null,
                    longitude: Number(item.longitude),
                    latitude: Number(item.latitude),
                    country: item.countryCode ? Countries[item.countryCode] : item.countryCode,
                } as LocationUpdate),
        );
    }
    function modifyRemovedLocations(items: LocationCustomerSchema[]): LocationDelete[] {
        return items.map(({ location_id }) => ({ location_id } as LocationDelete));
    }

    const template: TransformedLocations = {};
    const modificatedAddedLocations = added ? modifyAddedLocations(added) : null;
    const modificatedUpdatedLocations = changed ? modifyUpdatedLocations(changed) : null;
    const modificatedRemovedLocations = removed ? modifyRemovedLocations(removed) : null;

    // add only if they are not empty
    if (modificatedAddedLocations?.length) {
        template.toCreate = modificatedAddedLocations;
    }
    if (modificatedUpdatedLocations?.length) {
        template.toUpdate = modificatedUpdatedLocations;
    }
    if (modificatedRemovedLocations?.length) {
        template.toDelete = modificatedRemovedLocations;
    }
    return template;
}

const checkingLocationValues: ILocationCheckingValues[] = [
    'company',
    'countryCode',
    'postalCode',
    'city',
    'street',
    'longitude',
    'latitude',
    'loading',
    'discharge',
    'firstName',
    'lastName',
    'phone',
    'email',
    'note',
];
function getLocationsChanges(
    oldLocations: LocationCustomerSchema[],
    newLocations: LocationCustomerSchema[],
): IGetLocationsChanges {
    // "newLocations" does not mean vehicle "toCreate", but all locations which are in actual/current form.
    // unchanged locations do not need to be sent to the server.
    // please note new created locations have a negative random ID (-0.9537891247494177)

    // locations which are new, to create
    const added = newLocations.filter((item) => isNegative(item.location_id));
    // locations which are updated or unchanged
    const others = newLocations.filter((item) => !isNegative(item.location_id));

    const othersIDs = others.map((item) => item.location_id) as number[];
    // old locations which not exist in others
    const removed = oldLocations.filter((item) => (item.location_id ? !othersIDs.includes(item?.location_id) : false));

    // old locations which still exist
    const stayedOld = oldLocations.filter((item) =>
        !isNegative(item.location_id) ? othersIDs.includes(item?.location_id) : false,
    );
    // new locations which still exist
    const stayedNew = newLocations.filter((item) =>
        !isNegative(item.location_id) ? othersIDs.includes(item?.location_id) : false,
    );

    // updated locations - at least one value is changed
    const changed = stayedNew.filter((item, index) =>
        checkingLocationValues.some((value) => item[value] !== stayedOld[index][value]),
    );

    const template: IGetLocationsChanges = {};
    // add only if they are not empty
    if (added.length) {
        template.added = added;
    }
    if (changed.length) {
        template.changed = changed;
    }
    if (removed.length) {
        template.removed = removed;
    }
    return template;
}
