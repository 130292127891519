import type { IconProps } from './types';

export const IconExport: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} id="ico_export" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <rect id="Rectangle_6647" data-name="Rectangle 6647" width="24" height="24" fill="none" />
            <g id="Group_14468" data-name="Group 14468" transform="translate(-1412 -103)">
                <g id="Group_12115" data-name="Group 12115" transform="translate(1436 123) rotate(180)">
                    <path
                        id="Path_5501"
                        data-name="Path 5501"
                        d="M19.25,18.12a3,3,0,0,1-2.85,3.13H5.6a3,3,0,0,1-2.85-3.13"
                        transform="translate(23 21.37) rotate(180)"
                        fill="none"
                        stroke="#1e2124"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Path_5502"
                        data-name="Path 5502"
                        d="M12,3.75V15"
                        transform="translate(0 1)"
                        fill="none"
                        stroke="#1e2124"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Path_5503"
                        data-name="Path 5503"
                        d="M12,15l4-4"
                        transform="translate(0 1)"
                        fill="none"
                        stroke="#1e2124"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                    <path
                        id="Path_5504"
                        data-name="Path 5504"
                        d="M12,15,8,11"
                        transform="translate(0 1)"
                        fill="none"
                        stroke="#1e2124"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                    />
                </g>
            </g>
        </svg>
    );
};
