import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthRole } from 'core/auth/types';
import { useNonTypedTranslation } from 'core/translation';
import { VehicleTypes } from 'modules/carriers';
import { FormGrid, FormSection } from 'modules/form';
import { VehicleOptions } from 'modules/form/components/VehicleProperties';
import { getVehicleFeatureOptions } from 'modules/onboarding/utils/vehicleFeature';
import { FormLabel, Typography } from 'modules/ui';

import { VehicleParameterItem } from '../VehicleParameterItem';

import styles from './VehicleParametersSection.module.scss';

interface IVehicleParametersSectionProps {
    vehicleType?: number[];
    vehicleFeaturesMustHaveOne?: number[];
    vehicleFeaturesMustHaveAll?: number[];
    role: AuthRole;
    disableParametersEdit?: boolean;

    setShowModifyParametersModal?: (value: boolean) => void;
}

export const VehicleParametersSection: React.FC<IVehicleParametersSectionProps> = ({
    vehicleType,
    vehicleFeaturesMustHaveOne,
    vehicleFeaturesMustHaveAll,
    role,
    disableParametersEdit = false,
    setShowModifyParametersModal,
}) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();
    return (
        <FormSection
            title={
                role === AuthRole.USER
                    ? t('offers.form.parametersSection.vehicleParameters')
                    : t('offers.form.parametersSection.vehicleRequirements')
            }
            headerEndSlot={
                role === AuthRole.USER && (
                    <button
                        className={styles.editBtn}
                        onClick={() => setShowModifyParametersModal && setShowModifyParametersModal(true)}
                        type="button"
                        disabled={disableParametersEdit}
                    >
                        <Typography variant="p" color="primary">
                            {t('offers.form.parametersSection.modifyParameters')}
                        </Typography>
                    </button>
                )
            }
            className={styles.formSection}
        >
            <FormGrid columns={4}>
                <div>
                    <FormLabel className={styles.vehicleParametersLabel}>
                        {t('commissions.form.carrier.searchCarrier.form.vehicleType')}
                    </FormLabel>
                    <div className={styles.vehicleParameter}>
                        {vehicleType
                            ?.map((item) => tnt(`carriers.form.dispatchervehicle.vehicleTypes.${VehicleTypes[item]}`))
                            .join(', ')}
                    </div>
                </div>
                <div>
                    <FormLabel className={styles.vehicleParametersLabel}>
                        {t('commissions.form.carrier.searchCarrier.form.vehicleFeaturesMustHaveOne')}
                    </FormLabel>
                    <div className={styles.vehicleParameter}>
                        {getVehicleFeatureOptions(t)
                            .filter((item) => vehicleFeaturesMustHaveOne?.includes(item.value))
                            .map(({ label }) => label)
                            .join(', ')}
                    </div>
                </div>
                <div>
                    <FormLabel className={styles.vehicleParametersLabel}>
                        {t('commissions.form.carrier.searchCarrier.form.vehicleFeaturesMustHaveAll')}
                    </FormLabel>
                    <div className={styles.vehicleParameter}>
                        {getVehicleFeatureOptions(t)
                            .filter((item) => vehicleFeaturesMustHaveAll?.includes(item.value))
                            .map(({ label }) => label)
                            .join(', ')}
                    </div>
                </div>
            </FormGrid>
            <div className={styles.vehicleParametersBottom}>
                <FormLabel className={styles.vehicleParametersLabel}>
                    {role === AuthRole.USER && t('offers.form.parametersSection.vehicleParameters')}
                    {role === AuthRole.DISPATCHER && t('offers.form.parametersSection.cargoParameters')}
                </FormLabel>
                <FormGrid columns={4}>
                    {role === AuthRole.USER ? (
                        <>
                            <VehicleParameterItem
                                name="parameters.minLength"
                                option={VehicleOptions.carLength}
                                order="max"
                            />
                            <VehicleParameterItem
                                name="parameters.minWidth"
                                option={VehicleOptions.carWidth}
                                order="max"
                            />
                            <VehicleParameterItem
                                name="parameters.minWeight"
                                option={VehicleOptions.carLoad}
                                order="max"
                            />
                            <VehicleParameterItem
                                name="parameters.minHeight"
                                option={VehicleOptions.carHeight}
                                order="max"
                            />
                        </>
                    ) : (
                        <>
                            <VehicleParameterItem name="totalLoadingMeters" option={'totalLoadingMeters'} order="max" />
                            <VehicleParameterItem name="totalWeight" option={'totalWeight'} order="max" />
                        </>
                    )}
                </FormGrid>
            </div>
        </FormSection>
    );
};
