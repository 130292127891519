import { useEffect, useState } from 'react';
import { Label } from '@radix-ui/react-label';
import { Column } from '@tanstack/react-table';
import { OnboardingState, OnboardingStateProps } from 'modules/onboarding/components/OnboardingState';
import { Checkbox } from 'modules/ui';

import styles from './DispatchersStateFilter.module.scss';

export const DispatchersStateFilter = ({ column }: { column: Column<any, unknown> }) => {
    const [unregisteredChecked, setUnregisteredChecked] = useState(false);
    const [registeredChecked, setRegisteredChecked] = useState(false);
    const [pendingChecked, setPendingChecked] = useState(false);
    const [valuesInjected, setValuesInjected] = useState(false);

    useEffect(() => {
        const { unregistered, pending, registered } = JSON.parse(column.getFilterValue() as string || '{}');
        setUnregisteredChecked(unregistered || false);
        setPendingChecked(pending || false);
        setRegisteredChecked(registered || false);
        setValuesInjected(true);
    }, [column, column.getFilterValue]);

    const handleCheckboxChange = (value: boolean, state: OnboardingStateProps['state']) => {
        const stateHandler = {
            unregistered: setUnregisteredChecked,
            pending: setPendingChecked,
            registered: setRegisteredChecked,
        };
        stateHandler[state](value);
    };

    useEffect(() => {
        if(!valuesInjected) return;
        
        const { unregistered, pending, registered } = JSON.parse(column.getFilterValue() as string || '{}');
        if(column.getFilterValue() === undefined && !unregisteredChecked && !pendingChecked && !registeredChecked) return;
        if(unregistered === unregisteredChecked && pending === pendingChecked && registered === registeredChecked) return;

        if(!unregisteredChecked && !pendingChecked && !registeredChecked) return column.setFilterValue(undefined);
        
        column.setFilterValue(
            JSON.stringify({
                unregistered: unregisteredChecked,
                pending: pendingChecked,
                registered: registeredChecked,
            }),
        );
    }, [unregisteredChecked, registeredChecked, pendingChecked, column, valuesInjected]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.inputWrapper}>
                <Checkbox
                    id="unregistered"
                    checked={unregisteredChecked}
                    onCheckedChange={(value) => handleCheckboxChange(Boolean(value), 'unregistered')}
                />
                <Label htmlFor="unregistered">
                    <OnboardingState size="big" state="unregistered" />
                </Label>
            </div>
            <div className={styles.inputWrapper}>
                <Checkbox
                    id="pending"
                    checked={pendingChecked}
                    onCheckedChange={(value) => handleCheckboxChange(Boolean(value), 'pending')}
                />
                <Label htmlFor="pending">
                    <OnboardingState size="big" state="pending" />
                </Label>
            </div>
            <div className={styles.inputWrapper}>
                <Checkbox
                    id="registered"
                    checked={registeredChecked}
                    onCheckedChange={(value) => handleCheckboxChange(Boolean(value), 'registered')}
                />
                <Label htmlFor="registered">
                    <OnboardingState size="big" state="registered" />
                </Label>
            </div>
        </div>
    );
};
