import React, { useMemo } from 'react';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { IconInfo } from 'assets/icons';
import classNames from 'classnames';
import { useNonTypedTranslation } from 'core/translation';
import useChange from 'hooks/useChange';
import { DispatcherVehicleFeature, useGetVehicleFeaturesQuery, VehicleTypes } from 'modules/carriers';
import {
    CheckboxField,
    FormActionButton,
    FormCard,
    FormGrid,
    FormSection,
    MultiSwitchField,
    RenderArgs,
    VerticalFormArray,
} from 'modules/form';
import { VehicleFeatures } from 'modules/form/components/VehicleFeatures';
import { VehicleOptions, VehicleProperties } from 'modules/form/components/VehicleProperties';
import { getVehicleTypeOptions } from 'modules/onboarding/utils/vehicleType';
import { Typography } from 'modules/ui';

import { DispatcherVehicleSchema, OnboardingSchema } from '../../types';

import styles from './VehiclesInfoForm.module.scss';

const vehicleDefaultData = {
    maxLength: 1,
    maxWidth: 1,
    maxWeight: 3,
    maxHeight: 1,
    dispatchervehiclefeature: [],
};

export interface IVehicleInfoFormProps {
    viewSide: 'onboarding' | 'profile' | 'detail';
    methods: UseFormReturn<OnboardingSchema>;
}

export const VehiclesInfoForm = ({ viewSide, methods }: IVehicleInfoFormProps) => {
    const { t } = useTranslation();
    const { tnt } = useNonTypedTranslation();

    const { control, register, setValue, getValues } = methods;
    const { dispatcherVehicles, deliveryVehicle, soloVehicle, semiTrailerVehicle, combinationVehicle } = useWatch({
        control,
    });

    const vehiclesFieldArray = useFieldArray({
        control,
        name: 'dispatcherVehicles',
    });

    const vehicleIndexes = useMemo(() => {
        const indexes: { [vehicleType_id: number]: number } = {};
        vehiclesFieldArray.fields.forEach((vehicle, index) => {
            indexes[vehicle.vehicleType_id] = index;
        });
        return indexes;
    }, [vehiclesFieldArray.fields]);

    const vehicleTypesData = [
        {
            title: t('onboarding.vehicleInfoForm.deliveryVehicle'),
            vehicleType: 'deliveryVehicle',
            basicEquipmentText: t('onboarding.vehicleInfoForm.deliveryVehicleEquipment'),
            basicParamsText: t('onboarding.vehicleInfoForm.deliveryVehicleParams'),
            vehicleType_id: 1,
            name: 'deliveryVehicleInfo',
            infoText: (
                <Trans i18nKey={`onboarding.vehicleInfoForm.deliveryVehicleInfo`} components={{ strong: <strong /> }} />
            ),
        },
        {
            title: t('onboarding.vehicleInfoForm.soloVehicle'),
            vehicleType: 'soloVehicle',
            basicEquipmentText: t('onboarding.vehicleInfoForm.soloVehicleEquipment'),
            basicParamsText: t('onboarding.vehicleInfoForm.soloVehicleParams'),
            vehicleType_id: 2,
            name: 'soloVehicleInfo',
            infoText: (
                <Trans i18nKey={`onboarding.vehicleInfoForm.soloVehicleInfo`} components={{ strong: <strong /> }} />
            ),
        },
        {
            title: t('onboarding.vehicleInfoForm.semiTrailerVehicle'),
            vehicleType: 'semiTrailerVehicle',
            basicEquipmentText: t('onboarding.vehicleInfoForm.semiTrailerVehicleEquipment'),
            basicParamsText: t('onboarding.vehicleInfoForm.semiTrailerVehicleParams'),
            vehicleType_id: 3,
            name: 'semiTrailerVehicleInfo',
            infoText: (
                <Trans
                    i18nKey={`onboarding.vehicleInfoForm.semiTrailerVehicleInfo`}
                    components={{ strong: <strong /> }}
                />
            ),
        },
        {
            title: t('onboarding.vehicleInfoForm.combinationVehicle'),
            vehicleType: 'combinationVehicle',
            basicEquipmentText: t('onboarding.vehicleInfoForm.combinationVehicleEquipment'),
            basicParamsText: t('onboarding.vehicleInfoForm.combinationVehicleParams'),
            vehicleType_id: 4,
            name: 'combinationVehicleInfo',
            infoText: (
                <Trans
                    i18nKey={`onboarding.vehicleInfoForm.combinationVehicleInfo`}
                    components={{ strong: <strong /> }}
                />
            ),
        },
    ];

    // hooks to control the array of dispatcher vehicles
    useChange(() => {
        const index = getValues('dispatcherVehicles').findIndex((vehicle) => vehicle.vehicleType_id === 1);

        if (!deliveryVehicle || index !== -1) {
            return vehiclesFieldArray.remove(index);
        }

        vehiclesFieldArray.prepend(
            {
                vehicleType_id: 1,
                ...vehicleDefaultData,
            },
            {
                shouldFocus: false,
            },
        );
    }, [deliveryVehicle]);
    useChange(() => {
        const index = getValues('dispatcherVehicles').findIndex((vehicle) => vehicle.vehicleType_id === 2);

        if (!soloVehicle || index !== -1) {
            return vehiclesFieldArray.remove(index);
        }

        // if there is an item with belongs to 0 index (vehicle type 1), insert the vehicle after him, even if there is another vehicle
        if (vehicleIndexes[1]) {
            vehiclesFieldArray.insert(
                1,
                {
                    vehicleType_id: 2,
                    ...vehicleDefaultData,
                },
                {
                    shouldFocus: false,
                },
            );
            return;
        }
        // otherwise, add the item to the start
        vehiclesFieldArray.prepend(
            {
                vehicleType_id: 2,
                ...vehicleDefaultData,
            },
            {
                shouldFocus: false,
            },
        );
    }, [soloVehicle]);
    useChange(() => {
        const index = getValues('dispatcherVehicles').findIndex((vehicle) => vehicle.vehicleType_id === 3);

        if (!semiTrailerVehicle || index !== -1) {
            return vehiclesFieldArray.remove(index);
        }

        // if there is an item with belongs to 3 index (vehicle type 4), insert the vehicle before him, even if there is another vehicle

        if (vehicleIndexes[4]) {
            vehiclesFieldArray.insert(
                2,
                {
                    vehicleType_id: 3,
                    ...vehicleDefaultData,
                },
                {
                    shouldFocus: false,
                },
            );
            return;
        }
        // otherwise, add the item to the end
        vehiclesFieldArray.append(
            {
                vehicleType_id: 3,
                ...vehicleDefaultData,
            },
            {
                shouldFocus: false,
            },
        );
    }, [semiTrailerVehicle]);
    useChange(() => {
        const index = getValues('dispatcherVehicles').findIndex((vehicle) => vehicle.vehicleType_id === 4);
        if (!combinationVehicle || index !== -1) {
            return vehiclesFieldArray.remove(index);
        }

        vehiclesFieldArray.append(
            {
                vehicleType_id: 4,
                ...vehicleDefaultData,
            },
            {
                shouldFocus: false,
            },
        );
    }, [combinationVehicle]);

    const { data: vehicleFeatures = [] } = useGetVehicleFeaturesQuery(null);
    const vehicleTypesFeatures: number[][] = vehicleFeatures.map((vehicleType) =>
        vehicleType.vehicleFeatures.map((feature) => feature.vehicleFeature_id),
    );

    const vehicleTypeByIndex = (index: number) => {
        if (!dispatcherVehicles) return '';
        const item = dispatcherVehicles[index];
        const vehicleType = item?.vehicleType_id ? item.vehicleType_id : 1;
        return `${tnt(`carriers.form.dispatchervehicle.vehicleTypes.${VehicleTypes[vehicleType]}`)} ${index}`;
    };

    // reset all vehicle features when toggle vehicle type
    const onVehicleTypeToggle = (index: number) => {
        setValue(`dispatcherVehicles.${index}.dispatchervehiclefeature`, []);
    };

    // get vehicle features associated with specific vehicle type
    const possibleVehicleFeatures = (index: number) => {
        const item = dispatcherVehicles?.[index];
        const vehicleTypeId = item?.vehicleType_id ? item.vehicleType_id : 1;
        const possibleVehicleFeatures = vehicleTypesFeatures[vehicleTypeId - 1];
        return possibleVehicleFeatures;
    };

    return (
        <div className={styles.container}>
            {viewSide === 'onboarding' ? (
                <>
                    <FormSection title={t('onboarding.vehicleInfoForm.detailOfVehicleFleet.sectionTitle')}>
                        <div>
                            <Typography className={styles.label} variant="p">
                                {t('onboarding.vehicleInfoForm.detailOfVehicleFleet.availableVehicles')}
                            </Typography>
                            <FormGrid columns={4}>
                                <CheckboxField
                                    name="deliveryVehicle"
                                    label={t('onboarding.vehicleInfoForm.deliveryVehicle')}
                                />
                                <CheckboxField name="soloVehicle" label={t('onboarding.vehicleInfoForm.soloVehicle')} />
                                <CheckboxField
                                    name="semiTrailerVehicle"
                                    label={t('onboarding.vehicleInfoForm.semiTrailerVehicle')}
                                />
                                <CheckboxField
                                    name="combinationVehicle"
                                    label={t('onboarding.vehicleInfoForm.combinationVehicle')}
                                />
                            </FormGrid>
                        </div>
                    </FormSection>
                    {vehiclesFieldArray.fields.map((vehicle, index) => {
                        const vehicleType = vehicleTypesData[vehicle.vehicleType_id - 1];

                        return (
                            <FormSection key={vehicleType.title} title={vehicleType.title}>
                                <Typography className={styles.label} variant="p">
                                    {vehicleType.basicEquipmentText}
                                </Typography>
                                <FormGrid columns={4}>
                                    <VehicleFeatures
                                        key={vehicleType.vehicleType_id}
                                        name={`dispatcherVehicles.${index}.dispatchervehiclefeature`}
                                        {...{ control, register, setValue }}
                                        possibleVehicleFeatures={vehicleTypesFeatures[vehicleType.vehicleType_id - 1]}
                                    />
                                </FormGrid>
                                <Typography className={classNames(styles.label, styles.basicParamsText)} variant="p">
                                    {vehicleType.basicParamsText}
                                </Typography>
                                <div className={styles.infoContainer}>
                                    <IconInfo className={styles.infoIcon} color="#738291" />
                                    <Typography className={styles.infoText} variant="subheading">
                                        {vehicleType.infoText}
                                    </Typography>
                                </div>
                                <FormGrid columns={4}>
                                    <VehicleProperties
                                        name={`dispatcherVehicles.${index}.maxLength`}
                                        option={VehicleOptions.carLength}
                                        order="max"
                                    />
                                    <VehicleProperties
                                        name={`dispatcherVehicles.${index}.maxWidth`}
                                        option={VehicleOptions.carWidth}
                                        order="max"
                                    />
                                    <VehicleProperties
                                        name={`dispatcherVehicles.${index}.maxWeight`}
                                        option={VehicleOptions.carLoad}
                                        order="max"
                                    />
                                    <VehicleProperties
                                        name={`dispatcherVehicles.${index}.maxHeight`}
                                        option={VehicleOptions.carHeight}
                                        order="max"
                                    />
                                </FormGrid>
                            </FormSection>
                        );
                    })}
                </>
            ) : (
                <VerticalFormArray
                    {...vehiclesFieldArray}
                    name="dispatcherVehicles"
                    title={t('carriers.form.dispatchervehicle.sectionTitle')}
                    addTitle={t('carriers.form.dispatchervehicle.addVehicle')}
                    defaultValues={{
                        vehicleType_id: 1,
                        dispatchervehiclefeature: [] as DispatcherVehicleFeature[],
                        maxLength: 1,
                        maxWidth: 1,
                        maxWeight: 3,
                        maxHeight: 1,
                    }}
                    render={({
                        index,
                        getFieldProps,
                        remove,
                    }: RenderArgs<OnboardingSchema, DispatcherVehicleSchema>) => {
                        const vehicle = vehiclesFieldArray.fields[index];
                        const vehicleType = VehicleTypes[vehicle.vehicleType_id];
                        return (
                            <FormCard
                                title={vehicleTypeByIndex(index)}
                                headerEndSlot={
                                    <FormActionButton variant="danger" onClick={() => remove(index)}>
                                        {t('form.removeItem')}
                                    </FormActionButton>
                                }
                            >
                                <FormGrid columns={2}>
                                    <MultiSwitchField
                                        name={register(`dispatcherVehicles.${index}.vehicleType_id`).name}
                                        label={t('carriers.form.dispatchervehicle.vehicleType')}
                                        options={getVehicleTypeOptions(t)}
                                        onToggle={() => onVehicleTypeToggle(index)}
                                        isNumber
                                    />
                                </FormGrid>
                                <Typography className={styles.label} variant="p">
                                    {t('carriers.form.dispatchervehicle.equipment')}
                                </Typography>
                                <FormGrid columns={4} rows={4}>
                                    <VehicleFeatures
                                        name={`dispatcherVehicles.${index}.dispatchervehiclefeature`}
                                        {...{ control, register, setValue }}
                                        possibleVehicleFeatures={possibleVehicleFeatures(index)}
                                    />
                                </FormGrid>
                                <Typography className={styles.label} variant="p">
                                    {t('carriers.form.dispatchervehicle.parameters', {
                                        context: vehicleType,
                                        defaultValue: '',
                                    })}
                                </Typography>
                                <FormGrid columns={4}>
                                    <VehicleProperties
                                        {...getFieldProps('maxLength')}
                                        option={VehicleOptions.carLength}
                                        order="max"
                                    />
                                    <VehicleProperties
                                        {...getFieldProps('maxWidth')}
                                        option={VehicleOptions.carWidth}
                                        order="max"
                                    />
                                    <VehicleProperties
                                        {...getFieldProps('maxWeight')}
                                        option={VehicleOptions.carLoad}
                                        order="max"
                                    />
                                    <VehicleProperties
                                        {...getFieldProps('maxHeight')}
                                        option={VehicleOptions.carHeight}
                                        order="max"
                                    />
                                </FormGrid>
                            </FormCard>
                        );
                    }}
                />
            )}
        </div>
    );
};
