import { Controller } from 'react-hook-form';
import { FormField } from 'modules/ui';

import { AutocompleteInner } from './AutocompleteInner';

export interface AutocompleteProps<T> {
    options: T[];
    labelProperty: keyof T;
    valueProperty: keyof T;
    name: string;
    label?: string;
    placeholder?: string;
    isNumber?: boolean;
    isDisabled?: boolean;
    actionLabel?: string;
    actionIcon?: JSX.Element;
    actionOnClick?: (e: React.MouseEvent<HTMLElement>) => void;
    actionCloseOnClick?: boolean;
    onChange?: (value: T | null) => void;
}
export const Autocomplete = <T,>(props: AutocompleteProps<T>) => {
    return (
        <Controller
            name={props.name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={props.name}>
                    <AutocompleteInner<T>
                        {...props}
                        formValue={field.value}
                        errorMessage={error?.message}
                        fieldOnChange={field.onChange}
                        placeholder={props.placeholder}
                    />
                </FormField>
            )}
        />
    );
};
