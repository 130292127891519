import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import Backend from 'i18next-http-backend';

import transCs from '../../../../public/locales/cs/translation.json';
import transDe from '../../../../public/locales/de/translations.json';
import transEn from '../../../../public/locales/en/translations.json';

export enum Language {
    cs = 'cs',
    'cs-CZ' = 'cs-CZ',
    en = 'en',
    de = 'de',
}
export const supportedLanguages: Language[] = [Language.cs, Language['cs-CZ'], Language.de, Language.en];

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'cs',
        supportedLngs: supportedLanguages,
        debug: false,
        resources: {
            en: {
                translation: transEn,
            },
            cs: {
                translation: transCs,
            },
            de: {
                translation: transDe,
            },
        },
    });

export default i18n;
