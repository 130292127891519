import { User } from 'core/auth/types';
import { BasicCommissionSchema } from 'modules/commissions/types';

import { TransformedBasicDataSchema, transformedBasicDataSchema } from './types';

export function transformCommissionBasicData(
    basicData: BasicCommissionSchema,
    editedBy: User,
): TransformedBasicDataSchema {
    // basic commission data

    let orderNumber = basicData.orderNumber;

    if (basicData.orderNumber === 'číslo objednávky') {
        orderNumber = basicData.fakeOrderNumber ?? '—';
    } else if (basicData.orderNumber === 'nezadáno') {
        orderNumber = null;
    }

    const template: TransformedBasicDataSchema = {
        ...basicData,
        relation: basicData.relation || '',
        editedBy: editedBy.email,
        customer_id: Number(basicData.customer_id),
        customerContact_id: basicData.customerContact_id || undefined,
        orderDate: Number(basicData.orderDate),
        tsEdited: new Date().getTime(),
        priceCustomer: basicData.priceCustomer !== null ? String(basicData.priceCustomer) : null,
        priceCarrier: !basicData.priceCarrier ? null : String(basicData.priceCarrier),
        carrier_id: basicData.carrier_id,
        dispatcher_id: !basicData.carrier_id || !basicData.dispatcher_id ? null : basicData.dispatcher_id,
        orderNumber,
    };

    return transformedBasicDataSchema.parse(template);
}
