import type { IconProps } from './types';

export const CustomerAddressMarkIcon: React.FC<IconProps> = ({ ...props }) => {
    return (
        <svg width="24" height="32" viewBox="0 0 25 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.75 2.27734H12.7501C14.2605 2.2771 15.7561 2.57441 17.1516 3.15229C18.547 3.73018 19.815 4.57732 20.883 5.64533C21.951 6.71333 22.7981 7.98128 23.376 9.37675C23.9539 10.7722 24.2512 12.2679 24.251 13.7782V13.7783C24.251 14.9614 23.8308 16.3974 23.112 17.973C22.397 19.5405 21.4053 21.2056 20.3055 22.8354C18.1062 26.0946 15.502 29.1722 13.8945 30.984L13.8936 30.9849C13.7507 31.1469 13.575 31.2765 13.3782 31.3653C13.1814 31.4541 12.9679 31.5001 12.752 31.5001C12.536 31.5001 12.3225 31.4541 12.1257 31.3653C11.9289 31.2765 11.7532 31.1469 11.6103 30.9849L11.6097 30.9843C10.0029 29.1703 7.3982 26.0926 5.19808 22.8336C4.09792 21.2039 3.10587 19.539 2.39046 17.9715C1.67135 16.396 1.25098 14.9599 1.25098 13.7764C1.25098 10.7266 2.46248 7.80181 4.61896 5.64533C6.77545 3.48884 9.70027 2.27734 12.75 2.27734Z"
                fill="#356EF5"
                stroke="white"
            />
            <path
                d="M17.3584 17.3709H8.14355V12.3447L12.7508 7.94608L17.3584 12.3447V17.3709Z"
                stroke="white"
                strokeLinejoin="round"
            />
        </svg>
    );
};
