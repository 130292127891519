import { Avatar } from 'modules/common';

import styles from './AvatarGroup.module.scss';

const DEFAULT_LIMIT = 2;

export type AvatarGroupProps = {
    avatars: React.ReactNode[];
    limit?: number;
};

export const AvatarGroup: React.FC<AvatarGroupProps> = ({ avatars, limit = DEFAULT_LIMIT }) => {
    const shownAvatars = avatars.slice(0, limit);

    return (
        <ul className={styles.list}>
            {shownAvatars.map((avatar, index) => (
                <li key={index} className={styles.item}>
                    {avatar}
                </li>
            ))}
            {avatars.length > limit && (
                <li className={styles.item}>
                    <Avatar fallback={`+${avatars.length - limit}`} />
                </li>
            )}
        </ul>
    );
};
