import { User } from 'core/auth/types';
import { Subset } from 'types/helpers';

import { useEmailMessageSaverType } from '../hooks/useEmailMessageSaver';
import { SelectedDispatcher } from '../hooks/useSelectedDispatchers';
import { CommissionLoadingSchema, DispatcherSearchParameters } from '../types';

export const buildEnquiryEmailBody = (
    user: User,
    data: useEmailMessageSaverType['messages']['enquiry'],
    dispatchers: SelectedDispatcher[],
    loadings: Subset<CommissionLoadingSchema & { companyName: string }>[],
    discharges: Subset<CommissionLoadingSchema & { companyName: string }>[],
    commission_id: number,
    parameters: DispatcherSearchParameters
) => {
    const firstLoading = loadings[0];
    const lasDischarge = discharges[discharges.length - 1];

    return {
        to: dispatchers.map(({ dispatcherId }) => ({
            dispatcherId: dispatcherId,
        })),
        parameters,
        dispatcher: {
            name: user.name,
            surname: user.surname,
            phone: user.mobilePhone,
            email: user.email,
        },
        body: {
            cs: data?.cs || '',
            en: data?.en || '',
            de: data?.de || '',
        },
        subject: `${firstLoading?.location?.countryCode || '-'} ${firstLoading?.location?.city || '-'} -> ${
            lasDischarge?.location?.countryCode || '-'
        } ${lasDischarge?.location?.city || '-'}`,
        commissionId: commission_id,
    };
};
