import type { T } from 'core/translation/types';
import { Countries, CountryCodes, languageToLanguageId } from 'modules/common';
import { dispatcherVehicleSchema, onboardingSchema } from 'modules/onboarding/types';
import z from 'zod';

export const VehicleTypes: { [key: number]: string } = {
    1: 'wagon',
    2: 'solo',
    3: 'semi',
    4: 'set',
};

export const commissionCarrierSearch = (t: T) =>
    carrierNewSchema(t).pick({ carrier_id: true, company: true, note: true, place: true, dispatcher: true });

export const vehicleFeaturesList = () =>
    z.array(
        z.object({
            vehicleType_id: z.number(),
            type: z.string(),
            vehicleFeatures: z.array(
                z.object({
                    feature: z.string(),
                    vehicleFeature_id: z.number(),
                    dispatcherVehicleFeature_id: z.number().optional(),
                }),
            ),
        }),
    );

export const placeSchema = (t: T) =>
    z.object({
        city: z.string().min(1, { message: t('form.errors.required') }),
        street: z.string().min(1, { message: t('form.errors.required') }),
        country: z.nativeEnum(Countries),
        latitude: z.number(),
        longitude: z.number(),
        postalCode: z.string().min(1, { message: t('form.errors.required') }),
        countryCode: CountryCodes(t),
    });

export const dispatcherVehicleFeature = () =>
    z.object({
        vehicleFeature_id: z.number().optional(),
        // dispatcherVehicleFeature_id used for PUT and DELETE feature
        dispatcherVehicleFeature_id: z.number().optional(),
    });

export const carrierDispatcherSchema = (t: T) =>
    dispatcherSchema(t).extend({
        dispatcherVehicles: z.array(dispatcherVehicleSchema()).nullish(),
        places: z.array(carrierPlaceSchema(t)).nullish(),
    });

export const dispatcherSchema = (t: T) =>
    onboardingSchema(t)
        .omit({
            password: true,
            confirmPassword: true,
            carrier_id: true,
            deliveryVehicle: true,
            semiTrailerVehicle: true,
            soloVehicle: true,
            combinationVehicle: true,
            notificationEmail: true,
            notificationWhatsapp: true,
        })
        .extend({
            onboardingState: z.enum(['unregistered', 'registered', 'pending']).nullish(),
            lastRequestTimeSent: z.number().nullable(),
        });

export const vehicleSchema = (t: T) =>
    z.object({
        vehicleType: z.object({
            vehicleType_id: z.number().optional(),
        }),
        dispatcher_id: z
            .number({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullish(),
        dispatcherVehicle_id: z.number().optional(),
        dispatcher: z
            .string({
                required_error: t('form.errors.required'),
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .min(1, { message: t('form.errors.required') }),
        maxHeight: z.number(),
        maxWidth: z.number(),
        maxLength: z.number(),
        maxWeight: z.number(),
        dispatchervehiclefeature: z.array(dispatcherVehicleFeature()),
    });

const basicCarrierSchema = (t: T) =>
    z.object({
        // other
        carrier_id: z.number().optional(),
        addedBy: z.string().nullish(),
        editedBy: z.string().nullish(),
        phone: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
        email: z.string().nullish(),
        // basic info
        company: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
                required_error: t('form.errors.required'),
            })
            .min(2, { message: t('form.errors.required') }),
        countryCode: CountryCodes(t),
        postalCode: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
                required_error: t('form.errors.required'),
            })
            .min(1, { message: t('form.errors.required') }),
        city: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
                required_error: t('form.errors.required'),
            })
            .min(1, { message: t('form.errors.required') }),
        street: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
                required_error: t('form.errors.required'),
            })
            .min(1, { message: t('form.errors.required') }),
        companyRegistrationNumber: z
            .union([
                z
                    .string({
                        invalid_type_error: t('form.errors.stringFormat'),
                    })
                    .min(8, { message: t('form.errors.minLengthOther', { minLength: 8 }) }),
                z.string().length(0),
            ])
            .nullable(),
        taxId: z
            .string({
                invalid_type_error: t('form.errors.stringFormat'),
            })
            .nullish(),
        // Note
        note: z
            .string()
            // .min(2, { message: t('form.errors.minLengthFew', { minLength: 2 }) })
            .optional()
            .or(z.literal(''))
            .nullable(),
        place: placeSchema(t).partial(),
        // other
        country: z.nativeEnum(Countries),
        ts_added: z.number().nullable(),
        ts_edited: z.number().nullable(),
    });
export const carrierSchema = (t: T) =>
    basicCarrierSchema(t).extend({
        carrier_id: z.number(),
        addedBy: z.string(),
        countryCode: z.string(),
        editedBy: z.string(),
        email: z.string(),
        firstName: z.string(),
        lastName: z.string(),
        phone: z.string(),
        qid: z.number(),
        number: z.number(),
        ts_edited: z.string(),
        ts_added: z.string(),
        deleted: z.boolean(),
        dispatchersregistered: z.number(),
        dispatcherspending: z.number(),
        dispatchersunregistered: z.number(),
    });

export const carrierPlaceSchema = (t: T) =>
    z.object({
        place_id: z.number().nullish(),
        dispatcher_id: z.number().nullish(),
        country: z.nativeEnum(Countries),
        countryCode: CountryCodes(t),
        city: z.string(),
        postalCode: z.string(),
        latitude: z.number({
            invalid_type_error: t('form.errors.numberFormat'),
            required_error: t('form.errors.required'),
        }),
        longitude: z.number({
            invalid_type_error: t('form.errors.numberFormat'),
            required_error: t('form.errors.required'),
        }),
        directionLoading: z.boolean(),
        directionDischarge: z.boolean(),
        note: z.string().nullable(),
    });

export const carrierNewSchema = (t: T) =>
    basicCarrierSchema(t).extend({
        // dispatcher
        dispatcher: z.array(carrierDispatcherSchema(t)),
        // place
        place: placeSchema(t).partial(),
    });

export const carrierPageSchema = (t: T) =>
    z.object({
        data: z.array(carrierSchema(t)),
        totalRows: z.number(),
    });

export const languageSchema = () =>
    z.object({
        language_id: z.number(),
        languageCode: z.string().min(2),
    });

const getCarrierListSchema = () =>
    z.object({
        data: z.array(
            z.object({
                carrier_id: z.number(),
                company: z.string(),
            }),
        ),
        totalRows: z.number(),
    });

const postOnboardingEmailSchema = () =>
    z.object({
        to: z.array(
            z.object({
                email: z.string(),
                lang: z.enum(Object.keys(languageToLanguageId) as [string, ...string[]]),
                dispatcher_id: z.number().optional(),
            }),
        ),
        carrier_id: z.number(),
        dispatcher: z.object({
            email: z.string(),
            name: z.string(),
            phone: z.string(),
            surname: z.string(),
        }),
    });

export type CommissionCarrierSearch = z.infer<ReturnType<typeof commissionCarrierSearch>>;
export type VehicleFeaturesList = z.infer<ReturnType<typeof vehicleFeaturesList>>;
export type PlaceSchema = z.infer<ReturnType<typeof placeSchema>>;
export type DispatcherSchema = z.infer<ReturnType<typeof dispatcherSchema>>;
export type CarrierDispatcherSchema = z.infer<ReturnType<typeof carrierDispatcherSchema>>;
export type CarrierPlaceSchema = z.infer<ReturnType<typeof carrierPlaceSchema>>;
export type VehicleSchema = z.infer<ReturnType<typeof vehicleSchema>>;
export type CarrierSchema = z.infer<ReturnType<typeof carrierSchema>>;
export type CarrierPageSchema = z.infer<ReturnType<typeof carrierPageSchema>>;
export type CarrierNewSchema = z.infer<ReturnType<typeof carrierNewSchema>>;
export type DispatcherVehicleFeature = z.infer<ReturnType<typeof dispatcherVehicleFeature>>;
export type BasicCarrierSchema = z.infer<ReturnType<typeof basicCarrierSchema>>;
export type LanguageSchema = z.infer<ReturnType<typeof languageSchema>>;
export type GetCarrierListSchema = z.infer<ReturnType<typeof getCarrierListSchema>>;
export type PostOnboardingEmailSchema = z.infer<ReturnType<typeof postOnboardingEmailSchema>>;
