import React from 'react';
import { Controller } from 'react-hook-form';
import { IconHeight, IconLength, IconWeight } from 'assets/icons';
import classNames from 'classnames';
import { useNonTypedTranslation } from 'core/translation';
import { BaseFieldProps } from 'modules/form';
import { FormError, FormField, Typography } from 'modules/ui';

import styles from './VehicleParameterItem.module.scss';

// //? max values are strings because of input width with decimal numbers (input is one digit wider thanks to that)

const VehicleInfo = {
    carLength: { img: <IconLength />, unit: 'm' },
    carWidth: { img: <IconLength />, unit: 'm' },
    carLoad: { img: <IconWeight />, unit: 't' },
    carHeight: {
        img: <IconHeight />,
        unit: 'm',
    },
    totalLoadingMeters: { img: <IconLength />, unit: 'm' },
    totalWeight: { img: <IconWeight />, unit: 't' },
};

export type IVehicleParameterItemProps = Omit<BaseFieldProps, 'label'> & {
    option: 'carLength' | 'carWidth' | 'carLoad' | 'carHeight' | 'totalLoadingMeters' | 'totalWeight';
    order: 'max' | 'min';
};

export const VehicleParameterItem: React.FC<IVehicleParameterItemProps> = ({ name, option, order }) => {
    const { tnt } = useNonTypedTranslation();

    tnt(`carriers.form.dispatchervehicle.vehicleProperties.${option}`);

    return (
        <Controller
            name={name}
            render={({ field: { value = null }, fieldState: { error } }) => (
                <FormField name={name}>
                    <div
                        className={classNames(styles.field, {
                            [styles['field-error']]: error,
                        })}
                    >
                        <div className={styles['wrapper']}>
                            <div className={styles['image']}>{VehicleInfo[option].img}</div>
                            <label htmlFor={name}>
                                {tnt(`carriers.form.dispatchervehicle.vehicleProperties.${option}`, {
                                    order: order === 'max' ? 'Max.' : 'Min.',
                                })}
                            </label>
                            <Typography className={styles.value} variant="p" color="primary" fontWeight="bold">
                                {value ?? '-'} {VehicleInfo[option].unit}
                            </Typography>
                        </div>
                    </div>
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};
