import { createContext, useContext, useState } from 'react';

export type SelectedDispatcher = {
    dispatcherId: number;
};

type SelectedDispatchersContext = {
    selectedDispatchers: SelectedDispatcher[];
    setSelectedDispatchers: (ids: SelectedDispatcher[]) => void;
};

const SelectedDispatchersContext = createContext<SelectedDispatchersContext>(undefined!);

export interface SelectedDispatchersProviderProps {
    children: React.ReactNode;
}
export const SelectedDispatchersProvider = ({ children }: SelectedDispatchersProviderProps) => {
    const [selectedDispatchers, setSelectedDispatchers] = useState<SelectedDispatcher[]>([]);
    return (
        <SelectedDispatchersContext.Provider value={{ selectedDispatchers, setSelectedDispatchers }}>
            {children}
        </SelectedDispatchersContext.Provider>
    );
};

export const useSelectedDispatchers = () => {
    const { selectedDispatchers, setSelectedDispatchers } = useContext(SelectedDispatchersContext);
    return { selectedDispatchers, setSelectedDispatchers };
};
