import { Currency } from 'modules/common';
import { z } from 'zod';

// BASIC

export const transformedBasicDataSchema = z.object({
    // basic info
    relation: z.string(),
    week: z.number(),
    number: z.number().nullable(),
    enteredCarrierByNumber: z.number().nullable(),
    year: z.number(),
    // commission status
    orderConfirmationSent: z.boolean(),
    carrierOrderSent: z.boolean(),
    loadingConfirmationSent: z.boolean(),
    dischargeConfirmationSent: z.boolean(),
    // customer
    priceCustomer: z.string().nullable(),
    currencyCustomer: z.string().nullable(),
    // invNumber: z.number().nullable(),
    orderNumber: z.string().nullable(),
    orderDate: z.number(),
    customerContact_id: z.number().optional(),
    // carrier assignment
    carrier_id: z.number().nullable(),
    dispatcher_id: z.number().nullable(),
    enteredCarrierBy: z.string().nullable(),
    carrierDriver: z.string().nullable(),
    carrierGsm: z.string().nullable(),
    carrierRegistrationPlate: z.string().nullable(),
    priceCarrier: z.string().nullable(),
    currencyCarrier: z.nativeEnum(Currency).nullable(),
    carrierInvoiceNumber: z.string().nullable(),
    // other
    disposition: z.string().nullable(),
    note: z.string().nullable(),
    notification: z.boolean(),
    // another
    qid: z.string().nullish(),
    addedBy: z.string().optional(),
    editedBy: z.string().optional(),
    customer_id: z.number().optional(),
    loadingRadius: z.number().nullish(),
    dischargeRadius: z.number().nullish(),
    tsEdited: z.number(),
    vat: z.enum(['0', '21']),
    state: z.number(),
    exchangeRateCustomer: z.number(),
    exchangeRateCarrier: z.number(),
});

export type TransformedBasicDataSchema = z.infer<typeof transformedBasicDataSchema>;
