import { useEffect, useState } from 'react';
import { Column } from '@tanstack/react-table';
import { OfferStatePill } from 'modules/offers/components/OfferStatePill';
import { AdminOfferStatusValue } from 'modules/offers/types';
import { RadioGroup } from 'modules/ui';

import styles from "./OfferStateFilter.module.scss";

export interface IOfferStateFilterProps {
    column: Column<any, unknown>;
}
export const OfferStateFilter: React.FC<IOfferStateFilterProps> = ({ column }) => {
    const [checked, setChecked] = useState<AdminOfferStatusValue | undefined>(undefined);

    const columnFilterValue = column.getFilterValue() as AdminOfferStatusValue | undefined;

    useEffect(() => {
        setChecked(columnFilterValue);
    }, [column, columnFilterValue])

    const filterOptions = [
        {
            value: AdminOfferStatusValue.Opened,
            label: (
                <OfferStatePill state={AdminOfferStatusValue.Opened} />
            )
        },
        {
            value: AdminOfferStatusValue.Closed,
            label: (
                <OfferStatePill state={AdminOfferStatusValue.Closed} />
            )
        }
    ]

    const handleRadioChange = (value: string) => {
        setChecked(value as AdminOfferStatusValue);
        column.setFilterValue(value)
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.inputWrapper}>
                <RadioGroup
                    value={checked}
                    options={filterOptions}
                    onValueChange={handleRadioChange}
                />
            </div>
        </div>
    );
};
