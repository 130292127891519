import { useCallback, useEffect, useState } from 'react';
import React from 'react';
import { useImperativeHandle, useLayoutEffect } from 'react';
import { useButton, useComboBox, useFilter } from 'react-aria';
import { Controller } from 'react-hook-form';
import { Item, useComboBoxState } from 'react-stately';
import type { ComboBoxProps as ComboBoxBaseProps } from '@react-types/combobox';
import { ChevronDownIcon } from 'assets/icons';
import classnames from 'classnames';
import useChange from 'hooks/useChange';
import { debounce } from 'lodash';
import { ComboBoxPopover } from 'modules/combo-box/components/ComboBoxPopover';
import { ListBox } from 'modules/combo-box/components/ListBox';
import { BaseFieldProps } from 'modules/form/types';
import { FormError, FormField, FormLabel } from 'modules/ui';

import styles from './DebounceComboboxField.module.scss';

// this component was originally created from ComboboxField

type ComboBoxFieldProps<T extends object> = BaseFieldProps & Omit<ComboBoxProps<T>, 'onChange' | 'value'>;

export const DebounceComboboxField = <T extends object>({
    name,
    label,
    placeholder,
    isNumber = false,
    ...props
}: Omit<ComboBoxFieldProps<T>, 'options'>) => {
    return (
        <Controller
            name={name}
            render={({ field, fieldState: { error } }) => (
                <FormField name={name}>
                    <FormLabel htmlFor={name}>{label}</FormLabel>
                    <ComboBox
                        id={name}
                        error={!!error}
                        placeholder={placeholder || label}
                        {...field}
                        {...props}
                        onChange={(value) => {
                            field.onChange(isNumber && value !== null ? Number(value) : value);
                        }}
                        value={field.value ? String(field.value) : ''}
                        label={label}
                    />
                    <FormError>{error?.message}</FormError>
                </FormField>
            )}
        />
    );
};

type ComboBoxProps<T extends object> = Omit<ComboBoxBaseProps<T>, 'children'> & {
    id?: string;
    error?: boolean;
    onChange: (value: string | null) => void;
    onTypingFetchData: (value: string, fetchedData: (data: { label: string; value: string }[]) => void) => void;
    value: string;
    isValueALabel?: boolean;
    activeOptions?: { label: string; value: string }[];
    defaultOptions?: { label: string; value: string }[];
    isNumber?: boolean;
    withFilter?: boolean;
};

const ComboBoxInner = <T extends object>(
    {
        id,
        error,
        onChange,
        onTypingFetchData,
        onSelectionChange,
        activeOptions,
        value,
        isValueALabel = false,
        defaultOptions = [],
        withFilter,
        ...props
    }: ComboBoxProps<T>,
    ref: React.ForwardedRef<HTMLInputElement>,
) => {
    const [dropdownData, setDropdownData] = useState<
        {
            label: string;
            value: string;
        }[]
    >(defaultOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleTyping = useCallback(
        debounce((value) => {
            // value = to send current input value
            // data = data fetched according to input value
            onTypingFetchData(value, (data) => {
                setDropdownData(data);
            });
        }, 300),
        [],
    );

    useChange(() => {
        if (!activeOptions || !activeOptions.length) return;
        setDropdownData(activeOptions);
    }, [activeOptions]);

    const { contains } = useFilter({ sensitivity: 'base' });
    const state = useComboBoxState({
        ...props,
        defaultFilter: withFilter ? undefined : contains,
        defaultSelectedKey: value,
        children: dropdownData.map((option) => <Item key={option.value}>{option.label}</Item>),
    });
    const [popoverWidth, setPopoverWidth] = React.useState<number | undefined>(undefined);

    const buttonRef = React.useRef(null);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const listBoxRef = React.useRef(null);
    const popoverRef = React.useRef(null);

    const {
        inputProps,
        listBoxProps,
        buttonProps: triggerProps,
    } = useComboBox(
        {
            ...props,
            inputRef,
            buttonRef,
            listBoxRef,
            popoverRef,
        },
        state,
    );

    const { buttonProps } = useButton(triggerProps, buttonRef);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    useLayoutEffect(() => {
        const handleResize = () => {
            if (inputRef.current) {
                const { width } = inputRef.current.getBoundingClientRect();
                setPopoverWidth(width);
            }
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [inputRef]);

    // on first render set value
    useEffect(() => {
        if (isValueALabel) {
            state.setInputValue(value || '');
            return;
        }
        state.setSelectedKey(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    // on clicking the option set the string as value
    useEffect(() => {
        if (isValueALabel && state.inputValue !== '' && state.inputValue !== value) {
            // set the value to the from the list
            onChange(state.inputValue);
            onSelectionChange && onSelectionChange(state.selectedKey);
            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.inputValue]);

    // on clicking the options set the key as value
    useEffect(() => {
        if (isValueALabel) return;
        onChange(state.selectedKey === null || state.selectedKey === 'null' ? null : String(state.selectedKey));
        onSelectionChange && onSelectionChange(state.selectedKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.selectedKey]);

    return (
        <div className={styles['combo-box']}>
            <div className={styles['input-wrapper']}>
                <input
                    id={id}
                    {...inputProps}
                    onChange={(e) => {
                        // update the state of input
                        inputProps.onChange && inputProps.onChange(e);
                        // while typing, fetch options in parallel
                        handleTyping(e.target.value);
                        // while typing, set the value to form state in parallel
                        isValueALabel && onChange && onChange(e.target.value);
                    }}
                    value={isValueALabel ? value : inputProps.value}
                    ref={inputRef}
                    className={classnames(styles.input, {
                        [styles['input-error']]: error,
                    })}
                />
                <button {...buttonProps} ref={buttonRef} className={styles['input-icon']} type="button">
                    <ChevronDownIcon aria-hidden="true" />
                </button>
            </div>
            {state.isOpen && (
                <ComboBoxPopover
                    popoverRef={popoverRef}
                    triggerRef={inputRef}
                    state={state}
                    isNonModal
                    width={popoverWidth}
                    placement="bottom start"
                >
                    <ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
                </ComboBoxPopover>
            )}
        </div>
    );
};

const ComboBox = React.forwardRef(ComboBoxInner) as <T extends object>(
    props: ComboBoxProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> },
) => ReturnType<typeof ComboBoxInner>;
